import style from "./invite-emails.module.css";
import FormBubblesField from "./form-fields/form-bubbles-field";
import StyledButton from "./styled-button";
import React from "react";
import useStateValue from "../state/value";
import { InviteResponse } from "frontend/utils/invite-utils";
import { useInvite } from "frontend/hooks/use-invite";
import { CloseIcon } from "./svg-shapes";
import { getPathPrefix } from "../utils/getPathPrefix";

export default function InviteEmails({ source, documentId }: { source: string; documentId?: string }) {
  const [{ user }, dispatch] = useStateValue();
  const { handleInvite, inviteUsersResponse, emails, submittingEmail, highlightInvalidEmails, changeEmails } =
    useInvite();

  return (
    <div className={style.container}>
      {renderResponseStrip(
        inviteUsersResponse,
        `\n the user you invited would be added to your account and to  ${user?.account?.name} team`
      )}

      <div className={style.inputContainer}>
        <div className={style.fieldContainer}>
          <FormBubblesField
            id={"emails"}
            onChange={(e) => changeEmails(e, source)}
            value={emails}
            placeholder="Enter a list of emails to invite"
            highlightInvalid={highlightInvalidEmails}
          />
        </div>
        <div className={style.buttonContainer} data-testid="sendinvitationbutton">
          <StyledButton
            title={emails.length > 1 ? "Send Invitations" : "Send invite"}
            loading={submittingEmail}
            loadingTitle="Sending Invitations..."
            onClick={() =>
              handleInvite({
                source,
                dispatch,
                documentId,
                inviteActions: null,
              })
            }
            customStyle={{ height: "32px" }}
          />
        </div>
      </div>
    </div>
  );
}

export function InviteFailedEmailsList({ failedEmails }: { failedEmails: string[] }) {
  const failedCount = failedEmails.length;
  const failedEamilsString = failedEmails.join(", ");
  return (
    <div className={style.failedContainer}>
      <div className={style.failedStrip}>
        <div className={style.invitationFailed}>
          <CloseIcon color={"#F8746B"} style={{ marginTop: 2 }} />
          <div>
            <span>{`${failedCount} invitation${failedCount > 1 ? "s" : ""} failed!`}</span>
            <div className={style.failedEmails}>
              <span className={style.failedEmailRow}>{failedEamilsString}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={style.failedMessageContainer}>
        <img src={getPathPrefix("/images/error-info-icon.svg")} />
        <div className={style.failedMessage}>You don&apos;t have permission to invite new people to the account</div>
      </div>
    </div>
  );
}

export function renderResponseStrip(inviteUsersResponse: InviteResponse | null, additionalText: string = "") {
  if (inviteUsersResponse) {
    const invitedCount = inviteUsersResponse.usersCount;
    const failedEmails = inviteUsersResponse.failed;
    const failedCount = failedEmails.length;
    const responseStipe = [];

    if (invitedCount) {
      responseStipe.push(
        <div className={style.successStrip}>
          <img src={getPathPrefix("/images/green-checkmark.svg")} />
          <span>
            <p>{`${invitedCount} invitation${invitedCount > 1 ? "s" : ""} sent!`}</p>
            <p>{additionalText}</p>
          </span>
        </div>
      );
    }
    if (failedCount) {
      responseStipe.push(<InviteFailedEmailsList failedEmails={failedEmails} />);
    }
    return (
      <>
        {responseStipe.map((element, index) => (
          <div key={index}>{element}</div>
        ))}
      </>
    );
  }
  return null;
}
