import { ReactNode, useEffect } from "react";
import tracking from "frontend/tracking";
import { ButtonStyle } from "frontend/ui-components/styled-button";
import { useKey } from "react-use";
import consts from "shared/consts";
import { userAtom } from "state-atoms/users-atoms";
import { useAtomValue } from "jotai";
import Modal from "frontend/modal/modal";
import UpgradeModalContent from "./upgrade-modal-content";

export interface UpgradeModalNeededProps {
  trialDays: number;
  onDismiss: () => void;
  onUpgrade: () => void;
  insideModal: boolean;
}

export default function UpgradeModalGeneric({
  trackingEvent,
  title,
  replaceTitleWith,
  mainTitle,
  content,
  bulletpoints,
  trialDays,
  image,
  imageClassName = "",
  showCancelLine = true,
  showDismissButton = false,
  upgradeButtonText,
  upgradeButtonStyle = ButtonStyle.filled,
  onDismiss,
  onUpgrade,
  insideModal,
  cancelLineText,
}: {
  trackingEvent: string;
  title: React.ReactNode;
  replaceTitleWith?: React.ReactNode;
  mainTitle?: string;
  content: string;
  bulletpoints: string[];
  trialDays: number;
  image: string;
  imageClassName?: string;
  showCancelLine?: boolean;
  cancelLineText?: ReactNode;
  showDismissButton?: boolean;
  upgradeButtonText?: string;
  upgradeButtonStyle?: ButtonStyle;
  onDismiss: () => void;
  onUpgrade: () => void;
  insideModal: boolean;
}) {
  useKey("Escape", onDismiss);
  const user = useAtomValue(userAtom);

  const isDowngraded = user?.planInfo?.is_downgraded;

  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.BILLING,
      "upgrade-modal-shown",
      trackingEvent,
      user?.account?.id,
      user?.id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMainTitle() {
    if (mainTitle) {
      return mainTitle;
    }

    if (isDowngraded) {
      return "Upgrade To Canvas Pro";
    }

    return "Try Canvas Pro for free";
  }

  function getButtonText() {
    if (upgradeButtonText) {
      return upgradeButtonText;
    }

    if (isDowngraded) {
      return "Upgrade Now";
    }

    return `Try it free for ${trialDays} days`;
  }

  function renderContent() {
    const mainTitleProperty = getMainTitle();
    const buttonTextProperty = getButtonText();

    return (
      <UpgradeModalContent
        title={title}
        mainTitle={mainTitleProperty}
        content={content}
        bulletpoints={bulletpoints}
        image={image}
        imageClassName={imageClassName}
        showCancelLine={showCancelLine}
        cancelLineText={cancelLineText}
        upgradeButtonStyle={upgradeButtonStyle}
        buttonText={buttonTextProperty}
        onDismiss={onDismiss}
        onUpgrade={onUpgrade}
        showDismissButton={showDismissButton}
        replaceTitleWith={replaceTitleWith}
      />
    );
  }

  return insideModal ? (
    renderContent()
  ) : (
    <Modal dimBackground={true} onClickBackground={onDismiss}>
      {renderContent()}
    </Modal>
  );
}
