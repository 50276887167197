import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import { getPathPrefix } from "../../utils/getPathPrefix";

export default function UpgradeModalForCreateTemplate(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="create-template"
      title={<span>Upgrade to create your own templates</span>}
      content={"Create your own templates to save time and and share with your team."}
      bulletpoints={[
        "Create your own templates",
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      image={getPathPrefix("/images/upgrade-modal-images/upgrade-for-create-template.png")}
      {...props}
    />
  );
}
