import Konva from "konva";
import { Group, Rect, Text } from "react-konva";
import BaseElementComponent from "elements/base/base-element-component";
import consts from "shared/consts";
import { getTextColorForBackgroundColor } from "utils/color-utils";
import { useEffect, useRef, useState } from "react";
import { EditableText } from "frontend/editableText";
import { useEvent } from "react-use";
import { setMousePointer, unsetMouse } from "utils/mouse-cursor-utils";
import { differenceInDays, startOfDay } from "date-fns";
import { GanttOpenTaskCard } from "elements/gantt/icons/gantt-open-task-card";
import ganttConstant from "elements/gantt/constants";
import { GanttTaskCell } from "elements/gantt/layout-builder";
import useObservableController from "elements/hooks/use-observable-controller";
import { useAtomValue } from "jotai";
import { mouseStateAtom, transformerRefAtom } from "state-atoms";
import { trackGanttEvent } from "elements/gantt/utils";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { TaskCard, IntegrationItem } from "shared/datamodel/schemas";
import { cleanDate } from "shared/util/date-utils";
import useDebounceCallback from "frontend/hooks/use-debounce-callback";
import { GanttMondayIcon } from "elements/gantt/icons/gantt-monday-icon";

const START_END_SHIFT = 10;
const START_CORNER_RADIUS = [0, 5, 5, 0];
const END_CORNER_RADIUS = [5, 0, 0, 5];

export default function GanttTaskComponent({
  controller,
  isEditing,
}: {
  controller: IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>;
  isEditing: boolean;
}) {
  const mouseState = useAtomValue(mouseStateAtom);
  const transformerRef = useAtomValue(transformerRefAtom);

  const title = controller.getTitle();
  const [currentTitle, setCurrentTitle] = useState(title ?? "");

  const layout = controller.getLayout();
  const textRef = useRef<Konva.Text>(null);
  const padding = ganttConstant.taskCellPadding;
  const isMondayItem = controller.isIntegrated() && controller.id.includes(consts.CANVAS_ELEMENTS.INTEGRATION);

  const changeTitle = useDebounceCallback((title: string) => {
    if (!layout && !controller.isInitialLoading?.()) {
      return;
    }
    controller.changeTitle(title);

    trackGanttEvent("gantt_task_edited", {
      from: "gantt",
      field: "title",
    });
  }, 200);

  useObservableController(controller, () => {
    if (controller.isSelected()) {
      transformerRef?.current?.forceUpdate();
    }
  });

  const unsetMouseAction = (e?: any) => {
    const stage = Konva.stages[0];
    if (stage) {
      unsetMouse(e, stage);
    }
  };

  useEvent("keydown", (e) => {
    if (e.key === "Enter") {
      changeTitle(currentTitle);
      return;
    }

    if (e.key === "Escape") {
      unsetMouseAction(e);
      changeTitle(currentTitle);
    }
  });

  useEffect(() => {
    if (title !== currentTitle) {
      changeTitle(currentTitle);
    }
  }, [currentTitle]);

  useEffect(
    () => () => {
      setMousePointer("default");
    },
    []
  );

  useEffect(() => {
    setCurrentTitle(title);
  }, [title]);

  if (!layout) {
    return null;
  }

  const {
    height: textHeight,
    width: textWidth,
    newTitle,
  } = measureTextTitle({
    isEditing: isEditing,
    layout,
    title: currentTitle,
  });
  const background = controller.getBackgroundColor();
  const textColor = getTextColorForBackgroundColor(background);

  const startShift = layout.isCutStart ? -START_END_SHIFT : 0;
  const endShift = layout.isCutEnd ? START_END_SHIFT : 0;

  const cornerRadius = calculateCornerRadius(endShift, startShift);

  let width = layout.width - padding * 2;

  const height = layout.height - padding * 2;

  const endAmountDiff = getDaysDiff(controller.getEndDate() || cleanDate(new Date()), controller.getGanttEndDate());
  const startAmountDiff = getDaysDiff(
    controller.getGanttStartDate(),
    controller.getStartDate() || cleanDate(new Date())
  );

  width += Math.abs(startShift);
  width += Math.abs(endShift);

  if (controller.isInitialLoading?.() && mouseState === "unknown") {
    return null;
  }

  return (
    <Group
      onMouseLeave={() => {
        unsetMouseAction();
      }}
    >
      <BaseElementComponent
        x={layout.x + padding + startShift}
        y={layout.y + padding}
        id={controller.id}
        element={controller.element}
        controller={controller}
        isConnectable={false}
        isSelectable={true}
        isDraggable={false}
        type={consts.CANVAS_ELEMENTS.TASK_CARD}
      >
        <Rect
          id={`task-${controller.id}`}
          width={width}
          height={height}
          fill={ganttConstant.TasksColors[background][0]}
          cornerRadius={cornerRadius}
        />
        <Rect width={width} height={height} fill={"#FFFFFF80"} cornerRadius={cornerRadius} listening={false} />

        <Rect id={`task-${controller.id}`} width={8} height={height} fill={background} cornerRadius={[5, 0, 0, 5]} />
        {controller.isSelected() && (
          <Rect
            listening={false}
            width={width}
            height={height}
            fill={"transparent"}
            stroke="#00A1FF"
            cornerRadius={[5, 5, 5, 5]}
          />
        )}

        <EditableText
          x={padding + 8}
          y={padding - 5}
          width={textWidth}
          text={newTitle}
          fill={textColor}
          height={textHeight}
          fontSize={15}
          isEditing={isEditing}
          isFixedHeight={true}
          onChange={(newTextValue) => {
            setCurrentTitle(newTextValue);
          }}
          placeholder=""
          font={consts.DEFAULTS.FONT}
          onStopEditing={() => {
            unsetMouseAction();
          }}
        />

        {isMondayItem && <GanttMondayIcon x={width - padding * 3 - 2} y={height - padding * 2 - 2} />}

        {layout.isCutStart && startAmountDiff > 0 && (
          <Text
            id={`task-${controller.id}-startText`}
            x={padding}
            y={height - 20}
            width={width - padding * 2}
            text={`Started ${startAmountDiff} day${startAmountDiff > 1 ? "s" : ""} ago...`}
            fontFamily={"Poppins"}
            fill={textColor}
            height={20}
            ellipsis={true}
            ref={textRef}
            align="left"
            opacity={0.5}
          />
        )}
        {layout.isCutEnd && endAmountDiff > 0 && (
          <Text
            id={`task-${controller.id}-endText`}
            x={padding}
            y={height - 20}
            width={width - padding * 2}
            text={`${endAmountDiff} day${endAmountDiff > 1 ? "s" : ""} more...`}
            fontFamily={"Poppins"}
            fill={textColor}
            height={20}
            ellipsis={true}
            ref={textRef}
            align="right"
            opacity={0.5}
          />
        )}

        <Group x={width - 15} y={5} id={`task-${controller.id}-openTask`}>
          <GanttOpenTaskCard id={`task-${controller.id}-openTask`} />
        </Group>
      </BaseElementComponent>
    </Group>
  );
}

const getDaysDiff = (date1?: number | Date, date2?: number | Date) => {
  return Math.abs(differenceInDays(startOfDay(date1 ?? 0), startOfDay(date2 ?? 0)));
};

const calculateCornerRadius = (endShift: number, startShift: number) => {
  let cornerRadius: number | number[] = 5;
  if (endShift !== 0) {
    cornerRadius = END_CORNER_RADIUS;
  }
  if (startShift !== 0) {
    cornerRadius = START_CORNER_RADIUS;
  }

  return cornerRadius;
};

export const measureTextTitle = ({
  title = "",
  layout,
  isEditing,
}: {
  title: string;
  layout: GanttTaskCell;
  isEditing: boolean;
}) => {
  const width = layout.width - ganttConstant.taskCellPadding * 4 - 28;

  let height = layout.height - ganttConstant.taskCellPadding * 2;

  const amountOfRowsThatFit = Math.floor(height / ganttConstant.TaskTitleRowHeight);

  const ellipsedText = title
    .split("\n")
    .slice(0, amountOfRowsThatFit - 1)
    .concat("...")
    .map((line) => line.trim())
    .join("\n");

  const linesCount = title.split("\n").length;
  const newText = amountOfRowsThatFit >= linesCount ? title : ellipsedText;
  const textBlockHeight = Math.min(linesCount, amountOfRowsThatFit) * ganttConstant.TaskTitleRowHeight;

  height = !isEditing ? textBlockHeight : height - 8;

  return { width, height, newTitle: isEditing ? title : newText };
};
