type IndexedDbRequest = {
  objectStore: string;
};

export type IndexedDbDeleteRequest = IndexedDbRequest & {
  key: string;
};

export type IndexedDbGetRequest = IndexedDbRequest & {
  key: string;
  index?: string;
};

export type IndexedDbAddRequest = IndexedDbRequest & {
  value: any;
};

// Local storage
export function setLocalStorageItem(key: any, value: any, ttlInDays: number = 365) {
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const timestamp = new Date();
  const expirationTimeStamp = new Date();
  expirationTimeStamp.setHours(timestamp.getHours() + 24 * ttlInDays);
  const item = {
    value: value,
    expirationTimeStamp: expirationTimeStamp,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function isLocalStorageAvailable() {
  try {
    return typeof localStorage !== "undefined";
  } catch {
    return false;
  }
}

export function getLocalStorageItem(key: any) {
  try {
    let item = localStorage.getItem(key) as any;

    // if the item doesn't exist, return null
    if (!item) return null;

    item = JSON.parse(item);
    // compare the expiry time of the item with the current time
    if (item.expiry && Date.now() > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);

      return null;
    }

    return item.value;
  } catch {
    return null;
  }
}

export function addToLocalStorageArray(arrayName: string, value: any) {
  const existingObject = (getLocalStorageItem(arrayName) as Array<any>) || [];
  if (!existingObject.includes(value)) {
    existingObject.push(value);
    setLocalStorageItem(arrayName, existingObject, 1);
  }
}

export function removeItemFromLocalStorage(key: any) {
  localStorage.removeItem(key);
}
