import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

const ganttSplitRowSchema = z.object({
  id: z.string().describe("The unique identifier for the row."),
  title: z.string().describe("The title of the row."),
  parentRowId: z.string().nullish().describe("The ID of the parent row, if any."),
  color: z.string().optional().describe("The color of the row, if any."),
});

const ganttSplitSchema = z.object({
  id: z.string().describe("The unique identifier for the split."),
  type: z.enum(["custom"]).default("custom").describe("The type of the split, defaulting to 'custom'."),
  width: z.number().nullish().describe("The width of the split, if any."),
  rows: z.array(ganttSplitRowSchema).describe("An array of row objects within the split."),
});

const granularity = z.enum(["day", "week", "month", "quarter"]);

const connector = z.object({
  id: z.string(),
  type: z.enum(["custom", "monday"]).default("custom").describe("The type of the connector, defaulting to 'custom'."),
  from: z.string(),
  to: z.string(),
});

const schema = z.object({
  startDate: z.number().describe("The start date of the Gantt chart, represented as a timestamp."),
  endDate: z.number().describe("The end date of the Gantt chart, represented as a timestamp."),
  splits: z.array(ganttSplitSchema).describe("An array of split objects, each containing rows."),
  connectors: z.array(connector).optional().describe("An array of connector objects."),
  granularity: granularity
    .default("week")
    .describe("The granularity of the Gantt chart, can be 'day', 'week', 'month', or 'quarter', defaulting to 'day'."),
});

export const ganttSchema = canvasElementBaseSchema.merge(schema);

export type Granularity = z.infer<typeof granularity>;
export type GanttConnector = z.infer<typeof connector>;
export type GanttSplit = z.infer<typeof ganttSplitSchema>;
export type GanttSplitRow = z.infer<typeof ganttSplitRowSchema>;
export type GanttElement = z.infer<typeof ganttSchema>;
