import React, { useRef, useState } from "react";
import collaborationStyle from "./collaboration-modal.module.css";
import style from "./move-canvas-modal.module.css";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import DropdownPicker from "frontend/ui-components/picker/dropdown-picker";
import { useTeam } from "frontend/hooks/use-team";
import { Team } from "shared/datamodel/schemas/team";
import { useProject } from "frontend/hooks/use-project";
import { Project } from "shared/datamodel/schemas/project";
import { Board } from "shared/datamodel/schemas";
import useStateValue from "frontend/state/value";
import { moveBoard } from "frontend/services/boardsService";
import { InitialsIcon } from "frontend/ui-components/initialsIcon";

enum Step {
  chooseTeamProjectModal,
  approvePermissionChangeModal,
}

export default function MoveCanvasModals({
  board,
  currentProject,
  onDismiss,
  setCanvasMovingMessage,
}: {
  board: Board;
  currentProject: Project | null;
  onDismiss: () => void;
  setCanvasMovingMessage: (movingDetails: any) => void;
}) {
  const [, dispatch] = useStateValue();

  const [step, setStep] = useState<Step>(Step.chooseTeamProjectModal);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const modalRef = useRef(null);
  const { myTeams, getEditableTeamsForUser, getTeamName } = useTeam();
  const { getEditableProjectsForUser, getProjectName } = useProject();

  const editableTeamsForUser = getEditableTeamsForUser();
  const editableProjectsForUser: Project[] | [] = selectedTeam
    ? getEditableProjectsForUser(selectedTeam).filter((p) => p.id !== currentProject?.id)
    : [];

  const alertIcon = (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" id="alert">
      <g stroke="currentColor" strokeWidth="1.5" id="alert">
        <path
          d="M1 8.5C1 9.48491 1.19399 10.4602 1.5709 11.3701C1.94781 12.2801 2.50026 13.1069 3.1967 13.8033C3.89314 14.4997 4.71993 15.0522 5.62987 15.4291C6.53982 15.806 7.51509 16 8.5 16C9.48491 16 10.4602 15.806 11.3701 15.4291C12.2801 15.0522 13.1069 14.4997 13.8033 13.8033C14.4997 13.1069 15.0522 12.2801 15.4291 11.3701C15.806 10.4602 16 9.48491 16 8.5C16 6.51088 15.2098 4.60322 13.8033 3.1967C12.3968 1.79018 10.4891 1 8.5 1C6.51088 1 4.60322 1.79018 3.1967 3.1967C1.79018 4.60322 1 6.51088 1 8.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line x1="8.3916" y1="4.21387" x2="8.3916" y2="6.35672" />
        <line x1="8.3916" y1="4.21387" x2="8.3916" y2="6.35672" />
        <line x1="8.3916" y1="7.42816" x2="8.3916" y2="12.7853" />
        <line x1="8.3916" y1="7.42816" x2="8.3916" y2="12.7853" />
      </g>
    </svg>
  );

  const handleMoveCanvas = async () => {
    const newBoard = {
      ...board,
      updatedAt: new Date(),
      teamId: selectedTeam?.id ?? null,
      projectId: selectedProject?.id ?? null,
    };
    const response: any = await moveBoard(dispatch, newBoard, board);
    if (response?.status >= 200 && response?.status <= 300) {
      //only successful status codes
      const source = board.projectId
        ? `"${getProjectName(board.projectId)}"`
        : `"${getTeamName(board.teamId ?? myTeams[0].id)}"`;
      const destination =
        newBoard.teamId && newBoard.projectId
          ? `"${getProjectName(newBoard.projectId)}"`
          : newBoard.teamId
          ? `"${getTeamName(newBoard.teamId)}"`
          : `"${getTeamName(myTeams[0].id)}"`;
      const message = (
        <>
          <b>{`"${board.name}"`}</b>
          {" canvas has been moved from "}
          <b>{`${source}`}</b>
          {` ${board.projectId ? "project" : "team"} to `}
          <b> {`${destination}`}</b>
          {` ${newBoard.projectId ? "project" : "team"}`}
        </>
      );
      setCanvasMovingMessage(<span className={style.toastText}>{message}</span>);
    } else {
      setCanvasMovingMessage(
        <>
          <div className={style.icon}>{alertIcon}</div>
          <div className={style.errorContent}>
            <span className={style.errorTitle}>{"Oops! something went wrong."}</span>
            <span className={style.toastText}>{"We couldn't move your canvas, Please try again later"}</span>
          </div>
        </>
      );
    }
  };

  function renderCloseIcon() {
    return (
      <svg
        className={collaborationStyle.closeIcon}
        onClick={onDismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="#848199"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function optionRenderer(team: Team) {
    return {
      element: (
        <div className={style.optionRenderer}>
          <InitialsIcon text={team.name} list={myTeams} />
          {team.name}
        </div>
      ),
      enabled: true,
    };
  }

  function renderChooseTeamProjectModal() {
    return (
      <div className={style.container} ref={modalRef}>
        {renderCloseIcon()}
        <p className={style.title}>{`Move "${board.name}" canvas to:`}</p>
        <div>
          <p className={style.subtitle}>This canvas will automatically be shared with all project members.</p>
          <p className={style.note}>Only projects where you have editing rights are shown.</p>
        </div>
        <div className={style.picker}>
          <DropdownPicker
            placeholder="Select team"
            optionsPickerStyle={{ fontSize: 12, color: "#113357" }}
            options={editableTeamsForUser}
            titles={(team) => team.name}
            onChange={(team) => {
              setSelectedTeam(team);
            }}
            isSelected={(team) => team.id === selectedTeam?.id}
            enabled={true}
            optionRenderer={({ option: team }) => optionRenderer(team)}
          />
          <DropdownPicker
            placeholder="Select project"
            optionsPickerStyle={{
              fontSize: 12,
              color: editableProjectsForUser.length > 0 ? "#113357" : "#848199",
            }}
            options={editableProjectsForUser}
            titles={(project) => project.name}
            onChange={(project) => {
              setSelectedProject(project);
            }}
            isSelected={(project) => project.id === selectedProject?.id}
            enabled={editableProjectsForUser.length > 0}
          />
        </div>
        <StyledButton
          title={`Move Canvas`}
          style={ButtonStyle.filled}
          size={ButtonSize.regular}
          onClick={() => setStep(Step.approvePermissionChangeModal)}
          enabled={!!selectedTeam}
          customStyle={{ width: "127px" }}
        />
      </div>
    );
  }

  function renderApprovePermissionChangeModal() {
    return (
      <div className={style.container} ref={modalRef}>
        {renderCloseIcon()}
        <p className={style.title}>{`Change who has access?`}</p>
        <p className={style.subtitle}>
          {`Everyone who can see ”${board.name}” will lose access to this canvas unless they have access to the target project or team.`}
        </p>
        <div className={style.buttons}>
          <StyledButton
            title={`Move Canvas`}
            style={ButtonStyle.filled}
            size={ButtonSize.regular}
            onClick={() => {
              handleMoveCanvas();
              onDismiss();
            }}
            customStyle={{ width: "127px" }}
          />
          <StyledButton
            title={`Cancel`}
            style={ButtonStyle.filled}
            size={ButtonSize.regular}
            onClick={() => {
              onDismiss();
            }}
            enabled={!!selectedTeam}
            customStyle={{ width: "127px", backgroundColor: "#F4F7FA", color: "#113357", fontWeight: 400 }}
          />
        </div>
      </div>
    );
  }

  function renderMoveCanvasStep() {
    switch (step) {
      case Step.chooseTeamProjectModal: {
        return renderChooseTeamProjectModal();
      }
      case Step.approvePermissionChangeModal: {
        return renderApprovePermissionChangeModal();
      }
    }
  }

  return renderMoveCanvasStep();
}
