import classNames from "classnames/bind";
import style from "./option-card.module.css";

type OptionCardProps = {
  isSelected: boolean;
  imagePathName: string;
  title: string;
  onClick: () => void;
};

export default function OptionCard({ isSelected, onClick, imagePathName, title }: OptionCardProps) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(style.cardContainer, { [style.selected]: isSelected })}
      onClick={onClick}
      data-testid="option-card"
    >
      <img src={imagePathName} alt={title} className={style.cardImage} />
      <span className={style.cardTitle}>{title}</span>
    </div>
  );
}
