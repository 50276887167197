import { getPathPrefix } from "utils/getPathPrefix";
import style from "./logo.module.css";
import classNames from "classnames";
import { isInIframe } from "../utils/url-utils";

export default function AppLogo({ logoClicked }: { logoClicked?: () => void }) {
  const clickable = logoClicked !== undefined;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isInIframe()) {
      event.preventDefault(); // Prevent the default anchor behavior
      const url = getPathPrefix("/");
      window.open(url, "_blank"); // Open in a new tab
    } else if (logoClicked) {
      logoClicked();
    }
  };

  return (
    <a
      href={getPathPrefix("/")}
      className={classNames(style.logo, { [style.clickable]: clickable })}
      onClick={handleClick}
    >
      work<b>canvas</b>
      <small>.com</small>
    </a>
  );
}
