import Konva from "konva";
import { LineCap } from "konva/types/Shape";
import { ReactNode, useRef, useState } from "react";
import { Group, Line, Rect } from "react-konva";

export default function AddButton({
  width,
  height,
  hitWidth = width,
  hitHeight = height,
  children,
}: {
  width: number;
  height: number;
  hitWidth?: number;
  hitHeight?: number;
  children?: ReactNode;
}) {
  const iconSize = Math.min(12, Math.min(width, height) * 0.5);
  const ref = useRef<Konva.Group>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <Rect
        name="anchor ignore"
        x={-hitWidth / 2}
        y={-hitHeight / 2}
        width={hitWidth}
        height={hitHeight}
        onMouseEnter={() => {
          if (ref.current) {
            ref.current.to({ scaleX: 1.2, scaleY: 1.2, duration: 0.1, easing: Konva.Easings.EaseOut });
            ref.current.getChildren()[0].to({ fill: "#6DAFFF", duration: 0.1, easing: Konva.Easings.EaseOut });
            setShowTooltip(true);
          }
        }}
        onMouseLeave={() => {
          if (ref.current) {
            ref.current.to({ scaleX: 1, scaleY: 1, duration: 0.1, easing: Konva.Easings.EaseOut });
            ref.current.getChildren()[0].to({ fill: "#BFDCFF", duration: 0.1, easing: Konva.Easings.EaseOut });
            setShowTooltip(false);
          }
        }}
      />
      <Group ref={ref} listening={false}>
        <Rect x={-width / 2} y={-height / 2} width={width} height={height} cornerRadius={13} fill="#BFDCFF" />
        <PlusIcon size={iconSize} color="#0072FF" />
      </Group>
      {showTooltip ? children : null}
    </>
  );
}

function PlusIcon({
  size,
  color,
  width = 2,
  lineCap = "square",
}: {
  size: number;
  color: string;
  width?: number;
  lineCap?: LineCap;
}) {
  return (
    <>
      <Line
        points={[0, -size / 2, 0, size / 2]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
      <Line
        points={[-size / 2, 0, size / 2, 0]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
    </>
  );
}
