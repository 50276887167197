import React, { useLayoutEffect, createContext, useRef, useEffect, useCallback } from "react";
import { customAlphabet } from "nanoid";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";

// some parts of this file were shamelessly copied from react-hotkeys-hook
// I really like their approach, but I don't want all the scopes mechanism,
// and I want something suited for canvas, not html elements

const nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 5);

const stopPropagation = (e: KeyboardEvent): void => {
  e.stopPropagation();
  e.preventDefault();
  e.stopImmediatePropagation();
};

const useSafeLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

const HotkeysContext = createContext({} as any);

type KeyFilter = string | ((e: KeyboardEvent) => boolean);

interface KeyListener {
  id: string;
  iskey: KeyFilter;
  fn: { current: (e: KeyboardEvent) => boolean };
  opts: useHotkeyOptions;
}

export function HotkeysProvider({ children }: { children: any }) {
  const callbacks = useRef<KeyListener[]>([]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === undefined) {
        // Synthetic event (e.g., Chrome autofill).  Ignore.
        return;
      }
      // only catch events for canvas
      if (document.activeElement != document.body) {
        return;
      }

      for (const listener of callbacks.current) {
        const {
          disabled = false,
          allowRepeat = false,
          keyDown = true,
          keyUp = false,
          keyPress = false,
        } = listener.opts ?? {};

        // check the basics first
        if (disabled) continue;
        if (e.repeat && !allowRepeat) continue;
        if (e.type === "keydown" && !keyDown) continue;
        if (e.type === "keyup" && !keyUp) continue;
        if (e.type === "keypress" && !keyPress) continue;

        // check for key match
        const keyMatches =
          (typeof listener.iskey == "string" && listener.iskey === e.key) ||
          (typeof listener.iskey == "function" && listener.iskey(e));
        if (!keyMatches) continue;

        if (keyMatches && listener.fn.current(e)) {
          stopPropagation(e);
          break;
        }
      }
    };

    document.body.addEventListener("keydown", handler);
    document.body.addEventListener("keyup", handler);
    document.body.addEventListener("keypress", handler);
    return () => {
      document.body.removeEventListener("keydown", handler);
      document.body.removeEventListener("keyup", handler);
      document.body.removeEventListener("keypress", handler);
    };
  }, []);

  const register = useCallback((iskey, ref, opts) => {
    const id = nanoid();
    callbacks.current.push({ id, iskey, fn: ref, opts });
    return () => (callbacks.current = callbacks.current.filter((cb) => cb.id !== id));
  }, []);

  return <HotkeysContext.Provider value={register}>{children}</HotkeysContext.Provider>;
}

//====================================================================
//                                 useHotkey
//====================================================================

interface useHotkeyOptions {
  disabled?: boolean;
  allowRepeat?: boolean;
  keyDown?: boolean;
  keyUp?: boolean;
  keyPress?: boolean;
}

export function useHotkey(iskey: KeyFilter, callback: (e: KeyboardEvent) => boolean, opts: useHotkeyOptions = {}) {
  const register = React.useContext(HotkeysContext);

  const cbRef = useRef(callback);
  cbRef.current = callback;

  const memoisedOpts = useDeepEqualMemo(opts);

  //TODO: should re-register when iskey is string and is changed
  useSafeLayoutEffect(() => {
    if (opts?.disabled) return;
    return register(iskey, cbRef, opts);
  }, [memoisedOpts]);
}
