import { CSSProperties, useEffect } from "react";
import { Point } from "frontend/utils/math-utils";
import consts from "shared/consts";
import { FontProperties } from "shared/datamodel/schemas/textEnabled";
import { fontPropertiesToString, konvaTextDecoration } from "shared/util/utils";
import { FreeSizeTextEditor } from ".";
import { Group, Rect, Text } from "react-konva";
import Transform, { Degrees } from "frontend/utils/transform";
import { Connector } from "shared/datamodel/schemas";
import { KonvaEventObject } from "konva/types/Node";

export function ConnectorTextLabel({
  element,
  isEditing,
  position,
  textSize,
  updateText,
  updateTextSize,
  onTextDrag,
}: {
  element: Connector;
  isEditing: boolean;
  position: Point;
  textSize: { width: number; height: number };
  updateText: (initial: string, final: string) => void;
  updateTextSize: (width: number, height: number) => void;
  onTextDrag: (e: KonvaEventObject<DragEvent>) => void;
}) {
  const konvaTextProps = {
    fontFamily: element.font,
    lineHeight: consts.LINE_HEIGHT,
    fontSize: +(element.fontSize ?? consts.DEFAULTS.FONTSIZE),
    fontStyle: fontPropertiesToString(element.fontProps),
    textDecoration: konvaTextDecoration(element.fontProps),
    ellipsis: false,
    wrap: "word",
    perfectDrawEnabled: false,
    align: element.align ?? "center",
    verticalAlign: "middle",
  };

  const htmlTextProps: CSSProperties = {
    fontFamily: element.font,
    lineHeight: consts.LINE_HEIGHT,
    // fontSize: `${+(element.fontSize ?? consts.DEFAULTS.FONTSIZE)}px`,
    fontWeight: element.fontProps & FontProperties.Bold ? "bold" : "normal",
    fontStyle: element.fontProps & FontProperties.Italic ? "italic" : "normal",
    color: element.textColor ?? "black",
    textAlign: element.align ?? "center",
    verticalAlign: "middle",
    textDecoration: konvaTextDecoration(element.fontProps),
    wordBreak: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    padding: "2px 0",
    backgroundColor: "white",
  };

  function handleTextSizeChange() {
    if (element.text) {
      const spanElement = document.createElement("span");
      document.body.appendChild(spanElement);
      spanElement.textContent = element.text;
      Object.assign(spanElement.style, htmlTextProps);
      spanElement.style.fontFamily = element.font ?? consts.DEFAULTS.FONT;
      spanElement.style.fontSize = `${+(element.fontSize ?? consts.DEFAULTS.FONTSIZE)}px`;
      spanElement.style.position = "fixed";
      spanElement.style.width = "auto";
      spanElement.style.height = "auto";
      spanElement.style.padding = "0";
      const width = Math.max(spanElement.scrollWidth, 36) + 5;
      const height = spanElement.scrollHeight;
      updateTextSize(width, height);
      document.body.removeChild(spanElement);
    } else {
      updateTextSize(0, 0);
    }
  }
  // 1. update text size when font changes
  // 2. update text size on text change (from another user, or undo/redo)
  // but only when not editing because then the <TextEditor> is responsible for resize
  useEffect(() => {
    if (!isEditing) {
      document.fonts.ready.then(handleTextSizeChange);
    }
  }, [isEditing, element.text, element.fontSize, element.fontProps]);

  const { scaleX = 1, scaleY = 1, rotate = 0, x, y, fontSize, text, textColor } = element;
  // When drawing the text I don't want it scaled nor rotated.
  // I need to position it at the right place though, and the right place is the midpoint
  // of the line, transformed with scaling and rotation.

  const m = Transform.Point({ x: 0, y: 0, width: scaleX, height: scaleY, rotate: rotate as Degrees }, position);
  return isEditing ? (
    <FreeSizeTextEditor
      absX={x + m.x}
      absY={y + m.y}
      fontSize={+(fontSize ?? consts.DEFAULTS.FONTSIZE)}
      initialValue={text}
      updateText={updateText}
      updateTextSize={updateTextSize}
      textProps={htmlTextProps}
    />
  ) : text ? (
    <Group scaleX={1 / scaleX} scaleY={1 / scaleY}>
      <Group rotation={-rotate}>
        <Text
          x={m.x}
          y={m.y}
          offsetX={textSize.width / 2}
          offsetY={textSize.height / 2}
          width={textSize.width}
          height={textSize.height}
          fill={textColor ?? "black"}
          text={text}
          listening={false}
          {...konvaTextProps}
        />
        <Rect
          name="connector-text-label"
          x={m.x}
          y={m.y}
          offsetX={textSize.width / 2}
          offsetY={textSize.height / 2}
          width={textSize.width}
          height={textSize.height}
          fillEnabled={true}
          fill="transparent"
          draggable={true}
          onDragStart={onTextDrag}
          onDragMove={onTextDrag}
          onDragEnd={(e) => {
            onTextDrag(e);
            e.currentTarget.setPosition({ x: m.x, y: m.y }); // position the rect back on the text label
          }}
        />
      </Group>
    </Group>
  ) : null;
}
