import { z } from "zod";

export enum AccountCreatedFromSource {
  Unknown = "unknown",
  WorkCanvas = "workcanvas",
  Monday = "monday",
}

export const accountSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  logo: z.string().nullish(),
  createdFromSource: z.nativeEnum(AccountCreatedFromSource).optional(),
});

export function accountFromDBModel(obj: any): Account {
  return accountSchema.parse(obj);
}

export type Account = z.infer<typeof accountSchema>;
