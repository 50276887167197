import { useRef, useEffect, useState, useCallback } from "react";

interface UseInfiniteScrollProps {
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  failedLoadingUsers: boolean;
}

export default function useInfiniteScroll({
  loadMore,
  hasMore,
  isLoading,
  failedLoadingUsers,
}: UseInfiniteScrollProps) {
  const observer = useRef<IntersectionObserver | null>(null);
  const targetElement = useRef<HTMLDivElement | null>(null);
  const [hasIntersected, setHasIntersected] = useState(false);

  const handleIntersect = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        if (!hasIntersected && hasMore && !failedLoadingUsers && !isLoading) {
          await loadMore();
          setHasIntersected(true);
        }
      } else {
        setHasIntersected(false);
      }
    },
    [loadMore, hasMore, failedLoadingUsers, hasIntersected]
  );

  useEffect(() => {
    if (!hasMore || isLoading || failedLoadingUsers) return;

    observer.current = new IntersectionObserver(handleIntersect, {
      rootMargin: "100px",
    });

    const currentTarget = targetElement.current;
    if (currentTarget) {
      observer.current.observe(currentTarget);
    }

    return () => {
      observer.current?.disconnect();
    };
  }, [handleIntersect, hasMore, failedLoadingUsers]);

  return targetElement;
}
