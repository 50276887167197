import { User } from "shared/datamodel/schemas/user";
import style from "./share-user-model-cell.module.css";
import { UserRole, getUserRole } from "shared/datamodel/schemas/ability";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";

export default function ShareModalUserCell({ user }: { user: User }) {
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const userRole = user && getUserRole(user, applyRestrictedLimitation);

  return (
    <div className={style.container}>
      <img src={user.thumbnail} />
      <div className={style.innerContainer}>
        <div className={style.nameContainer}>
          <span className={style.name}>{user.name}</span>
          {userRole === UserRole.Restricted && <div className={style.free}>Free</div>}
        </div>
        <span className={style.email}>{user.pending ? "Invited" : user.email}</span>
      </div>
    </div>
  );
}
