import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const AlignHCenter = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M8.84789 12L8.84789 8L4 8V5L8.84789 5L8.84789 2.00001L10.2 2.00001L10.2 5L15 5V8L10.2 8L10.2 12H13V15H10.2L10.2 18H8.84789L8.84789 15H6L6 12H8.84789Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

AlignHCenter.displayName = "AlignHCenter";

export { AlignHCenter };
