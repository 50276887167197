import { useCallback, useEffect, useRef } from "react";

export enum CanvasKeyboardShortcut {
  delete = "delete; backspace",
  enter = "enter",
  esc = "escape",
  space = "space",
  keyUp = "up",
  undo = "meta+z",
  redo = "meta+shift+z; meta+y; f4",
  selectAll = "meta+a",
  zoomIn = "meta+=",
  zoomOut = "meta+-",
  resetZoom = "meta+0",

  left = "ArrowLeft",
  right = "ArrowRight",
  up = "ArrowUp",
  down = "ArrowDown",

  shiftLeft = "shift+ArrowLeft",
  shiftRight = "shift+ArrowRight",
  shiftUp = "shift+ArrowUp",
  shiftDown = "shift+ArrowDown",

  duplicate = "meta+d",
}

const AllowedRepeat = [
  CanvasKeyboardShortcut.left,
  CanvasKeyboardShortcut.right,
  CanvasKeyboardShortcut.up,
  CanvasKeyboardShortcut.down,
  CanvasKeyboardShortcut.shiftLeft,
  CanvasKeyboardShortcut.shiftRight,
  CanvasKeyboardShortcut.shiftUp,
  CanvasKeyboardShortcut.shiftDown,
];

const MODIFIERS = ["meta", "shift", "control", "alt"];

const ArrowKeys: { [key: string]: number } = {
  ArrowLeft: 1,
  ArrowRight: 2,
  ArrowUp: 4,
  ArrowDown: 8,
};

export function setKey(bitmap: number, key: string): number {
  if (key in ArrowKeys) {
    return bitmap | ArrowKeys[key];
  }
  return bitmap;
}

export function unsetKey(bitmap: number, key: string): number {
  if (key in ArrowKeys) {
    return bitmap & ~ArrowKeys[key];
  }
  return bitmap;
}

export function arrowKeysToVector(bitmap: number): [number, number] {
  let x = 0,
    y = 0;
  if (bitmap & ArrowKeys.ArrowLeft) x -= 1;
  if (bitmap & ArrowKeys.ArrowRight) x += 1;
  if (bitmap & ArrowKeys.ArrowUp) y -= 1;
  if (bitmap & ArrowKeys.ArrowDown) y += 1;
  return [x, y];
}

function isMatchingPattern(pattern: string, e: KeyboardEvent, key: string) {
  let keys = pattern.toLowerCase().split("+");
  const modifiersMatch =
    keys.includes("meta") == e.metaKey &&
    keys.includes("shift") == e.shiftKey &&
    keys.includes("control") == e.ctrlKey &&
    keys.includes("alt") == e.altKey;

  if (!modifiersMatch) return false;

  keys = keys.filter((key) => !MODIFIERS.includes(key));

  return (keys.length == 0 && MODIFIERS.includes(key)) || (keys.length > 0 && keys.includes(key));
}

function isKeyCommand(pattern: string, e: KeyboardEvent, key: string) {
  if (navigator.userAgent.indexOf("Macintosh") == -1) pattern = pattern.replaceAll("meta", "control");
  const patterns = pattern.replace(" ", "").split(";");
  for (const pattern of patterns) {
    if (isMatchingPattern(pattern, e, key)) {
      return true;
    }
  }
  return false;
}

// Check the keyboard event against the shortcut list
function findShortcut(e: KeyboardEvent): CanvasKeyboardShortcut | null {
  let key = e.key.toLowerCase();
  if (key == " ") key = "space";

  for (const shortcut of Object.values(CanvasKeyboardShortcut)) {
    if (isKeyCommand(shortcut, e, key)) {
      return shortcut;
    }
  }
  return null;
}

// We ignore keyboard shortcut if the target is an html text input element of any kind
function shouldIgnoreShortcut(): boolean {
  const activeElement = document.activeElement;
  if (activeElement) {
    return activeElement.tagName !== "BODY";
  }
  return false;
}

type KeyboardShortcutHookOptions = {
  enabled: boolean;
  capture: boolean;
  disablePropagation: boolean;
};

export function useKeyboardShortcuts(
  onKeyboardShortcut: (shortcut: CanvasKeyboardShortcut, e: KeyboardEvent, keyDown: boolean) => void,
  deps: any = [],
  options?: KeyboardShortcutHookOptions
) {
  const { capture = false, enabled = true, disablePropagation = true } = options || {};

  useEffect(() => {
    if (!enabled) return;
    window.addEventListener("keydown", handleKeyDown, capture);
    window.addEventListener("keyup", handleKeyUp, capture);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [...deps]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (shouldIgnoreShortcut()) return;
      const shortcut = findShortcut(e);
      if (shortcut) {
        disablePropagation && e.preventDefault(); // don't let the browser do the shortcut
        // don't trigger callback multiple times when key is held, except on some allowed keys
        if (!e.repeat || AllowedRepeat.includes(shortcut)) {
          onKeyboardShortcut(shortcut, e, true);
        }
      }
    },
    [onKeyboardShortcut]
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      onKeyboardShortcut(CanvasKeyboardShortcut.keyUp, e, false);
    },
    [onKeyboardShortcut]
  );
}

export interface ModifiersState {
  shift: boolean;
  alt: boolean;
  ctrl: boolean;
  meta: boolean;
  ctrlOrCmd: boolean;
}

export function useModifierKeys() {
  const modifiers = useRef<ModifiersState>({
    shift: false,
    alt: false,
    meta: false,
    ctrl: false,
    ctrlOrCmd: false,
  });
  useEffect(() => {
    function handleKey(e: KeyboardEvent) {
      modifiers.current.shift = e.shiftKey;
      modifiers.current.alt = e.altKey;
      modifiers.current.meta = e.metaKey;
      modifiers.current.ctrl = e.ctrlKey;
      modifiers.current.ctrlOrCmd = isMac() ? e.metaKey : e.ctrlKey;
    }

    window.addEventListener("keydown", handleKey);
    window.addEventListener("keyup", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
      window.removeEventListener("keyup", handleKey);
    };
  }, []);
  return modifiers.current;
}

export function isMac() {
  return /mac/i.test((navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform);
}

export function isWindows() {
  return /win/i.test((navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform);
}
