import { getTeam } from "frontend/api";
import { teamCacheAtom } from "state-atoms";
import { Team, TeamCache } from "shared/datamodel/schemas/team";
import { useGenericCache } from "./use-general-cache";

export function useTeamsCache() {
  const { getItems, isLoading } = useGenericCache<Team, TeamCache, "id">({
    cacheAtom: teamCacheAtom,
    fetchByIds: async (ids: string[]) => {
      const teams = await Promise.all(ids.map((id) => getTeam(id)));
      return teams;
    },
    entityName: "teams",
    entityKey: "team",
    keyField: "id",
  });

  return { getTeams: getItems, isLoading };
}
