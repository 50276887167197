import Konva from "konva";
import React, { useCallback, useEffect, useRef } from "react";
import { Group, Path, Rect } from "react-konva";

type GanttMonayConnectorProps = {
  onHover?: (isHover: boolean) => void;
};

export const GanttMonayConnector = ({ onHover: onConnectorHover }: GanttMonayConnectorProps) => {
  const shapeRef = useRef<Konva.Rect>(null);

  const onHover = useCallback(
    (isHover: boolean) => {
      return () => {
        onConnectorHover?.(isHover);
      };
    },
    [onConnectorHover]
  );

  useEffect(() => {
    shapeRef.current?.addEventListener("mouseover", onHover(true));
    shapeRef.current?.addEventListener("mouseout", onHover(false));

    return () => {
      shapeRef.current?.removeEventListener("mouseover");
      shapeRef.current?.removeEventListener("mouseout");
    };
  }, [onHover]);

  return (
    <Group x={-5} y={-5.5}>
      <Path
        data="M0 5.5C0 2.46243 2.46243 0 5.5 0H14.5C17.5376 0 20 2.46243 20 5.5C20 8.53757 17.5376 11 14.5 11H5.5C2.46243 11 0 8.53757 0 5.5Z"
        fill="#657E9A"
      />
      <Group x={5} y={1.5}>
        <Rect width={4.9} height={1.4} fill="white" cornerRadius={1} />
        <Rect width={4.9} height={1.4} x={2.1} y={2.797} fill="white" cornerRadius={1}></Rect>
        <Rect width={4.9} height={1.4} x={4.199} y={5.602} fill="white" cornerRadius={1}></Rect>
      </Group>
      <Rect width={20} height={11} ref={shapeRef} />
    </Group>
  );
};
