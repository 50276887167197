import { useAtom, useSetAtom } from "jotai";
import { Board, CanvasTemplate, BlankCanvasTemplate, Permission, User } from "shared/datamodel/schemas";
import { State, Team } from "shared/datamodel/schemas/team";
import { isBusyAtom, teamsAtom } from "state-atoms";
import { createProject as createProjectApi, deleteProject, editProject } from "frontend/services/projectsService";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import useStateValue from "frontend/state/value";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";
import { Project, UserProjectPermission } from "shared/datamodel/schemas/project";
import { moveBoard } from "frontend/services/boardsService";

export function useProject() {
  const setIsBusy = useSetAtom(isBusyAtom);
  const [myTeams, setMyTeams] = useAtom(teamsAtom);
  const [{ user }, dispatch] = useStateValue();

  const getSelectedTeam = (teamId: number): Team | undefined => {
    const selectedTeam = myTeams?.find((team: Team) => team.id === teamId);
    return selectedTeam;
  };

  async function createProject(name: string, users: User[] = [], teamId: number): Promise<Project> {
    const usersForDB: UserProjectPermission[] = users.map((user) => ({
      id: user.id,
      state: State.active,
      permission: user.permission,
    }));
    return await createProjectApi(name, usersForDB, teamId, dispatch);
  }

  function addProjectToSelectedTeam(project: Project, selectedTeam: Team) {
    const selectedTeamWithProject = {
      ...selectedTeam,
      projects: [...(selectedTeam?.projects?.filter((p) => p.id !== project.id) || []), project],
    };

    const filteredTeams = myTeams.filter((t) => t.id !== project.teamId);
    setMyTeams([...filteredTeams, selectedTeamWithProject]);

    const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
    setLocalStorageItem(
      "selectedTeamsAndProjects",
      selectedTeamsAndProjects.filter((item: any) => item.selectedTeam !== selectedTeam?.id)
    );

    addToLocalStorageArray("selectedTeamsAndProjects", {
      selectedTeam: selectedTeam?.id,
      selectedProject: project.id,
    });
  }

  async function addCanvasesToProject(
    projectId: number,
    canvases: Board[],
    createNewBoard?: (template: CanvasTemplate, projectId?: number) => void,
    newCanvasForProject?: boolean
  ) {
    if (newCanvasForProject && createNewBoard) {
      createNewBoard(BlankCanvasTemplate, projectId);
    } else {
      await Promise.all(
        canvases.map((board) => moveBoard(dispatch, { ...board, projectId: projectId, updatedAt: new Date() }, board))
      );
    }
  }

  // create project -->handleCreateProjectWithoutAddingCanvases
  async function handleCreateProjectWithoutAddingCanvases(
    selectedTeam: Team,
    name: string,
    users: User[] = []
  ): Promise<Project | undefined> {
    setIsBusy(true);
    try {
      const projectFromDB = await createProject(name, users, selectedTeam.id);

      if (projectFromDB) {
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.PROJECT_ACTION,
          "new_project_created",
          name,
          projectFromDB.accountId
        );

        addProjectToSelectedTeam(projectFromDB, selectedTeam);
        return projectFromDB;
      }
    } catch (error) {
      console.error(`Create Project Error for user ${user?.id}: `, error);
    } finally {
      setIsBusy(false);
    }
  }

  // create project --> name and teammates
  async function handleCreateProject(
    selectedTeam: Team,
    name: string,
    users: User[] = [],
    canvases: Board[] = [],
    createNewBoard?: (template: CanvasTemplate, projectId?: number) => void,
    newCanvasForProject?: boolean
  ) {
    setIsBusy(true);
    try {
      const projectFromDB = await createProject(name, users, selectedTeam.id);

      if (projectFromDB) {
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.PROJECT_ACTION,
          "new_project_created",
          name,
          projectFromDB.accountId
        );

        addProjectToSelectedTeam(projectFromDB, selectedTeam);

        await addCanvasesToProject(projectFromDB.id, canvases, createNewBoard, newCanvasForProject);
      }
    } catch (error) {
      console.error(`Create Project Error for user ${user?.id}: `, error);
    } finally {
      setIsBusy(false);
    }
  }

  // delete project
  async function handleDeleteProject(project: Project) {
    setIsBusy(true);
    try {
      await deleteProject(project, dispatch);
      tracking.trackEvent(consts.TRACKING_CATEGORY.PROJECT_ACTION, "delete_project", project.name, project.accountId);

      const selectedTeam = getSelectedTeam(project.teamId);
      const filteredTeams = myTeams.filter((t) => t.id !== project.teamId);
      const filteredProjects = selectedTeam?.projects?.filter((p) => project.id !== p.id);

      const newSelectedTeam = {
        ...selectedTeam,
        projects: filteredProjects,
      };

      const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
      setLocalStorageItem(
        "selectedTeamsAndProjects",
        selectedTeamsAndProjects.filter((item: any) => item.selectedProject !== project.id)
      );

      addToLocalStorageArray("selectedTeamsAndProjects", {
        selectedTeam: newSelectedTeam.id,
        selectedProject: filteredProjects && filteredProjects[0] ? filteredProjects[0]?.id : null,
      });

      setMyTeams([...filteredTeams, newSelectedTeam] as Team[]);
    } catch (error) {
      console.error(`Delete Project Error for user ${user?.id}: `, error);
    } finally {
      setIsBusy(false);
    }
  }

  // edit project name
  async function handleEditProjectName(name: string, project: Project) {
    try {
      const editedProject = await editProject(project, { name }, dispatch);
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.PROJECT_ACTION,
        "edit_project_name",
        project.name,
        project.accountId
      );

      const selectedTeam = getSelectedTeam(project.teamId);
      const filteredTeams = myTeams?.filter((team) => team.id !== selectedTeam?.id);

      const selectedTeamUpdateProjects =
        selectedTeam?.projects?.map((p) => (p.id === project.id ? editedProject : p)) ?? [];
      const newSelectedTeam = { ...selectedTeam, projects: selectedTeamUpdateProjects };

      setMyTeams([...filteredTeams, newSelectedTeam] as Team[]);
    } catch (error) {
      console.error(`Edit Project Name Error for user ${user?.id}: `, error);
    }
  }

  // edit members --> add and remove
  async function handleEditProjectMembers({
    project,
    users,
    state,
    showManageProjectModal = false,
  }: {
    project: Project;
    users: User[] | [];
    state: State | null;
    showManageProjectModal?: boolean;
  }) {
    if (!showManageProjectModal) {
      setIsBusy(true);
    }

    const selectedTeam = getSelectedTeam(project.teamId);

    try {
      const usersUpdatedState = users.map((user) => ({
        id: user.id,
        permission: user.permission ?? Permission.editor,
        state: state === State.deleted ? State.deleted : State.active,
      }));

      const editedProject = await editProject(project, { users: usersUpdatedState }, dispatch);

      if (editedProject) {
        tracking.trackEvent(
          consts.TRACKING_CATEGORY.PROJECT_ACTION,
          "edit_project_members",
          project.name,
          project.accountId
        );

        const filteredTeams = myTeams.filter((team) => team.id !== selectedTeam?.id);
        const selectedTeamUpdateProjects =
          selectedTeam?.projects?.map((p) => (p.id === project.id ? editedProject : p)) ?? [];
        const newSelectedTeam = { ...selectedTeam, projects: selectedTeamUpdateProjects };
        setMyTeams([...filteredTeams, newSelectedTeam] as Team[]);
      }
    } catch (error) {
      console.error(`Edit Project Members Error for user ${user?.id}: `, error);
    } finally {
      if (!showManageProjectModal) {
        setIsBusy(false);
      }
    }
  }

  // leave project
  async function handleLeaveProject(project: Project) {
    setIsBusy(true);
    const selectedTeam = getSelectedTeam(project.teamId);

    try {
      if (project.userProjectPermissions?.length === 1) {
        await handleDeleteProject(project);
      } else if (user) {
        const userPermission =
          project.userProjectPermissions?.find((teammate) => teammate.id.toString() === user.id)?.permission ??
          Permission.editor;
        const deletedPermission: UserProjectPermission = {
          id: user.id,
          state: State.deleted,
          permission: userPermission,
        };

        if (project.isOwner) {
          const newOwnerPermission = project.userProjectPermissions?.find(
            (teammate) => teammate.id.toString() !== user.id
          );
          const newOwnerId = newOwnerPermission?.id ?? null;

          if (newOwnerId) {
            await editProject(project, { users: [deletedPermission], newOwner: Number(newOwnerId) }, dispatch);
          }
        } else {
          await editProject(project, { users: [deletedPermission] }, dispatch);
        }

        tracking.trackEvent(consts.TRACKING_CATEGORY.PROJECT_ACTION, "leave_project", project.name, project.accountId);

        const filteredTeams = myTeams.filter((team) => team.id !== selectedTeam?.id);
        const filteredProjects = selectedTeam?.projects?.filter((p) => project.id !== p.id) ?? [];
        const newSelectedTeam = { ...selectedTeam, projects: filteredProjects };

        const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
        setLocalStorageItem(
          "selectedTeamsAndProjects",
          selectedTeamsAndProjects.filter(
            (item: any) => item.selectedProject !== project.id && item.selectedProject !== filteredProjects[0]?.id
          )
        );

        addToLocalStorageArray("selectedTeamsAndProjects", {
          selectedTeam: newSelectedTeam.id,
          selectedProject: filteredProjects[0]?.id ?? null,
        });

        setMyTeams([...filteredTeams, newSelectedTeam] as Team[]);
      }
    } catch (error) {
      console.error(`Leave Project Error for user ${user?.id}:`, error);
    } finally {
      setIsBusy(false);
    }
  }

  function isUserEditorInProject(project: Project, userId: string | undefined) {
    return project.userProjectPermissions.find((u) => {
      return String(u.id) === userId && u.permission === Permission.editor;
    });
  }

  function hasProjectEditorPermission(project: Project) {
    return project.isOwner || isUserEditorInProject(project, user?.id);
  }

  function getEditableProjectsForUser(team: Team): Project[] {
    return team.projects?.filter((p: Project) => hasProjectEditorPermission(p)) ?? [];
  }

  function getProjectName(projectId: number | null) {
    if (!projectId) return "";
    const project = getProjectById(projectId);
    if (!project) return "";
    return project.name;
  }

  function getProjectById(ProjectId: number): any {
    for (const team of myTeams) {
      const foundProject = team.projects?.find((project) => project.id === ProjectId);
      if (foundProject) {
        return foundProject;
      }
    }
    return null;
  }

  return {
    getSelectedTeam,
    handleCreateProject,
    handleDeleteProject,
    handleEditProjectName,
    handleEditProjectMembers,
    handleLeaveProject,
    getEditableProjectsForUser,
    getProjectName,
    hasProjectEditorPermission,
    getProjectById,
    handleCreateProjectWithoutAddingCanvases,
    addCanvasesToProject,
  };
}
