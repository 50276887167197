import { Group, Path } from "react-konva";
import { Point } from "shared/datamodel/schemas";
import * as PointUtils from "frontend/utils/point-utils";

const ArrowHeadNames = {
  NONE: "none",
  ARROW: "arrow",
  FILLED_LONG_ARROW: "filled-long-arrow",
  FILLED_WIDE_ARROW: "filled-wide-arrow",
  FILLED_ROUNDED_ARROW: "filled-rounded-arrow",
  LONG_ARROW: "long-arrow",
  WIDE_ARROW: "wide-arrow",
  ROUNDED_ARROW: "rounded-arrow",
} as const;

export type ArrowHeadType = typeof ArrowHeadNames[keyof typeof ArrowHeadNames];

type ArrowHeadPathSegment = { path: string; fill?: string | boolean; stroke?: boolean };

export const ArrowHeadDrawingData: Record<
  ArrowHeadType,
  { segments: ArrowHeadPathSegment[]; width: number; height: number }
> = {
  none: { segments: [], width: 0, height: 0 },
  arrow: {
    segments: [
      { path: "M1 1L8.88725 8.94241C8.94952 9.00511 8.94917 9.10642 8.88646 9.16869L1 17" },
      { path: "M2.8 9 L8 9" },
    ],
    width: 12,
    height: 16,
  },
  "filled-long-arrow": {
    segments: [
      {
        path: "M21.9138 7.6222C22.2719 7.74677 22.272 8.25324 21.9138 8.3778L0.531406 15.8152C0.271469 15.9056 -1.17761e-06 15.7126 -1.16558e-06 15.4374L-5.15389e-07 0.562634C-5.03359e-07 0.287422 0.271471 0.0944238 0.531408 0.184837L21.9138 7.6222Z",
        fill: true,
        stroke: false,
      },
    ],
    width: 22.75,
    height: 14,
  },
  "filled-wide-arrow": {
    segments: [
      {
        path: "M18.4 11.1536C18.6667 11.3076 18.6667 11.6925 18.4 11.8464L0.849999 21.9789C0.583333 22.1329 0.250001 21.9404 0.250001 21.6325L0.250002 1.3675C0.250002 1.05958 0.583336 0.867132 0.850002 1.02109L18.4 11.1536Z",
        fill: true,
        stroke: false,
      },
    ],
    width: 19,
    height: 21,
  },
  "filled-rounded-arrow": {
    segments: [
      {
        path: "M17.7529 11.4482L0.77312 21.9239C0.389551 22.1599 -0.0835528 21.8116 0.0126158 21.3634L2.97726 11.1123C2.99273 11.0386 2.99273 10.9614 2.97726 10.8877L0.0126167 0.636639C-0.0835518 0.188426 0.389552 -0.159932 0.773121 0.0760891L17.7529 10.5518C18.0824 10.7549 18.0824 11.2451 17.7529 11.4482Z",
        fill: true,
        stroke: false,
      },
    ],
    width: 18.25,
    height: 20,
  },
  "long-arrow": {
    segments: [{ path: "M0.75 1.05494L20.717 8L0.749999 14.9451L0.75 1.05494Z", fill: "white" }],
    width: 23.75,
    height: 14,
  },
  "wide-arrow": {
    segments: [{ path: "M1 1.97372L17.5 11.5L1 21.0263L1 1.97372Z", fill: "white" }],
    width: 20,
    height: 21.5,
  },
  "rounded-arrow": {
    segments: [
      {
        path: "M3.69773 11.3207L3.70552 11.2938L3.71128 11.2663C3.74806 11.091 3.74806 10.909 3.71128 10.7337L3.70552 10.7062L3.69773 10.6793L0.910635 1.04218L17.051 11L0.910634 20.9578L3.69773 11.3207ZM17.3593 11.1902C17.3591 11.1901 17.359 11.19 17.3588 11.1899L17.3591 11.1901L17.3593 11.1902ZM17.3589 10.8101C17.359 10.81 17.3592 10.8099 17.3593 10.8098L17.3591 10.8099L17.3589 10.8101Z",
        fill: "white",
      },
    ],
    width: 20,
    height: 20,
  },
};

export function getArrowheadIconRenderer(arrowHead: ArrowHeadType) {
  return ArrowHeadIcons[arrowHead];
}

const ArrowHeadIcons: Record<ArrowHeadType, () => JSX.Element> = {
  none: () => <></>,
  arrow: () => (
    <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L20 5" stroke="currentColor" />
      <path d="M17 1L20.8873 4.91444C20.9495 4.97714 20.9492 5.07845 20.8865 5.14071L17 9" stroke="currentColor" />
    </svg>
  ),
  "long-arrow": () => (
    <svg width="23" height="9" viewBox="0 -1 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4 L13 4" stroke="currentColor" />
      <path d="M20.6062 4L13.25 6.4269L13.25 1.5731L20.6062 4Z" stroke="currentColor" />
    </svg>
  ),
  "filled-long-arrow": () => (
    <svg width="23" height="9" viewBox="0 -1 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4 L13 4" stroke="currentColor" />
      <path d="M20.6062 4L13.25 6.4269L13.25 1.5731L20.6062 4Z" stroke="currentColor" fill="currentColor" />
    </svg>
  ),
  "wide-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L15 5" stroke="currentColor" />
      <path d="M15.5 1.91055L21.3862 5L15.5 8.08945L15.5 1.91055Z" stroke="currentColor" />
    </svg>
  ),
  "filled-wide-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L15 5" stroke="currentColor" />
      <path d="M15.5 1.91055L21.3862 5L15.5 8.08945L15.5 1.91055Z" fill="currentColor" stroke="currentColor" />
    </svg>
  ),
  "rounded-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L17 5" stroke="currentColor" />
      <path
        d="M21.995 5.01975L16.0285 9C16.0285 9 15.9951 9 16.0006 8.97424L17.0043 5.03907C17.0043 5.03907 17.0043 5.03263 17.0043 5.02619L16.0006 1.02661C16.0006 1.02661 16.0118 0.994411 16.0285 1.00085L21.995 4.9811C21.995 4.9811 22.0062 5.01331 21.995 5.01975Z"
        stroke="currentColor"
      />
    </svg>
  ),
  "filled-rounded-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L17 5" stroke="currentColor" />
      <path
        d="M21.995 5.01975L16.0285 9C16.0285 9 15.9951 9 16.0006 8.97424L17.0043 5.03907C17.0043 5.03907 17.0043 5.03263 17.0043 5.02619L16.0006 1.02661C16.0006 1.02661 16.0118 0.994411 16.0285 1.00085L21.995 4.9811C21.995 4.9811 22.0062 5.01331 21.995 5.01975Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  ),
};

export function ArrowHead({
  arrowHead,
  position,
  comingFrom,
  strokeWidth,
  strokeColor,
}: {
  arrowHead: ArrowHeadType;
  position: Point;
  comingFrom: Point;
  strokeWidth: number;
  strokeColor: string;
}) {
  if (PointUtils.distance(comingFrom, position) < 1) return null;
  const direction = PointUtils.vectorFromTo(comingFrom, position);
  PointUtils.normalize(direction, direction);

  const { segments, width, height } = ArrowHeadDrawingData[arrowHead];
  const angle = (Math.atan2(direction.y, direction.x) * 180) / Math.PI;

  const defaultStokeWidth = 5; // Second option on the slider
  const scale = strokeWidth / defaultStokeWidth;

  return (
    <Group
      x={position.x}
      y={position.y}
      rotation={angle}
      offsetX={width}
      offsetY={height / 2 + 1}
      scaleX={scale}
      scaleY={scale}
    >
      {segments.map(({ path, fill, stroke }, i) => (
        <Path
          key={i}
          data={path}
          fill={typeof fill === "string" ? fill : fill ? strokeColor : "transparent"}
          strokeEnabled={stroke !== false}
          stroke={strokeColor}
          strokeWidth={strokeWidth / scale}
          lineCap="round"
          lineJoin="round"
          name="connector-line-arrowhead"
        />
      ))}
    </Group>
  );
}
