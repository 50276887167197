import React, { useEffect, useState } from "react";
import { CanvasTemplate } from "shared/datamodel/schemas";
import style from "./side-menu-new.module.css";
import { Project } from "shared/datamodel/schemas/project";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import { getTeamsWithProjects } from "frontend/api";
import { TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY } from "shared/consts";
import ProjectList from "./projects-list";
import {
  AccountMembersButton,
  CreatePrivateCanvas,
  MyCanvasesButton,
  RecentCanvasesButton,
} from "./temporary-menu-components";

export function TeamHierarchy({
  selectedTeam,
  selectedProject,
  setSelectedProject,
  onEditSeats,
}: {
  selectedTeam: Team | null;
  selectedProject: Project | null;
  setSelectedProject: (project: Project | null) => void;
  onEditSeats: () => void;
}) {
  const [teamsWithProjects, setTeamsWithProjects] = useState<TeamWithProjects[]>([]);
  const [expandedTeams, setExpandedTeams] = useState<number[]>([]);

  useEffect(() => {
    const fetchTeamsWithProjects = async () => {
      const teamsWithProjects = await getTeamsWithProjects();
      setTeamsWithProjects(teamsWithProjects);
    };
    fetchTeamsWithProjects();
  }, []);

  const toggleTeam = (teamId: number) => {
    setExpandedTeams((previousTeams) =>
      previousTeams.includes(teamId) ? previousTeams.filter((id) => id !== teamId) : [...previousTeams, teamId]
    );
  };

  const handleProjectSelection = (project: Project) => {
    setSelectedProject(project);
    const selectedTeamsAndProjects = getLocalStorageItem(TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY);
    setLocalStorageItem(
      TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY,
      selectedTeamsAndProjects.filter(
        (item: { selectedTeam: number; selectedProject: number }) => item.selectedTeam !== selectedTeam?.id
      )
    );
    addToLocalStorageArray(TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY, {
      selectedTeam: selectedTeam?.id,
      selectedProject: project.id,
    });
  };

  function renderProjects(team: TeamWithProjects) {
    return (
      <>
        {expandedTeams.includes(team.id) && (
          <ProjectList
            key={team.id}
            team={team}
            selectedProject={selectedProject}
            handleProjectSelection={handleProjectSelection}
            onEditSeats={onEditSeats}
          />
        )}
      </>
    );
  }

  return (
    <div className="w-64 bg-white shadow-sm rounded-lg p-4">
      {teamsWithProjects.map((team) => (
        <div key={team.id} className="mb-2">
          <div
            onClick={() => toggleTeam(team.id)}
            className="flex items-center cursor-pointer py-1"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                toggleTeam(team.id);
              }
            }}
            role="button"
            tabIndex={0}
          >
            <span className="mr-2 text-gray-600">{expandedTeams.includes(team.id) ? "▼" : "▶"}</span>
            <span className="text-gray-800">{team.name}</span>
          </div>
          {renderProjects(team)}
        </div>
      ))}

      <div className="mt-4 pt-2 border-t border-gray-200">
        <button className="flex items-center text-blue-500 hover:text-blue-600">
          <span className="mr-1">+</span>
          Create new team
        </button>
      </div>
    </div>
  );
}

export default function SideMenuNew({
  selectedTeam,
  selectedProject,
  setSelectedProject,
  createNewBoard,
  onEditSeats,
  setShowAccountMembers,
  onMyCanvasesClick,
  onRecentCanvasesClick,
}: {
  selectedTeam: Team | null;
  selectedProject: Project | null;
  setSelectedProject: (project: Project | null) => void;
  createNewBoard: (template: CanvasTemplate, projectId?: number | null, teamId?: number | null) => void;
  onEditSeats: () => void;
  setShowAccountMembers: (show: boolean) => void;
  onMyCanvasesClick: () => void;
  onRecentCanvasesClick: () => void;
}) {
  return (
    <div className={style.container}>
      <div className={style.startContainer}>
        <AccountMembersButton setShowAccountMembers={setShowAccountMembers} />
        <CreatePrivateCanvas onCreateCanvas={createNewBoard} />
        <MyCanvasesButton onMyCanvasesClick={onMyCanvasesClick} />
        <RecentCanvasesButton onRecentCanvasesClick={onRecentCanvasesClick} />
      </div>
      <div className={style.centerContainer}>
        <div className={style.divider}></div>
        <TeamHierarchy
          selectedProject={selectedProject}
          selectedTeam={selectedTeam}
          setSelectedProject={setSelectedProject}
          onEditSeats={onEditSeats}
        />
      </div>
      <div className={style.endContainer}>
        <div className={style.divider}></div>
      </div>
    </div>
  );
}
