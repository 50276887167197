import style from "./projects-cell.module.css";
import styles from "./rename-project.module.css";
import { useRef, useState } from "react";
import Modal from "frontend/modal/modal";
import { useHover, useOnClickOutside } from "usehooks-ts";
import { Project } from "shared/datamodel/schemas/project";
import LeaveCollaborationModal from "frontend/modals/collaboration/leave-collaboration-modal";
import DeleteCollaborationModal from "frontend/modals/collaboration/delete-collaboration-modal";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import InlineRename, { InlineRenameStyle } from "./inlineRename";
import { CollaborationType, InviteMode } from "state-atoms/teams-atoms";
import { useProject } from "frontend/hooks/use-project";
import NoTeammatesToInviteModal from "frontend/modals/collaboration/project/no-teammates-to-invite-modal";
import InviteModal from "frontend/modals/invite-modal";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import ManageCollaborationMembers from "frontend/modals/collaboration/manage-collaboration-members";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Team } from "shared/datamodel/schemas/team";

export function ProjectCellNew({
  parentTeam,
  project,
  isSelected,
  onClick,
  onEditSeats,
}: {
  parentTeam: Team;
  project: Project;
  isSelected: boolean;
  onClick: () => void;
  onEditSeats: () => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [focused, setFocused] = useState(false);
  const [projectName, setProjectName] = useState(project.name);
  const [showInviteProjectMembersModal, setShowInviteProjectMembersModal] = useState(false);
  const [showManageProjectModal, setShowManageProjectModal] = useState(false);
  const [showLeaveCollaborationModal, setShowLeaveCollaborationModal] = useState(false);
  const [showDeleteCollaborationModal, setShowDeleteCollaborationModal] = useState(false);
  const [showNoTeammatesToInviteMessage, setShowNoTeammatesToInviteMessage] = useState(false);

  const { handleEditProjectName } = useProject();

  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement | null>(null);
  const hover = useHover(ref);

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  function menuClicked(e: any) {
    e.stopPropagation();
    setShowMenu((show) => !show);
  }

  function renameClicked(e: any) {
    e.stopPropagation();
    setShowMenu(false);
    setFocused(true);
  }

  const projectMenuOwnerOptions = () => {
    return (
      <>
        <span
          data-testid="invite-members"
          className={style.item}
          onClick={() => {
            tracking.trackEvent(
              consts.TRACKING_CATEGORY.ADMIN_ACTION,
              "invite_to_project_button_clicked",
              "invite_type",
              "account"
            );
            setShowInviteProjectMembersModal(true);
            setShowMenu(false);
          }}
        >
          {"Invite members"}
        </span>
        <span
          data-testid="Manage members"
          className={style.item}
          onClick={() => {
            setShowManageProjectModal(true);
            setShowMenu(false);
          }}
        >
          {"Manage members"}
        </span>
        <span
          data-testid="Rename"
          className={style.item}
          onClick={(e) => {
            renameClicked(e);
            setShowMenu(false);
          }}
        >
          {"Rename"}
        </span>
        <span
          data-testid="Leave"
          className={style.item}
          onClick={() => {
            setShowLeaveCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          {"Leave"}
        </span>
        <span
          data-testid="Delete"
          className={classNames(style.item, style.delete)}
          onClick={() => {
            setShowDeleteCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          {"Delete"}
        </span>
      </>
    );
  };

  const projectMenuMemberOptions = () => {
    return (
      <>
        <span
          data-testid="Project members"
          className={style.item}
          onClick={() => {
            setShowManageProjectModal(true);
            setShowMenu(false);
          }}
        >
          {"Project members"}
        </span>
        <span
          data-testid="Leave"
          className={style.item}
          onClick={() => {
            setShowLeaveCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          {"Leave"}
        </span>
      </>
    );
  };

  const renderProjectMenu = () => {
    return (
      <Floater
        relativeTo={menuButtonRef}
        side={"bottom"}
        ref={menuRef}
        extraStyle={{
          zIndex: "1",
          filter: "drop-shadow(0px 4.799998760223389px 9.599997520446777px rgba(0, 0, 0, 0.20))",
        }}
      >
        <div className={style.arrow} />
        <div className={style.projectMenu}>
          {project.isOwner ? projectMenuOwnerOptions() : projectMenuMemberOptions()}
        </div>
      </Floater>
    );
  };

  const renderVisibleText = (selectedProject: boolean) => (
    <>
      {project && (
        <InlineRename
          value={projectName}
          textStyle={selectedProject ? InlineRenameStyle.bold : InlineRenameStyle.regular}
          style={{
            minWidth: "140px",
            maxWidth: "162px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          focus={focused}
          onEditing={(editing: boolean) => setFocused(editing)}
          onChange={(newName: string) => {
            setProjectName(newName);
            handleEditProjectName(newName, project);
          }}
          styles={styles}
          showTooltipOnHover={true}
        />
      )}
    </>
  );

  return (
    <div
      key={project.id}
      ref={ref}
      className={classNames(style.cell, { [style.selectedProject]: isSelected, [style.rename]: focused })}
    >
      <div data-testid={projectName} className={style.projectName} onClick={onClick}>
        {renderVisibleText(isSelected)}
      </div>
      {(hover || showMenu) && !focused && (
        <div data-testid="projectmenuicon" className={style.menuIcon} onClick={menuClicked} ref={menuButtonRef} />
      )}
      {showMenu && renderProjectMenu()}
      {showNoTeammatesToInviteMessage && parentTeam && (
        <Modal dimBackground={true}>
          <NoTeammatesToInviteModal
            team={parentTeam}
            onDismiss={() => setShowNoTeammatesToInviteMessage(false)}
            title={`All team members are already invited to this project`}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {showInviteProjectMembersModal && project && parentTeam && (
        <Modal dimBackground={true}>
          <InviteModal
            onDismiss={() => {
              setShowInviteProjectMembersModal(false);
            }}
            inviteMode={InviteMode.Edit}
            project={project}
            team={parentTeam}
            onEditSeats={onEditSeats}
            source="edit-project-members"
          />
        </Modal>
      )}
      {showManageProjectModal && project && (
        <Modal dimBackground={true} onClickBackground={() => setShowManageProjectModal(false)}>
          <ManageCollaborationMembers
            instance={project}
            type={InstanceType.Project}
            onDismiss={() => {
              setShowManageProjectModal(false);
            }}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {showLeaveCollaborationModal && project && (
        <Modal dimBackground={true}>
          <LeaveCollaborationModal
            type={CollaborationType.Project}
            onDismiss={() => setShowLeaveCollaborationModal(false)}
            project={project}
            subtitle={`You will lose access to "${project.name}" project and it’s canvases.`}
          />
        </Modal>
      )}
      {showDeleteCollaborationModal && project && (
        <Modal dimBackground={true}>
          <DeleteCollaborationModal
            type={CollaborationType.Project}
            onDismiss={() => setShowDeleteCollaborationModal(false)}
            project={project}
            subtitle={`All canvases within this project will be permanently deleted. You can move canvases to another project or export them before deleting.`}
          />
        </Modal>
      )}
    </div>
  );
}
