import { z } from "zod";
import { canvasElementBaseSchema, pointSchema } from "./canvas-element";

export const commentThreadSchemaInternal = z.object({
  store: z.string(),
  storeId: z.string(),
  placedByUser: z.string().optional(),
  userPhotoUrl: z.string().optional(),
  attachedTo: z.string().optional(),
  attachedLocation: pointSchema.optional(),
});

export const commentThreadSchema = canvasElementBaseSchema.merge(commentThreadSchemaInternal);

export type CommentThread = z.infer<typeof commentThreadSchema>;
