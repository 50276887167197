import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const ShapeHexagon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M10 1L18 5.6188V14.8564L10 19.4752L2 14.8564V5.6188L10 1ZM10 2.80464L3.56287 6.52112V13.9541L10 17.6706L16.4371 13.9541V6.52112L10 2.80464Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeHexagon.displayName = "ShapeHexagon";

export { ShapeHexagon };
