import React, { useEffect, useRef, useState } from "react";
import { Permission, User } from "shared/datamodel/schemas/user";
import { Team, State } from "shared/datamodel/schemas/team";
import ShareModalUserCell from "../share-user-model-cell";
import { Project } from "shared/datamodel/schemas/project";
import style from "./manage-collaboration-modal.module.css";
import permissionStyle from "./permission-menu.module.css";
import { useOnClickOutside } from "usehooks-ts";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import { useTeam } from "frontend/hooks/use-team";
import { useProject } from "frontend/hooks/use-project";
import useStateValue from "frontend/state/value";
import useAbility from "frontend/hooks/use-ability";
import { InstanceType, UserRole, getUserRole } from "shared/datamodel/schemas/ability";
import StyledButton from "frontend/ui-components/styled-button";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";

export function CollaborationMember({
  teammate,
  members = [],
  setMembers,
  index,
  team,
  project,
  isOwner = false,
  shouldShowPermissionMenu,
  setChangingPermissionMessage,
  onRestrictedUserInvite,
}: {
  teammate: User;
  members?: User[];
  setMembers?: (members: User[]) => void;
  index: number;
  team?: Team | null;
  project?: Project;
  isOwner?: boolean;
  shouldShowPermissionMenu?: boolean;
  setChangingPermissionMessage?: (message: string) => void;
  onRestrictedUserInvite?: (user: User) => void;
}) {
  const { getDefaultAccountTeam } = useTeam();

  const [{ user }] = useStateValue();
  const { canMemberEditInstance } = useAbility();
  const instance = project
    ? { type: InstanceType.Project, self: project }
    : { type: InstanceType.Team, self: team ?? getDefaultAccountTeam() };
  const permission = canMemberEditInstance(teammate, instance.self, instance.type) ? "Editor" : "Viewer";

  const [userPermission, setUserPermission] = useState(permission);
  const [showPermissionMenu, setShowPermissionMenu] = useState(shouldShowPermissionMenu ?? false);
  const [inviteButtonText, setInviteButtonText] = useState("Invite");

  const { handleEditTeamMembers } = useTeam();
  const { getSelectedTeam, handleEditProjectMembers } = useProject();
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const teammateRole = user && getUserRole(teammate, applyRestrictedLimitation);

  const permissionRef = useRef(null);
  const permissionMenuRef = useRef(null);
  const inviteColumnHidden = true;

  const ref = useRef<HTMLDivElement | null>(null);

  team ??= project && getSelectedTeam(project.teamId);

  useEffect(() => {
    setUserPermission(permission);
  }, [teammate.isSubscribedToCanvas, permission]);

  useOnClickOutside(permissionMenuRef, () => {
    setShowPermissionMenu(false);
  });

  const selectedIcon = (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 5.5L5.66667 9L13 2" stroke="#1973FF" strokeWidth="1.5" strokeLinecap="square" />
    </svg>
  );

  const handleRemove = async (entityName: string) => {
    if (project) {
      await handleEditProjectMembers({
        project,
        users: [teammate as User],
        state: State.deleted,
        showManageProjectModal: true,
      });
    } else if (team) {
      await handleEditTeamMembers({
        team,
        users: [teammate],
        permission: Permission.viewer,
        state: State.deleted,
      });
    }
    setChangingPermissionMessage?.(`"${teammate.name}" has been removed from "${entityName}"`);
  };

  const handleDropdownChange = (value: string, setValue: (value: string) => void) => {
    const entityName = project ? project.name : team?.name ?? "";
    if (value === "Remove") {
      handleRemove(entityName);
    } else {
      setValue(value);
      const permissionToDB = value === "Editor" ? Permission.editor : Permission.viewer;
      if (project) {
        handleEditProjectMembers({
          project,
          users: [{ ...teammate, permission: permissionToDB }],
          state: null,
          showManageProjectModal: true,
        });
      } else if (team) {
        handleEditTeamMembers({
          team,
          users: [teammate],
          permission: permissionToDB,
          state: null,
        });
      }
      setChangingPermissionMessage?.(
        `"${teammate.name}" permission has been changed to ${value.toLowerCase()} on "${entityName}"`
      );
    }
  };

  const renderMembersPermissionDropDown = (value: string, setValue: (value: string) => void, teammate: User) => {
    const { id: userId, email: userEmail } = teammate;
    const shouldRenderRemove = user?.id !== userId && (project ? true : !team?.accountTeam);

    return (
      <>
        <div
          data-testid={`${userEmail}-permissionsdropdown`}
          className={permissionStyle.permissionDropDown}
          ref={permissionRef}
          onClick={() => setShowPermissionMenu(true)}
        >
          <span>{value}</span>
          <span>
            <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.11442 1L4.55713 4.55729" stroke="#11355E" strokeLinecap="square" />
              <path d="M1 1L4.55729 4.55729" stroke="#11355E" strokeLinecap="square" />
            </svg>
          </span>
        </div>
        {showPermissionMenu && (
          <Floater
            relativeTo={permissionRef}
            side={"bottom"}
            extraStyle={{
              zIndex: "1",
              filter: "drop-shadow(0px 4.799998760223389px 9.599997520446777px rgba(0, 0, 0, 0.20))",
              marginLeft: "-10px",
              marginTop: "-12px",
            }}
            ref={permissionMenuRef}
          >
            <div className={style.arrow} />
            <div className={permissionStyle.permissionMenu}>
              <div
                data-testid="Viewer"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === "Viewer",
                })}
                onClick={() => {
                  handleDropdownChange("Viewer", setValue);
                  setShowPermissionMenu(false);
                }}
              >
                <span className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === "Viewer" })}>
                  Viewer
                </span>
                {value === "Viewer" && selectedIcon}
              </div>
              <div
                data-testid="Editor"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === "Editor",
                })}
                onClick={() => {
                  handleDropdownChange("Editor", setValue);
                  setShowPermissionMenu(false);
                }}
              >
                <span className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === "Editor" })}>
                  Editor
                </span>
                {value === "Editor" && selectedIcon}
              </div>
              {shouldRenderRemove && (
                <div
                  data-testid="Remove"
                  className={permissionStyle.item}
                  onClick={() => {
                    handleDropdownChange("Remove", setValue);
                    setMembers && setMembers(members.filter((m) => m.id !== userId));
                    setShowPermissionMenu(false);
                  }}
                >
                  <span className={permissionStyle.name}> Remove </span>
                </div>
              )}
            </div>
          </Floater>
        )}
      </>
    );
  };

  const renderMembersPermission = (value: string) => {
    return (
      <div className={style.permission}>
        <span>{value}</span>
      </div>
    );
  };

  function renderRestrictedMemberInviteButton() {
    if (inviteColumnHidden) {
      return null;
    }
    return (
      <div className={style.invite}>
        {teammateRole === UserRole.Restricted && onRestrictedUserInvite && (
          <StyledButton
            title={inviteButtonText}
            customStyle={{
              width: 57,
              height: 24,
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 12,
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: "0.219",
            }}
            enabled={inviteButtonText === "Invite"}
            onClick={() => {
              onRestrictedUserInvite(teammate);
              setInviteButtonText("Invited");
            }}
          />
        )}
      </div>
    );
  }

  function renderPermissionActions() {
    const permissionSelectionDisabled = !isOwner || (!teammate.isSubscribedToCanvas && applyRestrictedLimitation);
    return (
      <div
        className={classNames(style.userPermission, {
          [style.flexStart]: !permissionSelectionDisabled || !inviteColumnHidden,
          [style.narrowDropdown]: inviteColumnHidden,
        })}
      >
        {permissionSelectionDisabled
          ? renderMembersPermission(userPermission)
          : renderMembersPermissionDropDown(userPermission, setUserPermission, teammate)}
      </div>
    );
  }

  return (
    <div
      data-testid={teammate.email}
      key={index}
      className={classNames(style.userLine, { [style.checkListLine]: isOwner })}
      ref={ref}
    >
      <div className={classNames(style.listUser, { [style.checkListUser]: isOwner })}>
        <ShareModalUserCell key={index} user={teammate} />
      </div>
      {renderPermissionActions()}
      {renderRestrictedMemberInviteButton()}
    </div>
  );
}
