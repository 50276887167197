import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

export enum InlineRenameStyle {
  regular = "textStyleRegular",
  bold = "textStyleBold",
}

export default function InlineRename({
  value,
  onChange,
  onEditing,
  textStyle,
  style = {},
  styles,
  focus = false,
  showTooltipOnHover = false,
}: {
  value: string;
  onChange: (value: string) => void;
  onEditing: (editing: boolean) => void;
  textStyle: InlineRenameStyle;
  style?: any;
  styles: any;
  focus?: boolean;
  showTooltipOnHover?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tooltip, setTooltip] = useState<any>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<any>(null);

  useEffect(() => {
    onEditing(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (focus) {
      setIsEditing(true);
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [focus]);

  function handleKeyDown(e: any) {
    if (e.key == "Enter") {
      handleTitleChange(e);
    } else if (e.key == "Escape") {
      setIsEditing(false);
    }
  }

  function handleTitleChange(e: any) {
    setIsEditing(false);

    const newValue = e.target.value;
    if (newValue === value) {
      e.target.value = value;
      return;
    }

    onChange(newValue);
  }

  return (
    <>
      {showTooltipOnHover && tooltip && (
        <Tooltip
          relativeTo={valueRef}
          label={value}
          side={"bottom"}
          withArrow={false}
          customStyle={{
            position: "absolute",
            width: "fit-content",
            backgroundColor: "#EBEDF3",
            color: "#113357",
            padding: "0 12px",
            boxShadow: "0px 2px 4px 0px #00000026",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            font: "400 16px Poppins",
            whiteSpace: "nowrap",
            height: "36px",
            marginLeft: 176,
            marginTop: -10,
          }}
        />
      )}
      <div
        className={cn({
          [styles.title]: true,
          [styles.textStyleRegular]: textStyle === InlineRenameStyle.regular,
          [styles.textStyleBold]: textStyle === InlineRenameStyle.bold,
          [styles.editable]: true,
        })}
        style={style}
        onMouseEnter={() => {
          const isTextOverflowing = valueRef.current && valueRef.current.scrollWidth > valueRef.current.clientWidth;
          isTextOverflowing ? setTooltip(valueRef.current.innerText) : setTooltip(null);
        }}
        onMouseLeave={() => setTooltip(null)}
      >
        <div
          className={cn({
            [styles.inputBorder]: true,
            [styles.show]: isEditing,
          })}
        />
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            className={styles.titleInput}
            defaultValue={value}
            autoFocus={true}
            onKeyDown={handleKeyDown}
            onBlur={handleTitleChange}
            onFocus={(e: any) => e.target.select()}
            autoComplete="off"
          />
        ) : (
          [
            <span className={styles.textOverflow} key={1} ref={valueRef}>
              {value}
            </span>,
          ]
        )}
      </div>
    </>
  );
}
