import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const AlignVCenter = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M15 15H12V10.2L8 10.2V13H5V10.2L2 10.2V8.84791L5 8.84791V6H8V8.84791L12 8.8479V4H15V8.8479L17.9998 8.8479V10.2L15 10.2V15Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

AlignVCenter.displayName = "AlignVCenter";

export { AlignVCenter };
