import { useRef } from "react";
import { equals } from "rambda";

export function useDeepEqualMemo<T>(value: T) {
  const ref = useRef<T>(value);

  if (!equals(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}
