import Modal from "frontend/modal/modal";
import style from "./delete-template-modal.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { deleteCanvasTemplate } from "frontend/api";
import useCanvasTemplates from "frontend/hooks/use-canvas-templates";
import { useEffect } from "react";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export default function DeleteTemplateModal({ templateId, onDismiss }: { templateId: string; onDismiss: () => void }) {
  const { deleteTemplate } = useCanvasTemplates();

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "delete-template-shown", templateId);
  }, []);

  function onDeleteClick() {
    templateId && deleteCanvasTemplate(templateId);
    deleteTemplate(templateId);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.TEMPLATES, // category
      "delete_template", // action
      templateId
    );
    onDismiss();
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={(e: any) => {
          e.stopPropagation();
          onDismiss();
        }}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(17, 51, 87, 0.65)",
        }}
        onClick={onDismiss}
      />
      <Modal
        customStyle={{
          height: 300,
          width: 632,
          background: "#fff",
          margin: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        dimBackground={true}
        withPortal={false}
      >
        <div className={style.deleteContainer}>
          {renderCloseIcon()}
          <p className={style.title}>Delete template?</p>
          <p>Are you sure you want to delete your template?</p>
          <div className={style.buttonsContainer}>
            <StyledButton
              title="Yes, delete"
              onClick={(e: any) => {
                e.stopPropagation();
                onDeleteClick();
              }}
              customStyle={{ width: 210, height: 40 }}
            />
            <StyledButton
              title="Oops, no"
              onClick={(e: any) => {
                e.stopPropagation();
                onDismiss();
              }}
              style={ButtonStyle.outline}
              customStyle={{ width: 210, height: 40 }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
