import React, { useRef, useState } from "react";
import style from "./board-card.module.css";
import { BoardCardWrapper } from "./board-card-wrapper";
import { Board, MinimalBoard } from "shared/datamodel/schemas/board";
import { format } from "timeago.js";
import {
  createMenuOption,
  createMenuSeparator,
  OptionsContextMenu,
  OptionsContextMenuTheme,
} from "frontend/context-menu/optionsContextMenu";
import Modal from "frontend/modal/modal";
import useStateValue from "frontend/state/value";
import { deleteBoard, duplicateBoard, editBoard } from "frontend/services/boardsService";
import EditableText, { EditableTextStyle } from "frontend/ui-components/editable-text";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { useAccountReps } from "frontend/hooks/use-account-reps";
import { fillBoardContent } from "frontend/utils/replicache-utils";
import { useAtom, useAtomValue } from "jotai";
import { userAtom } from "state-atoms/users-atoms";
import { isBusyAtom } from "state-atoms/general-atoms";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import { getPathPrefix } from "../utils/getPathPrefix";

export function BoardCardNew({
  onClick,
  board,
  showDynamicPreview,
  onDuplicateError,
  board_index,
  setCanvasToMove,
}: {
  onClick?: any;
  board: MinimalBoard;
  showDynamicPreview?: boolean;
  onDuplicateError: (maxAllowedBoards: number) => void;
  board_index: number;
  setCanvasToMove: (board: Board | null) => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState<boolean>(false);
  const { updateBoardLastChanged } = useAccountReps();
  const { canPerformAnyActionNew } = useAbility();

  const [, dispatch] = useStateValue();
  const user = useAtomValue(userAtom);
  const [, setBusy] = useAtom(isBusyAtom);

  const menuButtonRef = useRef(null);
  const shouldShoWRestrictedModal = user && !canPerformAnyActionNew(user, board as Board, InstanceType.Board);

  function menuClicked(e: any) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  function backgroundClicked() {
    setShowMenu(false);
  }

  function shareClicked(e: any) {
    e.stopPropagation();
    navigator.clipboard.writeText(`${location.host}/d/${board.documentId}`);
    setShowMenu(false);
  }

  function deleteClicked(e: any) {
    tracking.trackAnalyticsEvent("delete_canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
    });
    e.stopPropagation();
    deleteBoard(dispatch, board as Board);
    setShowMenu(false);
  }

  async function duplicateClicked(e: any) {
    tracking.trackAnalyticsEvent("duplicate_canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
    });
    e.stopPropagation();
    setShowMenu(false);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
      return;
    }
    if (user && user.repsToken) {
      const token = user.repsToken;
      setBusy(true);
      duplicateBoard(dispatch, board as Board)
        .then(async ({ documentId, contents }) => {
          await fillBoardContent(user.id, documentId, token, contents);
        })
        .catch((err) => {
          if (err.response.status === 406) {
            onDuplicateError(err.response.data.maxBoardsAllowed);
          }
        })
        .finally(() => setBusy(false));
    }
  }

  async function moveCanvasClicked(e: any) {
    tracking.trackAnalyticsEvent("move canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
      property: board.documentId,
    });
    e.stopPropagation();
    setShowMenu(false);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
      return;
    }
    setCanvasToMove(board as Board);
  }

  const createOptions = (board: Board) => {
    const options = [
      createMenuOption({
        title: "Copy link",
        iconPath: getPathPrefix("/images/share-icon.svg"),
        id: "copy-link",
        onClick: shareClicked,
      }),
    ];
    if (board.isOwner) {
      options.push(
        createMenuOption({
          title: "Delete",
          iconPath: getPathPrefix("/images/trash-icon.svg"),
          id: "delete",
          onClick: deleteClicked,
        })
      );
    }
    if (!board.isReadOnly && board.hasMemberAccess) {
      options.push(
        createMenuOption({
          title: "Duplicate",
          iconPath: getPathPrefix("/images/duplicate-icon.svg"),
          id: "duplicate",
          onClick: duplicateClicked,
        })
      );
      if (board.isOwner) {
        options.push(createMenuSeparator());
        options.push(
          createMenuOption({
            title: "Move canvas",
            iconPath: getPathPrefix("/images/move-canvas-icon.svg"),
            id: "move canvas",
            onClick: moveCanvasClicked,
          })
        );
      }
    }
    return options;
  };

  function handleTitleChange(newName: string) {
    const newBoard = { ...board, name: newName, updatedAt: new Date() };
    editBoard(dispatch, newBoard as Board, board as Board);
    updateBoardLastChanged(board.documentId);
  }

  const backgroundImage = showDynamicPreview
    ? board.thumbnail ?? `/images/canvas-placeholder-new-${board.id % 4}.jpg`
    : `/images/canvas-placeholder-new-${board.id % 4}.jpg`;

  return (
    <BoardCardWrapper onClick={onClick}>
      <div className={style.container} data-testid={board_index}>
        <div
          className={style.thumbnail}
          style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: showDynamicPreview ? (board.thumbnail ? "contain" : "cover") : "100% 100%",
            backgroundPosition: "center",
          }}
        >
          <div className={style.menuIcon} onClick={menuClicked} ref={menuButtonRef} data-testid="board_menu" />
          <div className={style.boardName}>
            <EditableText
              style={{ width: "100%" }}
              value={board.name}
              editable={board.isOwner}
              onChange={handleTitleChange}
              textStyle={EditableTextStyle.card}
              showTooltipOnHover={true}
            />
          </div>
        </div>
        <div className={style.details} data-testid="board_card_details">
          <span className={style.lastModified}>Modified {format(board.updatedAt)}</span>
        </div>
      </div>
      {showMenu && (
        <Modal dimBackground={false}>
          <OptionsContextMenu
            options={createOptions(board as Board)}
            attachToRef={menuButtonRef}
            onDismiss={backgroundClicked}
            theme={OptionsContextMenuTheme.light}
          />
        </Modal>
      )}
      {showRestrictedModal && (
        <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
      )}
    </BoardCardWrapper>
  );
}
