import React from "react";
import style from "./offline-icon.module.css";

export default function OfflineIcon() {
  return (
    <div className={style.offlineIconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
        <path
          d="M8.55151 16.4077H17.0885C19.7424 16.4077 21.8915 14.1891 21.8915 11.4494C21.8915 8.70977 19.7424 6.49121 17.0885 6.49121H16.194"
          stroke="#848199"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M13.5345 5.84365C13.1151 3.63275 11.2555 2.07324 8.99135 2.07324C6.47851 2.07324 4.43706 4.12704 4.34427 6.69812C2.41417 7.28437 1 9.12743 1 11.3115C1 11.8365 1.08537 12.3384 1.23384 12.8097C1.80545 14.8865 3.65389 16.4115 5.85123 16.4115"
          stroke="#848199"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path d="M16.9455 1L3.38652 19.7946" stroke="#848199" strokeWidth="1.5" strokeMiterlimit="10" />
      </svg>
      <div className={style.toastContainer}>
        <span>You&apos;re currently offline</span>
      </div>
    </div>
  );
}
