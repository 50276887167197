import StyledButton from "frontend/ui-components/styled-button";
import CollaborationHeader from "../collaboration-header";
import style from "./no-teammates-to-invite-modal.module.css";
import { Team } from "shared/datamodel/schemas/team";
import useStateValue from "frontend/state/value";
import { InviteMode } from "state-atoms";
import { useState } from "react";
import InviteModal from "frontend/modals/invite-modal";

export default function NoTeammatesToInviteModal({
  team,
  onDismiss,
  title,
  subtitle,
  canInviteToTeam,
  onEditSeats,
}: {
  team: Team;
  onDismiss: () => void;
  title: string;
  subtitle?: string;
  canInviteToTeam?: boolean | null | undefined;
  onEditSeats: () => void;
}) {
  const [{ user }] = useStateValue();

  canInviteToTeam ??= team?.isOwner || (team?.accountTeam && user?.canInvite);
  subtitle ??= canInviteToTeam
    ? "Invite more teammates to the team so you can add them to the project"
    : "You do not have permission to invite members to this team, please request team owner to add teammates.";

  const [showInviteToTeamModal, setShowInviteToTeamModal] = useState(false);

  const renderInviteToTeamModal = () => {
    return (
      <InviteModal
        team={team}
        onDismiss={onDismiss}
        inviteMode={InviteMode.Edit}
        onEditSeats={onEditSeats}
        source="no-teammates-to-invite-modal"
      />
    );
  };

  const renderButtons = () => {
    return canInviteToTeam ? (
      <div className={style.ownerButtons}>
        <StyledButton
          title={"Invite teammates"}
          customStyle={{ width: "fit-content" }}
          onClick={() => setShowInviteToTeamModal(true)}
        />
        <button
          className={style.cancelButton}
          onClick={() => {
            onDismiss();
          }}
        >
          Cancel
        </button>
      </div>
    ) : (
      <StyledButton title={"Got it"} customStyle={{ width: "107px" }} onClick={() => onDismiss()} />
    );
  };

  return showInviteToTeamModal ? (
    renderInviteToTeamModal()
  ) : (
    <div className={style.container}>
      <CollaborationHeader
        title={title}
        subtitle={subtitle}
        onDismiss={() => {
          onDismiss();
        }}
      />
      {renderButtons()}
    </div>
  );
}
