import classNames from "classnames";
import React from "react";
import style from "./responsive-template-card.module.css";
import { TemplateCardStyle } from "./template-card";

export function ShowAllTemplatesCard({
  cardStyle = TemplateCardStyle.regular,
  setShowTemplatesModal,
}: {
  cardStyle?: TemplateCardStyle;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const isSmall = cardStyle === TemplateCardStyle.small;

  function renderIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="6" viewBox="0 0 27 6" fill="none">
        <circle cx="3.028" cy="3.30388" r="2.58073" fill="#A5AAAD" />
        <circle cx="13.3522" cy="3.30388" r="2.58073" fill="#A5AAAD" />
        <circle cx="23.6745" cy="3.30388" r="2.58073" fill="#A5AAAD" />
      </svg>
    );
  }

  return (
    <>
      <div className={classNames(style.gridContainer)} onClick={() => setShowTemplatesModal(true)}>
        <div className={classNames(style.templateImage, style.menuIcon, { [style.small]: isSmall })}>
          {renderIcon()}
        </div>
        <div className={classNames(style.templateTitle, { [style.small]: isSmall })}>All templates</div>
      </div>
    </>
  );
}
