import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const LiveCardStackIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { width = 22, height = 22 } = props;
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 22 22"
      ref={ref}
      style={{ verticalAlign: "middle" }}
      fill="none"
      renderPath={(color: string) => {
        return (
          <>
            <path
              d="M21 9.74765C20.6943 7.54381 19.6738 5.5018 18.0958 3.93617C16.5178 2.37054 14.4697 1.36816 12.2671 1.08342C10.0645 0.79868 7.82953 1.24739 5.9065 2.36042C4.17575 3.36216 2.78409 4.852 1.90046 6.63729M1 2.23362V7.24297H6"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              d="M1 12.2524C1.3057 14.4563 2.32616 16.4983 3.9042 18.0639C5.48224 19.6295 7.5303 20.6319 9.73291 20.9167C11.9355 21.2014 14.1705 20.7527 16.0935 19.6397C17.8366 18.6308 19.2357 17.1268 20.1184 15.3246M21 19.7665V14.7571H16"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

LiveCardStackIcon.displayName = "LiveCardStackIcon";

export { LiveCardStackIcon };
