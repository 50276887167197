import { projectCacheAtom } from "state-atoms";
import { Project, ProjectCache } from "shared/datamodel/schemas/project";
import { getProjectsByIds } from "frontend/api";
import { useGenericCache } from "./use-general-cache";

export function useProjectsCache() {
  const { getItems, isLoading } = useGenericCache<Project, ProjectCache, "id">({
    cacheAtom: projectCacheAtom,
    fetchByIds: getProjectsByIds,
    entityName: "projects",
    entityKey: "project",
    keyField: "id",
  });

  return { getProjects: getItems, isLoading };
}
