import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const Crown = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={() => {
        return (
          <g transform="scale(1.2, 1.2)">
            <path
              d="M8.5 1L11.8333 7L16 3L14.3333 13H2.66667L1 3L5.16667 7L8.5 1Z"
              fill="#F5BE58"
              stroke="#F5BE58"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Crown.displayName = "Crown";

export { Crown };
