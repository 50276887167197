import LoadedState from "frontend/state/loadedState";
import { atom } from "jotai";
import { CanvasTemplateMinimal, CanvasTemplateTag } from "shared/datamodel/schemas";

type TemplatesState = {
  templates?: CanvasTemplateMinimal[];
  tags?: CanvasTemplateTag[];
  hiddenTags?: CanvasTemplateTag[];
  loadingState: LoadedState;
};

export const templateGlobalState = atom<TemplatesState>({ loadingState: LoadedState.initial });
