import React, { memo } from "react";
import { Line } from "react-konva";
import { useUnmount } from "react-use";
import { TableCellsInfo } from "./table-info";
import { TableIds } from "./table-utils";

type ColResizeEvent = { type: "drag" | "drag-end"; col: number; x: number; startSize: number; prevX: number };
type RowResizeEvent = { type: "drag" | "drag-end"; row: number; y: number; startSize: number; prevY: number };

interface Props {
  id: string;
  xs: number[];
  ys: number[];
  onMouseEnter: (kind: "col" | "row") => void;
  onMouseLeave: (e?: any) => void;
  onColResize: (e: ColResizeEvent) => number;
  onRowResize: (e: RowResizeEvent) => number;
  cells: Record<string, any>;
  info: TableCellsInfo;
}

export const DraggableTableLines = memo(
  ({ xs, ys, onMouseEnter, onMouseLeave, onColResize, onRowResize, cells, info }: Props) => {
    useUnmount(() => onMouseLeave());

    type SimpleSegment = [number, number];
    type SimpleSegmentList = SimpleSegment[];
    type GridLines = SimpleSegmentList[];
    const verticals: GridLines = [];
    const horizontals: GridLines = [];

    // Let's build lists of vertical and horizontal table lines
    verticals.push([[0, 0]]); // first one is a dummy, since it's not draggable
    for (let i = 1; i < xs.length; i++) {
      verticals.push([[0, ys.length - 1]]);
    }
    horizontals.push([[0, 0]]);
    for (let i = 1; i < ys.length; i++) {
      horizontals.push([[0, xs.length - 1]]);
    }

    // Now go over cells where spanX!=1 || spanY!=1, and break apart
    // the lines inside those "merged" cells.
    for (const [key, cell] of Object.entries(cells)) {
      const spanX = cell?.spanX ?? 1;
      const spanY = cell?.spanY ?? 1;
      if (spanX > 1 || spanY > 1) {
        const { col, row } = TableIds.getColAndRow(key);
        const minx = info.colIndex(col);
        const miny = info.rowIndex(row);
        const maxx = minx + spanX;
        const maxy = miny + spanY;
        for (let i = minx + 1; i < maxx; i++) {
          const line = verticals[i];
          verticals[i] = line.flatMap((segment) => {
            const [segmentStart, segmentEnd] = segment;
            if (segmentStart <= miny && segmentEnd >= maxy) {
              return [
                [Math.min(miny, segmentStart), miny],
                [maxy, Math.max(maxy, segmentEnd)],
              ];
            } else {
              return [segment];
            }
          });
        }
        for (let i = miny + 1; i < maxy; i++) {
          const line = horizontals[i];
          horizontals[i] = line.flatMap((segment) => {
            const [segmentStart, segmentEnd] = segment;
            if (segmentStart <= minx && segmentEnd >= maxx) {
              return [
                [Math.min(minx, segmentStart), minx],
                [maxx, Math.max(maxx, segmentEnd)],
              ];
            } else {
              return [segment];
            }
          });
        }
      }
    }

    return (
      <>
        {verticals.flatMap((line, i) => {
          if (i == 0) return null; // the first line is a dummy
          return line.map((segment, index) => {
            const [startY, endY] = segment;
            if (endY - startY == 0) return null;
            return (
              <Line
                key={index}
                name="anchor"
                nth={i}
                prevX={xs[i - 1]}
                x={xs[i]}
                y={ys[startY]}
                points={[0, 0, 0, ys[endY] - ys[startY]]}
                stroke={"transparent"}
                strokeWidth={10}
                strokeScaleEnabled={false}
                hitStrokeWidth={15}
                draggable={true}
                onMouseEnter={() => onMouseEnter("col")}
                onMouseLeave={onMouseLeave}
                onDragStart={(e) => {
                  e.currentTarget.attrs.start = xs[i];
                  e.currentTarget.attrs.startSize = xs[i] - xs[i - 1];
                }}
                onDragMove={(e) => {
                  const line = e.currentTarget;
                  line.y(0);
                  const newX = onColResize({
                    type: "drag",
                    col: line.attrs.nth,
                    x: line.x(),
                    startSize: line.attrs.startSize,
                    prevX: line.attrs.prevX,
                  });
                  line.x(newX);
                }}
                onDragEnd={(e) => {
                  const line = e.currentTarget;
                  line.y(0);
                  line.x(
                    onColResize({
                      type: "drag-end",
                      col: line.attrs.nth,
                      x: line.x(),
                      startSize: line.attrs.startSize,
                      prevX: line.attrs.prevX,
                    })
                  );
                }}
              />
            );
          });
        })}
        {horizontals.flatMap((line, i) => {
          if (i == 0) return null; // the first line is a dummy
          return line.flatMap((segment) => {
            const [startX, endX] = segment;
            if (endX - startX == 0) return null;
            return (
              <Line
                name="anchor"
                nth={i}
                prevY={ys[i - 1]}
                y={ys[i]}
                x={xs[startX]}
                points={[0, 0, xs[endX] - xs[startX], 0]}
                stroke={"transparent"}
                hitStrokeWidth={15}
                strokeScaleEnabled={false}
                draggable={true}
                onMouseEnter={() => onMouseEnter("row")}
                onMouseLeave={onMouseLeave}
                onDragStart={(e) => {
                  e.currentTarget.attrs.start = ys[i];
                  e.currentTarget.attrs.startSize = ys[i] - ys[i - 1];
                }}
                onDragMove={(e) => {
                  const line = e.currentTarget;
                  line.x(0);
                  line.y(
                    onRowResize({
                      type: "drag",
                      row: line.attrs.nth,
                      y: line.y(),
                      startSize: line.attrs.startSize,
                      prevY: line.attrs.prevY,
                    })
                  );
                  e.cancelBubble = true;
                }}
                onDragEnd={(e) => {
                  const line = e.currentTarget;
                  line.x(0);
                  line.y(
                    onRowResize({
                      type: "drag-end",
                      row: line.attrs.nth,
                      y: line.y(),
                      startSize: line.attrs.startSize,
                      prevY: line.attrs.prevY,
                    })
                  );
                }}
              />
            );
          });
        })}
      </>
    );
  }
);

DraggableTableLines.displayName = "DraggableTableLines";
