import { Connector } from "shared/datamodel/schemas";
import { useCanvasElementsByIds } from "subscriptions";
import { useAtomValue } from "jotai";
import { syncServiceAtom } from "state-atoms";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { SyncService } from "frontend/services/syncService";

export default function useConnectorShapes(id: string, element: Connector, allElementsData?: Array<[string, any]>) {
  const connectedShapes = element.connectedShapes;
  const syncService = useAtomValue(syncServiceAtom) as SyncService<RW>;
  const replicacheSubscription = useCanvasElementsByIds(
    syncService?.getReplicache(),
    [connectedShapes[0]?.id, connectedShapes[1]?.id, id],
    [connectedShapes[0]?.id, connectedShapes[1]?.id, id]
  );

  if (!connectedShapes || connectedShapes.length < 2) {
    return {
      startShape: null,
      endShape: null,
      currentElement: element,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getShape: (index: number) => null,
    };
  }

  let startShape: any, endShape: any, currentElement: any;
  if (allElementsData) {
    // Mini-stage uses all-elements instead of subscribing
    startShape = allElementsData.find(([elementId]) => elementId === connectedShapes[0]?.id)?.[1];
    endShape = allElementsData.find(([elementId]) => elementId === connectedShapes[1]?.id)?.[1];
    currentElement = element;
  } else {
    [startShape, endShape, currentElement] = replicacheSubscription[1] || [null, null, element];
    if (!currentElement) currentElement = element;
  }
  const getShape = (index: number) => (index == 0 ? startShape : endShape);

  return {
    startShape,
    endShape,
    currentElement,
    getShape,
  };
}
