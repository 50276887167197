import Constants from "elements/gantt/constants";
import React, { ComponentProps, useMemo, useState } from "react";
import { GanttConnector } from "elements/gantt/components/gantt-connector/gantt-connector";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { GanttMonayConnector } from "elements/gantt/icons/gantt-monay-connector";
import { KonvaTooltip } from "frontend/canvas-designer-new/utility-elements/konva-tooltip";
import { TaskCard, IntegrationItem } from "shared/datamodel/schemas";
import { Degrees } from "utils/transform";
import { GanttConnectorLayout } from "elements/gantt/layout-builder";
import { Portal } from "react-konva-utils";
import { Group } from "react-konva";

type GanttConnectorProps = {
  tasks: (IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>)[];
  connector: GanttConnectorLayout;
  isSelected: boolean;
  bboxPosition?: { x: number; y: number };
  removeConnector: () => void;
} & ComponentProps<typeof GanttConnector>;

export const GanttConnectorWrapper = ({
  bboxPosition,
  tasks,
  connector,
  isSelected,
  removeConnector,
}: GanttConnectorProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const relevantTasks = tasks.filter((task) => task.id === connector.from.id || task.id === connector.to.id);
  const someAreLoading = relevantTasks.some((task) => task?.isInitialLoading?.());

  const showMondayIndicator = connector.type === "monday";

  const overlayNodes = useMemo(() => {
    if (!showMondayIndicator) {
      return [];
    }

    return [
      {
        node: (
          <GanttMonayConnector
            onHover={(shouldShowTooltip) => {
              setShowTooltip(shouldShowTooltip);
            }}
          />
        ),
        relativePosition: 0.2,
        rotatable: true,
      },
      {
        node: ({ x, y }: { x: number; y: number }) => {
          return (
            <Portal enabled={showTooltip} selector=".Overlay">
              {showTooltip && (
                <Group x={(bboxPosition?.x || 0) + x} y={(bboxPosition?.y || 0) + y}>
                  <KonvaTooltip y={-40} x={-15} parentWidth={20} text={"Connected tasks"} />
                </Group>
              )}
            </Portal>
          );
        },
        relativePosition: 0.2,
        rotatable: false,
      },
    ];
  }, [showMondayIndicator, showTooltip, bboxPosition]);

  if (someAreLoading) {
    return null;
  }
  return (
    <GanttConnector
      key={connector.id}
      id={`${Constants.ConnectorPrefix}-${connector.id}`}
      p1={{ ...connector.from, rotation: 0 as Degrees }}
      p2={{ ...connector.to, rotation: 180 as Degrees }}
      lineType={"curve"}
      element={{
        strokeWidth: 1,
        stroke: isSelected ? "#00A1FF" : "#657E9A",
        hitStrokeWidth: 10,
      }}
      removeConnector={removeConnector}
      isSelected={isSelected}
      overlayNodes={overlayNodes}
    />
  );
};
