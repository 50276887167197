import classNames from "classnames";

type RadioBlockFontSize = "small" | "medium" | "large";

function getCSSFontSize(fontSize: RadioBlockFontSize) {
  switch (fontSize) {
    case "small": {
      return "0.9em";
    }
    case "medium": {
      return "1.1em";
    }
    case "large": {
      return "1.2em";
    }
  }
}

export default function RadioBlock({
  title,
  isSelected,
  imageSrc,
  onSelect,
  fontSize = "medium",
}: {
  title: string;
  isSelected: boolean;
  imageSrc: string;
  onSelect: () => void;
  fontSize?: RadioBlockFontSize;
}) {
  return (
    <div className={classNames("block", { ["selected"]: isSelected })} onClick={onSelect}>
      <div className={"imageContainer"}>
        <img src={imageSrc} />
      </div>
      <div className={"blockTitle"}>
        <div className={classNames("radio", { ["selected"]: isSelected })} />
        <span>{title}</span>
      </div>

      <style jsx>{`
        .block {
          display: flex;
          flex-direction: column;
          border: 2px solid #dadce0;
          justify-content: flex-end;
          align-items: stretch;
          padding: 14px 20px;
          aspect-ratio: 0.78;
          max-height: 330px;
          min-width: 280px;
        }

        .block img {
          max-width: 100%;
        }

        .block.selected {
          border: 2px solid #1973ff;
        }

        .imageContainer {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
        }

        .block:hover {
          cursor: pointer;
          background: #f2f8ff;
        }

        .blockTitle {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          font-size: ${getCSSFontSize(fontSize)};
          font-weight: 600;
        }

        @media only screen and (max-height: 560px) {
          .blockTitle {
            font-size: 1em;
          }
        }

        .radio {
          position: relative;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          outline: none;
        }

        .radio::before {
          content: "";
          position: absolute;
          top: -4px;
          right: -4px;
          bottom: -4px;
          left: -4px;
          border: 2px solid #dadce0;
          border-radius: 10px;
        }

        .radio.selected {
          background: #1973ff;
        }

        .radio.selected::before {
          content: "";
          position: absolute;
          top: -4px;
          right: -4px;
          bottom: -4px;
          left: -4px;
          border: 2px solid #1973ff;
          border-radius: 10px;
        }
      `}</style>
    </div>
  );
}
