import { useEffect, useRef } from "react";

export const PortalID = {
  Modal: "modal-container",
  AppHeader: "app-header",
  Floaters: "floaters",
  UiWidgets: "ui-widgets",
  Tooltips: "tooltips",
  Comments: "comments",
  WalkthroughTips: "walkthrough-tips",
  Checkout: "checkout",
  ErrorPage: "error-page",
};

export function usePortal(id: string) {
  const rootElemRef = useRef(document.createElement("div"));

  useEffect(() => {
    const parentElem = document.getElementById(id)!;

    for (const child of parentElem?.childNodes || []) {
      parentElem?.removeChild(child);
    }

    parentElem.appendChild(rootElemRef.current);
    parentElem.style.display = "block";
    return () => {
      rootElemRef.current.remove();
      if (parentElem?.childNodes.length == 0) {
        parentElem.style.display = "none";
      }
    };
  }, [id]);

  return rootElemRef.current;
}
