import { useEffect, useState } from "react";
import style from "./side-menu.module.css";
import Modal from "frontend/modal/modal";
import NewCollaborationModal from "frontend/modals/collaboration/new-collaboration-modal";
import { Project } from "shared/datamodel/schemas/project";
import { ProjectCell } from "./projects-cell";
import AddCanvasesToProjectModel from "frontend/modals/collaboration/project/add-canvases-to-project-modal";
import { CollaborationType, InviteMode } from "state-atoms/teams-atoms";
import { useProject } from "frontend/hooks/use-project";
import { Board, CanvasTemplate } from "shared/datamodel/schemas";
import { Team } from "shared/datamodel/schemas/team";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";
import useStateValue from "frontend/state/value";
import useAbility from "frontend/hooks/use-ability";
import { InstanceType } from "shared/datamodel/schemas/ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModal from "frontend/modals/invite-modal";

export function ProjectPicker({
  selectedTeam,
  selectedProject,
  setSelectedProject,
  createNewBoard,
  handleNoPermissionToCreate,
  onEditSeats,
}: {
  selectedTeam: Team;
  selectedProject: Project | null;
  setSelectedProject: (project: Project) => void;
  createNewBoard: (template: CanvasTemplate, projectId?: number) => void;
  handleNoPermissionToCreate: () => void;
  onEditSeats: () => void;
}) {
  const plusIcon = (
    <svg
      data-testid="projects-plusicon"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.49658 1V8" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M8.18652 4.5L1.00009 4.5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  );

  const [projectName, setProjectName] = useState("");
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showInviteProjectMembersModal, setShowInviteProjectMembersModal] = useState(false);
  const [showAddCanvasesToProjectModel, setShowAddCanvasesToProjectModel] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);
  const [canvases, setCanvases] = useState<Board[]>([]);
  const [newCanvasForProject, setNewCanvasForProject] = useState(false);
  const [createdProject, setCreatedProject] = useState<Project | null>(null);

  const [{ boards, user }] = useStateValue();
  const { canPerformAnyAction, canCreateInstance } = useAbility();

  const sortedProjects = selectedTeam?.projects;
  const boardsForProjectCreation = Array.from(boards.values()).filter(
    (board) => !board.projectId && (board.teamId === selectedTeam?.id || board.teamId === null) && board.isOwner
  );

  const { addCanvasesToProject } = useProject();

  useEffect(() => {
    createdProject && addCanvasesToProject(createdProject?.id, canvases, createNewBoard, newCanvasForProject);
  }, [createdProject]);

  const handleProjectSelection = (project: Project) => {
    setSelectedProject(project);
    const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
    setLocalStorageItem(
      "selectedTeamsAndProjects",
      selectedTeamsAndProjects.filter((item: any) => item.selectedTeam !== selectedTeam?.id)
    );
    addToLocalStorageArray("selectedTeamsAndProjects", {
      selectedTeam: selectedTeam?.id,
      selectedProject: project.id,
    });
  };

  function renderProjects() {
    if (sortedProjects) {
      return sortedProjects.length
        ? sortedProjects.map((project: Project) => (
            <ProjectCell
              key={project.id}
              project={project}
              isSelected={selectedProject?.id === project.id}
              onClick={() => handleProjectSelection(project)}
              onEditSeats={onEditSeats}
            />
          ))
        : null;
    } else {
      return null;
    }
  }

  function handlePlusIconClick() {
    const shouldShoWRestrictedModal = user && !canPerformAnyAction(user, selectedTeam, InstanceType.Team);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
    } else if (user && !canCreateInstance(user, selectedTeam, InstanceType.Team)) {
      handleNoPermissionToCreate();
    } else {
      setShowCreateProjectModal(true);
    }
  }

  return (
    <div className={style.projects}>
      <div data-testid="Projects" className={style.projectsTitle}>
        <span>Projects</span>
        <span className={style.plusIcon} onClick={() => handlePlusIconClick()}>
          {plusIcon}
        </span>
      </div>
      <div className={style.projectList}>{renderProjects()}</div>
      {showCreateProjectModal && (
        <Modal dimBackground={true}>
          <NewCollaborationModal
            onDismiss={() => setShowCreateProjectModal(false)}
            onCreateProject={(name: any) => {
              setProjectName(name);
              setShowCreateProjectModal(false);
              if (boardsForProjectCreation.length > 0) {
                setShowAddCanvasesToProjectModel(true);
              } else {
                setCanvases([]);
                setShowInviteProjectMembersModal(true);
              }
            }}
            type={CollaborationType.Project}
            subtitle={"People in your project have access to every canvas."}
            inputPlaceholder={"Name your new project"}
          />
        </Modal>
      )}
      {showAddCanvasesToProjectModel && (
        <Modal dimBackground={true}>
          <AddCanvasesToProjectModel
            onDismiss={() => setShowAddCanvasesToProjectModel(false)}
            onCreateProject={(canvases: any[], newCanvasForProject?: boolean) => {
              setCanvases(canvases);
              setNewCanvasForProject(newCanvasForProject ?? false);
              setShowAddCanvasesToProjectModel(false);
              setShowInviteProjectMembersModal(true);
            }}
            boards={boardsForProjectCreation}
          />
        </Modal>
      )}
      {showInviteProjectMembersModal && (
        <Modal dimBackground={true}>
          <InviteModal
            name={projectName}
            team={selectedTeam}
            onDismiss={() => {
              setShowInviteProjectMembersModal(false);
            }}
            inviteMode={InviteMode.Create}
            setCreated={(project) => {
              project && setCreatedProject(project as Project);
            }}
            onEditSeats={onEditSeats}
            source="create-project"
            selectedCanvases={canvases}
          />
        </Modal>
      )}
      {showRestrictedModal && (
        <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
      )}
    </div>
  );
}
