import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import {
  Alignment,
  calculateArrowPlacement,
  calculatePlacement,
  DOMPlacement,
  getBoundingRect as getDOMRect,
  insetRect,
  isAnchorPartiallyInViewport,
  RelativePlacement,
} from "./position-utils";
import style from "./floaters.module.css";

export interface FloaterProps extends React.PropsWithChildren<unknown> {
  relativeTo: DOMRectReadOnly | React.RefObject<HTMLElement> | HTMLElement;
  className?: string;
  side?: RelativePlacement;
  boundary?: DOMRectReadOnly | string;
  boundaryPadding?: number;
  arrowSize?: number;
  arrowColor?: string;
  margin?: number;
  extraStyle?: CSSProperties;
  alignment?: Alignment;
  useContainerForPositioning?: boolean;
  lockPosition?: boolean;
}

function areRectsEqual(r1: DOMPlacement, r2: DOMPlacement): boolean {
  return (
    Math.abs(r1.left - r2.left) < 1e-3 &&
    Math.abs(r1.top - r2.top) < 1e-3 &&
    r1.side == r2.side &&
    Math.abs(r1.shift - r2.shift) < 1e-3
  );
}

export const Floater = React.forwardRef<HTMLDivElement, FloaterProps>(
  (props: FloaterProps, ref: React.Ref<HTMLDivElement>) => {
    const localRef = useRef<HTMLDivElement>(null);
    const [placement, setPlacement] = useState<DOMPlacement>({ top: -999, left: -999, shift: 0, side: "top" });

    const margin = props.margin ?? 5;
    const arrowSize = props.arrowSize ?? 10;

    // THIS EFFECT CAUSES WEIRD BUGS FOR ELEMENT TOOLBAR
    // DISABLING FOR NOW
    // FLOATER SHOULD BE REWRITTEN ANYWAY SINCE IT'S NOT VERY PERFORMANT (InterscectionObserver instead of polling)
    // useEffect(() => {
    //   if (localRef.current) {
    //     function callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    //       if (entries[0].intersectionRatio < 1) {
    //         const { boundingClientRect, intersectionRect, rootBounds } = entries[0];
    //         if (boundingClientRect.bottom < rootBounds!.bottom) {
    //           localRef.current!.style.transform = "";
    //         } else {
    //           const padding = props.boundaryPadding ?? 0;
    //           localRef.current!.style.transform = `translateY(${
    //             -boundingClientRect.bottom + rootBounds!.bottom - padding
    //           }px)`;
    //         }
    //       }
    //     }
    //     const obsv = new IntersectionObserver(callback, { threshold: 1.0 });
    //     obsv.observe(localRef.current);
    //     return () => obsv.disconnect();
    //     // root?: Element | Document | null;
    //     // rootMargin?: string;
    //     // threshold?: number | number[];
    //   }
    // }, []);

    // poll target element if we're given one,
    // or just recalc when the anchor rect changes
    useLayoutEffect(() => {
      if (props.lockPosition) {
        return;
      }

      let id = 0;
      function poll() {
        recalc();
        id = window.requestAnimationFrame(poll);
      }

      if (props.relativeTo instanceof HTMLElement || ("current" in props.relativeTo && props.relativeTo.current)) {
        poll();
        return () => cancelAnimationFrame(id);
      } else {
        recalc();
      }
    }, [props.relativeTo, (props.relativeTo as any)?.current]);

    const recalc = () => {
      let rect: DOMRectReadOnly;
      if (props.relativeTo instanceof HTMLElement) {
        rect = props.relativeTo.getBoundingClientRect();
      } else if ("current" in props.relativeTo && props.relativeTo.current) {
        const el = props.relativeTo.current;
        rect = el.getBoundingClientRect();
        const parentRect = el.parentElement?.getBoundingClientRect();
        if (parentRect && !!props.useContainerForPositioning) {
          rect = {
            top: rect.top - parentRect.top,
            left: rect.left - parentRect.left,
            right: rect.right - parentRect.left,
            bottom: rect.bottom - parentRect.top,
            width: rect.width,
            height: rect.height,
          } as DOMRectReadOnly;
        }
      } else {
        rect = props.relativeTo as DOMRectReadOnly;
      }

      if (!rect || !isAnchorPartiallyInViewport(rect) || !localRef.current) {
        setPlacement({ top: -999, left: -999, shift: 0, side: "top" });
        return;
      }

      // if we are using the container for positioning, the boundary will be the window
      const boundary = props.useContainerForPositioning ? undefined : props.boundary ?? "no-controls-area";
      let boundingRect = getDOMRect(boundary);
      boundingRect = insetRect(boundingRect, props.boundaryPadding ?? 0);

      const floaterRect = localRef.current.getBoundingClientRect();
      const newPos = calculatePlacement(
        rect,
        floaterRect.width,
        floaterRect.height,
        margin + arrowSize,
        props.side ?? "top",
        boundingRect,
        props.alignment ?? "middle"
      );
      setPlacement((p) => {
        if (areRectsEqual(p, newPos)) {
          return p;
        }
        return newPos;
      });
    };

    function renderChildren() {
      if (!placement) return null;
      if (typeof props.children === "function") {
        return props.children(placement);
      }
      return props.children;
    }

    const element = (
      <div
        className={props.className}
        ref={mergeRefs([localRef, ref])}
        style={{
          position: "fixed",
          opacity: 1,
          top: placement.top,
          left: placement.left,
          ...props.extraStyle,
        }}
      >
        {renderChildren()}
        {arrowSize > 0 && (
          <div
            className={style.arrow}
            style={calculateArrowPlacement(placement?.side ?? "bottom", arrowSize, placement?.shift)}
          />
        )}
      </div>
    );
    return element;
  }
);

Floater.displayName = "Floater";
