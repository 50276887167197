import Konva from "konva";
import React from "react";
import { Line } from "react-konva";

export interface CanvasArrowProps extends Konva.ShapeConfig {
  direction?: Konva.Vector2d;
  pointerLength: number;
  pointerWidth: number;
  closed?: boolean;
}

export function CanvasArrow(props: CanvasArrowProps) {
  let {
    closed = false,
    direction,
    lineCap = "round",
    lineJoin = "round",
    rotation,
    pointerLength,
    pointerWidth,
    ...restProps
  } = props;
  if (direction) {
    let theta = Math.atan2(direction.y, direction.x); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    rotation = theta;
  }
  return (
    <Line
      points={[-pointerLength, pointerWidth, 0, 0, -pointerLength, -pointerWidth]}
      closed={closed}
      lineCap={lineCap}
      lineJoin={lineJoin}
      rotation={rotation}
      {...restProps}
    />
  );
}
