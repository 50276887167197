import { Plan } from "../consts";
import { PlanInfo } from "../datamodel/schemas/user";

export function isTrialActive(planInfo: PlanInfo | null) {
  if (!planInfo) {
    return false;
  }
  const daysLeft = numberOfTrialDaysLeft(planInfo);
  if (daysLeft === null) {
    return false;
  }
  return daysLeft > 0;
}

export function isTrialExpired(planInfo: PlanInfo | null) {
  if (!planInfo) {
    return false;
  }
  const daysLeft = numberOfTrialDaysLeft(planInfo);
  if (daysLeft === null) {
    return false;
  }
  return daysLeft <= 0;
}

export function numberOfTrialDaysLeft(planInfo: PlanInfo) {
  if (!planInfo) {
    return null;
  }

  let trialEndDate = planInfo.trial_end_date;
  if (!trialEndDate) {
    return null;
  }
  if (!(trialEndDate instanceof Date)) {
    trialEndDate = new Date(trialEndDate);
  }
  const now = new Date();
  return dateDiffInDays(now, trialEndDate);
}

// a and b are javascript Date objects
export function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function planToString(plan: Plan): string | undefined {
  switch (plan) {
    case Plan.basic: {
      return "Basic";
    }
    case Plan.team: {
      return "Team";
    }
    case Plan.pro: {
      return "Pro";
    }
    default: {
      break;
    }
  }
}
