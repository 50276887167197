import { Action, DispatchFunc } from "frontend/state/actions";
import {
  createTeam as internalCreateTeam,
  deleteTeam as internalDeleteTeam,
  editTeam as internalEditTeam,
  getTeams,
} from "../api";

import { Team, UserTeamPermission } from "shared/datamodel/schemas/team";
import { Permission } from "shared/datamodel/schemas";

export async function createTeam(
  name: string,
  usersIds: number[] | [],
  permission: Permission,
  dispatch?: DispatchFunc
): Promise<Team> {
  const team: Team = await internalCreateTeam(name, usersIds, permission);
  dispatch && dispatch({ type: Action.CreatedTeam, payload: team });
  return { ...team };
}

export async function reloadTeams(dispatch: DispatchFunc) {
  dispatch({ type: Action.LoadingTeams, payload: null });
  try {
    const teamsFromDB = await getTeams();
    dispatch({ type: Action.UpdatedTeams, payload: teamsFromDB });
  } catch (error) {
    console.error("reload teams failed error:", error);
    dispatch({ type: Action.FailedLoadingTeams, payload: null });
  }
}

export async function editTeam(
  team: Team,
  requestPayload: { name?: string; users?: UserTeamPermission[] | null; newOwner?: number },
  dispatch: any
): Promise<Team> {
  dispatch({ type: Action.EditedTeam, payload: team });
  try {
    const editedTeam = await internalEditTeam(team.documentId, requestPayload);
    return editedTeam as Team;
  } catch {
    dispatch({ type: Action.FailedEditTeam, payload: team });
    return team;
  }
}

export async function deleteTeam(team: Team, dispatch: any) {
  dispatch({ type: Action.DeleteTeam, payload: team.documentId });
  try {
    await internalDeleteTeam(team.documentId);
  } catch {
    dispatch({ type: Action.FailedDeleteTeam, payload: team });
  }
}
