import { useState } from "react";
import { Group, Shape } from "react-konva";
import * as utils from "./connector-utils";
import { Connector, InnerPointSchema } from "shared/datamodel/schemas";
import { ConnectorTransformPointRadius, HANDLE_COLOR, CONNETOR_EDITOR_SNAP_THRESHOLD } from "./connector-constants";
import { drawScaledCircle, getConnectorEditPoints } from "./connector-drawing-utils";
import { ConnectorEditPoint } from "./connector-utils";
import { KonvaEventObject } from "konva/types/Node";
import { nanoid } from "nanoid";

function EditPoint({
  point,
  start,
  end,
  allPoints,
  element,
  isDragging,
  onDragStart,
  onDragEnd,
  onChangeElement,
}: {
  point: ConnectorEditPoint;
  start: utils.ConnectorEndpoint;
  end: utils.ConnectorEndpoint;
  allPoints: ConnectorEditPoint[];
  element: Connector;
  isDragging: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
  onChangeElement?: utils.OnChangeElementFn;
}) {
  const doesPointExist = point.real;

  /**
   * Gets the nearest coordinate to snap to, if any. Requires points to have orientation set.
   */
  function getSnapCoordinate(currentPoint: ConnectorEditPoint, allPoints: InnerPointSchema[]): number | null {
    if (!currentPoint.orientation) return null;
    if (!element.innerPoints || element.innerPoints.length === 0) return null;

    const coordinate = currentPoint.orientation === "vertical" ? "x" : "y";
    let nearestValue = null;
    let minDistance = CONNETOR_EDITOR_SNAP_THRESHOLD;

    const compareTo = [...allPoints];
    const innerPointIndex = element.innerPoints?.findIndex((p) => p.id == currentPoint.id);
    if (innerPointIndex === 0) compareTo.push({ ...start, id: nanoid(10), orientation: currentPoint.orientation });
    if (innerPointIndex === element.innerPoints.length - 1)
      compareTo.push({ ...end, id: nanoid(10), orientation: currentPoint.orientation });

    for (const point of compareTo) {
      if (point.id === currentPoint.id) continue;
      if (point.orientation !== currentPoint.orientation) continue;
      const distance = Math.abs(point[coordinate] - currentPoint[coordinate]);
      if (distance < minDistance) {
        minDistance = distance;
        nearestValue = point[coordinate];
      }
    }

    return nearestValue;
  }

  /**
   * Returns if the provided points should be merged.
   */
  // eslint-disable-next-line unicorn/consistent-function-scoping
  function shouldMergePoints(currentPoint: ConnectorEditPoint, points: ConnectorEditPoint[]) {
    const threshold = CONNETOR_EDITOR_SNAP_THRESHOLD;
    const coordinate = currentPoint.orientation === "vertical" ? "x" : "y";
    if (points.length === 0) return false;
    return points.every((point) => Math.abs(point[coordinate] - currentPoint[coordinate]) < threshold);
  }

  /**
   * Adds a point if it doesn't exist yet
   */
  function handleDragStart(e: KonvaEventObject<DragEvent>) {
    e.target.setAttr("undoData", element.innerPoints ?? []);
    if (e.target.attrs.real === true || point.addAt === undefined) return;

    const pos = e.target.position();
    let newInnerPoints;
    if (element.innerPoints) {
      newInnerPoints = [...element.innerPoints];
      newInnerPoints.splice(point.addAt, 0, {
        x: pos.x,
        y: pos.y,
        id: e.target.attrs.id,
        orientation: point.orientation,
      });
    } else {
      newInnerPoints = [{ x: pos.x, y: pos.y, id: point.id, orientation: point.orientation }];
    }
    onChangeElement?.({ innerPoints: newInnerPoints }, { shouldAdd: false });
  }

  /**
   * Moves an existing point and updates element.innerPoints acordingly
   */
  function handleDragMove(e: KonvaEventObject<DragEvent>) {
    if (e.target.attrs.real && !isDragging) onDragStart();
    if (!element.innerPoints) return;

    const innerPointIndex = element.innerPoints?.findIndex((p) => p.id == e.target.attrs.id);
    if (!e.target.attrs.real === true || innerPointIndex == undefined || innerPointIndex == -1) return;

    const pos = e.target.position();
    const pointData = element.innerPoints[innerPointIndex];

    const newPos = { x: pos.x, y: pos.y };

    // Elbow connectors have extra logic for axis locking and snapping.
    // Return early if the point is not an elbow point for clarity.
    if (element.lineType !== "elbow") {
      const newInnerPoints = [...(element.innerPoints ?? [])];
      newInnerPoints[innerPointIndex] = { ...pointData, x: newPos.x, y: newPos.y };
      onChangeElement?.({ innerPoints: newInnerPoints }, { shouldAdd: false });
      return;
    }

    if (pointData.orientation === "vertical") {
      newPos.y = point.y;
      e.currentTarget.y(point.y);
    } else if (pointData.orientation === "horizontal") {
      newPos.x = point.x;
      e.currentTarget.x(point.x);
    }

    const snapPoints = allPoints; // Inner points are only updated in reflect when they are moved
    const snapTo = getSnapCoordinate({ ...point, x: e.currentTarget.x(), y: e.currentTarget.y() }, snapPoints);
    if (pointData.orientation === "vertical" && snapTo !== null) {
      newPos.x = snapTo;
      e.currentTarget.x(snapTo);
    }
    if (pointData.orientation === "horizontal" && snapTo !== null) {
      newPos.y = snapTo;
      e.currentTarget.y(snapTo);
    }

    const newInnerPoints = [...(element.innerPoints ?? [])];
    newInnerPoints[innerPointIndex] = { ...pointData, x: newPos.x, y: newPos.y };
    onChangeElement?.({ innerPoints: newInnerPoints }, { shouldAdd: false });
  }

  /**
   * Adds the undo operation for the inner point edit.
   * For orthogonal points, handles merging.
   */
  function handleDragEnd(e: KonvaEventObject<DragEvent>) {
    if (!e.evt) return; // Stopped dragging due to external interruption

    if (!element.innerPoints) return;

    const pointIndex = allPoints.findIndex((p) => p.id === e.target.attrs.id);
    const innerPointIndex = element.innerPoints.findIndex((p) => p.id === e.target.attrs.id);
    if (pointIndex === -1 || innerPointIndex === -1) return;

    const newInnerPoints = [...element.innerPoints];
    const currentPoint = allPoints[pointIndex];

    // Elbow connectors have extra logic for axis locking and snapping.
    // Return early if the point is not an elbow point for clarity.
    if (element.lineType !== "elbow" || !currentPoint.orientation) {
      onChangeElement?.(
        { innerPoints: newInnerPoints },
        { shouldAdd: true, previousProps: { innerPoints: e.target.attrs.undoData } }
      );
      onDragEnd();
      return;
    }

    const previousPoints = allPoints.slice(Math.max(0, pointIndex - 2), pointIndex);
    const nextPoints = allPoints.slice(pointIndex + 1, pointIndex + 3);
    let startIndex = innerPointIndex;
    if (shouldMergePoints(currentPoint, previousPoints)) {
      const realPreviousPoints = previousPoints.filter((p) => p.real);
      const toDelete = startIndex === 0 ? 1 : 2;
      newInnerPoints.splice(startIndex - realPreviousPoints.length, toDelete);
      startIndex -= toDelete;
    }
    if (shouldMergePoints(currentPoint, nextPoints)) {
      const toDelete = startIndex === newInnerPoints.length - 1 ? 1 : 2;
      newInnerPoints.splice(startIndex, toDelete);
    }

    onChangeElement?.(
      { innerPoints: newInnerPoints },
      { shouldAdd: true, previousProps: { innerPoints: e.target.attrs.undoData } }
    );
    onDragEnd();
  }

  /**
   * Remove an existing point from element.innerPoints
   */
  function onDoubleClick(e: KonvaEventObject<MouseEvent>) {
    if (!e.target.attrs.real === true) return;
    if (!element.innerPoints) return;

    const index = element.innerPoints.findIndex((p) => p.id == e.target.attrs.id);
    if (index == -1) return;

    // Points with orientation in the middle of the path also delete the point after them
    const toDelete = !!point.orientation && index > 0 && index < element.innerPoints.length - 1 ? 2 : 1;

    const newInnerPoints = [...element.innerPoints];
    newInnerPoints.splice(index, toDelete);

    onChangeElement?.(
      { innerPoints: newInnerPoints },
      { shouldAdd: true, previousProps: { innerPoints: element.innerPoints } }
    );
  }

  return (
    <Shape
      key={point.id}
      id={point.id}
      name="connector-shape-handle anchor"
      x={point.x}
      y={point.y}
      scaleX={1 / (element.scaleX ?? 1)}
      scaleY={1 / (element.scaleY ?? 1)}
      rotation={0}
      undoData={null}
      strokeWidth={1.5}
      hitStrokeWidth={1.5}
      strokeScaleEnabled={false}
      fill={doesPointExist ? "white" : HANDLE_COLOR}
      stroke={doesPointExist ? HANDLE_COLOR : "white"}
      radius={ConnectorTransformPointRadius}
      real={doesPointExist}
      draggable={true}
      opacity={isDragging ? 0 : 1}
      sceneFunc={drawScaledCircle}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onDblClick={onDoubleClick}
    />
  );
}

export function ConnectorEditor({
  start,
  end,
  data,
  element,
  onChangeElement,
}: {
  start: utils.ConnectorEndpoint;
  end: utils.ConnectorEndpoint;
  data: utils.SimpleConnectorData;
  element: Connector;
  onChangeElement?: utils.OnChangeElementFn;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const allPoints = getConnectorEditPoints(element, data);

  return (
    <Group x={element.x} y={element.y} scaleX={element.scaleX} scaleY={element.scaleY} rotation={element.rotate}>
      {allPoints.map((point) => (
        <EditPoint
          key={point.id}
          start={start}
          end={end}
          point={point as ConnectorEditPoint}
          allPoints={allPoints}
          element={element}
          onChangeElement={onChangeElement}
          isDragging={isDragging}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
        />
      ))}
    </Group>
  );
}
