import { userAtom, userTipsAtom } from "state-atoms/users-atoms";
import { useAtomValue } from "jotai";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import { TipName } from "shared/datamodel/schemas";
import { useMemo } from "react";

const CHOOSE_TEMPLATE_ONBOARDING_TIPS = [
  TipName.onboardingTeamSize,
  TipName.onboardingChooseTemplate,
  TipName.onboardingInvite,
];

const REGULAR_ONBOARDING_TIPS = [TipName.onboardingTeamSize, TipName.onboardingInvite];

function useOnboardingTips() {
  const isOnboardingChooseTemplateEnabled = useFeatureFlag("onboarding-choose-template");

  return useMemo(
    () => (isOnboardingChooseTemplateEnabled ? CHOOSE_TEMPLATE_ONBOARDING_TIPS : REGULAR_ONBOARDING_TIPS),
    [isOnboardingChooseTemplateEnabled]
  );
}

export function useCurrentAccountOnboardingTip() {
  const tips = useAtomValue(userTipsAtom);
  const onboardingTips = useOnboardingTips();

  return useMemo(() => {
    const filteredSteps = onboardingTips.filter((step) => !tips.has(step));

    return filteredSteps.length === 0 ? null : filteredSteps[0];
  }, [tips, onboardingTips]);
}

export default function useShowOnboarding() {
  const userTips = useAtomValue(userTipsAtom);
  const user = useAtomValue(userAtom);

  return user?.serialNumber === 1 && !REGULAR_ONBOARDING_TIPS.every((tip) => userTips.has(tip));
}
