//TODO: After mobile block, create a new componenent with all relevant hooks.
/* eslint-disable react-hooks/rules-of-hooks */
import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { useEffect, useRef, useState } from "react";
import useStateValue from "frontend/state/value";
import { BoardsGrid, GridError } from "frontend/boards-grid/boards-grid";
import useAuthentication from "frontend/hooks/use-authentication";
import AppLoader from "frontend/loader/app-loader";
import MobileBlockError from "frontend/error/mobile-block-screen";
import Head from "next/head";
import tracking from "frontend/tracking";
import consts, { MIN_PAID_SEATS, Plan } from "shared/consts";
import getStripePaymentStatus, { stringToPlan, updateAccountSeats, openCustomerPortal } from "frontend/billingUtils";
import { deleteCookie, readCookieValue } from "frontend/utils/cookie-utils";
import { createBoard } from "frontend/services/boardsService";
import { useRouter } from "next/router";
import { BoardPermission, BoardState, isPublicPermission } from "shared/datamodel/schemas/board";
import mixpanel from "mixpanel-browser";
import { addToLocalStorageArray } from "frontend/utils/storage-utils";
import Modal from "frontend/modal/modal";
import Promotion from "frontend/modals/promotions/promotion";
import { Action } from "frontend/state/actions";
import { loadAllInAppPromotions, loadPromotionById } from "frontend/utils/promotions-utils";
import { useSetAtom } from "jotai";
import { invoicePreviewsAtom, plansDataAtom } from "state-atoms/billing-atoms";
import { getPlansData, getUser, retrieveUpcomingInvoicePreview } from "frontend/api";
import { isBusyAtom } from "state-atoms/general-atoms";
import OnboardingFlow from "../frontend/modals/onboarding";
import PaymentErrorModal from "frontend/billing/payment-error-modal";
import useShowOnboarding from "../frontend/hooks/use-onboarding";
import useUrlQueryParamValue from "frontend/hooks/use-url-query-param";
import BigModalContainer from "frontend/modals/big-modal-container";
import InviteView from "frontend/modals/onboarding/invite-view";
import posthog from "posthog-js";
import { experimentsAtom } from "state-atoms/experiments-atmos";
import { planToString } from "shared/util/billing";
import useSeatsCount from "frontend/hooks/use-seats-count";
import { ErrorBoundary } from "react-error-boundary";
import { ModalErrorPage } from "frontend/error/error-page";
import { PlanData } from "shared/datamodel/schemas";
import { useFetchWithJotai } from "frontend/hooks/use-fetch-with-jotai";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import _JSXStyle from "styled-jsx/style";
import { BoardsGridNew } from "frontend/boards-grid/boards-grid-new";
import useReloadUserAfterCheckout from "frontend/hooks/use-reload-user-after-checkout";

if (typeof global !== "undefined") {
  Object.assign(global, { _JSXStyle });
}

export default function Home({
  isMobile,
  promotionId,
  paymentIntentClientSecret,
  showInvite,
}: {
  isMobile: boolean;
  promotionId: string;
  paymentIntentClientSecret: string;
  showInvite: boolean;
}) {
  const isNewHomePageEnabled = useFeatureFlag("home-page-refactor");
  if (isMobile) {
    return <MobileBlockError />;
  }

  //atoms
  const setInvoicePreviewsAtom = useSetAtom(invoicePreviewsAtom);
  const setExperimenstAtom = useSetAtom(experimentsAtom);

  const [showGridError, setShowGridError] = useState<GridError | null>(null);
  const [showPaymentFailureModal, setShowPaymentFailureModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(showInvite);

  const [{ user, boards, activePromotion }, dispatch] = useStateValue();
  const { isLoggedIn } = useAuthentication(true);
  const setBusy = useSetAtom(isBusyAtom);
  const showOnboarding = useShowOnboarding();
  const [error] = useUrlQueryParamValue("error");
  const [data] = useUrlQueryParamValue("data");

  const { userAccountCount, accountSubscribedCount, loadAccountSubscribedCount } = useSeatsCount(user);

  const router = useRouter();

  const didUpdateSeats = useRef(false); //bah react...
  const enablePaymentAbTest = useRef<boolean>(false); //bah react...

  useReloadUserAfterCheckout();

  useFetchWithJotai<PlanData[]>({ key: "plansData", fetchFn: getPlansData, atom: plansDataAtom });
  useEffect(() => {
    const enableAbTest = window && window.screen && window.screen.availWidth >= consts.MIN_WIDTH_TO_SHOW_PAYMENT_PAGE;
    enablePaymentAbTest.current = enableAbTest;
  }, []);

  useEffect(() => {
    error === "limitReached" && setShowGridError({ type: error, data });
  }, [error]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    async function checkPaymentStatus() {
      const status = await getStripePaymentStatus(paymentIntentClientSecret);
      tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-status", status);
      if (status === "failure") {
        setShowPaymentFailureModal(true);
      }
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    function initExpirements(retries: number, timeOutId?: NodeJS.Timeout) {
      const params = new URLSearchParams(window.location.search);
      const checkoutQueryParam = params.get("checkoutVariant");
      if (checkoutQueryParam && ["test", "control"].includes(checkoutQueryParam)) {
        setExperimenstAtom([{ name: consts.Experiment.CHECKOUT, value: checkoutQueryParam }]);
        return;
      }
      const MAX_RETRIES = 2;
      const checkoutExperimentValue = posthog.getFeatureFlag(consts.Experiment.CHECKOUT);
      console.log("checkoutExperimentValue", checkoutExperimentValue);
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
      if (typeof checkoutExperimentValue === "string") {
        setExperimenstAtom([{ name: consts.Experiment.CHECKOUT, value: checkoutExperimentValue }]);
      } else {
        if (retries < MAX_RETRIES) {
          //for some reason, we don't alwats get the feature flag value on initial page load after signup
          console.log("initExpirements - feature flag was not found, retrying", retries);
          const id = setTimeout(() => {
            initExpirements(++retries, id);
          }, 500);
        } else {
          console.log("initExpirements - max retires excceded, fallback to default vaoue");
          setExperimenstAtom([{ name: consts.Experiment.CHECKOUT, value: "test" }]); //default value
        }
      }
    }

    // TODO: Remove? Was inside if (false)
    // if (false && enablePaymentAbTest.current && (!experimentsAtomValue || experimentsAtomValue.length < 1)) {
    //   initExpirements(0);
    // }

    if (paymentIntentClientSecret) {
      checkPaymentStatus();
    }
  }, [isLoggedIn]);

  async function updateSeats(seatsCount: number) {
    try {
      await updateAccountSeats(seatsCount);
      //reload users data after updating
      const user = await getUser();
      dispatch({ type: Action.UpdatedUser, payload: user });
    } catch (e) {
      console.error("couldn't update number of seats because of an error", e);
    }
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    //Since we don't currently have a dedicated cron job that periodically checks the status of the users in the account (users added, accepted invite, removed etc.)
    //we check it here on page load. If we are on the free tier, make sure the account's subscription is synced with the amount of users in the account
    if (user?.planInfo?.is_free && !didUpdateSeats.current && userAccountCount !== 0) {
      didUpdateSeats.current = true;
      updateSeats(Math.max(userAccountCount, MIN_PAID_SEATS));
    } else if (user && !user?.planInfo?.is_free) {
      loadAccountSubscribedCount();
    }
    if (mixpanel) {
      const numberOfUsers = user.planInfo?.is_free ? userAccountCount : accountSubscribedCount;
      mixpanel.register({ numberOfUsers: numberOfUsers });
    }
  }, [user, userAccountCount]);

  useEffect(() => {
    if (
      user?.planInfo &&
      !user?.planInfo?.is_free &&
      !didUpdateSeats.current &&
      accountSubscribedCount > user?.planInfo?.seats_count
    ) {
      didUpdateSeats.current = true;
      updateSeats(accountSubscribedCount);
    }
  }, [accountSubscribedCount]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function checkForPromotions() {
      if (promotionId) {
        loadPromotionById(dispatch, promotionId);
      } else {
        loadAllInAppPromotions(dispatch);
      }
    }

    async function createTemplateBoard(templateId: string) {
      try {
        const boardState = BoardState.templateNotSetUp;
        const name = "New Canvas";
        return await createBoard(name, boardState, templateId, null, null, dispatch);
      } catch (e: any) {
        if (e.response.status === 406) {
          setShowGridError({ type: "limitReached", data: e.response.data.maxBoardsAllowed });
          setBusy(false);
        }
      }
      return null;
    }

    async function initTemplate() {
      const templateId = readCookieValue(consts.COOKIE_NAMES.BOARD_TEMPLATE);
      deleteCookie(consts.COOKIE_NAMES.BOARD_TEMPLATE, "/", ".workcanvas.com");
      if (templateId) {
        setBusy(true);
        const board = await createTemplateBoard(templateId);
        setBusy(false);
        if (board) {
          router.push(`/d/${board.documentId}`);
        }
      }
    }

    //A workaround to detect hard-signups: If x minutes has passes since the user was created until he landed in the app, we consider it a new signup
    if (user && user.createdAt) {
      initTemplate();
    }

    /* if (user?.planInfo && !activePromotion) {
       checkForPromotions();
     }*/
  }, [user]);

  useEffect(() => {
    if (boards && boards.length > 0) {
      const canvasesData = {
        totalCanvases: boards.length,
        totalOwnedCanvases: boards.filter((i) => i.isOwner).length,
        totalPublicCanvases: boards.filter((i) => isPublicPermission(i.permission)).length,
        totalPrivateCanvases: boards.filter((i) => i.permission === BoardPermission.private).length,
      };
      mixpanel.register(canvasesData);
    }
  }, [boards]);

  useEffect(() => {
    async function previewInvoices() {
      if (user?.planInfo?.seats_count) {
        const paidPlans = Object.values(Plan)
          .filter((i) => isNaN(parseInt(i as string)))
          .filter((i) => i != planToString(Plan.basic)?.toLowerCase());
        const invoicePromises: Promise<any>[] = [];
        for (const plan of paidPlans) {
          for (const period of ["month", "year"]) {
            const invoicePromise = retrieveUpcomingInvoicePreview(
              stringToPlan(plan as string)!,
              Math.max(user?.planInfo?.seats_count, MIN_PAID_SEATS),
              period as any
            );
            invoicePromises.push(invoicePromise);
          }
        }
        const invoices = await Promise.all(invoicePromises);
        setInvoicePreviewsAtom(invoices);
      }
    }
    if (user?.planInfo?.is_free) {
      previewInvoices();
    }
  }, [user?.planInfo?.seats_count]);

  function renderBoardsState() {
    return (
      <React.Fragment>
        {!showOnboarding && !showInviteModal && isNewHomePageEnabled ? (
          <BoardsGridNew showGridError={showGridError} />
        ) : (
          <BoardsGrid showGridError={showGridError} />
        )}
        {!showOnboarding && showInviteModal && (
          <BigModalContainer>
            <InviteView onCompleted={() => setShowInviteModal(false)} />
          </BigModalContainer>
        )}
        {showOnboarding && <OnboardingFlow />}
        {activePromotion && !activePromotion?.isDismissed && (
          <Modal dimBackground={true}>
            <Promotion
              id={activePromotion.id}
              discount={activePromotion.discount}
              duration={activePromotion.duration}
              code={activePromotion.couponCode}
              validUntill={activePromotion.validUntill}
              type={activePromotion.type}
              usersCount={user?.planInfo?.is_free ? userAccountCount : accountSubscribedCount}
              onClose={() => {
                addToLocalStorageArray(consts.LOCAL_STORAGE_KEYS.PROMOTIONS_DISMISSED, activePromotion.id);
                dispatch({ type: Action.PromotionLoaded, payload: { ...activePromotion, isDismissed: true } });
              }}
              plan={Plan.pro}
              interval={"month"}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  function render() {
    if (isLoggedIn) {
      return renderBoardsState();
    }
    return <AppLoader />;
  }

  return (
    <ErrorBoundary FallbackComponent={ModalErrorPage}>
      <div>
        <Head>
          <title>WorkCanvas.com</title>
        </Head>
        {render()}
        {showPaymentFailureModal && (
          <Modal dimBackground={true}>
            <PaymentErrorModal
              onClick={() => openCustomerPortal({ type: "update-payment" })}
              onDismiss={() => setShowPaymentFailureModal(false)}
            />
          </Modal>
        )}
      </div>
    </ErrorBoundary>
  );
}

export function getServerSideProps(context: any) {
  const UA = context.req.headers["user-agent"];
  const { promotionId, payment_intent_client_secret, show_invite } = context.query;

  let isMobile;
  if (UA) {
    isMobile = Boolean(
      /\b(blackberry|webos|iphone|iemobile)\b/i.test(UA) || /\b(android|windows phone|ipad|ipod)\b/i.test(UA)
    );
  }
  return {
    props: {
      isMobile,
      promotionId: promotionId || null,
      paymentIntentClientSecret: payment_intent_client_secret || null,
      showInvite: show_invite || null,
    },
  };
}
