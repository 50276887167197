import { Plan } from "shared/consts";
import { StripePortalConfig } from "shared/datamodel/schemas/billing";
import { createCheckoutSession, createPortalSession, updateSubscriptionSeats } from "./api";

export type PaymentStatus = "succsess" | "failure" | "processing";

export function runCheckoutSession(planId: Plan) {
  createCheckoutSession(planId).then(function (url: string) {
    setTimeout(() => {
      //make sure the server-side webhooks are getting called and executed
      window.location.assign(url);
    }, 1000);
  });
}

export function openCustomerPortal(portalConfig: StripePortalConfig) {
  return createPortalSession(portalConfig).then(function (url: string) {
    if (portalConfig.openInNewTab) {
      window.open(url, "_blank")!.focus();
    } else {
      window.location.assign(url);
    }
  });
}

export function updateAccountSeats(seatsCount: number) {
  return updateSubscriptionSeats(seatsCount);
}

export function stringToPlan(planName: string | null): Plan | undefined {
  switch (planName) {
    case "basic": {
      return Plan.basic;
    }
    case "team": {
      return Plan.team;
    }
    case "pro": {
      return Plan.pro;
    }
    default: {
      break;
    }
  }
}

export default async function getStripePaymentStatus(clientSecret: string | null): Promise<PaymentStatus | undefined> {
  // Initialize Stripe.js using your publishable key
  const stripePromise = (window as any).Stripe(process.env.STRIPE_PKEY!);

  if (clientSecret) {
    // Retrieve the PaymentIntent
    const { paymentIntent } = await stripePromise!.retrievePaymentIntent(clientSecret);

    // Inspect the PaymentIntent `status` to indicate the status of the payment
    // to your customer.
    //
    // Some payment methods will [immediately succeed or fail][0] upon
    // confirmation, while others will first enter a `processing` state.
    //
    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
    if (paymentIntent) {
      switch (paymentIntent.status) {
        case "succeeded": {
          return "succsess";
        } //'Success! Payment received.';
        case "processing": {
          return "processing";
        } //"Payment processing. We'll update you when payment is received.";
        case "requires_payment_method": {
          return "failure";
        } //'Payment failed. Please try another payment method.';
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        default: {
          return "failure";
        } //'Something went wrong.';
      }
    }
  }
}
