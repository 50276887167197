import { useMondayTask } from "frontend/hooks/use-board-integrations";
import { SyncService } from "frontend/services/syncService";
import { Group, Rect, Text } from "react-konva";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { IntegrationType } from "shared/integrations/integration";
import { IntegrationItem } from "shared/datamodel/schemas/integration-item";
import { useCurrentCanvasValue } from "../canvas-context";
import MondayTaskElement from "./monday-task-element";
import KonvaShimmer from "frontend/ui-components/konva-shimmer";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";
import BaseCanvasElement from "./base-canvas-element";
import { noop } from "frontend/utils/fn-utils";
import consts from "shared/consts";
import { BoardIntegrationLoadingState } from "frontend/hooks/use-board-integrations.types";

export function IntegrationCanvasElementFreeStanding({
  syncService,
  id,
  element,
  isEditingLink,
  changeElement,
  isEditable = true,
  onResize,
}: {
  syncService?: SyncService<RW>;
  id: string;
  element: IntegrationItem;
  isEditable?: boolean;
  isEditingLink: boolean;
  changeElement: any;
  onResize: any;
}) {
  return (
    <BaseCanvasElement
      id={id}
      type={"integrationItem"}
      x={element.x}
      y={element.y}
      element={element}
      onResize={onResize}
      isSelectable={true}
      isEditingLink={isEditingLink}
      changeElement={changeElement}
    >
      {element.integrationType == IntegrationType.monday && (
        <MondayItemElement
          id={id}
          syncService={syncService}
          isEditable={isEditable}
          color={element.fill}
          integrationId={element.integrationId}
          itemId={element.configuration.itemId}
          configuration={element.configuration}
        />
      )}
    </BaseCanvasElement>
  );
}

export function IntegrationCanvasElementInStack({
  syncService,
  id,
  element,
  isEditingLink,
  changeElement,
  isEditable = true,
}: {
  syncService?: SyncService<RW>;
  id: string;
  element: IntegrationItem;
  isEditable?: boolean;
  isEditingLink: boolean;
  changeElement: any;
}) {
  return (
    <BaseCanvasElement
      id={id}
      type={"integrationItem"}
      x={0}
      y={0}
      element={{ ...element, scaleX: 1, scaleY: 1 }}
      onResize={noop}
      isSelectable={true}
      isEditingLink={isEditingLink}
      changeElement={changeElement}
    >
      {element.integrationType == IntegrationType.monday && (
        <MondayItemElement
          id={id}
          syncService={syncService}
          isEditable={isEditable}
          integrationId={element.integrationId}
          itemId={element.configuration.itemId}
          configuration={element.configuration}
        />
      )}
    </BaseCanvasElement>
  );
}

export function MondayItemElement({
  id,
  color,
  integrationId,
  itemId,
  configuration = { itemId },
  syncService,
  isEditable = true,
  // Used as a hack to re-render the element.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeValue,
}: {
  id: string;
  color?: string | null;
  integrationId: string;
  itemId: string;
  configuration?: any;
  syncService?: SyncService<RW>;
  isEditable?: boolean;
  onChangeValue?: () => void;
}) {
  const [{ documentId, pusherChannel }] = useCurrentCanvasValue();

  const {
    item,
    isLoading,
    isFailed,
    allColumns,
    integrationConfig,
    updateItemData,
    isLoadingAccountIntegrations,
    loadingBoardIntegrationsState,
  } = useMondayTask(documentId, integrationId, itemId, pusherChannel);

  const showLoader = !item || isLoading || !documentId;

  function onChangeConfiguration(configuration: any) {
    syncService?.mutate.updateIntegrationItemConfiguration({ id, configuration });
  }

  if (
    isFailed ||
    isLoadingAccountIntegrations == BoardIntegrationLoadingState.failed ||
    loadingBoardIntegrationsState == BoardIntegrationLoadingState.failed
  ) {
    return (
      <Group>
        <Rect
          width={consts.DEFAULTS.MONDAY_CARD_WIDTH}
          height={consts.DEFAULTS.MONDAY_CARD_HEIGHT}
          shadowColor={"rgba(0, 0, 0, 0.12)"}
          shadowBlur={12}
          shadowOffsetY={4}
          stroke={"#EBEDF3"}
          strokeWidth={1}
          shadowForStrokeEnabled={false}
          fill={"white"}
        />
        <Text x={30} y={25} text="Failed loading this item" fontFamily="Poppins" fontSize={18} />
        <Text
          x={30}
          y={75}
          width={consts.DEFAULTS.MONDAY_CARD_WIDTH - 60}
          text={"It happens when you no longer have permissions, or the integration was removed from this canvas"}
          fontFamily="Poppins"
          fill={"gray"}
          fontSize={16}
          lineHeight={1.3}
        />
      </Group>
    );
  }

  if (showLoader) {
    return (
      <Group>
        <Rect
          width={376}
          height={220}
          shadowColor={"rgba(0, 0, 0, 0.12)"}
          shadowBlur={12}
          shadowOffsetY={4}
          stroke={"#EBEDF3"}
          strokeWidth={1}
          shadowForStrokeEnabled={false}
          fill={"white"}
        />
        <KonvaShimmer width={376} height={220} />
      </Group>
    );
  }

  const orderedSelectedColumns = allColumns.filter((column) =>
    integrationConfig.columns?.find((selectedColumn: any) => selectedColumn.id === column.id)
  );

  return (
    <MondayTaskElement
      id={id}
      color={color}
      element={item}
      onChangeColumnValue={updateItemData}
      documentId={documentId}
      allColumns={allColumns}
      selectedColumns={orderedSelectedColumns}
      configuration={configuration}
      onChangeConfiguration={onChangeConfiguration}
      integrationId={integrationId}
      isEditable={isEditable}
    />
  );
}

export function integrationItemTraits(element: IntegrationItem): ITraits {
  const { fill, integrationId, configuration } = element;
  return {
    integrationItemColor: {
      fill,
      integrationId,
      colorBy: configuration.colorBy,
    },
  };
}

export function integrationItemValidateTrait(element: IntegrationItem, trait: Trait, value: any) {
  if (trait == Trait.integrationItemColor) {
    const configuration = {
      ...element.configuration,
      colorBy: value.colorBy,
    };

    return { fill: value.fill, configuration };
  }

  return value;
}
