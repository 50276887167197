import React from "react";
import { Group, Rect } from "react-konva";
import { DEPARTMENT_ID, NAME_ID, PHOTO_ID, ROLE_ID } from "shared/datamodel/schemas/org-chart";
import { TextFieldHeight, NodeDrawProps } from "./orgchart-element";
import * as utils from "../orgchart-utils";
import { OrgchartOpenSidePanelButton } from "./orgchart-open-sidepanel-button";
import { OrgchartPersonPhoto } from "./orgchart-person-photo";
import {
  OrgChartNodeTextStyles,
  OrgchartSimpleInputField,
  OrgChartViewOnlyTextField,
} from "./orgchart-simple-input-field";
import { calcDashProperties } from "frontend/utils/node-utils";
import { KonvaEventObject } from "konva/types/Node";
import { TEXT_NODE_COLOR } from "shared/datamodel/mindmap-org-chart";

const LightBgStart = 62;
interface Point {
  x: number;
  y: number;
}

interface ReadOnlyNodeProps {
  id: string;
  type: string;
  isRoot: boolean;
  position: Point;
  width: number;
  height: number;
  fieldsToShow: string[];
  fieldsTitle: Record<string, { name: string }>;
  showImage: boolean;
  data: any;
  nodeDrawProps: NodeDrawProps;
  element: any;

  isSidePanelOpenForMe: boolean;
  onOpenPanel: any;
  onClick?: (ev: KonvaEventObject<MouseEvent>) => void;
  isExporting: boolean;
  isTextOnly?: boolean;
  text?: string;
  children?: React.ReactNode | undefined;
  //allow extra props
  [key: string]: any;
}

interface NodeProps extends ReadOnlyNodeProps {
  isSelected: boolean;
  editingFieldId: string;
  setEditingFieldId: any;
  onFieldUpdate: any;
  onDragStart?: (ev: any) => void;
  onPhotoClick?: () => void;
  //allow extra props
  [key: string]: any;
}

export function OrgChartNode({
  id,
  type,
  isRoot,
  position,
  width,
  height,
  fieldsToShow,
  fieldsTitle,
  showImage,
  data,
  nodeDrawProps,
  element,
  isSelected,
  editingFieldId,
  setEditingFieldId,
  onFieldUpdate,
  isSidePanelOpenForMe,
  onOpenPanel,
  onClick,
  onDragStart,
  onPhotoClick,
  isExporting,
  children,
  ...rest
}: NodeProps) {
  const { color, lineWidth, dash } = nodeDrawProps;
  const strokeColor = color;
  const strokeWidth = lineWidth;
  const top = position.y - height / 2,
    left = position.x - width / 2;
  const topPadding = showImage ? 20 : 0;
  const bgColor = utils.lightenColorMore(strokeColor);
  const stripeStart = showImage ? LightBgStart : LightBgStart - 20;
  const bgProps = {
    stripeColor: fieldsToShow.length ? bgColor : undefined,
    stripeStart,
    stripeEnd: height,
  };

  function renderField(
    fieldId: string,
    fieldTitle: string,
    y: number,
    initialValue: string,
    textStyleId: OrgChartNodeTextStyles
  ) {
    return (
      <OrgchartSimpleInputField
        key={fieldId}
        isEditing={isSelected && editingFieldId == fieldId}
        listening={isSelected && !element.lock}
        setIsEditing={(state) => setEditingFieldId(state ? fieldId : "")}
        rect={{ x: 1, width: width - 2, height: TextFieldHeight, y }}
        initialValue={initialValue}
        onFinish={(initial, final) => onFieldUpdate(fieldId, final, initial)}
        fieldTitle={fieldTitle}
        textStyleId={textStyleId}
        textColor={strokeColor}
      />
    );
  }

  // Some notes:
  // x,y are undefined for internal nodes, since we they should be draggable
  // by Konva events; so the top group's position is not controlled.
  // except for the root node, which is controlled by the canvas-stage.

  return (
    <Group
      type={type}
      x={isRoot ? element.x : undefined}
      y={isRoot ? element.y : undefined}
      id={id}
      name={id}
      element={element}
      isCanvasElement={true}
      isSelectable={true}
      isConnectable={false}
      isConnector={false}
      isFrame={false}
      isDraggable={isRoot} // this means: is draggable by canvas-stage
      draggable={!isRoot && !element.lock && !!onDragStart} // this means: is draggable by Konva events
      onDragStart={onDragStart}
      onClick={onClick}
      {...rest}
    >
      <Group x={left} y={top}>
        <Background
          width={width}
          height={height}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
          dash={dash}
          bgProps={bgProps}
        />
        {/* Header */}
        {showImage && <OrgchartPersonPhoto x={width / 2} y={0} photoUrl={data[PHOTO_ID]} onClick={onPhotoClick} />}
        {renderField(NAME_ID, "Employee Name", topPadding + 10, data[NAME_ID], "h1")}
        {renderField(ROLE_ID, "Role", topPadding + 25, data[ROLE_ID], "h2")}
        {/* Body */}
        <Group y={topPadding + 48}>
          {fieldsToShow.map((fieldId: string, index: number) =>
            renderField(fieldId, fieldsTitle[fieldId].name || " -- ", TextFieldHeight * index, data[fieldId], "default")
          )}
        </Group>
        {!isExporting && (
          <OrgchartOpenSidePanelButton
            cx={width - 19}
            cy={8}
            color={strokeColor}
            hoverColor={utils.lightenColorMore(color)}
            highlight={isSidePanelOpenForMe}
            onClick={onOpenPanel}
          />
        )}
      </Group>
      <Group x={position.x} y={position.y}>
        {children}
      </Group>
    </Group>
  );
}

function Background({
  width,
  height,
  strokeColor,
  strokeWidth,
  dash,
  bgProps,
  onMouseEnter,
  onMouseLeave,
}: {
  width: number;
  height: number;
  strokeColor: string;
  strokeWidth: number;
  dash: number;
  bgProps: {
    stripeColor: string | undefined;
    stripeStart: number;
    stripeEnd: number;
    borderRadius?: number;
  };
  onMouseEnter?: any;
  onMouseLeave?: any;
}) {
  function getInnerBorderRadius() {
    if (!bgProps.borderRadius) return 0;
    return Math.abs(strokeWidth - bgProps.borderRadius);
  }

  return (
    <>
      <Rect
        width={width}
        height={height}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        {...calcDashProperties(strokeWidth, dash)}
        fill="white"
        hitStrokeWidth={0}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cornerRadius={bgProps.borderRadius || 0}
      />
      {bgProps.stripeColor && (
        <Rect
          x={strokeWidth / 2}
          y={bgProps.stripeStart}
          width={width - strokeWidth}
          height={bgProps.stripeEnd - bgProps.stripeStart - strokeWidth / 2}
          fill={bgProps.stripeColor}
          cornerRadius={getInnerBorderRadius()}
        />
      )}
    </>
  );
}

export function OrgChartViewOnlyNode({
  id,
  type,
  isRoot,
  position,
  width,
  height,
  fieldsToShow,
  fieldsTitle,
  showImage,
  data,
  nodeDrawProps,
  isSidePanelOpenForMe,
  onOpenPanel,
  onClick,
  isExporting,
  isTextOnly,
  text,
  children,
  element,
  ...rest
}: ReadOnlyNodeProps) {
  const top = position.y - height / 2;
  const left = position.x - width / 2;
  const topPadding = showImage ? 20 : 0;
  let stripeStart = showImage ? LightBgStart : LightBgStart - 20;

  function getNodeBgProps() {
    const { color, lineWidth, dash } = nodeDrawProps;
    const strokeColor = color;
    const strokeWidth = lineWidth;

    let bgColor = fieldsToShow.length ? utils.lightenColorMore(strokeColor) : undefined;
    let borderRadius = 0;

    if (isTextOnly) {
      bgColor = TEXT_NODE_COLOR;
      stripeStart = strokeWidth / 2;
      borderRadius = 2;
    }

    const bgProps = {
      stripeColor: bgColor,
      stripeStart,
      stripeEnd: height,
      borderRadius,
    };

    return { color, dash, strokeColor, strokeWidth, bgProps };
  }

  const { color, dash, strokeColor, strokeWidth, bgProps } = getNodeBgProps();

  function renderField(
    fieldId: string,
    fieldTitle: string,
    y: number,
    initialValue: string,
    textStyleId: OrgChartNodeTextStyles
  ) {
    return (
      <OrgChartViewOnlyTextField
        key={fieldId}
        rect={{ x: 1, width: width - 2, height: TextFieldHeight, y }}
        initialValue={initialValue}
        fieldTitle={fieldTitle}
        textStyleId={textStyleId}
        textColor={strokeColor}
      />
    );
  }

  return (
    <Group
      type={type}
      x={isRoot ? element.x : undefined}
      y={isRoot ? element.y : undefined}
      id={id}
      name={id}
      element={element}
      isCanvasElement={true}
      isSelectable={true}
      isConnectable={false}
      isConnector={false}
      isFrame={false}
      isDraggable={isRoot} // this means: is draggable by canvas-stage
      draggable={false} // this means: is draggable by Konva events
      onClick={onClick}
      {...rest}
    >
      <Group x={left} y={top}>
        <Background
          width={width}
          height={height}
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
          dash={dash}
          bgProps={bgProps}
        />

        {!isTextOnly || !text ? (
          <>
            {/* Header */}
            {showImage && <OrgchartPersonPhoto x={width / 2} y={0} photoUrl={data[PHOTO_ID]} />}
            {renderField(NAME_ID, "Employee Name", topPadding + 10, data[NAME_ID], "h1")}
            {renderField(ROLE_ID, "Role", topPadding + 25, data[ROLE_ID], "h2")}
            {/* Body */}
            <Group y={topPadding + 48}>
              {fieldsToShow.map((fieldId: string, index: number) =>
                renderField(
                  fieldId,
                  fieldsTitle[fieldId].name || "--",
                  TextFieldHeight * index,
                  data[fieldId],
                  "default"
                )
              )}
            </Group>
            {!isExporting && (
              <OrgchartOpenSidePanelButton
                cx={width - 19}
                cy={8}
                color={strokeColor}
                hoverColor={utils.lightenColorMore(color)}
                highlight={isSidePanelOpenForMe}
                onClick={onOpenPanel}
              />
            )}
          </>
        ) : (
          <OrgChartViewOnlyTextField
            key={DEPARTMENT_ID}
            rect={{ x: 0, width, height, y: 0 }}
            initialValue={text}
            fieldTitle="Department"
            textStyleId="sourceText"
            textColor={strokeColor}
          />
        )}
      </Group>

      <Group x={position.x} y={position.y}>
        {children}
      </Group>
    </Group>
  );
}
