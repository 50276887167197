type Track = { id: string };

interface TrackData {
  key: string;
  i: number;
  size: number;
  start: number;
  end: number;
}

interface CellData {
  col: TrackData;
  row: TrackData;
  get key(): string;
}

export class TableCellsInfo {
  private _idsToIndex: undefined | { [key: string]: number };
  private _cellData: undefined | Map<number, CellData>;

  constructor(readonly _cols: Track[], readonly _rows: Track[], readonly _xs: number[], readonly _ys: number[]) {}

  get numColumns() {
    return this._cols.length;
  }
  get numRows() {
    return this._rows.length;
  }

  private buildMapping() {
    const mapping: Record<string, number> = {};
    for (let i = 0; i < this._cols.length; i++) {
      mapping[this._cols[i].id] = i;
    }
    for (let i = 0; i < this._rows.length; i++) {
      mapping[this._rows[i].id] = i;
    }
    return mapping;
  }

  private colInfo(i: number) {
    return {
      key: this._cols[i].id,
      i,
      size: this._xs[i + 1] - this._xs[i],
      start: this._xs[i],
      end: this._xs[i + 1],
    };
  }

  private rowInfo(i: number) {
    return {
      key: this._rows[i].id,
      i,
      size: this._ys[i + 1] - this._ys[i],
      start: this._ys[i],
      end: this._ys[i + 1],
    };
  }

  *cols(start = 0, end = Infinity) {
    start = Math.max(0, start);
    end = Math.min(this._cols.length, end);
    for (let i = start; i < end; i++) yield this.colInfo(i);
  }

  *rows(start = 0, end = Infinity) {
    /* eslint-disable @typescript-eslint/no-unused-vars */

    start = Math.max(0, start);
    end = Math.min(this._rows.length, end);
    for (let i = 0; i < this._rows.length; i++) yield this.rowInfo(i);
  }

  *getCellsInIntersection(colKeys: undefined | string[], rowKeys: undefined | string[]) {
    const columns = colKeys ?? this._cols.map((c) => c.id);
    const rows = rowKeys ?? this._rows.map((r) => r.id);
    for (const col of columns) {
      for (const row of rows) {
        if (this.colIndex(col) >= 0 && this.rowIndex(row) >= 0) {
          yield this.cell(col, row);
        }
      }
    }
  }

  colIndex(key: string) {
    this._idsToIndex ||= this.buildMapping();
    return this._idsToIndex[key];
  }

  // think about throwing exception if key not found. can make code easier for callers
  rowIndex(key: string) {
    this._idsToIndex ||= this.buildMapping();
    return this._idsToIndex[key];
  }

  colKey(index: number) {
    return this._cols[index].id;
  }
  rowKey(index: number) {
    return this._rows[index].id;
  }

  private hash(col: string, row: string) {
    return this.colIndex(col) + this.rowIndex(row) * 100000;
  }

  cell(col: string, row: string) {
    this._cellData ||= new Map();
    const h = this.hash(col, row);
    let value = this._cellData.get(h);
    if (!value) {
      value = {
        col: this.colInfo(this.colIndex(col)),
        row: this.rowInfo(this.rowIndex(row)),
        get key() {
          return `${col}-${row}`;
        },
      };
      this._cellData.set(h, value);
    }
    return value;
  }

  *infoForCells(cells: Iterable<{ col: string; row: string }>) {
    for (const cell of cells) {
      if (this.colIndex(cell.col) >= 0 && this.rowIndex(cell.row) >= 0) {
        yield this.cell(cell.col, cell.row);
      }
    }
  }
}
