import { minmax } from "frontend/geometry/utils";
import { minIndexBy, prop } from "frontend/utils/fn-utils";
import { clamp } from "frontend/utils/math-utils";
import { equals, keys, values } from "rambda";
import { TypeTableCell, TypeTableElement } from "shared/datamodel/schemas/table";
import { TableCellsInfo } from "./table-info";
import { TableIds } from "./table-utils";

export interface CellKey {
  col: string;
  row: string;
}

export type TableSelection =
  | null
  | { type: "set"; cells: CellKey[] }
  | { type: "rect"; start: CellKey; end: CellKey }
  | { type: "cols"; colKeys: string[] }
  | { type: "rows"; rowKeys: string[] };

export interface TableState {
  isEditing: boolean;
  cursor: null | CellKey;
  selection: TableSelection;
}

type TableActionType =
  | "dblclick"
  | "start-edit"
  | "end-edit"
  | "toggle-cell"
  | "reset-on-cell"
  | "reset"
  | "select-col"
  | "select-row"
  | "move-cursor"
  | "extend-selection";

export interface TableAction {
  type: TableActionType;
}

function assertNever(_: never): never {
  throw new Error("Unhandled case");
}

function* cellsInRect(start: CellKey, end: CellKey, element: any) {
  let colStart = element.cols.findIndex(({ id }: { id: string }) => id == start.col);
  let rowStart = element.rows.findIndex(({ id }: { id: string }) => id == start.row);
  let colEnd = element.cols.findIndex(({ id }: { id: string }) => id == end.col);
  let rowEnd = element.rows.findIndex(({ id }: { id: string }) => id == end.row);
  [colStart, colEnd] = minmax(colStart, colEnd);
  [rowStart, rowEnd] = minmax(rowStart, rowEnd);
  for (let col = Math.max(colStart, 0); col <= Math.min(colEnd, element.cols.length - 1); col++)
    for (let row = Math.max(rowStart, 0); row <= Math.min(rowEnd, element.rows.length - 1); row++) {
      yield { col: element.cols[col].id, row: element.rows[row].id };
    }
}

// TODO: should return coordinates in numbers since that's more useful for the callers
// but sometimes keys is also useful :-/
export function* cellsInSelection(selection: TableSelection, element: any) {
  if (selection == null) return;
  switch (selection.type) {
    case "set": {
      yield* selection.cells;
      break;
    }
    case "rect": {
      yield* cellsInRect(selection.start, selection.end, element);
      break;
    }
    case "cols": {
      for (const col of selection.colKeys) for (const { id: row } of element.rows) yield { col, row };
      break;
    }
    case "rows": {
      for (const { id: col } of element.cols) for (const row of selection.rowKeys) yield { col, row };
      break;
    }
    default: {
      assertNever(selection);
    }
  }
}

/**
 * Checks if selection is of full-columns (entire column along the table) and returns the column keys.
 * Will return null otherwise, if selection contains at least 1 columns that is not fully selected.
 */
export function getColumnsThatAreFullySelected(selection: TableSelection, info: TableCellsInfo): null | string[] {
  if (selection == null) return null;
  switch (selection.type) {
    case "cols": {
      return selection.colKeys.length ? selection.colKeys : null;
    }
    case "rows": {
      // if all the rows are selected, we treat that as if all columns are selected
      if (selection.rowKeys.length == info.numRows) return [...info.cols()].map(prop("key"));
    }
    // Fallthrough is intentional here.
    // eslint-disable-next-line no-fallthrough
    case "rect":
    case "set": {
      // Count how man cells are selected in each column
      // then check if that's the entire length of the table
      const countCols = {} as Record<string, number>;
      for (const cell of selectionCells(selection, info)) {
        countCols[cell.col.key] = (countCols[cell.col.key] ?? 0) + 1;
      }
      if (values(countCols).every(equals(info.numRows))) return keys(countCols);
      return null;
    }
    default: {
      assertNever(selection);
    }
  }
}
/**
 * Checks if selection is of full-rows (entire row along the table) and returns the row keys.
 * Will return null otherwise, if selection contains at least 1 row that is not fully selected.
 */
export function getRowsThatAreFullySelected(selection: TableSelection, info: TableCellsInfo): null | string[] {
  if (selection == null) return null;
  switch (selection.type) {
    case "cols": {
      // if all the columns are selected, we treat that as if all rows are selected
      if (selection.colKeys.length == info.numRows) return [...info.rows()].map(prop("key"));
      return null;
    }
    case "rows": {
      return selection.rowKeys.length ? selection.rowKeys : null;
    }
    case "rect":
    case "set": {
      // Count how man cells are selected in each row
      // then check if that's the entire length of the table
      const countRows = {} as Record<string, number>;
      for (const cell of selectionCells(selection, info)) {
        countRows[cell.row.key] = (countRows[cell.row.key] ?? 0) + 1;
      }
      if (values(countRows).every(equals(info.numColumns))) return keys(countRows);
      return null;
    }
    default: {
      assertNever(selection);
    }
  }
}

export function getTopLeftCell(selection: TableSelection, info: TableCellsInfo): CellKey | null {
  switch (selection?.type) {
    case "cols": {
      const [minCol] = minIndexBy((c) => info.colIndex(c), selection.colKeys);
      return { col: minCol, row: info.rowKey(0) };
    }

    case "rows": {
      const [minRow] = minIndexBy((r) => info.rowIndex(r), selection.rowKeys);
      return { col: info.colKey(0), row: minRow };
    }

    case "rect": {
      const colStart = info.colIndex(selection.start.col);
      const rowStart = info.rowIndex(selection.start.row);
      const colEnd = info.colIndex(selection.end.col);
      const rowEnd = info.rowIndex(selection.end.row);
      const c = Math.min(colStart, colEnd);
      const r = Math.min(rowStart, rowEnd);
      return { col: info.colKey(c), row: info.rowKey(r) };
    }

    case "set": {
      let minx = Number.MAX_SAFE_INTEGER,
        miny = Number.MAX_SAFE_INTEGER;
      for (const cell of selection.cells) {
        const x = info.colIndex(cell.col);
        const y = info.rowIndex(cell.row);
        minx = Math.min(minx, x);
        miny = Math.min(miny, y);
      }
      return { col: info.colKey(minx), row: info.rowKey(miny) };
    }
    default: {
      return null;
    }
  }
}

export function isSelectionRectangular(selection: TableSelection, info: TableCellsInfo): boolean {
  switch (selection?.type) {
    case "cols":
    case "rows":
    case "rect": {
      return true;
    }
    case "set": {
      const cells = selection.cells;
      // for each cell, convert it from key-coordinate to index-coordinate
      let minx = Number.MAX_SAFE_INTEGER,
        maxx = Number.MIN_SAFE_INTEGER,
        miny = Number.MAX_SAFE_INTEGER,
        maxy = Number.MIN_SAFE_INTEGER;
      for (const cell of cells) {
        const x = info.colIndex(cell.col);
        const y = info.rowIndex(cell.row);
        minx = Math.min(minx, x);
        maxx = Math.max(maxx, x);
        miny = Math.min(miny, y);
        maxy = Math.max(maxx, y);
      }
      // check that the entire selection is a rectangle by making sure all cells between min(x,y) and max(x,y) are selected
      // TODO: copilot wrote this so it can probably be optimized
      for (let x = minx; x <= maxx; x++)
        for (let y = miny; y <= maxy; y++)
          if (!cells.some((cell) => info.colIndex(cell.col) == x && info.rowIndex(cell.row) == y)) return false;
      return true;
    }
    default: {
      return false;
    }
  }
}
export function* selectionCells(selection: TableSelection, info: TableCellsInfo) {
  if (selection == null) return;
  switch (selection.type) {
    case "set": {
      yield* info.infoForCells(selection.cells);
      break;
    }
    case "rect": {
      {
        let colStart = info.colIndex(selection.start.col);
        let rowStart = info.rowIndex(selection.start.row);
        let colEnd = info.colIndex(selection.end.col);
        let rowEnd = info.rowIndex(selection.end.row);
        [colStart, colEnd] = minmax(colStart, colEnd);
        [rowStart, rowEnd] = minmax(rowStart, rowEnd);
        for (let col = Math.max(colStart, 0); col <= Math.min(colEnd, info.numColumns - 1); col++)
          for (let row = Math.max(rowStart, 0); row <= Math.min(rowEnd, info.numRows - 1); row++) {
            yield info.cell(info.colKey(col), info.rowKey(row));
          }
      }
      break;
    }
    case "cols": {
      yield* info.getCellsInIntersection(selection.colKeys, undefined);
      break;
    }
    case "rows": {
      yield* info.getCellsInIntersection(undefined, selection.rowKeys);
      break;
    }
    default: {
      assertNever(selection);
    }
  }
}

function toggleValueInArray<T>(arr: T[], value: T, equalsFn?: (i: T) => boolean): void {
  equalsFn ||= equals(value);
  const index = arr.findIndex(equalsFn);
  if (index == -1) arr.push(value);
  else arr.splice(index, 1);
}

function extendSelectionRectTo(draft: TableState, colrow: CellKey) {
  if (draft.selection == null || draft.selection.type != "rect") {
    draft.selection = { type: "rect", start: colrow, end: colrow };
  } else {
    draft.selection.end = colrow;
  }
}

function toggleCell(draft: TableState, cell: CellKey, element: any) {
  if (draft.selection?.type != "set") {
    const currentlySelected = [...cellsInSelection(draft.selection, element)];
    draft.selection = { type: "set", cells: currentlySelected };
  }
  toggleValueInArray(draft.selection.cells, cell);
}

export function isSelectionEmpty(selection: TableSelection): boolean {
  if (selection == null) return true;
  switch (selection.type) {
    case "set": {
      return selection.cells.length == 0;
    }
    case "rect": {
      return false;
    }
    case "cols": {
      return selection.colKeys.length == 0;
    }
    case "rows": {
      return selection.rowKeys.length == 0;
    }
    default: {
      assertNever(selection);
    }
  }
}

export function initialTableState() {
  return {
    isEditing: false,
    cursor: null,
    selection: null,
  } as TableState;
}

export function tableReducer(draft: TableState, action: TableAction & Record<string, any>) {
  switch (action.type) {
    case "start-edit": {
      draft.isEditing = draft.cursor != null;
      break;
    }
    case "end-edit": {
      draft.isEditing = false;
      break;
    }
    case "reset": {
      draft.isEditing = false;
      draft.cursor = null;
      draft.selection = null;
      break;
    }
    case "extend-selection": {
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      extendSelectionRectTo(draft, draft.cursor);
      break;
    }
    case "toggle-cell": {
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      toggleCell(draft, { col: action.col, row: action.row }, action.element);
      break;
    }
    case "select-col": {
      draft.isEditing = false;
      draft.cursor = null;
      if (draft.selection?.type != "cols") {
        draft.selection = { type: "cols", colKeys: [action.col] };
      } else {
        if (action.toggle) toggleValueInArray(draft.selection.colKeys, action.col);
        else draft.selection = { type: "cols", colKeys: [action.col] };
      }
      break;
    }
    case "select-row": {
      draft.isEditing = false;
      draft.cursor = null;
      if (draft.selection?.type != "rows") {
        draft.selection = { type: "rows", rowKeys: [action.row] };
      } else {
        if (action.toggle) toggleValueInArray(draft.selection.rowKeys, action.row);
        else draft.selection = { type: "rows", rowKeys: [action.row] };
      }
      break;
    }
    case "reset-on-cell": {
      draft.isEditing = false;
      draft.cursor = { col: action.col, row: action.row };
      draft.selection = { type: "rect", start: draft.cursor, end: draft.cursor };
      break;
    }
    case "move-cursor": {
      if (draft.cursor) {
        const { dx, dy, element, cells } = action;
        const cellArray = buildCellsArray(element, cells);

        // this loop is horrible; there must be a simpler way
        const x = element.cols.findIndex(({ id }: { id: string }) => id == draft.cursor!.col);
        const y = element.rows.findIndex(({ id }: { id: string }) => id == draft.cursor!.row);

        // Movement within a grid with merged cells should behave as follows:
        // dx,dy are always +1 or -1 or 0
        // the cursor should move to the next cell in the direction of dx,dy
        // if the cursor is inside a merged-cell when it starts, the next step must be outside of the merged
        // cell. if not, the movement is single-step
        // cursor is limited to grid legal coordinates at all times
        let nextX = x + dx;
        let nextY = y + dy;
        nextX = clamp(nextX, 0, element.cols.length - 1);
        nextY = clamp(nextY, 0, element.rows.length - 1);

        const original = cellArray.get(x, y);
        let data = cellArray.get(nextX, nextY);

        if (original != data && original.mergedTo && data.mergedTo == original.mergedTo) {
          while (data.mergedTo == original.mergedTo) {
            nextX += dx;
            nextY += dy;
            if (nextX < 0 || nextY < 0 || nextX >= element.cols.length || nextY >= element.rows.length) {
              break;
            }
            data = cellArray.get(nextX, nextY);
          }
        }
        nextX = clamp(nextX, 0, element.cols.length - 1);
        nextY = clamp(nextY, 0, element.rows.length - 1);
        draft.cursor = { col: element.cols[nextX].id, row: element.rows[nextY].id };
        draft.selection = { type: "rect", start: draft.cursor, end: draft.cursor };
      }
      break;
    }
  }
}

function buildCellsArray(element: TypeTableElement, cells: Record<string, TypeTableCell>) {
  const col_ids = element.cols.map(({ id }) => id);
  const row_ids = element.rows.map(({ id }) => id);
  const cellsArray = new Array(col_ids.length * row_ids.length);
  for (const id in cells) {
    const { col, row } = TableIds.getColAndRow(id);
    const x = col_ids.indexOf(col);
    const y = row_ids.indexOf(row);
    if (x >= 0 && y >= 0) {
      cellsArray[y * col_ids.length + x] = cells[id];
    }
  }
  return {
    get(col: number, row: number) {
      return cellsArray[row * col_ids.length + col];
    },
  };
}

export function checkIsSelectionARectangle(
  ids: string[],
  info: TableCellsInfo,
  cells: Record<any, { mergedTo?: string; spanX?: number; spanY?: number }>
): null | {
  minx: number;
  miny: number;
  width: number;
  height: number;
} {
  const grid = new Array(info.numColumns * info.numRows).fill(0);
  for (const id of ids) {
    const { col, row } = TableIds.getColAndRow(id);
    const x = info.colIndex(col);
    const y = info.rowIndex(row);
    if (x != undefined && y != undefined) {
      grid[y * info.numColumns + x] = 1;
    }
    if (cells[id]) {
      const { spanX = 1, spanY = 1 } = cells[id];
      if (spanX != 1 || spanY != 1) {
        for (let xx = x; xx < x + spanX; xx++) {
          for (let yy = y; yy < y + spanY; yy++) {
            grid[yy * info.numColumns + xx] = 1;
          }
        }
        // find all cells that are part of the merged cell, mark them as selected in our grid
        // for (const k in cells) {
        //   const cellData = cells[k];
        //   if (cellData?.mergedTo == cells[id].mergedTo) {
        //     const { col, row } = TableIds.getColAndRow(k);
        //     let x = info.colIndex(col);
        //     let y = info.rowIndex(row);
        //     if (x != undefined && y != undefined) {
        //       grid[y * info.numColumns + x] = 1;
        //     }
        //   }
        // }
      }
    }
  }
  // now check if the grid contains a single rectangular area
  // if not, return null
  // if true, return the top-left and width and height
  let minx = Number.MAX_SAFE_INTEGER,
    miny = Number.MAX_SAFE_INTEGER;
  let maxx = Number.MIN_SAFE_INTEGER,
    maxy = Number.MIN_SAFE_INTEGER;
  for (let y = 0; y < info.numRows; y++) {
    for (let x = 0; x < info.numColumns; x++) {
      if (grid[y * info.numColumns + x] == 1) {
        minx = Math.min(minx, x);
        maxx = Math.max(maxx, x);
        miny = Math.min(miny, y);
        maxy = Math.max(maxy, y);
      }
    }
  }

  // Check if the grid contains a single rectangular area
  for (let y = miny; y <= maxy; y++) {
    for (let x = minx; x <= maxx; x++) {
      if (grid[y * info.numColumns + x] == 0) {
        return null;
      }
    }
  }

  return {
    minx,
    miny,
    width: maxx - minx + 1,
    height: maxy - miny + 1,
  };
}
