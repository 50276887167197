import { DOMPlacement } from "frontend/ui-components/floaters/position-utils";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import { CSSProperties, useState } from "react";
import { WalkthroughTipModel } from "./walkthrough-tip-model";
import style from "./walkthrough-tip.module.css";
import { TipName } from "shared/datamodel/schemas";
import { PlayIcon } from "frontend/icons/play";

export default function WalkthroughTip({
  tip,
  onDismiss,
  placement,
  showSkipButton = true,
  headerTitle,
  onActionClick,
  showArrow = true,
}: {
  tip: Omit<WalkthroughTipModel, "id"> & { id?: TipName };
  onDismiss: (skip: boolean) => void;
  placement?: DOMPlacement;
  showSkipButton?: boolean;
  headerTitle?: string;
  onActionClick?: () => void;
  showArrow?: boolean;
}) {
  const { title, text, image, buttonTitle = "Got it" } = tip;
  const [showTipAction, setShowTipAction] = useState(true);

  const arrowSize = 15;

  const tipActionMap: Partial<Record<TipName, (onAction?: () => void) => JSX.Element | null>> = {
    [TipName.walkthroughExploreOrgChart]: (onAction) => (
      <div
        className={style.walkthroughPlayButton}
        onClick={() => {
          setShowTipAction(false);
          onAction?.();
        }}
      >
        <PlayIcon />
      </div>
    ),
  };

  function renderTipAction() {
    if (!tip.id) return null;
    return tipActionMap[tip.id]?.(onActionClick) ?? null;
  }

  function arrowPosition(placement: DOMPlacement) {
    const { side = "bottom", shift = 0 } = placement;
    let top, left, right, bottom, backgroundColor;
    let transform;
    switch (side) {
      case "top": {
        bottom = 0;
        left = `max(min(calc( 50% - ${shift}px ), calc( 100% - ${arrowSize}px )), ${arrowSize}px)`;
        transform = "translate(-50%, 50%) rotate(-45deg)";
        backgroundColor = "#113357";
        break;
      }
      case "bottom": {
        top = 0;
        left = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        transform = "translate(-50%, -50%) rotate(135deg)";
        backgroundColor = "#fff";
        break;
      }
      case "left": {
        top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        right = 0;
        transform = "translate(-50%, -50%) rotate(225deg)";
        if (shift < arrowSize / 2) {
          backgroundColor = "#113357";
        } else {
          backgroundColor = "#fff";
        }
        break;
      }
      case "right": {
        top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        left = 0;
        transform = "translate(-50%, -50%) rotate(45deg)";
        if (shift < arrowSize / 2) {
          backgroundColor = "#113357";
        } else {
          backgroundColor = "#fff";
        }
        break;
      }
      default: {
        // We should never get here!
        // Will throw build error if all cases were not covered
        const _unhandled: never = side;
      }
    }
    return {
      divStyle: {
        position: "absolute",
        top,
        left,
        right,
        bottom,
        transform,
      } as CSSProperties,
      svgStyle: {
        width: arrowSize,
        height: arrowSize,
        viewBox: `0 0 ${arrowSize} ${arrowSize}`,
        fill: backgroundColor,
      },
    };
  }

  let arrowSvgStyle, arrowDivStyle;
  if (placement) {
    const { divStyle, svgStyle } = arrowPosition(placement);
    arrowSvgStyle = svgStyle;
    arrowDivStyle = divStyle;
  }

  const showCloseIcon = tip.id === TipName.walkthroughExploreOrgChart;

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={() => onDismiss(false)}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <div className={style.container}>
      {showCloseIcon && renderCloseIcon()}
      {showArrow && placement && (
        <div className={style.arrow} style={arrowDivStyle}>
          <svg {...arrowSvgStyle}>
            <polygon points={`0,0 0,15 15,15`} />
          </svg>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <div className={style.image} style={{ backgroundImage: `url(${image})` }} />
        {showTipAction && renderTipAction()}
      </div>
      <div className={style.content}>
        {headerTitle && <div className={style.miniTitle}>{headerTitle}</div>}
        <div className={style.title}>{title}</div>
        <div className={style.description}>{text}</div>
        <div className={style.buttonContainer}>
          {showSkipButton ? (
            <span className={style.skipButton} onClick={() => onDismiss(true)}>
              Skip tutorial
            </span>
          ) : (
            <p />
          )}
          {showCloseIcon ? null : (
            <StyledButton title={buttonTitle} size={ButtonSize.small} onClick={() => onDismiss(false)} />
          )}
        </div>
      </div>
    </div>
  );
}
