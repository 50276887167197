import { pathToAbs, parsePath as mzSvgParsePath } from "mz-svg";
import { Point } from "frontend/utils/math-utils";
import BoundingBox from "./bounding-box";

export interface ISegmentMetrics {
  get bbox(): BoundingBox;
  get length(): number;
  finalPoint(): Point;
  point(t: number): Point;
  offsetAndScale(x: number, y: number, sx: number, sy: number): ISegmentMetrics;
}

const SvgPathSegmentRe = /([A-Z][^A-Z]*)/g;

interface MoveToCommand {
  cmd: "M";
  points: [number, number];
}

interface CloseCommand {
  cmd: "Z";
}

interface LineToCommand {
  cmd: "L";
  points: [number, number];
}
interface CurveCommand {
  cmd: "C";
  points: [number, number, number, number, number, number]; // cp1x, cp1y, cp2x, cp2y, x, y
}
interface QuadraticCurveCommand {
  cmd: "Q";
  points: [number, number, number, number]; // cpx, cpy, x, y
}
interface ArcCommand {
  cmd: "A";
  points: [number, number, number, number, number, number, number]; // rx, ry, x-axis-rotation, large-arc-flag, sweep-flag, x, y
}

type SvgPathCmd = MoveToCommand | CloseCommand | LineToCommand | CurveCommand | QuadraticCurveCommand | ArcCommand;

export function* svgPathToDrawCmds(path: string): Generator<SvgPathCmd> {
  const { commands } = mzSvgParsePath(/[a-z]/.test(path) ? pathToAbs(path) : path);

  let prevX = 0,
    prevY = 0;
  let pathStartX = 0,
    pathStartY = 0;

  for (const command of commands) {
    switch (command.command) {
      case "M": {
        yield { cmd: "M", points: command.params as [number, number] };
        pathStartX = prevX = command.params[0];
        pathStartY = prevY = command.params[1];
        break;
      }
      case "Z": {
        yield { cmd: "L", points: [pathStartX, pathStartY] };
        prevX = pathStartX;
        prevY = pathStartY;
        break;
      }
      case "L": {
        yield { cmd: "L", points: command.params as [number, number] };
        prevX = command.params[0];
        prevY = command.params[1];
        break;
      }
      case "H": {
        yield {
          cmd: "L",
          points: [command.params[0], prevY],
        };
        prevX = command.params[0];
        break;
      }
      case "V": {
        yield {
          cmd: "L",
          points: [prevX, command.params[0]],
        };
        prevY = command.params[0];
        break;
      }
      case "C": {
        yield { cmd: "C", points: command.params as [number, number, number, number, number, number] };
        prevX = command.params[4];
        prevY = command.params[5];
        break;
      }
      case "Q": {
        yield { cmd: "Q", points: command.params as [number, number, number, number] };
        prevX = command.params[2];
        prevY = command.params[3];
        break;
      }
      case "A": {
        // arc
        yield { cmd: "A", points: command.params as [number, number, number, number, number, number, number] };
        prevX = command.params[5];
        prevY = command.params[6];
        break;
      }

      case "S": // cubic smooth
      case "T": {
        // quadratic curve smooth
        // not supported for now
        break;
      }
    }
  }
}

function splitPath(path: string) {
  return path.replaceAll(",", " ").match(SvgPathSegmentRe) || [];
}

interface ParsedPathSegment {
  cmd: "L" | "C" | "Q";
  points: number[];
}

export function parsePath(path: string): null | ParsedPathSegment[] {
  let startPoint = [0, 0],
    curPoint = [0, 0],
    first = true;
  const list: ParsedPathSegment[] = [];
  for (const pathSegment of splitPath(path)) {
    let cmd = pathSegment[0];
    let numbers = pathSegment.substring(1).split(" ").filter(Boolean).map(Number);

    if (first && (cmd != "M" || numbers.length != 2)) {
      console.error("Path does not start with M");
      return null;
    }

    switch (cmd) {
      case "M": {
        first = false;
        startPoint = numbers;
        break;
      }
      case "H":
      case "V": {
        numbers = cmd == "H" ? [numbers[0], curPoint[1]] : [curPoint[0], numbers[0]];
        cmd = "L";
      }
      // fallthrough to L - easiest to implement, but many math functions are actually simpler and faster for H and V
      case "L":
      case "Q":
      case "C": {
        if (numbers.length % 2 != 0) {
          console.error("Invalid number of parameters for path command ", cmd, numbers);
          return null;
        }
        list.push({ cmd: cmd as any, points: curPoint.concat(numbers) });
        break;
      }
      case "Z": {
        list.push({ cmd: "L", points: curPoint.concat(startPoint) });
        break;
      }
      case "A":
      case "S":
      case "T": {
        console.error("Unsupported path command " + cmd);
        return null;
      }
    }
    curPoint = numbers.slice(-2);
  }
  return list;
}
