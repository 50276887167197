import { CanvasTemplateMinimal } from "shared/datamodel/schemas";
import TemplatePreview from "./template-preview";
import style from "./template-preview-modal.module.css";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import { RESET } from "jotai/utils";

export default function TemplatePreviewModal({
  template,
  chooseTemplate,
  setDisableOutsideDetection,
  onDismiss,
}: {
  template: CanvasTemplateMinimal;
  chooseTemplate: (template: CanvasTemplateMinimal) => void;
  setDisableOutsideDetection: (disable: boolean) => void;
  onDismiss: () => void;
}) {
  const { setModalDeepLink } = useModalDeepLink();

  const onClosePreviewTemplate = () => {
    setModalDeepLink({ modalType: RESET });
    onDismiss();
  };

  const renderCloseIcon = () => {
    return (
      <svg
        className={style.closeIcon}
        onClick={onClosePreviewTemplate}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="#848199"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div className={style.previewContainer}>
      {renderCloseIcon()}
      <TemplatePreview
        template={template}
        onChooseTemplate={chooseTemplate}
        onUpgradeModalShown={() => setDisableOutsideDetection(true)}
        onUpgradeModalHidden={() => setDisableOutsideDetection(false)}
        onDismiss={onClosePreviewTemplate}
      />
    </div>
  );
}
