import React, { useCallback, useContext, useRef, useState } from "react";

import Uppy, { UploadedUppyFile } from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Compressor from "@uppy/compressor";
import { DashboardModal, useUppy } from "@uppy/react";
import ImageEditor from "@uppy/image-editor";

import { getWriteSignedUrl } from "frontend/api";
import { buildUploadUrl } from "frontend/utils/file-utils";
import { Board } from "shared/datamodel/schemas";

interface OnSuccesCallback {
  (file: UploadedUppyFile<any, any>): void;
}

interface OpenModalFunction {
  (uniqueId: string, nodeId: string, onComplete: OnSuccesCallback): void;
}

interface ModalState {
  onComplete?: OnSuccesCallback;
  curOrgchartId: string;
  curNode: string;
  board?: Board | null;
}

export function useOrgChartUploaderAndDashboard(board?: Board | null) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const state = useRef<ModalState>({
    curOrgchartId: "",
    curNode: "",
    board,
  });
  state.current.board = board;

  const uppy = useUppy(() =>
    new Uppy({
      debug: false,
      restrictions: {
        maxFileSize: 15 * 1024 * 1024, //limit upload size to 15MB
        maxNumberOfFiles: 1,
        minNumberOfFiles: 0,
        allowedFileTypes: ["image/*"],
      },
    })
      .use(AwsS3, {
        companionUrl: `${process.env.HOST}/companion`,
        metaFields: ["name", "type", "url", "nodeId"],
        getUploadParameters(file) {
          const url = (file.meta as any).url;
          return getWriteSignedUrl(url, file.type!, "orgchart");
        },
      })
      .use(Compressor)
      .use(ImageEditor, {
        id: "ImageEditor",
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false,
        },
        cropperOptions: {
          croppedCanvasOptions: {},
          viewMode: 1,
          aspectRatio: 1,
          center: false,
          guides: false,
          responsive: true,
        },
      })
      .on("file-added", (file) => {
        const { curNode: nodeId, board } = state.current;
        if (!board) {
          console.warn("I don't have board data");
          return;
        }
        file.meta = {
          name: file.name,
          type: file.type,
          url: buildUploadUrl(board.accountId, board.documentId, file.name),
          nodeId,
        };
      })
      .on("complete", (result) => {
        if (state.current.onComplete) {
          result.successful.forEach(state.current.onComplete);
          delete state.current.onComplete; // delete the callback so GC can free up memory
        }
        uppy.cancelAll();
      })
  );

  const openModal = useCallback<OpenModalFunction>((uniqueId, nodeId, onComplete) => {
    state.current.onComplete = onComplete;
    state.current.curNode = nodeId;
    state.current.curOrgchartId = uniqueId;
    setModalOpen(true);
  }, []);

  const dashboard = (
    <DashboardModal
      key="dashboard-modal-for-orgchart"
      uppy={uppy}
      animateOpenClose={true}
      closeModalOnClickOutside={true}
      closeAfterFinish={true}
      autoOpenFileEditor={true}
      showRemoveButtonAfterComplete={false}
      open={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      plugins={["ImageEditor"]}
    />
  );
  return { dashboard, openModal };
}

const OrgchartFileUploader = React.createContext<OpenModalFunction | null>(null);

export function OrgchartFileUploaderProvider({
  openModal,
  children,
}: {
  openModal: OpenModalFunction;
  children: React.ReactNode;
}) {
  return <OrgchartFileUploader.Provider value={openModal}>{children}</OrgchartFileUploader.Provider>;
}

export function useOrgchartFileUploader() {
  return useContext(OrgchartFileUploader);
}
