import useStateValue from "frontend/state/value";
import { useEffect } from "react";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import LoadedState from "frontend/state/loadedState";
import { loadBoards } from "frontend/services/boardsService";

export default function useBoards() {
  const [{ user, boards, boardsState }, dispatch] = useStateValue();
  const isNewHomePageEnabled = useFeatureFlag("home-page-refactor");
  const isLoading = [LoadedState.initial, LoadedState.loading].includes(boardsState);

  useEffect(() => {
    if (user && dispatch) {
      loadBoards(dispatch, isNewHomePageEnabled);
    }
  }, [user, dispatch, isNewHomePageEnabled]);

  return { boards, isLoading };
}
