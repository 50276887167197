import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import {
  canvasElementPrefix,
  getUnixTimestampUTC,
  textEnabledDefaults,
  validateSchema,
  authenticatedPut,
  createElementId,
} from "../util/utils";
import { Point } from "./schemas/canvas-element";
import { Connector, connectorSchema, ConnectorInput } from "./schemas/connector";
import { ElementTextProperties } from "./canvas-element";

export async function getConnector(tx: ReadTransaction, id: string): Promise<Connector | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified shape ${id} not found.`);
    return null;
  }
  return validateSchema(connectorSchema, jv);
}

export async function placeConnectorPointAtIndex(
  tx: WriteTransaction,
  { id, index, point }: { id: string; index: number; point: Point }
): Promise<void> {
  const connector = await getConnector(tx, id);
  if (connector) {
    connector.points[index].x = point.x;
    connector.points[index].y = point.y;
    await putConnector(tx, { id, connector });
  }
}

export async function updateConnectorPointAtIndex(
  tx: WriteTransaction,
  { id, index, point }: { id: string; index: number; point: Point }
): Promise<void> {
  const connector = await getConnector(tx, id);
  if (connector) {
    connector.points[index].x += point.x;
    connector.points[index].y += point.y;
    await putConnector(tx, { id, connector });
  }
}

export async function updateConnectorOrientation(
  tx: WriteTransaction,
  { id, anchorY, otherAnchorIndex }: { id: string; anchorY: number; otherAnchorIndex: number }
): Promise<void> {
  const connector = await getConnector(tx, id);
  if (connector) {
    const orientation = anchorY - connector.points[otherAnchorIndex].y;
    connector.anchorOrientation = orientation > 0 ? "top" : "buttom";
    await putConnector(tx, { id, connector });
  }
}

export async function moveConnector(
  tx: WriteTransaction,
  { id, dx, dy }: { id: string; dx: number; dy: number }
): Promise<void> {
  const connector = await getConnector(tx, id);
  if (connector) {
    connector.x += dx;
    connector.y += dy;
    await putConnector(tx, { id, connector });
  }
}

export function putConnector(
  tx: WriteTransaction,
  { id, connector }: { id: string; connector: Connector }
): Promise<void> {
  //connector.anchors = connector.anchors.filter(n => n); //remove nulls;
  const next = { ...(connector as ReadonlyJSONObject), lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

function key(id: string): string {
  return `${canvasElementPrefix}${id}`;
}

export const connectorPrefix = `${canvasElementPrefix}connector-`;

const defaultStroke = "#0B2642";
const defaultStrokeWidth = 4;
const defaultLineType = "line";

type ConnectorInitOptions = {
  stroke?: string;
  strokeWidth?: number;
  lineType?: string;
  dash?: number;
  startArrow?: string;
  endArrow?: string;
} & ElementTextProperties;

export function placeConnector(data: ConnectorInitOptions, point: Point) {
  const {
    stroke = defaultStroke,
    strokeWidth = defaultStrokeWidth,
    lineType = defaultLineType,
    dash = 0,
    startArrow = "none",
    endArrow = "arrow",
    font = textEnabledDefaults.font,
    fontSize = textEnabledDefaults.fontSize,
    align = textEnabledDefaults.align,
    textColor = textEnabledDefaults.textColor,
    fontProps = textEnabledDefaults.fontProps,
  } = data;
  const initialPoints = [
    { x: 30, y: -130 },
    { x: 253, y: 320 },
  ];
  const id = createElementId();

  return {
    id: id,
    type: "connector",
    connector: {
      type: "connector",
      x: point.x,
      y: point.y,
      rotate: 0,
      connectedShapes: [],
      points: initialPoints,
      anchorMode: null,
      anchorsMode: [],
      point1_t: 0,
      point2_t: 0,
      anchorOrientation: null,
      anchorIndexes: [],
      stroke,
      strokeWidth,
      activeAnchorIndex: null,
      lineType,
      dash,
      font,
      fontSize,
      align,
      textColor,
      fontProps,
      pointerStyles: [endArrow, startArrow],
      zIndexLastChangeTime: getUnixTimestampUTC(),
    } as ConnectorInput,
  };
}
