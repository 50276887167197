import { z } from "zod";

export const metaDataSchema = z.object({
  type: z.string(),
  lastModifiedTimestamp: z.number().optional(),
});

export const TagType = "Tag";
const tagSchemaIntenal = z.object({
  type: z.literal(TagType),
  name: z.string(),
  color: z.string(),
  order: z.number().optional(), // optional because it was added late, so some tags don't have it
});
export const tagSchema = metaDataSchema.merge(tagSchemaIntenal);

export enum FileUploadState {
  NotStarted,
  InProgress,
  Completed,
  Failed,
}
const FileUploadStateEnum = z.nativeEnum(FileUploadState);
type FileUploadStateEnum = z.infer<typeof FileUploadStateEnum>;

export const FileMetadataType = "File";
export const FileUnlimitedSlot = -1;
const fileInfoSchemaInternal = z.object({
  type: z.literal(FileMetadataType),
  id: z.string(),
  fileName: z.string(),
  fileType: z.string(),
  url: z.string(),
  limitedSlot: z.number(),
  uploadedBy: z.string(),
  uploadState: FileUploadStateEnum,
  uploadProgress: z.number(),
});
export const fileInfoSchema = metaDataSchema.merge(fileInfoSchemaInternal);

export type Metadata = z.infer<typeof metaDataSchema>;
export type Tag = z.infer<typeof tagSchema>;
export type FileMetadata = z.infer<typeof fileInfoSchema>;
