import { useRef } from "react";

export function useBgJob<T>(callback: (data: T) => void, maxQueueSize = 1) {
  const ctx = useRef({
    queue: [] as T[],
    isRunning: false,
    callback,
  });
  ctx.current.callback = callback;

  function queue(data: T, clearPrevious = false) {
    if (clearPrevious) ctx.current.queue.length = 0;
    ctx.current.queue.push(data);
    if (ctx.current.queue.length > maxQueueSize) {
      ctx.current.queue.shift();
    }
    if (!ctx.current.isRunning) {
      ctx.current.isRunning = true;
      // google's dev blog suggested using requestAnimatioFrame(()=>setTimeout(run))
      // I have no idea why they did that, but I'm going to do it too
      window.requestAnimationFrame(() => setTimeout(run));
    }
  }

  function reset() {
    ctx.current.isRunning = false;
    ctx.current.queue = [];
  }

  function run() {
    while (ctx.current.queue.length) {
      ctx.current.callback(ctx.current.queue.shift()!);
    }
    ctx.current.isRunning = false;
  }

  return { queue, reset };
}
