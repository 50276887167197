import React from "react";
import style from "./invite-notification.module.css";
import StyledButton from "frontend/ui-components/styled-button";
import { CloseIcon } from "frontend/ui-components/svg-shapes";
import { getPathPrefix } from "../utils/getPathPrefix";

export default function InviteNotification({
  onDismiss,
  onInviteClick,
}: {
  onDismiss: () => void;
  onInviteClick?: (title?: string) => void;
}) {
  const title = "Invite People";

  return (
    <div className={style.container}>
      {/* {renderLeftSideCursors()} */}
      <div className={style.centerContainer}>
        <div className={style.text}>
          <span className={style.bold}>Share the joy! ✨ </span>
          <span>Invite your teammates to collaborate on your canvas </span>
        </div>
        <StyledButton
          title={title}
          icon={getPathPrefix("/images/plus-icon.svg")}
          customStyle={{ height: "30px" }}
          onClick={() => onInviteClick && onInviteClick(`${title} banner_button`)}
        />
      </div>
      {/* {renderRightSideCursors()} */}
      <div data-testid="close_invite_banner">
        <CloseIcon onClick={() => onDismiss()} color={"white"} />
      </div>
    </div>
  );
}
