import Konva from "konva";
import { nanoid } from "nanoid";
import { Connector, InnerPointSchema } from "shared/datamodel/schemas";
import { Degrees, konvaTransformForElement, toRadians } from "frontend/utils/transform";
import { ConnectorEndpoint, ConnectorEditPoint } from "./connector-utils";
import { Point, IRect, Rectangle, Segment, smoothMaxUnit } from "frontend/utils/math-utils";
import { ArrowHeadDrawingData, ArrowHeadType } from "./arrow-heads";
import {
  BASIC_ORTHOGONAL_ROUTE_MARGIN,
  MAX_ORTHOGONAL_ROUTE_MARGIN,
  MIN_ORTHOGONAL_ROUTE_MARGIN,
} from "./connector-constants";
import * as MathUtils from "frontend/utils/math-utils";
import * as utils from "./connector-utils";
import * as PointUtils from "frontend/utils/point-utils";

export function drawScaledCircle(context: any, shape: Konva.Shape) {
  const stage = shape.getStage();
  if (!stage) return;

  // Get the current scale of the stage
  const scale = stage.scaleX();

  // Adjust the radius and strokeWidth based on the scale
  const adjustedRadius = shape.attrs.radius / scale;

  // Begin drawing
  context.beginPath();
  context.arc(0, 0, adjustedRadius, 0, Math.PI * 2, false);

  // Complete the shape
  context.fillStrokeShape(shape);
}

export function computeConnectorDrawingData(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  element: Partial<Connector>,
  startBbox: IRect,
  endBbox: IRect,
  startRotation: number,
  endRotation: number,
  curveStrength?: number
) {
  let router: ConnectorRouter;

  switch (element.lineType) {
    case "line": {
      router = new LinearConnectorRouter(start, end, element, startBbox, endBbox, startRotation, endRotation);
      break;
    }
    case "curve": {
      router = new BezierConnectorRouter(
        start,
        end,
        element,
        startBbox,
        endBbox,
        startRotation,
        endRotation,
        curveStrength
      );
      break;
    }
    case "elbow": {
      // We do not support rotation for elbow connectors with inner points.
      // Also, we do not support inner points for elbow connectors with rotation.
      const isRotated = element.rotate && element.rotate !== 0;
      const hasInnerPoints = element.innerPoints && element.innerPoints.length > 0;

      if (!hasInnerPoints && !isRotated) {
        router = new SmartOrthogonalConnectorRouter(
          start,
          end,
          element,
          startBbox,
          endBbox,
          startRotation,
          endRotation
        );
      } else {
        router = new OrthogonalConnectorRouter(start, end, element, startBbox, endBbox, startRotation, endRotation);
      }
      break;
    }
    case undefined: {
      console.warn("lineType must be supplied");
      return new utils.SimpleConnectorData();
    }
    default: {
      console.warn("Unsupported linetype");
      return new utils.SimpleConnectorData();
    }
  }

  return router.draw();
}

export function debugConnectorDraw(...args: Parameters<typeof computeConnectorDrawingData>) {
  let [start, end, element, startBbox, endBbox, startRotation, endRotation] = args;
  if (element.lineType !== "elbow") return { data: computeConnectorDrawingData(...args), byproducts: {} };
  if (!startBbox) startBbox = { x: start.x, y: start.y, width: 0, height: 0 };
  if (!endBbox) endBbox = { x: end.x, y: end.y, width: 0, height: 0 };
  const router = new SmartOrthogonalConnectorRouter(
    start,
    end,
    element,
    startBbox,
    endBbox,
    startRotation,
    endRotation
  );
  const data = router.draw();
  return { data, byproducts: router.byproducts };
}

export function getConnectorEditPoints(element: Connector, data: utils.SimpleConnectorData): ConnectorEditPoint[] {
  switch (element.lineType) {
    case "line":
    case "curve": {
      return calculateEditPoints(element, data);
    }
    case "elbow": {
      return calculateOrthogonalEditPoints(element, data);
    }
    default: {
      console.warn("Unsupported linetype");
      return [];
    }
  }
}

/**
 * Returns a list of existing and available points that can be used to customize the path.
 * Assumes a path consists of startPoint -> ...innerPoints -> endPoint
 */
function calculateEditPoints(element: Connector, data: utils.SimpleConnectorData): ConnectorEditPoint[] {
  const points: ConnectorEditPoint[] = [];
  let index = 0;
  for (const segment of data.segments) {
    const [x, y] = segment.point(0);
    const [xMid, yMid] = segment.point(0.5);
    const startPoint = element.innerPoints?.find((p) => Math.abs(p.x - x) < 1e-3 && Math.abs(p.y - y) < 1e-3);
    const midPoint = { x: xMid, y: yMid };

    if (startPoint) {
      points.push({ ...startPoint, real: true });
      index++;
    }
    points.push({ ...midPoint, id: nanoid(10), real: false, addAt: index });
  }
  return points;
}

/**
 * Returns a list of existing and available points that can be used to customize the orthogonal path.
 * Orthogonal path points are calculated differently than normal paths.
 */
function calculateOrthogonalEditPoints(element: Connector, data: utils.SimpleConnectorData) {
  const points: ConnectorEditPoint[] = [];
  if (data.segments.length === 1) return points;
  if (element.rotate && element.rotate !== 0) return points; // We do not support rotation for elbow connectors with inner points

  const getSegmentOrientation = (segment: utils.ICurveSegment) => {
    const [x1, y1] = segment.point(0);
    const [x2, y2] = segment.point(1);

    if (segment.length() > 0 && Math.abs(x1 - x2) < 1e-3) {
      return "vertical";
    } else if (segment.length() > 0 && Math.abs(y1 - y2) < 1e-3) {
      return "horizontal";
    }
  };

  const getAvailableEditPoint = (index: number, addAt?: number) => {
    const addAtIndex = addAt ?? index;
    const segment = data.segments[index];
    if (segment.length() === 0) return null;

    // Ignore segments created as a result of padding
    const isEdgeSegment = index === 0 || index === data.segments.length - 1;
    if (isEdgeSegment && segment.length() <= MAX_ORTHOGONAL_ROUTE_MARGIN) return null;

    const [x, y] = segment.point(0.5);

    // No duplicate points
    if (element.innerPoints?.some((innerPoint) => innerPoint.x === x && innerPoint.y === y)) {
      return null;
    }

    const orientation = getSegmentOrientation(segment);
    const point = { x, y };
    return { ...point, orientation, id: nanoid(10), real: false, addAt: addAtIndex };
  };

  let firstEditPoint = getAvailableEditPoint(0);
  if (!firstEditPoint) firstEditPoint = getAvailableEditPoint(1, 0);
  if (firstEditPoint) points.push(firstEditPoint);

  // Generate available points in the middle of the path if no inner points exist
  const innerPoints = element.innerPoints ?? [];
  if (innerPoints.length === 0 && data.segments.length > 2) {
    for (let index = 0; index < data.segments.length - 1; index++) {
      const editPoint = getAvailableEditPoint(index);
      if (editPoint) points.push(editPoint);
    }
  }

  // Calculate final edit point for fallback
  const totalPoints = points.length + innerPoints.length;
  let finalEditPoint = getAvailableEditPoint(data.segments.length - 1, totalPoints);
  if (!finalEditPoint) finalEditPoint = getAvailableEditPoint(data.segments.length - 2, totalPoints);

  // Turn inner points into edit points
  for (const [index, point] of innerPoints.entries()) {
    const _point = { ...point };
    const previous = index > 0 ? innerPoints[index - 1] : firstEditPoint ?? data.firstPoint();
    const next = index < innerPoints.length - 1 ? innerPoints[index + 1] : finalEditPoint ?? data.finalPoint();

    if (_point.orientation === "vertical") {
      _point.y = (previous.y + next.y) / 2;
    } else if (point.orientation === "horizontal") {
      _point.x = (previous.x + next.x) / 2;
    }

    points.push({ ..._point, real: true });
  }

  // Recalculate final point after adding inner points
  finalEditPoint = getAvailableEditPoint(data.segments.length - 1, points.length);
  if (!finalEditPoint) finalEditPoint = getAvailableEditPoint(data.segments.length - 2, points.length);
  if (finalEditPoint) points.push(finalEditPoint);

  return points;
}

abstract class ConnectorRouter {
  protected innerPoints: InnerPointSchema[];
  protected startArrowOffset: number;
  protected endArrowOffset: number;
  public byproducts: any = {}; // For debugging

  constructor(
    protected start: ConnectorEndpoint,
    protected end: ConnectorEndpoint,
    protected element: Partial<Connector>,
    protected startBbox: IRect,
    protected endBbox: IRect,
    protected startRotation: number,
    protected endRotation: number,
    protected curveStrength?: number
  ) {
    this.innerPoints = element.innerPoints ?? [];
    const arrows = (element.pointerStyles ?? ["none", "none"]) as ArrowHeadType[];

    const defaultStrokeWidth = 5; // Second option on the slider
    const arrowHeadScale = (Number(element.strokeWidth) ?? 5) / defaultStrokeWidth;
    const scale = Math.max(this.element.scaleX ?? 1, this.element.scaleY ?? 1); // Undo element scale

    this.startArrowOffset = (ArrowHeadDrawingData[arrows[1]].width * 0.8 * arrowHeadScale) / scale;
    this.endArrowOffset = (ArrowHeadDrawingData[arrows[0]].width * 0.8 * arrowHeadScale) / scale;
  }

  protected initialize() {
    [this.start, this.end] = this.adjustEndpoints();
  }

  public abstract route(): Point[];
  public abstract draw(): utils.SimpleConnectorData;

  /**
   * Adjusts the start and end points of a connector, taking into account their arrowheads.
   * This function runs as part of the initialize() method.
   */
  protected abstract adjustEndpoints(): [ConnectorEndpoint, ConnectorEndpoint];
}

class LinearConnectorRouter extends ConnectorRouter {
  constructor(...args: ConstructorParameters<typeof ConnectorRouter>) {
    super(...args);
    this.initialize();
  }

  protected adjustEndpoints(): [ConnectorEndpoint, ConnectorEndpoint] {
    const secondPoint = this.innerPoints.length > 0 ? this.innerPoints[0] : this.end;
    const newStart = PointUtils.moveTowards(this.start, secondPoint, this.startArrowOffset);

    const secondToLastPoint = this.innerPoints.length > 0 ? this.innerPoints[this.innerPoints.length - 1] : this.start;
    const newEnd = PointUtils.moveTowards(this.end, secondToLastPoint, this.endArrowOffset);

    return [
      { ...newStart, rotation: this.start.rotation },
      { ...newEnd, rotation: this.end.rotation },
    ];
  }

  public route(): Point[] {
    return [this.start, ...this.innerPoints, this.end];
  }

  public draw(): utils.SimpleConnectorData {
    const data = new utils.SimpleConnectorData();
    const points = this.route();
    data.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      data.lineTo(points[i].x, points[i].y);
    }
    return data;
  }
}

class BezierConnectorRouter extends ConnectorRouter {
  private inverseTransform: Konva.Transform;
  private controlPoints: Point[];

  constructor(...args: ConstructorParameters<typeof ConnectorRouter>) {
    super(...args);
    this.inverseTransform = konvaTransformForElement({
      x: 0,
      y: 0,
      scaleX: this.element.scaleX ?? 1,
      scaleY: this.element.scaleY ?? 1,
      rotate: this.element.rotate ?? 0,
    });
    // we invert the scale-rotation matrix so we can apply it to normal vectors
    // The point is that we want to compute normals in the connector reference frame,
    // but they will be scaled and rotated and deformed !
    // so we apply the inverse transform, and then the real transform will bring them
    // back to their good shape.
    this.inverseTransform.invert();
    this.controlPoints = this.getControlPoints();

    // During intialization the conrol points for the curves are calculated.
    // This means a lot of computation needs to be done beforehand.
    this.initialize();
  }

  protected adjustEndpoints(): [ConnectorEndpoint, ConnectorEndpoint] {
    const adjustedStart = PointUtils.moveTowards(this.start, this.controlPoints[0], this.startArrowOffset);
    const adjustedEnd = PointUtils.moveTowards(
      this.end,
      this.controlPoints[this.controlPoints.length - 1],
      this.endArrowOffset
    );
    return [adjustedStart, adjustedEnd];
  }

  private getControlPoints() {
    const startNormal =
      this.start.rotation == undefined ? { x: Number.NaN, y: Number.NaN } : this.prepNormal(this.start.rotation);
    const endNormal =
      this.end.rotation == undefined ? { x: Number.NaN, y: Number.NaN } : this.prepNormal(this.end.rotation);

    if (this.innerPoints.length === 0) {
      const signedDx = this.end.x - this.start.x;
      const signedDy = this.end.y - this.start.y;
      const orientation = signedDx > signedDy - 0.0001 ? "horizonal" : "vertical"; // Note: the -0.0001 improves stability for cases when dx==dy
      return this.getControlPointsWithoutInnerPoints(signedDx, signedDy, startNormal, endNormal, orientation);
    } else {
      return this.getControlPointsWithInnerPoints(startNormal, endNormal);
    }
  }

  private getControlPointsWithoutInnerPoints(
    signedDx: number,
    signedDy: number,
    startNormal: Point,
    endNormal: Point,
    orientation: "horizonal" | "vertical"
  ) {
    let cp1: Point, cp2: Point;
    const dx = Math.abs(signedDx);
    const dy = Math.abs(signedDy);
    if (this.start.rotation == undefined && this.end.rotation == undefined) {
      // No rotation on either side -> Freestanding connector
      // Note: the -0.0001 improves stability for cases when dx==dy
      if (orientation === "horizonal") {
        cp1 = { x: this.start.x + 0.5 * signedDx, y: this.start.y };
        cp2 = { x: this.end.x - 0.5 * signedDx, y: this.end.y };
      } else {
        cp1 = { x: this.start.x, y: this.start.y + 0.5 * signedDy };
        cp2 = { x: this.end.x, y: this.end.y - 0.5 * signedDy };
      }
    } else if (this.start.rotation != undefined && this.end.rotation != undefined) {
      // Rotation on both sides -> Connector between elements
      const distance = this.curveStrength ?? smoothMaxUnit(10, Math.max(dx, dy) * 0.25);
      cp1 = { x: this.start.x + startNormal.x * distance, y: this.start.y + startNormal.y * distance };
      cp2 = { x: this.end.x + endNormal.x * distance, y: this.end.y + endNormal.y * distance };
    } else {
      const distance = smoothMaxUnit(50, Math.max(dx, dy) * 0.25);
      if (this.start.rotation != undefined) {
        // Only start shape attached
        cp1 = { x: this.start.x + startNormal.x * distance, y: this.start.y + startNormal.y * distance };
      } else {
        // Only end shape attached
        cp1 = { x: this.end.x + endNormal.x * distance, y: this.end.y + endNormal.y * distance };
      }
      cp2 = { ...cp1 };
    }
    return [cp1, cp2];
  }

  private getControlPointsWithInnerPoints(startNormal: Point, endNormal: Point) {
    const placeholder = { x: Number.NaN, y: Number.NaN }; // Placeholder for points to be filled later
    const result: Point[] = [{ ...placeholder }]; // Start normal

    for (let index = 0; index < this.innerPoints.length; index++) {
      const previous = index == 0 ? this.start : this.innerPoints[index - 1];
      const current = this.innerPoints[index];
      const next = index == this.innerPoints.length - 1 ? this.end : this.innerPoints[index + 1];

      const toPrevious = { x: current.x - previous.x, y: current.y - previous.y };
      const toNext = { x: current.x - next.x, y: current.y - next.y };
      const length1 = Math.sqrt(toPrevious.x * toPrevious.x + toPrevious.y * toPrevious.y);
      const length2 = Math.sqrt(toNext.x * toNext.x + toNext.y * toNext.y);

      // The bisector for this point = (toPrev/len1 + toNext/len2)
      const bisector = {
        x: toPrevious.x / length1 + toNext.x / length2,
        y: toPrevious.y / length1 + toNext.y / length2,
      };
      PointUtils.normalize(bisector);

      // Tangents are rotate(bisector, 90) and it's opposite
      // bisector can be(0,0), if cur is in the middle of [prev,next]
      // that will insert NaN into the calculations, so we check it and
      const tangent = PointUtils.invalid(bisector) ? PointUtils.normalized(toPrevious) : PointUtils.rotated90(bisector);

      // Tangents length should be some multiple of min(len1,len2)
      const tlen = 0.4 * Math.min(length1, length2);
      // compute control points:  cur ± tangent*tlen
      let controlPoint1 = PointUtils.pAdd(current, PointUtils.pMul(tangent, tlen));
      let controlPoint2 = PointUtils.pSub(current, PointUtils.pMul(tangent, tlen));
      // put control points in the right order; by looking at their angle with the tangent
      // angle is checked with dot product (if angle of 2 vectors < 90 deg, dot > 0)
      if (tangent.x * toPrevious.x + tangent.y * toPrevious.y > 0) {
        const temporary = controlPoint1;
        controlPoint1 = controlPoint2;
        controlPoint2 = temporary;
      }
      result.push(
        { x: controlPoint1.x, y: controlPoint1.y },
        { x: current.x, y: current.y },
        { x: controlPoint2.x, y: controlPoint2.y }
      );
    }
    result.push({ ...placeholder }); // End normal

    // Fill tangents for start and end; default is towards next and prev points
    if (this.start.rotation == undefined) {
      // Next point for start is at index 2
      const p = result[2];
      const cp = PointUtils.lerp(this.start, p, 0.3);
      result[0] = cp;
    } else {
      const availableLength = Math.max(
        Math.abs(this.innerPoints[0].x - this.start.x),
        Math.abs(this.innerPoints[0].y - this.start.y)
      );
      const controlPointDistance = availableLength / 2;
      const x = this.start.x + startNormal.x * controlPointDistance;
      const y = this.start.y + startNormal.y * controlPointDistance;
      result[0].x = x;
      result[0].y = y;
    }

    if (this.end.rotation == undefined) {
      // prev point for end is at index -3
      const p = result[result.length - 3];
      const cp = PointUtils.lerp(this.end, p, 0.3);
      result[result.length - 1] = cp;
    } else {
      const N = this.innerPoints.length - 1;
      const availableLength = Math.max(
        Math.abs(this.innerPoints[N].x - this.end.x),
        Math.abs(this.innerPoints[N].y - this.end.y)
      );
      const controlPointDistance = availableLength / 2;
      const x = this.end.x + endNormal.x * controlPointDistance;
      const y = this.end.y + endNormal.y * controlPointDistance;
      result[result.length - 1].x = x;
      result[result.length - 1].y = y;
    }

    return result;
  }

  public route(): Point[] {
    return [this.start, ...this.controlPoints, this.end];
  }

  private prepNormal(rotation: Degrees) {
    const rad = toRadians(rotation);
    const direction = this.inverseTransform.point({
      x: Math.cos(rad),
      y: Math.sin(rad),
    });
    return PointUtils.normalize(direction, direction);
  }

  public draw(): utils.SimpleConnectorData {
    const data = new utils.SimpleConnectorData();

    // Bezier curve extend from shapes perpendicularly.
    // Our connector might be rotated and scaled non-uniformly, and that means directions will be twisted.
    // I pass the rotation+scale transform to offset that
    const points = this.route();
    data.moveTo(points[0].x, points[0].y);

    let index = 1;
    while (index < points.length) {
      const cp1 = points[index++],
        cp2 = points[index++],
        xy2 = points[index++];

      data.bezierCurve(cp1.x, cp1.y, cp2.x, cp2.y, xy2.x, xy2.y);

      // Support for quadratic curves:
      // if (i == points.length - 6) {
      //   const cp1x = points[i++],
      //     cp1y = points[i++],
      //     x2 = points[i++],
      //     y2 = points[i++];
      //   data.quadraticCurve(cp1x, cp1y, x2, y2);
      // } else { <use the bezier code above>}
    }
    return data;
  }
}

abstract class BaseOrthogonalConnectorRouter extends ConnectorRouter {
  protected pointSides!: [string, string];

  constructor(...args: ConstructorParameters<typeof ConnectorRouter>) {
    super(...args);
    this.inferSides();
    this.initialize();
  }

  protected adjustEndpoints(): [ConnectorEndpoint, ConnectorEndpoint] {
    return [
      {
        ...PointUtils.moveTowardsSide(this.start, this.pointSides[0], this.startArrowOffset),
        rotation: this.start.rotation,
      },
      { ...PointUtils.moveTowardsSide(this.end, this.pointSides[1], this.endArrowOffset), rotation: this.end.rotation },
    ];
  }

  /**
   * Infer the sides of the start and end points.
   * Handles cases where element.anchorsMode can be null or "n/a".
   */
  private inferSides() {
    const sides = [
      utils.fixAnchorsMode(this.element.anchorsMode?.[0]),
      utils.fixAnchorsMode(this.element.anchorsMode?.[1]),
    ];
    const result = [];
    const moveDir = MathUtils.inferMoveDirection(this.start, this.end, true);

    if (!sides[0] || sides[0] === "n/a") {
      result[0] = moveDir;
    } else if (sides[0] === "outline") {
      result[0] = this.rotationToSide(this.start.rotation ?? 0 + this.startRotation);
    } else {
      const rotation = this.sideToRotation(sides[0]);
      result[0] = this.rotationToSide(rotation + this.startRotation);
    }

    if (!sides[1] || sides[1] === "n/a") {
      result[1] = MathUtils.getOppositeSide(moveDir, true);
    } else if (sides[1] === "outline") {
      result[1] = this.rotationToSide(this.end.rotation ?? 0 + this.endRotation);
    } else {
      const rotation = this.sideToRotation(sides[1]);
      result[1] = this.rotationToSide(rotation + this.endRotation);
    }

    this.pointSides = result as [string, string];
  }

  private sideToRotation(side: string) {
    const result = { right: 0, bottom: 90, buttom: 90, left: 180, top: 270 }[side];
    if (result === undefined) throw new Error("Invalid side: " + side);
    return result;
  }

  private rotationToSide(rotation: number) {
    const normalizedDegrees = (rotation + 360) % 360;
    if (normalizedDegrees <= 45 || normalizedDegrees > 315) return "right";
    if (normalizedDegrees > 45 && normalizedDegrees <= 135) return "bottom";
    if (normalizedDegrees > 135 && normalizedDegrees <= 225) return "left";
    return "top";
  }

  public draw(): utils.SimpleConnectorData {
    try {
      const data = new utils.SimpleConnectorData();
      const points = this.route();
      data.moveTo(points[0].x, points[0].y);
      for (let index = 1; index < points.length - 1; index++) {
        data.arcTo(points[index].x, points[index].y, points[index + 1].x, points[index + 1].y);
      }
      data.lineTo(points[points.length - 1].x, points[points.length - 1].y);
      return data;
    } catch (error) {
      console.error("Error while drawing orthogonal connector:", error);
      const data = new utils.SimpleConnectorData();
      data.moveTo(this.start.x, this.start.y);
      data.lineTo(this.end.x, this.end.y);
      return data;
    }
  }

  /**
   * Removes points along a path where there is no bend to reduce total points.
   */
  protected simplifyPath(points: Point[]): Point[] {
    if (points.length <= 2) return points;

    // Remove duplicate points
    points = points.filter(
      (point, index, array) =>
        index === 0 || !this.equals(point.x, array[index - 1].x) || !this.equals(point.y, array[index - 1].y)
    );

    const simplePath: Point[] = [points[0]];
    for (let index = 1; index < points.length - 1; index++) {
      const current = points[index];
      const previous = points[index - 1];
      const next = points[index + 1];
      if (this.isBend(previous, current, next)) {
        simplePath.push(current);
      }
    }

    simplePath.push(points[points.length - 1]);
    return simplePath;
  }

  private isBend(a: Point, b: Point, c: Point): boolean {
    const equalX = this.equals(a.x, b.x, c.x);
    const equalY = this.equals(a.y, b.y, c.y);
    return !(equalX || equalY);
  }

  /**
   * Konva uses floats with 16 decimal places, so we need to check if two numbers are equal within a threshold.
   */
  protected equals(...args: number[]): boolean {
    return args.every((value, _, array) => Math.abs(value - array[0]) < 1e-3);
  }
}

/**
 * Router for orthogonal connectors that routes an orthogonal path through a series of points.
 */
class OrthogonalConnectorRouter extends BaseOrthogonalConnectorRouter {
  private routePading = BASIC_ORTHOGONAL_ROUTE_MARGIN;

  public route(): Point[] {
    const startOrientation = this.innerPoints[0].orientation === "horizontal" ? "vertical" : "horizontal";
    const paddedStart = this.padEndpoint(this.start, this.pointSides[0], startOrientation);

    const endOrientation =
      this.innerPoints[this.innerPoints.length - 1].orientation === "horizontal" ? "vertical" : "horizontal";
    const paddedEnd = this.padEndpoint(this.end, this.pointSides[1], endOrientation);

    const points = [this.start];
    const planningPoints = [...this.innerPoints];

    // Conditionally add paddedStart if different from start
    if (paddedStart.x !== this.start.x || paddedStart.y !== this.start.y) points.push(paddedStart);

    // Conditionally add paddedEnd if different from end
    if (paddedEnd.x !== this.end.x || paddedEnd.y !== this.end.y)
      planningPoints.push({ ...paddedEnd, id: "", orientation: endOrientation });
    planningPoints.push({ ...this.end, id: "", orientation: endOrientation });

    for (const [index, point] of planningPoints.entries()) {
      if (!point.orientation) continue;
      const origin = index === 0 ? { ...paddedStart, orientation: startOrientation } : planningPoints[index - 1];

      let intermediate;
      if (point.orientation === "horizontal") {
        intermediate = { x: origin.x, y: point.y };
      } else {
        intermediate = { x: point.x, y: origin.y };
      }

      points.push(intermediate, point);
    }

    const simplified = this.simplifyPath(points);
    const clean = this.cleanupRoute(simplified);
    return clean;
  }

  private cleanupRoute(route: Point[]): Point[] {
    if (route.length <= 3) return route;

    /**
     * Check if 3 points form an orthogonal straight line.
     * This does not include diagonals.
     */
    const areColinear = (p1: Point, p2: Point, p3: Point) => {
      if (this.equals(p1.x, p2.x, p3.x)) return true;
      if (this.equals(p1.y, p2.y, p3.y)) return true;
      return false;
    };

    const result: Point[] = [route[0]];
    for (let index = 1; index < route.length - 1; index++) {
      const previous = result[result.length - 1];
      const current = route[index];
      const next = route[index + 1];

      if (this.equals(current.x, previous.x) && this.equals(current.y, previous.y)) continue;
      if (!areColinear(previous, current, next)) result.push(current);
    }

    result.push(route[route.length - 1]);
    return result;
  }

  private padEndpoint(point: Point, side: string, orientation: "horizontal" | "vertical"): Point {
    switch (side) {
      case "left": {
        if (orientation === "horizontal") return point;
        return { x: point.x - this.routePading, y: point.y };
      }
      case "right": {
        if (orientation === "horizontal") return point;
        return { x: point.x + this.routePading, y: point.y };
      }
      case "top": {
        if (orientation === "vertical") return point;
        return { x: point.x, y: point.y - this.routePading };
      }
      case "buttom":
      case "bottom": {
        if (orientation === "vertical") return point;
        return { x: point.x, y: point.y + this.routePading };
      }
      default: {
        return point;
      }
    }
  }
}

/**
 * Router for orthogonal connectors which calculates a route using A* algorithm.
 * Routes are calculated automatically and take into account connected shapes
 */
class SmartOrthogonalConnectorRouter extends BaseOrthogonalConnectorRouter {
  private maxMargin = MAX_ORTHOGONAL_ROUTE_MARGIN; // How far the first bend point will be from each shape
  private minMargin = MIN_ORTHOGONAL_ROUTE_MARGIN; // How close the path can be to shapes before rerouting
  private bounds!: Rectangle;
  private obstacles!: [Rectangle, Rectangle];
  private centerX: number = 0;
  private centerY: number = 0;

  constructor(...args: ConstructorParameters<typeof ConnectorRouter>) {
    super(...args);
    this.makeObstacles();
  }

  private getStartBoxMargins() {
    const startBox = Rectangle.fromRect(this.startBbox).unionPoint(this.start);
    const endBox = Rectangle.fromRect(this.endBbox).unionPoint(this.end);

    const dLeft = (startBox.left - endBox.right) / 2;
    const dRight = (endBox.left - startBox.right) / 2;
    const dTop = (startBox.top - endBox.bottom) / 2;
    const dBottom = (endBox.top - startBox.bottom) / 2;

    return {
      marginLeft: dLeft >= this.minMargin ? Math.min(dLeft, this.maxMargin) : this.maxMargin,
      marginRight: dRight >= this.minMargin ? Math.min(dRight, this.maxMargin) : this.maxMargin,
      marginTop: dTop >= this.minMargin ? Math.min(dTop, this.maxMargin) : this.maxMargin,
      marginBottom: dBottom >= this.minMargin ? Math.min(dBottom, this.maxMargin) : this.maxMargin,
    };
  }

  /**
   * Create the obstacles for the orthogonal router. This is used to avoid shapes.
   * Handles cases where no shapes are attached to the connector.
   */
  private makeObstacles() {
    const { marginLeft, marginRight, marginTop, marginBottom } = this.getStartBoxMargins();

    let startObstacle = Rectangle.fromRect(this.startBbox);
    let endObstacle = Rectangle.fromRect(this.endBbox);
    startObstacle = startObstacle.padSides(marginLeft, marginRight, marginTop, marginBottom);
    endObstacle = endObstacle.padSides(marginRight, marginLeft, marginBottom, marginTop); // Mirror startbox padding

    this.obstacles = [startObstacle, endObstacle];
    this.bounds = startObstacle.union(endObstacle);
  }

  public route(): Point[] {
    const origin = this.firstBendPoint(this.start, this.pointSides[0], this.obstacles[0]);
    const destination = this.firstBendPoint(this.end, this.pointSides[1], this.obstacles[1]);

    let path;
    if (this.obstacleContainsEndpoint()) {
      // In order to avoid rounting around containing shapes, we always use basic route on containment
      path = this.basicRoute();
      this.byproducts = { origin, destination, path: [...path], prettyPath: [...path] };
    } else {
      const rulers = this.makeRulers();
      const grid = new HananGrid(rulers.vertical, rulers.horizontal, this.bounds);
      const spots = this.makePoints(grid);
      const graph = new OrthogonalRouteGraph(spots);
      path = graph.shortestPath(origin, destination, this.start);
      this.byproducts.path = [...path];
      path = this.prettifyPath(path);
      this.byproducts = { ...this.byproducts, origin, destination, grid, rulers, spots, graph, prettyPath: [...path] };
    }

    if (path.length <= 2) {
      // Fallback to a basic path ignoring all obstacles if there is no route.
      // This can happen when the source/destination are inside an obstacle. (and more)
      path = this.basicRoute();
      this.byproducts = { origin, destination, path: [...path], prettyPath: [...path] };
    }

    const simplePath = this.simplifyPath([this.start, ...path, this.end]);
    this.byproducts.simplePath = simplePath;
    return simplePath;
  }

  /**
   * Most simple orthogonal route without exclusions.
   * Chooses the first route from start->end which does not backtrack.
   * This route can have up to 4 points. Does not return start and end as part of the path.
   */
  private basicRoute() {
    const source = this.firstBendPoint(this.start, this.pointSides[0], this.obstacles[0], this.maxMargin);
    const destination = this.firstBendPoint(this.end, this.pointSides[1], this.obstacles[1], this.maxMargin);
    const midX = (source.x + destination.x) / 2;
    const midY = (source.y + destination.y) / 2;
    const paths = [
      [source, { x: destination.x, y: source.y }, destination],
      [source, { x: source.x, y: destination.y }, destination],
      [source, { x: midX, y: source.y }, { x: midX, y: destination.y }, destination],
      [source, { x: source.x, y: midY }, { x: destination.x, y: midY }, destination],
    ];
    for (const path of paths) {
      if (!this.isPathBacktracking([this.start, ...path, this.end])) return path;
    }
    throw new Error("Basic route failed. This should never happen.");
  }

  /**
   * Detect if an orthogonal line is going back on itself.
   */
  private isPathBacktracking(path: Point[]): boolean {
    if (path.length < 3) return false;
    for (let i = 1; i < path.length - 1; i++) {
      const prev = path[i - 1];
      const curr = path[i];
      const next = path[i + 1];

      const dx1 = curr.x - prev.x;
      const dy1 = curr.y - prev.y;
      const dx2 = next.x - curr.x;
      const dy2 = next.y - curr.y;

      if ((this.equals(dx1, dx2, 0) && dy1 * dy2 < 0) || (this.equals(dy1, dy2, 0) && dx1 * dx2 < 0)) {
        return true;
      }
    }
    return false;
  }

  private prettifyPath(path: Point[]): Point[] {
    if (path.length < 4) return path; // Cannot be prettified due to its length

    // There will always be 1 possible pretty path, either on X or on Y.
    let newPath = [...path];
    for (const axis of ["x", "y"] as ("x" | "y")[]) {
      const { centerIntersect, parallelPaths } = this.getParallelPaths(path, axis);
      const result = centerIntersect && this.adjustPath(path, centerIntersect, parallelPaths);
      if (result) {
        newPath = result;
      }
    }
    return newPath;
  }

  /**
   * Returns the segments of a path that are parallel to the centerline between the obstacles
   */
  private getParallelPaths = (
    path: Point[],
    axis: "x" | "y"
  ): { centerIntersect: Point | null; parallelPaths: Segment[] } => {
    const center = axis === "x" ? this.centerX : this.centerY;
    const parallelPaths: [Point, Point][] = [];
    let start: null | Point = null;
    let centerIntersect: null | Point = null;

    for (let index = 0; index < path.length; index++) {
      const previous = path[index - 1];
      const current = path[index];
      if (start === null && previous && this.equals(previous[axis], current[axis])) {
        start = previous;
      } else if (start !== null && !this.equals(previous[axis], current[axis])) {
        parallelPaths.push([start, previous]);
        start = null;
      }
      if (this.equals(current[axis], center)) {
        centerIntersect = current;
      }
    }
    if (start) {
      parallelPaths.push([start, path[path.length - 1]]);
    }
    return { centerIntersect, parallelPaths };
  };

  /**
   * Adjusts a path to pass through the centerline between the obstacles based on parallel segments
   */
  private adjustPath = (path: Point[], centerIntersect: Point, parallelPaths: Segment[]): null | Point[] => {
    let result = null;
    for (const parallelPath of parallelPaths) {
      const rectPoints = [centerIntersect, ...parallelPath];
      const rectangle = Rectangle.fromPoints(
        { x: Math.min(...rectPoints.map((p) => p.x)), y: Math.min(...rectPoints.map((p) => p.y)) },
        { x: Math.max(...rectPoints.map((p) => p.x)), y: Math.max(...rectPoints.map((p) => p.y)) }
      );

      if (!this.obstacles.some((o) => rectangle.intersects(o))) {
        const rectanglePoints = rectangle.points();
        const corners = [
          rectanglePoints.topLeft,
          rectanglePoints.topRight,
          rectanglePoints.bottomRight,
          rectanglePoints.bottomLeft,
        ];

        const indexRangeInPath: number[] = [];
        const indexRangeInCorner: number[] = [];
        path.forEach((point, index) => {
          const cornerResult = corners.findIndex(
            (corner) => this.equals(point.x, corner.x) && this.equals(point.y, corner.y)
          );
          if (cornerResult !== -1) {
            indexRangeInPath.push(index);
            indexRangeInCorner.push(cornerResult);
          }
        });

        // After we create a virtual rectangle between the path and centerline, to retrieve the centerline
        // we find the 1 corner of the rectangle that is not on the path.
        const newPath = [...path];
        const missCorner = corners.find((_, index) => !indexRangeInCorner.includes(index)) as Point;
        const removeLength = Math.abs(indexRangeInPath[0] - indexRangeInPath[indexRangeInPath.length - 1]) + 1;
        newPath.splice(indexRangeInPath[0] + 1, removeLength - 2, missCorner);

        const simplePath = this.simplifyPath([this.start, ...path, this.end]);
        const newSimplePath = this.simplifyPath([this.start, ...newPath, this.end]);

        if (newSimplePath.length <= simplePath.length) {
          result = newPath;
        }
      }
    }
    return result;
  };

  private makeRulers() {
    const vertical: number[] = [];
    const horizontal: number[] = [];

    // Edges of obstacles
    for (const obs of this.obstacles) {
      vertical.push(obs.right, obs.left);
      horizontal.push(obs.top, obs.bottom);
    }

    //Center of obstacles
    for (const obs of this.obstacles) {
      vertical.push(obs.center);
      horizontal.push(obs.middle);
    }

    // Shape origins
    const startIsVertical = this.getOrientation(this.pointSides[0]) === "vertical";
    (startIsVertical ? vertical : horizontal).push(startIsVertical ? this.start.x : this.start.y);
    const endIsVertical = this.getOrientation(this.pointSides[1]) === "vertical";
    (endIsVertical ? vertical : horizontal).push(endIsVertical ? this.end.x : this.end.y);

    //First bend points
    const startBend = this.firstBendPoint(this.start, this.pointSides[0], this.obstacles[0]);
    const endBend = this.firstBendPoint(this.end, this.pointSides[1], this.obstacles[1]);
    horizontal.push(startBend.y, endBend.y);
    vertical.push(startBend.x, endBend.x);

    //Center between first bend points
    this.centerX = (this.start.x + this.end.x) / 2;
    this.centerY = (this.start.y + this.end.y) / 2;
    vertical.push(this.centerX);
    horizontal.push(this.centerY);

    vertical.sort((a, b) => a - b);
    horizontal.sort((a, b) => a - b);
    return { vertical, horizontal };
  }

  private makePoints(grid: HananGrid): Point[] {
    let points: Point[] = [];

    // Filtered grid points
    points.push(...grid.points());
    points = points.filter((p) => !this.obstacleContains(p));

    // Origin and destination
    points.push(
      this.firstBendPoint(this.start, this.pointSides[0], this.obstacles[0]),
      this.firstBendPoint(this.end, this.pointSides[1], this.obstacles[1])
    );

    return points;
  }

  private getOrientation(side: string) {
    if (side === "top" || side === "bottom" || side === "buttom") return "vertical";
    if (side === "left" || side === "right") return "horizontal";
    throw new Error(`Invalid side for endpoint: ${side}`);
  }

  private obstacleContains(p: Point) {
    // SafeFilter finds points that are contained in the rectangle but not on the edges
    const safeFilter = (o: Rectangle) =>
      o.contains(p) &&
      !(this.equals(p.x, o.left) || this.equals(p.x, o.right) || this.equals(p.y, o.top) || this.equals(p.y, o.bottom));

    return this.obstacles.some(safeFilter);
  }

  private obstacleContainsEndpoint() {
    if (this.obstacles[0].equals(this.obstacles[1])) return false;
    if (this.obstacles[0].contains(this.end) || this.obstacles[1].contains(this.start)) return true;
  }

  /**
   * Returns the first point along the connector where a bend can occur.
   */
  private firstBendPoint(connection: ConnectorEndpoint, side: string, obstacle: Rectangle, forcedOffset = 0): Point {
    const isShape = obstacle.area > 0;
    if (isShape) return this.firstShapeBendPoint(connection, side, obstacle, forcedOffset);
    return this.firstFreeBendPoint(connection, side);
  }

  private firstShapeBendPoint(
    connection: ConnectorEndpoint,
    side: string,
    obstacle: Rectangle,
    forcedOffset: number
  ): Point {
    const { x, y } = connection;
    switch (side) {
      case "top": {
        return { x, y: obstacle.top - forcedOffset };
      }
      case "right": {
        return { x: obstacle.right + forcedOffset, y };
      }
      case "buttom":
      case "bottom": {
        return { x, y: obstacle.bottom + forcedOffset };
      }
      case "left": {
        return { x: obstacle.left - forcedOffset, y };
      }
      default: {
        throw new Error(`Invalid side for endpoint: ${side}`);
      }
    }
  }

  private firstFreeBendPoint(connection: ConnectorEndpoint, side: string): Point {
    const { x, y } = connection;
    const freePointMargin = 40;
    let result: Point;

    switch (side) {
      case "top": {
        result = { x, y: y - freePointMargin };
        break;
      }
      case "right": {
        result = { x: x + freePointMargin, y };
        break;
      }
      case "buttom":
      case "bottom": {
        result = { x, y: y + freePointMargin };
        break;
      }
      case "left": {
        result = { x: x - freePointMargin, y };
        break;
      }
      default: {
        throw new Error(`Invalid side for endpoint: ${side}`);
      }
    }

    if (this.obstacleContains(result)) {
      return connection;
    }
    return result;
  }
}

/**
 * Hanan grid is a geometrical term for a set of points created by running vertical and horizontal lines through a set of points.
 * Read more: https://en.wikipedia.org/wiki/Hanan_grid
 */
class HananGrid {
  private _rows = 0;
  private _cols = 0;
  readonly data: Map<number, Map<number, Rectangle>> = new Map();

  /**
   * Construcs a Hanan grid from vertical and horizontal rulers
   */
  constructor(verticals: number[], horizontals: number[], bounds: Rectangle) {
    let lastX = bounds.x;
    let lastY = bounds.y;
    let column = 0;
    let row = 0;

    for (const y of horizontals) {
      // Cells of row
      for (const x of verticals) {
        this.set(row, column++, Rectangle.fromEdges(lastX, lastY, x, y));
        lastX = x;
      }

      // Last cell of row
      this.set(row, column, Rectangle.fromEdges(lastX, lastY, bounds.right, y));
      lastX = bounds.left;
      lastY = y;
      column = 0;
      row++;
    }
    lastX = bounds.left;

    // Last row
    for (const x of verticals) {
      this.set(row, column++, Rectangle.fromEdges(lastX, lastY, x, bounds.bottom));
      lastX = x;
    }

    // Last cell of last row
    this.set(row, column, Rectangle.fromEdges(lastX, lastY, bounds.right, bounds.bottom));
  }

  public set(row: number, column: number, rectangle: Rectangle) {
    this._rows = Math.max(this.rows, row + 1);
    this._cols = Math.max(this.columns, column + 1);
    const rowMap: Map<number, Rectangle> = this.data.get(row) || this.data.set(row, new Map()).get(row)!;
    rowMap.set(column, rectangle);
  }

  public get(row: number, column: number): Rectangle | null {
    const rowMap = this.data.get(row);
    if (rowMap) {
      return rowMap.get(column) || null;
    }
    return null;
  }

  public rectangles(): Rectangle[] {
    const r: Rectangle[] = [];
    for (const [, data] of this.data) {
      for (const [, rect] of data) {
        r.push(rect);
      }
    }
    return r;
  }

  public points(): Point[] {
    const result: Point[] = [];
    for (const [, data] of this.data) {
      for (const [, rect] of data) {
        const rectPoints = rect.points();
        result.push(rectPoints.topLeft, rectPoints.topRight, rectPoints.bottomRight, rectPoints.bottomLeft);
      }
    }
    return this.reducePoints(result);
  }

  private reducePoints(points: Point[]): Point[] {
    const result: Point[] = [];
    const map = new Map<number, number[]>();

    for (const point of points) {
      const { x, y } = point;
      const array: number[] = map.get(y) || map.set(y, []).get(y)!;
      if (!array.includes(x)) {
        array.push(x);
      }
    }

    for (const [y, xs] of map) {
      for (const x of xs) {
        result.push({ x, y });
      }
    }
    return result;
  }

  get columns(): number {
    return this._cols;
  }

  get rows(): number {
    return this._rows;
  }
}

/**
 * A graph containing all possible orthogonal connections between a set of points.
 */
class OrthogonalRouteGraph {
  private _connections: Segment[] = [];
  readonly data: Record<number, Record<number, RouteGraphNode>> = {};

  constructor(points: Point[]) {
    const allXs: number[] = [];
    const allYs: number[] = [];

    for (const p of points) {
      const { x, y } = p;
      if (!allXs.includes(x)) allXs.push(x);
      if (!allYs.includes(y)) allYs.push(y);
      this.add(p);
    }

    allXs.sort((a, b) => a - b);
    allYs.sort((a, b) => a - b);

    // Make all available connections
    for (let y = 0; y < allYs.length; y++) {
      for (let x = 0; x < allXs.length; x++) {
        const b = { x: allXs[x], y: allYs[y] };
        if (!this.has(b)) continue;

        if (x > 0) {
          const a = { x: allXs[x - 1], y: allYs[y] };
          if (this.has(a)) {
            this.connect(a, b);
            this.connect(b, a);
            this._connections.push([a, b]);
          }
        }

        if (y > 0) {
          const a = { x: allXs[x], y: allYs[y - 1] };
          if (this.has(a)) {
            this.connect(a, b);
            this.connect(b, a);
            this._connections.push([a, b]);
          }
        }
      }
    }
  }

  private has(p: Point): boolean {
    const { x, y } = p;
    for (const xKey in this.data) {
      if (this.equals(Number(xKey), x)) {
        const innerRecord = this.data[Number(xKey)];
        for (const innerKey in innerRecord) {
          if (this.equals(Number(innerKey), y)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private add(p: Point): void {
    const { x, y } = p;

    let xKeyFound = false;
    for (const xKey in this.data) {
      if (this.equals(Number(xKey), x)) {
        xKeyFound = true;
        const innerRecord = this.data[Number(xKey)];

        let yKeyFound = false;
        for (const yKey in innerRecord) {
          if (this.equals(Number(yKey), y)) {
            yKeyFound = true;
            innerRecord[Number(yKey)] = new RouteGraphNode(p);
            break;
          }
        }

        if (!yKeyFound) {
          innerRecord[Number(y)] = new RouteGraphNode(p);
        }
        break;
      }
    }

    if (!xKeyFound) {
      this.data[Number(x)] = { [Number(y)]: new RouteGraphNode(p) };
    }
  }

  private get(p: Point): RouteGraphNode | null {
    const { x, y } = p;
    for (const xKey in this.data) {
      if (this.equals(Number(xKey), x)) {
        const innerRecord = this.data[Number(xKey)];
        for (const yKey in innerRecord) {
          if (this.equals(Number(yKey), y)) {
            return innerRecord[Number(yKey)];
          }
        }
      }
    }
    return null;
  }

  private connect(a: Point, b: Point) {
    const nodeA = this.get(a);
    const nodeB = this.get(b);
    if (!nodeA || !nodeB) {
      throw new Error(`Error while connecting points ${a} and ${b}`);
    }
    nodeA.adjacentNodes.push(nodeB);
  }

  /**
   * Konva uses floats with 16 decimal places, so we need to check if two numbers are equal within a threshold.
   */
  private equals(...args: number[]): boolean {
    return args.every((v, _, array) => Math.abs(v - array[0]) < 1e-3);
  }

  /**
   * Returns the shortest path from start to end using A* algorithm.
   */
  public shortestPath(source: Point, destination: Point, beforeSource: Point): Point[] {
    const sourceNode = this.get(source);
    const destinationNode = this.get(destination);
    if (!sourceNode) throw new Error("Source point not found in graph");
    if (!destinationNode) throw new Error("Destination point not found in graph");

    const pathMap = this.aStarSearch(sourceNode, destinationNode, beforeSource, (node) =>
      PointUtils.manhattanDistane(node.data, destinationNode.data)
    );
    return this.reconstructPath(sourceNode, destinationNode, pathMap);
  }

  private aStarSearch(
    source: RouteGraphNode,
    destination: RouteGraphNode,
    beforeSource: Point,
    heuristic: (node: RouteGraphNode) => number
  ) {
    const frontier = new ProirityQueue();
    const pathMap = new Map<RouteGraphNode, RouteGraphNode>();
    const costs = new Map<RouteGraphNode, number>();
    costs.set(source, 0);
    frontier.enqueue(source, 0);

    while (frontier.length > 0) {
      const current = frontier.dequeue()!;
      const { x, y } = current.data;
      if (this.equals(x, destination.data.x) && this.equals(y, destination.data.y)) {
        break;
      }

      const previousPoint = pathMap.get(current)?.data ?? beforeSource;
      for (const next of current.adjacentNodes) {
        const weight = PointUtils.manhattanDistane(current.data, next.data);
        const distanceCost = costs.get(current)! + weight;
        const equalX = this.equals(previousPoint.x, current.data.x, next.data.x);
        const equalY = this.equals(previousPoint.y, current.data.y, next.data.y);
        const bendCost = !equalX && !equalY ? 1 : 0;
        const newcost = distanceCost + bendCost;

        if (newcost >= Number.MAX_SAFE_INTEGER) {
          throw new Error("Max int exceeded when calculating route");
        }

        if (!costs.has(next) || newcost < costs.get(next)!) {
          costs.set(next, newcost);
          const priority = newcost + heuristic(next);
          frontier.enqueue(next, priority);
          pathMap.set(next, current);
        }
      }
    }
    return pathMap;
  }

  private reconstructPath(
    source: RouteGraphNode,
    destination: RouteGraphNode,
    pathMap: Map<RouteGraphNode, RouteGraphNode>
  ) {
    if (!pathMap.has(destination)) {
      return [];
    }
    const result = [];
    let p = { ...destination.data };
    while (!this.equals(p.x, source.data.x) || !this.equals(p.y, source.data.y)) {
      const node = this.get(p);
      const previous = pathMap.get(node!);
      result.unshift(previous!.data);
      p = previous!.data;
    }
    return [...result, destination.data];
  }

  get connections(): Segment[] {
    return this._connections;
  }
}

class RouteGraphNode {
  public adjacentNodes: RouteGraphNode[] = [];
  // Constructor sets data property
  // eslint-disable-next-line no-useless-constructor
  constructor(public data: Point) {}
}

class ProirityQueue {
  private nodes: { node: RouteGraphNode; priority: number }[] = [];

  public enqueue(node: RouteGraphNode, priority: number) {
    this.nodes.push({ node: node, priority });
    this.nodes = this.nodes.sort((a, b) => a.priority - b.priority);
  }

  public dequeue(): RouteGraphNode | null {
    return this.nodes.shift()?.node ?? null;
  }

  get length() {
    return this.nodes.length;
  }
}
