import React, { useEffect, RefObject } from "react";
import { DatePickerRangeInput } from "frontend/ui-components/date-picker-range/date-picker-range-input";
import { trackGanttEvent } from "elements/gantt/utils";
import style from "./gantt-toolbar.module.css";
import { GanttElement } from "../schema";

export function GanttToolbarDatePicker({
  datePickerRef,
  elements,
  setStartDate,
  setEndDate,
  changeDate,
}: {
  datePickerRef: RefObject<HTMLDivElement>;
  elements: GanttElement[];
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
  changeDate: (start: Date | undefined, end: Date | undefined) => void;
}) {
  const startDate = new Date(elements[0].startDate);
  const endDate = new Date(elements[0].endDate);

  useEffect(() => {
    datePickerRef.current?.focus();
  }, []);

  return (
    <div
      className={style.pickerContainer}
      onClick={() => {
        datePickerRef.current?.focus();
      }}
      ref={datePickerRef}
      data-lock-toolbar={true}
      tabIndex={0}
    >
      <DatePickerRangeInput
        fromDate={startDate}
        toDate={endDate}
        onSelected={(start, end) => {
          setStartDate(start);
          setEndDate(end);
          changeDate(start, end);
          trackGanttEvent("gantt_dates_changed");
        }}
        theme="dark"
        showActions="none"
        showInputs={false}
      />
    </div>
  );
}
