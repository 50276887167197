import { OrgChartOrientation } from "shared/datamodel/schemas/org-chart";

export type TreeDirection = "toLeaves" | "toRoot" | "siblingAfter" | "siblingBefore";

type vector2 = [number, number];
type vector4 = [number, number, number, number];

interface PointRotation {
  x: number;
  y: number;
  rotation: number;
}

interface OrientationTraits {
  isHorizontal: boolean;
  toLeaves: PointRotation;
  toRoot: PointRotation;
  siblingAfter: PointRotation;
  siblingBefore: PointRotation;
  convert: (x: number, y: number) => vector2;
}

export const LayoutProps: Record<OrgChartOrientation, OrientationTraits> = {
  [OrgChartOrientation.LeftToRight]: {
    isHorizontal: true,
    toLeaves: { x: 1, y: 0, rotation: 0 },
    toRoot: { x: -1, y: 0, rotation: 180 },
    siblingAfter: { x: 0, y: -1, rotation: 270 },
    siblingBefore: { x: 0, y: 1, rotation: 90 },
    convert: (x: number, y: number) => [y, -x],
  },
  [OrgChartOrientation.TopToBottom]: {
    isHorizontal: false,
    toLeaves: { x: 0, y: 1, rotation: 90 },
    toRoot: { x: 0, y: -1, rotation: 270 },
    siblingAfter: { x: 1, y: 0, rotation: 0 },
    siblingBefore: { x: -1, y: 0, rotation: 180 },
    convert: (x: number, y: number) => [x, y],
  },
};

export abstract class TreeLayoutHelper {
  protected constructor(protected width: number, protected height: number, protected spacingLayers: number) {}

  static from(orientation: OrgChartOrientation, width: number, height: number, spacingLayers: number) {
    switch (orientation) {
      case OrgChartOrientation.LeftToRight: {
        return new TreeLayoutLeftToRight(width, height, spacingLayers);
      }
      case OrgChartOrientation.TopToBottom: {
        return new TreeLayoutTopToBottom(width, height, spacingLayers);
      }
      default: {
        // We should never get here!
        // Will throw build error if all cases were not covered
        const _unhandled: never = orientation;
        throw new Error(`Unknown orientation: ${orientation}`);
      }
    }
  }
  /**
   * give d3-tree the size of the node including spacing
   */
  abstract getNodeSizeForD3tree(): vector2;

  /**
   * get a point on the middle of the edge of the node, that's 'offset' length away
   */
  abstract getNodeSocket(direction: TreeDirection, offset: number): PointRotation;

  /**
   * Get edge of node that is perpendicular to the main axis of the tree.
   * main axis the the flow from root to leaves
   */
  abstract nodeEdgePerpMainAxis(): vector4;

  /**
   * Get edge of node that is perpendicular to the cross axis of the tree.
   * cross axis is the direction in which siblings are layed out
   */
  abstract nodeEdgePerpCrossAxis(): vector4;
}

class TreeLayoutTopToBottom extends TreeLayoutHelper {
  getNodeSizeForD3tree(): vector2 {
    return [this.width, this.height + this.spacingLayers];
  }
  getNodeSocket(direction: TreeDirection, offset: number): PointRotation {
    const dir = LayoutProps[OrgChartOrientation.TopToBottom][direction];
    return {
      x: dir.x * (this.width / 2 + offset),
      y: dir.y * (this.height / 2 + offset),
      rotation: dir.rotation,
    };
  }
  private _nodeEdgeMainAxis: vector4 | undefined;
  private _nodeEdgeCrossAxis: vector4 | undefined;
  nodeEdgePerpMainAxis() {
    return (this._nodeEdgeMainAxis ??= [-this.width / 2, 0, this.width / 2, 0]);
  }
  nodeEdgePerpCrossAxis() {
    return (this._nodeEdgeCrossAxis ??= [0, -this.height / 2, 0, this.height / 2]);
  }
}

class TreeLayoutLeftToRight extends TreeLayoutHelper {
  getNodeSizeForD3tree(): vector2 {
    return [this.height, this.width + this.spacingLayers];
  }
  getNodeSocket(direction: TreeDirection, offset: number): PointRotation {
    const dir = LayoutProps[OrgChartOrientation.LeftToRight][direction];
    return {
      x: dir.x * (this.width / 2 + offset),
      y: dir.y * (this.height / 2 + offset),
      rotation: dir.rotation,
    };
  }
  private _nodeEdgeMainAxis: vector4 | undefined;
  private _nodeEdgeCrossAxis: vector4 | undefined;
  nodeEdgePerpMainAxis() {
    return (this._nodeEdgeMainAxis ??= [0, -this.height / 2, 0, this.height / 2]);
  }
  nodeEdgePerpCrossAxis() {
    return (this._nodeEdgeCrossAxis ??= [-this.width / 2, 0, this.width / 2, 0]);
  }
}
