import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import BigModalContainer from "../big-modal-container";
import InviteView from "./invite-view";
import { markTipSeen } from "../../api";
import { TipName } from "shared/datamodel/schemas/tip";
import { useSetAtom } from "jotai";
import { userTipsAtom } from "state-atoms/users-atoms";
import AppLoader from "frontend/loader/app-loader";
import tracking from "../../tracking";
import consts from "shared/consts";
import useUrlQueryParamValue from "../../hooks/use-url-query-param";
import OnboardingChooseTemplate from "./choose-template/choose-template";
import { BoardState } from "shared/datamodel/schemas";
import { createBoard } from "frontend/services/boardsService";
import { useRouter } from "next/router";
import { useCurrentAccountOnboardingTip } from "frontend/hooks/use-onboarding";
import { TeamSize } from "./types";
import ChooseTeamSize from "./choose-team-size/choose-team-size";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import OldOnboardingTeamSize from "./old-team-size/old-team-size";

async function createTemplateBoard(templateAlias: string | null) {
  try {
    const boardState = templateAlias ? BoardState.templateNotSetUp : BoardState.default;
    const board = await createBoard("New Canvas", boardState, templateAlias, null, null);

    return board;
  } catch (error) {
    Sentry.captureException(error, { tags: { type: "onboarding", page: "onboarding-choose-template" } });
    return null;
  }
}

export default function OnboardingFlow() {
  const setUserTips = useSetAtom(userTipsAtom);
  const router = useRouter();
  const currentOnboardingTip = useCurrentAccountOnboardingTip();
  const onboardingChooseTemplateEnabled = useFeatureFlag("onboarding-choose-template");
  const [isLoading, setIsLoading] = useState(false);

  const [, setStepString] = useUrlQueryParamValue("step", { replace: true });

  async function completeTip(tip: TipName) {
    setUserTips((tips) => new Set([...tips, tip]));
    await markTipSeen(tip);
  }

  async function teamSizeSelected(teamSize: TeamSize) {
    completeTip(TipName.onboardingTeamSize);

    tracking.registerProperties({ team_size: teamSize });

    tracking.trackAnalyticsEvent("team_size_selected", {
      team_size: teamSize,

      // legacy properties
      category: consts.TRACKING_CATEGORY.ONBOARDING,
      stringValue: teamSize,
    });

    if (teamSize === "team") {
      tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.TEAM_SIGNUP);
    }
  }

  async function chooseTemplateCompleted(templateAlias: string | null) {
    setIsLoading(true);

    const board = await createTemplateBoard(templateAlias);

    if (board) {
      await completeTip(TipName.onboardingChooseTemplate);
      router.replace(`/d/${board.documentId}`);

      return;
    }

    completeTip(TipName.onboardingChooseTemplate);
    setIsLoading(false);
  }

  function renderTeamSize() {
    return onboardingChooseTemplateEnabled ? (
      <ChooseTeamSize onCompleted={teamSizeSelected} />
    ) : (
      <OldOnboardingTeamSize onCompleted={teamSizeSelected} />
    );
  }

  useEffect(() => {
    if (currentOnboardingTip) {
      setStepString(currentOnboardingTip);
    } else {
      setStepString(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOnboardingTip]);

  useEffect(() => {
    tracking.trackAnalyticsEvent("onboarding_flow_started", {
      category: consts.TRACKING_CATEGORY.PAGE_VIEWS,
    });

    return () => {
      tracking.trackAnalyticsEvent("onboarding_flow_finished", {
        category: consts.TRACKING_CATEGORY.ONBOARDING,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentOnboardingTip) return null;

  if (isLoading) {
    return (
      <BigModalContainer>
        <AppLoader />
      </BigModalContainer>
    );
  }

  return (
    <BigModalContainer>
      {currentOnboardingTip === TipName.onboardingTeamSize && renderTeamSize()}
      {currentOnboardingTip === TipName.onboardingChooseTemplate && (
        <OnboardingChooseTemplate onCompleted={chooseTemplateCompleted} />
      )}
      {currentOnboardingTip === TipName.onboardingInvite && (
        <InviteView onCompleted={() => completeTip(TipName.onboardingInvite)} />
      )}
    </BigModalContainer>
  );
}
