import React from "react";

export const GanttAddTaskIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" fill="none" viewBox="0 0 13 12">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.825 5.214V0H5.25v5.214H0v1.565h5.25V12h1.575V6.78h5.247V5.213z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const ToolbarGanttAddTaskIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" fill="none" viewBox="0 0 24 20">
    <path stroke="#fff" strokeWidth="1.5" d="M.75.945h22.498v17.883H.75zM3.691 15.137h16.614"></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.008 7.166v-3.28h-1.42v3.28h-3.28v1.422h3.28v3.28h1.42v-3.28h3.28V7.166z"
      clipRule="evenodd"
    ></path>
  </svg>
);
