// This file contains the core types and functions that can be reused for any ActionMap

// Define base types
type ActionWithPayload<T extends string, P> = {
  type: T;
  payload: P;
};

type ActionWithoutPayload<T extends string> = {
  type: T;
};

export type Action<T extends string, P> = P extends void ? ActionWithoutPayload<T> : ActionWithPayload<T, P>;

// Define ActionCreator types
type ActionCreatorWithPayload<T extends string, P> = (payload: P) => ActionWithPayload<T, P>;
type ActionCreatorWithoutPayload<T extends string> = () => ActionWithoutPayload<T>;
export type ActionCreator<T extends string, P> = P extends void
  ? ActionCreatorWithoutPayload<T>
  : ActionCreatorWithPayload<T, P>;

// Define PayloadMatcher type
export type PayloadMatcher<P> = P extends void ? () => void : (payload: P) => void;

// Define ActionHandlers type
// An object with key for each action, and function that takes the payload and returns void
export type ActionHandlers<T extends { [key: string]: any }> = {
  [P in keyof T]: T[P] extends infer V ? (V extends void ? () => void : (payload: V) => void) : never;
};

// Create the action builder
export function createActionBuilder<T extends string, P>(type: T): ActionCreator<T, P> {
  return ((payload?: P) => {
    return payload === undefined ? { type } : { type, payload };
  }) as ActionCreator<T, P>;
}

// Create type-safe action matcher (with type guard)
export function createActionMatcher<T extends string, P>(type: T) {
  return (action: any): action is Action<T, P> => action.type === type;
}

// Create helper function to generate all action creators and matchers
// export function createActionHelpers<AM extends Record<string, any>>(
//   actionMap: AM,
//   payloadMatchers: { [K in keyof AM]: PayloadMatcher<AM[K]> }
// ) {
//   type ActionType = keyof AM;

//   const creators = {} as { [K in ActionType]: ActionCreator<K & string, AM[K]> };
//   const typeMatchers = {} as { [K in ActionType]: (action: any) => action is Action<K & string, AM[K]> };

//   for (const type in actionMap) {
//     creators[type] = createActionBuilder<typeof type, AM[typeof type]>(type);
//     typeMatchers[type] = createActionMatcher<typeof type, AM[typeof type]>(type);
//   }

//   return { creators, typeMatchers, payloadMatchers };
// }

// Create helper function to generate all action creators
export function createActionBuilders<AM extends Record<string, any>>(actionMap: AM) {
  type ActionType = keyof AM;

  const creators = {} as {
    [K in ActionType]: ActionCreator<K & string, AM[K]>;
  };

  for (const type in actionMap) {
    creators[type] = createActionBuilder<typeof type, AM[typeof type]>(type);
  }

  return creators;
}

// Create helper function to generate all action matchers
export function createActionMatchers<AM extends Record<string, any>>(actionMap: AM) {
  type ActionType = keyof AM;

  const typeMatchers = {} as {
    [K in ActionType]: (action: any) => action is Action<K & string, AM[K]>;
  };

  for (const type in actionMap) {
    typeMatchers[type] = createActionMatcher<typeof type, AM[typeof type]>(type);
  }

  return typeMatchers;
}

// New type for action handlers
export type ActionHandler<T extends string, P> = (action: Action<T, P>) => void;

// Type for the object passed to createActionHandlers
export type ActionHandlersMap<AM extends Record<string, any>> = {
  [K in keyof AM]: ActionHandler<K & string, AM[K]>;
};

// Function to create action handlers
export function createActionHandlers<AM extends Record<string, any>>(
  handlers: ActionHandlersMap<AM>
): ActionHandlersMap<AM> {
  return handlers;
}
