import { atom } from "jotai";
import { ProjectCache } from "shared/datamodel/schemas/project";
import { Team, TeamCache } from "shared/datamodel/schemas/team";

export enum InviteMode {
  Create,
  Edit,
}

export enum CollaborationType {
  Team = "team",
  Project = "project",
}

export const teams = atom<Team[]>([]);

export const teamsAtom = atom(
  (get) => get(teams).sort((t1, t2) => (t1.createdAt < t2.createdAt ? -1 : 1)),
  (_get, set, update: Team[]) => {
    set(teams, update);
  }
);

export const teamCacheAtom = atom<TeamCache>({});

export const projectCacheAtom = atom<ProjectCache>({});
