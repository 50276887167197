export const DATA_NOT_LOADED = "not loaded";
export const DistanceToShowAnchors_px = 20;
export const DistanceToSnapToAnchorFromOutside_px = 40;
export const DistanceToSnapToAnchorFromInside_px = 10;
export const DistanceToSnapToOutline_px = 15;
export const ConnectorTransformPointRadius = 4; // in pixels - actual size = 2*(radius+stroke)
export const ConnectorSnapPointRadius = 4; // pixels
export const CONNETOR_EDITOR_SNAP_THRESHOLD = 2.5;
export const BASIC_ORTHOGONAL_ROUTE_MARGIN = 20; // pixels
export const MAX_ORTHOGONAL_ROUTE_MARGIN = 40; // pixels
export const MIN_ORTHOGONAL_ROUTE_MARGIN = 4; // pixels

export const HANDLE_COLOR = "#00A1FF";
