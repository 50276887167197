import { ModalType } from "frontend/modals/modal-manager";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import { showModalAtom } from "state-atoms/url-atoms";

export default function useModalDeepLink() {
  const [showModalDetails, setShowModalDetails] = useAtom(showModalAtom);
  const [modalTypeFromUrl, ...rest] = showModalDetails?.split("-") ?? [null, null];
  const getNameForUrl = (name: string) => name.toLowerCase().replace(/\s/g, "-");
  const getId = () => {
    const id = rest[0];
    return id ? id : undefined;
  };

  function setModalDeepLink({
    modalType,
    variant,
    id,
    name,
  }: {
    modalType: ModalType | typeof RESET;
    variant?: string;
    id?: string;
    name?: string;
  }) {
    if (modalType === RESET) {
      setShowModalDetails(RESET);
      return;
    }
    setShowModalDetails(
      `${modalType}${variant ? `-${variant}` : ""}${id ? `-${id}` : ""}${name ? `-${getNameForUrl(name)}` : ""}`
    );
  }
  return { modalTypeFromUrl, nameFromUrl: rest.join(" ") || null, setModalDeepLink, getId };
}
