import { SyncService } from "../services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useCanvasSettingsData } from "frontend/subscriptions";
import { CanvasSetting, CanvasSettingsSchema } from "shared/datamodel/schemas/canvas-settings";

export function useCanvasSettings(syncService: SyncService<RW>) {
  const areSettingsLoaded = !!syncService?.getReplicache();
  const settings: CanvasSettingsSchema | null = useCanvasSettingsData(syncService?.getReplicache());

  const defaultSettings: CanvasSettingsSchema = {
    showCursors: true,
  };

  function getSetting(setting: CanvasSetting) {
    if (!areSettingsLoaded) return null;
    return settings?.[setting] ?? defaultSettings[setting];
  }

  function updateSetting(setting: CanvasSetting, value: any) {
    if (!areSettingsLoaded) return;
    syncService?.mutate.updateSetting({ setting, value });
  }

  return {
    areSettingsLoaded,
    settings,
    getSetting,
    updateSetting,
  };
}
