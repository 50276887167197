import { useMindmapNodesForRoot } from "frontend/subscriptions";
import { useAtomValue } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { MindmapNodeDirection, MindmapNodeElement, MindmapNodeOrientation } from "shared/datamodel/schemas/mindmap";
import {
  editingElementIdAtom,
  editingElementLinkAtom,
  selectedElementIdsAtom,
  syncServiceAtom,
  utilsAtom,
} from "state-atoms";
import { Circle, Group, Rect, Text } from "react-konva";
import MindmapNodeCanvasElement from "./elements/mindmap-node-element";
import { Point } from "frontend/utils/math-utils";
import { Degrees } from "frontend/utils/transform";
import {
  buildNodesTree,
  calculateNodeConnectorAnchor,
  calculateParentConnectorAnchor,
  findNode,
  getNodesWithPositions,
  MindmapNodeTree,
} from "frontend/utils/mindmap-utils";
import consts from "shared/consts";
import { CanvasArrow } from "./utility-elements";
import { ITraits, Trait } from "./elements-toolbar/elements-toolbar-types";
import { getFontSize } from "./text-element";
import { useCurrentCanvasValue } from "./canvas-context";
import tracking from "frontend/tracking";
import { MindmapNewNodeButton } from "./utility-elements/mindmap-sockets";
import SimpleConnector from "./elements/connector/simple-connector";

export default function MindmapCanvasElement({
  id,
  element,
  changeElement,
  allElementsData,
}: {
  id: string;
  element: MindmapNodeElement;
  allElementsData?: Array<[string, any]>;
  changeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    updateSubMenuData?: any, //take the last change and make it the default when selecting this element again from the menu (e.g. make the default color the last color selected)
    elementId?: string
  ) => void;
}) {
  const syncService = useAtomValue(syncServiceAtom);
  const editingElementId = useAtomValue(editingElementIdAtom);
  const selectedElementIds = useAtomValue(selectedElementIdsAtom);
  const editingElementLinkId = useAtomValue(editingElementLinkAtom);

  const { nodes: allNodes, nodesSizes } = useMindmapNodesForRoot(
    syncService?.getReplicache(),
    element.rootId,
    allElementsData
  );

  const [nodeSizeById, setNodeSizeById] = useState<{ [id: string]: { width: number; height: number } }>(nodesSizes);

  const [{ documentId }] = useCurrentCanvasValue();
  const canvasUtils = useAtomValue(utilsAtom(documentId));

  // map all nodes to their children for easy access
  const childrenMap = Object.entries(allNodes).reduce((acc, [id, node]) => {
    if (!node.parentId) {
      return acc;
    }
    if (!acc[node.parentId]) {
      acc[node.parentId] = [];
    }
    acc[node.parentId].push(id);
    return acc;
  }, {} as { [parentId: string]: string[] });

  // build the tree of nodes
  const nodesTree = buildNodesTree({ rootId: id, root: element, childrenMap, allNodes });

  // calculate the positions of all nodes
  const layout = getNodesWithPositions(nodesTree, nodeSizeById);

  useEffect(() => {
    setNodeSizeById(nodesSizes);
  }, [nodesSizes]);

  function onChangeNodeSize(id: string, size: { width: number; height: number }) {
    setNodeSizeById((prev) => ({ ...prev, [id]: size }));
  }

  function onFinishedEditingNode(cancelled: boolean, node: MindmapNodeTree) {
    if (!canvasUtils) {
      return;
    }

    if (cancelled) {
      canvasUtils.clearSelectedElementIds();
    } else {
      const parentId = node.parentId ?? node.id;
      addNewNode(parentId, node.direction ?? MindmapNodeDirection.TRAILING);
    }
  }

  function addNewNode(parentId: string, direction: MindmapNodeDirection, order?: number) {
    if (!canvasUtils) {
      return;
    }
    const parent = findNode(nodesTree, parentId);
    if (!order && parent) {
      order = parent.children?.length ?? 0;
    }
    canvasUtils.addMindmapNode(parentId, element.rootId, direction, order ?? 0);
  }

  function renderConnector(node: Point, color: string, parent?: Point, isLeading: boolean = false) {
    if (!parent) {
      return null;
    }
    let parentRotation = element.orientation === MindmapNodeOrientation.VERTICAL ? 0 : (90 as Degrees);
    let nodeRotation = element.orientation === MindmapNodeOrientation.VERTICAL ? 180 : (270 as Degrees);
    if (isLeading) {
      // swap the rotation of the connector
      // so that the arrow is always pointing to the node from the parent
      [parentRotation, nodeRotation] = [nodeRotation, parentRotation];
    }
    return (
      <SimpleConnector
        id={"mindmap-connector"}
        p1={{ ...parent, rotation: parentRotation as Degrees }}
        p2={{ ...node, rotation: nodeRotation as Degrees }}
        lineType={"curve"}
        element={{
          strokeWidth: 3,
          stroke: color,
          // innerPoints: [{ x: parent!.x, y: parent!.y, id: "ASD" }],
        }}
        curveStrength={50}
      />
    );
  }

  function renderAddNodeButton(node: MindmapNodeTree, isLeading: boolean) {
    if (node.collapsed || selectedElementIds.length !== 1 || !selectedElementIds.includes(node.id)) {
      return null;
    }

    const showingCollapseButton = node.parentId && node.descendantsCount;
    const padding = showingCollapseButton
      ? consts.DEFAULTS.MINDMAP_ADD_NODE_BUTTON_PADDING
      : consts.DEFAULTS.MINDMAP_CONNECTOR_CHILD_PADDING;
    const toAnchor = calculateParentConnectorAnchor(node, element.orientation, isLeading, padding);
    const direction = isLeading ? MindmapNodeDirection.LEADING : MindmapNodeDirection.TRAILING;
    return (
      <MindmapNewNodeButton
        position={toAnchor}
        onClick={() => {
          tracking.trackEvent(consts.TRACKING_CATEGORY.CANVAS_ACTION, "add-mindmap-node-clicked");
          addNewNode(node.id, direction);
        }}
      />
    );
  }

  function renderCollapseButton(
    node: MindmapNodeTree,
    color: string,
    orientation: MindmapNodeOrientation,
    isLeading: boolean
  ) {
    if (!node.parentId || (!node.children?.length && !node.collapsed)) {
      return null;
    }
    const shouldShowCollapseButton = node.collapsed || selectedElementIds.includes(node.id);
    const fromAnchor = calculateParentConnectorAnchor(node, element.orientation, isLeading);
    const toAnchor = calculateParentConnectorAnchor(
      node,
      element.orientation,
      isLeading,
      consts.DEFAULTS.MINDMAP_CONNECTOR_CHILD_PADDING
    );
    const rotation = calculateButtonRotation(orientation, isLeading);
    return (
      <>
        {node.collapsed && renderConnector(toAnchor, color, fromAnchor, isLeading)}
        {shouldShowCollapseButton && (
          <CollapseButton
            position={toAnchor}
            collapsed={node.collapsed}
            color={color}
            onClick={() => {
              tracking.trackEvent(
                consts.TRACKING_CATEGORY.CANVAS_ACTION,
                "mindmap_collapse_clicked",
                node.collapsed ? "expand" : "collapse",
                node.descendantsCount
              );
              changeElement({ collapsed: !node.collapsed }, { shouldAdd: true }, undefined, node.id);
            }}
            text={node.collapsed ? `${node.descendantsCount}` : "-"}
            rotation={rotation}
          />
        )}
      </>
    );
  }

  function renderTreeNode(node: MindmapNodeTree, color: string, parentAnchor?: Point): React.ReactNode {
    const size = nodeSizeById[node.id];
    if (!size) {
      return null;
    }
    // we calculate both anchors from this node to its children
    // because the root node can have children on both sides
    // on other nodes we'll only use one of them
    const parentToTrailingNodeAnchor = calculateParentConnectorAnchor(node, element.orientation, false);
    const parentToLeadingNodeAnchor = calculateParentConnectorAnchor(node, element.orientation, true);
    const toChildAnchor = calculateNodeConnectorAnchor(node, element.orientation);
    const isLeading = node.direction === MindmapNodeDirection.LEADING;
    const renderLeadingAddButton = !node.parentId || isLeading;
    const renderTrailingAddButton = !node.parentId || !isLeading;
    let hasLeadingSibling = false;
    return (
      <React.Fragment key={node.id}>
        {renderConnector(toChildAnchor, node.color ?? color, parentAnchor, isLeading)}
        {!node.collapsed &&
          node.children.map((child) => {
            const isLeading = child.direction === MindmapNodeDirection.LEADING;
            const anchor = isLeading ? parentToLeadingNodeAnchor : parentToTrailingNodeAnchor;
            hasLeadingSibling = hasLeadingSibling || isLeading;
            return renderTreeNode(child, node.color ?? color, anchor);
          })}
        {!isLeading && renderCollapseButton(node, node.color ?? color, element.orientation, false)}
        {isLeading && renderCollapseButton(node, node.color ?? color, element.orientation, true)}
        {renderLeadingAddButton && renderAddNodeButton(node, true)}
        {renderTrailingAddButton && renderAddNodeButton(node, false)}
        <MemoizedMindmapNode
          id={node.id}
          node={node}
          absolutePosition={{ x: element.x + node.x, y: element.y + node.y }}
          size={size}
          changeElement={changeElement}
          isEditing={editingElementId === node.id}
          isEditingLink={editingElementLinkId === node.id}
          childrenCount={node.children?.length ?? 0}
          onChangeSize={(size) => onChangeNodeSize(node.id, size)}
          onFinishedEditing={(cancelled) => onFinishedEditingNode(cancelled, node)}
        />
      </React.Fragment>
    );
  }

  function renderLayout() {
    if (!layout) {
      return null;
    }
    return renderTreeNode(layout, element.color ?? consts.DEFAULTS.MINDMAP_COLOR);
  }

  return (
    <Group x={element.x} y={element.y}>
      {renderLayout()}
    </Group>
  );
}

function CollapseButton({
  position,
  collapsed,
  color,
  text,
  rotation,
  onClick,
}: {
  position: Point;
  collapsed: boolean;
  color: string;
  text: string;
  rotation: number;
  onClick: () => void;
}) {
  const [hover, setHover] = useState(false);

  const circleRef = useRef<any>(null);
  const rectRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  const CircleRadius = 8;
  const CirclePadding = 4;

  useEffect(() => {
    circleRef.current?.to({
      scaleX: hover ? 1.2 : 1,
      scaleY: hover ? 1.2 : 1,
      duration: 0.1,
    });
  }, [hover]);

  useEffect(() => {
    if (!textRef.current || !rectRef.current) {
      return;
    }
    const textWidth = textRef.current.getWidth();
    const rectWidth = Math.max(textWidth + CirclePadding * 2, CircleRadius * 2);
    textRef.current.x(-textWidth / 2);
    rectRef.current.x(-rectWidth / 2);
    rectRef.current.width(rectWidth);
    textRef.current.getLayer().batchDraw();
  }, [text, collapsed]);

  const mouseHandlingProps = {
    name: "mindmap-collapse anchor",
    onClick,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };

  return (
    <Group x={position.x} y={position.y} ref={circleRef} rotation={rotation}>
      {!collapsed && (
        <>
          <Circle
            radius={CircleRadius}
            fill={collapsed ? color : "white"}
            stroke={color}
            strokeWidth={2}
            {...mouseHandlingProps}
          />
          <Group x={2} y={0}>
            <CanvasArrow
              pointerLength={3}
              pointerWidth={3}
              strokeWidth={2}
              stroke={color}
              fill={color}
              listening={false}
            />
          </Group>
        </>
      )}
      {collapsed && (
        <Group rotation={-rotation}>
          <Rect
            ref={rectRef}
            y={-CircleRadius}
            height={CircleRadius * 2}
            cornerRadius={CircleRadius}
            fill={color}
            {...mouseHandlingProps}
          />
          <Text
            ref={textRef}
            y={CirclePadding - CircleRadius}
            text={text}
            align="center"
            verticalAlign="middle"
            fontFamily="Poppins"
            fontSize={10}
            // fontStyle="bold"
            fill="white"
            letterSpacing={1}
            listening={false}
          />
        </Group>
      )}
    </Group>
  );
}

export function mindmapTraits(element: MindmapNodeElement): ITraits {
  const defaultColor = element.parentId ? consts.DEFAULTS.MINDMAP_COLOR : consts.DEFAULTS.MINDMAP_ROOT_STROKE_COLOR;
  const baseTraits = {
    mindmapColor: element.color ?? defaultColor,
    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: getFontSize(element),
    font: element.font ?? consts.DEFAULTS.FONT,
  };
  if (element.parentId) {
    return baseTraits;
  }
  // root node
  return {
    ...baseTraits,
    mindmapOrientation: element.orientation,
  };
}

export function mindmapValidateTrait(_element: MindmapNodeElement, _trait: Trait, value: any) {
  return value;
}

function calculateButtonRotation(orientation: MindmapNodeOrientation, isLeading: boolean): Degrees {
  if (orientation === MindmapNodeOrientation.VERTICAL) {
    if (isLeading) {
      return 0 as Degrees;
    } else {
      return 180 as Degrees;
    }
  } else if (isLeading) {
    return 90 as Degrees;
  } else {
    return 270 as Degrees;
  }
}

const MemoizedMindmapNode = React.memo(MindmapNodeCanvasElement);
