import RadioBlock from "frontend/ui-components/radio-block";
import style from "./smart-template-modal.module.css";
import { CanvasTemplateMinimal, CanvasTemplate } from "shared/datamodel/schemas";
import { useState } from "react";
import StyledButton from "frontend/ui-components/styled-button";
import {
  openMondayIntegrationAuthorization,
  useBoardIntegrations,
  useBoardTasks,
} from "frontend/hooks/use-board-integrations";
import DropdownPicker from "frontend/ui-components/picker/dropdown-picker";
import useMondayBoardsPicker, { MondayBoard } from "frontend/hooks/use-monday-boards-picker";
import { createElementsFromItems, getSmartTemplateColumnsSettings } from "frontend/utils/templates-utils";
import { getMondayBoardItems } from "frontend/api";
import { MondayIntegrationType } from "shared/integrations/integration";
import Checkbox from "frontend/ui-components/checkbox";
import { getPathPrefix } from "../utils/getPathPrefix";

export default function SmartTemplateModal({
  template,
  handleCreatedTemplate,
  documentId,
}: {
  template: CanvasTemplateMinimal;
  handleCreatedTemplate: (template: CanvasTemplateMinimal | CanvasTemplate) => void;
  documentId: string;
}) {
  const { shouldAddMondayIntegration, mondayIntegration } = useBoardIntegrations(documentId);
  const [selectedOption, setSelectedOption] = useState<"import" | "start">("import");
  const [showMondayPicker, setShowMondayPicker] = useState(false);

  function onChoose() {
    if (selectedOption === "import") {
      if (shouldAddMondayIntegration || !mondayIntegration) {
        openMondayIntegrationAuthorization();
      } else {
        setShowMondayPicker(true);
      }
    } else {
      handleCreatedTemplate(template);
    }
  }

  function createTemplateFromMondayItems(elements: Record<string, any>) {
    handleCreatedTemplate({ ...template, data: { elements } });
  }

  function renderOptions() {
    return (
      <div className={style.container}>
        <div className={style.header}>How do you want to build your timeline?</div>
        <div className={style.buttons}>
          <RadioBlock
            title="Start from scratch"
            isSelected={selectedOption === "start"}
            imageSrc={getPathPrefix("/images/smart-template-start-from-scratch.png")}
            onSelect={() => setSelectedOption("start")}
            fontSize="small"
          />
          <div className={style.importFromMondayRadioBlock}>
            <RadioBlock
              title="Import from monday board"
              isSelected={selectedOption === "import"}
              imageSrc={getPathPrefix("/images/smart-template-import-data.png")}
              onSelect={() => setSelectedOption("import")}
              fontSize="small"
            />
            <img src={getPathPrefix("/images/monday-mini-logo.svg")} className={style.mondayLogo} />
          </div>
        </div>
        <div className={style.buttonContainer}>
          <StyledButton title={"Create"} onClick={onChoose} />
        </div>
      </div>
    );
  }

  return showMondayPicker ? (
    <MondayItemsPicker
      template={template}
      integrationId={mondayIntegration!.id}
      onSelection={createTemplateFromMondayItems}
      documentId={documentId}
    />
  ) : (
    renderOptions()
  );
}

function MondayItemsPicker({
  integrationId,
  template,
  onSelection,
  documentId,
}: {
  integrationId: string;
  template: CanvasTemplateMinimal;
  onSelection: (elements: Record<string, any>) => void;
  documentId: string;
}) {
  const [searchValue, setSearchValue] = useState("");

  const [selectedBoard, setSelectedBoard] = useState<MondayBoard | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<Record<string, string>>({});
  const [isLive, setIsLive] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const { addMondayIntegration } = useBoardTasks(documentId);
  const { boards, isLoading } = useMondayBoardsPicker(integrationId, searchValue);

  const columnTypes = getSmartTemplateColumnsSettings(template);

  const chooseBoardEnabled = !isLoading || searchValue.length > 0;
  const canImport = !!selectedBoard && columnTypes.every((c) => !!selectedColumns[c.id]);

  async function importClicked() {
    if (!selectedBoard) {
      return;
    }
    const selectedColumnIds = Object.values(selectedColumns);

    setIsImporting(true);
    try {
      let boardIntegrationId: string | undefined;
      if (isLive) {
        boardIntegrationId = await addMondayIntegration(
          selectedBoard.id,
          integrationId,
          selectedBoard.columns,
          MondayIntegrationType.live
        );
      }
      const result = await getMondayBoardItems(integrationId, selectedBoard!.id, {
        requiredColumnIds: selectedColumnIds,
      });
      const items = result.items;
      const elements = createElementsFromItems(template, items, selectedColumns, boardIntegrationId);
      onSelection(elements);
    } catch (e) {
      console.error("Failed to get items from monday board", e);
    } finally {
      setIsImporting(false);
    }
  }

  function renderColumnPicker(columnType: ReturnType<typeof getSmartTemplateColumnsSettings>[0]) {
    const columns = selectedBoard?.columns.filter((c) => c.type === columnType.type);
    return (
      <div className={style.mondayColumnPicker} key={columnType.id}>
        <div className={style.mondayColumnPickerLabel} style={{ fontWeight: 500 }}>
          {columnType.title}
        </div>
        <DropdownPicker
          options={columns ?? []}
          isSelected={(c) => selectedColumns[columnType.id] === c.id}
          titles={(c) => c.title}
          enabled={!!selectedBoard}
          onChange={(c) => setSelectedColumns((selected) => ({ ...selected, [columnType.id]: c.id }))}
          placeholder={selectedBoard ? "Choose Column" : "Choose a board first"}
        />
      </div>
    );
  }

  function renderColumns() {
    return columnTypes.map(renderColumnPicker);
  }

  return (
    <div className={style.mondayPickerContainer}>
      <div className={style.mondayPickerHeader}>
        <div className={style.mondayPickerTitle}>
          <div style={{ paddingBottom: 2 }}>Integrate with</div>
          <img src={getPathPrefix("/images/monday-logo.svg")} className={style.mondayLogoBig} />
        </div>
        <div className={style.mondayPickerSubtitle}>
          Choose the board and columns you want
          <br /> to visualize on your timeline
        </div>
      </div>
      <div className="separator" />
      <div className={style.mondayPickerContent}>
        <MondayPickerStep title={"Step 1:"} subtitle="Choose board">
          <div style={{ paddingLeft: 58 }}>
            <DropdownPicker
              options={boards}
              isSelected={(board) => selectedBoard?.id === board.id}
              titles={(board) => board.name}
              enabled={chooseBoardEnabled}
              onChange={(board) => setSelectedBoard(board)}
              enableSearch
              onSearch={setSearchValue}
              placeholder={isLoading ? "Loading..." : "Search for a board..."}
            />
          </div>
        </MondayPickerStep>
        {columnTypes.length > 0 && (
          <>
            <div className={"separator"} />
            <MondayPickerStep title={"Step 2:"} subtitle={`Choose the column${columnTypes.length > 1 ? "s" : ""}`}>
              {renderColumns()}
            </MondayPickerStep>
          </>
        )}
        <MondayPickerStep>
          <div className={style.mondayPickerLiveContainer}>
            <Checkbox checked={isLive} onChange={setIsLive} />
            <div className={style.mondayPickerLiveDescription} onClick={() => setIsLive(!isLive)}>
              <span className={style.mondayPickerLiveTitle}>Live sync</span>
              <span>By selecting this option all your items will be synced</span>
              <span>Any changes you make in monday will affect your Workcanvas and vice verca</span>
            </div>
          </div>
        </MondayPickerStep>
      </div>
      <div className={style.mondayPickerFooter}>
        <StyledButton
          title={"Import board data"}
          onClick={importClicked}
          enabled={canImport}
          customStyle={{ width: 200 }}
          loading={isImporting}
          loadingTitle="Importing..."
        />
      </div>
    </div>
  );
}

function MondayPickerStep({ title, subtitle, children }: { title?: string; subtitle?: string; children: any }) {
  return (
    <div className={style.mondayPickerStep}>
      {title && (
        <div className={style.mondayPickerStepLabel}>
          <div style={{ fontWeight: 500 }}>{title}</div>
          <div>{subtitle}</div>
        </div>
      )}
      <div className={style.mondayPickerStepContent}>{children}</div>
    </div>
  );
}
