import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import consts, { Plan } from "shared/consts";

import { openCustomerPortal } from "frontend/billingUtils";
import { useBoardValue } from "frontend/state/board-state";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import useOutsideRef from "frontend/utils/click-outside-handler";

import style from "./invite-visitors-modal.module.css";
import { isTrialActive, isTrialExpired } from "shared/util/billing";
import { getPathPrefix } from "../utils/getPathPrefix";

const shareIcon = getPathPrefix("/images/share-icon.svg");

function getInviteLink(documentId: string) {
  return `${location.origin}/d/${documentId}`;
}

export default function InviteVisitorsModal({ referrer, onDismiss }: { referrer: string; onDismiss: () => void }) {
  const ref = useRef<any>(null);
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);
  useOutsideRef(ref, onDismiss);

  const [{ documentId, board }, _] = useBoardValue();
  const [{ user }] = useStateValue();

  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      "visitor_invite_modal_shown",
      referrer,
      user?.id,
      board?.state
    );
  }, []);

  function handleCopyLink() {
    if (board) {
      tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.INVITE_LINK_COPIED);
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.ADMIN_ACTION,
        "invite_link_copied",
        referrer,
        user?.id,
        board?.state
      );
      navigator.clipboard.writeText(getInviteLink(documentId));
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 2000);
    }
  }

  function renderInvitation() {
    return (
      <div ref={ref} className={cn(style.centerInScreen, style.container, style.largeWindow)}>
        <div className={cn(style.section, style.header)}>Invite visitors with this link</div>

        <div className={cn(style.section, style.bottomHalf)}>
          <div className={style.linkContainer}>
            <img style={{ width: 12, height: 12 }} src={shareIcon} />
            <span className={style.linkText}>{getInviteLink(documentId)}</span>
            <div style={{ minWidth: 100, display: "flex", justifyContent: "flex-end" }}>
              <StyledButton
                size={ButtonSize.small}
                title={showCopySuccess ? "Link Copied!" : "Copy link"}
                onClick={() => {
                  !showCopySuccess && handleCopyLink();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderCantInvite() {
    return (
      <div ref={ref} className={cn(style.centerInScreen, style.container, style.cantInviteModal)}>
        <div className={cn(style.header2)}>Invite visitors to “{board?.name}”</div>
        <div className={style.regularText}>
          Simply send the invite link to anyone and start collaborating in real time.
          <br />
          Available for all pro users.
        </div>
        <StyledButton
          title={"Start Collaborating"}
          onClick={() => {
            tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
            tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "upgrade-invite-visitors");
            openCustomerPortal({ type: "upgrade", planId: Plan.pro, interval: "month" });
            onDismiss();
          }}
        />
      </div>
    );
  }

  let showTrial;
  if (user) {
    const trialActive = isTrialActive(user.planInfo!);
    const trialExpired = isTrialExpired(user.planInfo!);
    showTrial = trialActive || trialExpired;
  }
  const noInvite = !!user?.planInfo?.is_free && !showTrial;

  return noInvite ? renderCantInvite() : renderInvitation();
}
