import { useEffect, useRef, useState } from "react";
import style from "../manage-collaboration-modal.module.css";
import CollaborationHeader from "../collaboration-header";
import { Team } from "shared/datamodel/schemas/team";
import { CollaborationMember } from "../collaboration-member";
import useOutsideRef from "frontend/utils/click-outside-handler";
import useStateValue from "frontend/state/value";
import Skeleton from "react-loading-skeleton";
import { Permission, User } from "shared/datamodel/schemas";
import useUsers from "frontend/hooks/use-users";
import useInfiniteScroll from "frontend/hooks/use-infinite-scroll";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { useInvite } from "frontend/hooks/use-invite";
import { InviteMode } from "state-atoms";
import SearchEmptyState from "../empty-state/search-empty-state";
import SendInviteToast from "frontend/ui-components/toast/send-invite-toast";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";

export default function ManageTeamMembers({
  onDismiss,
  team,
  onEditSeats,
}: {
  onDismiss: () => void;
  team: Team;
  onEditSeats: () => void;
}) {
  const [{ user }] = useStateValue();
  const { users, hasMore, isLoading, loadUsers, failed: failedLoadingUsers } = useUsers(InstanceType.Team, team.id);
  const [members, setMembers] = useState<User[]>([] as User[]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inviteeName, setInviteeName] = useState<string | null>(null);
  const collaborationModalRef = useRef(null);

  const isFreePlan = !user || !user.planInfo || user.planInfo.is_free;
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";

  const { onInvite } = useInvite();

  const loadMoreRef = useInfiniteScroll({
    loadMore: loadUsers,
    hasMore,
    isLoading,
    failedLoadingUsers,
  });
  const isTouchAccount = user?.planInfo?.source === "touch";
  const canManageSeats = !isFreePlan && !isTouchAccount && user.isAdmin && user.isSubscribedToCanvas;
  const name = team.name.toLowerCase().endsWith("team") ? team.name.slice(0, -4).trim() : team.name;

  useOutsideRef(collaborationModalRef, () => {
    onDismiss();
  });

  useEffect(() => {
    setMembers(users);
  }, [team.id, isLoading]);

  //Hide the ask to edit message after some interval
  useEffect(() => {
    let timeOut: any;
    if (inviteeName) {
      timeOut = setTimeout(() => {
        setInviteeName(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [inviteeName]);

  function onRestrictedUserInvite(user: User) {
    onInvite({
      invitePermission: Permission.editor,
      inviteMode: InviteMode.Edit,
      team,
      source: "manage-team-modal",
      usersFromList: [user],
    });
    setInviteeName(user.name);
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredMembers = members.filter((member) => member.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const renderMembers = () => {
    if (members.length && filteredMembers.length === 0 && !isLoading) {
      return <SearchEmptyState searchTerm={searchQuery} subtitle="in team members" />;
    }
    const sortedMembersBySubscription = !applyRestrictedLimitation
      ? filteredMembers
      : [...filteredMembers].sort((a, b) => {
          if (a.isSubscribedToCanvas && !b.isSubscribedToCanvas) return 1;
          if (!a.isSubscribedToCanvas && b.isSubscribedToCanvas) return -1;
          return 0;
        });

    return sortedMembersBySubscription?.map((teammate, index) => (
      <CollaborationMember
        key={teammate.id}
        teammate={teammate}
        members={members}
        setMembers={setMembers}
        index={index}
        isOwner={team?.isOwner || ((team?.accountTeam && user?.isAdmin) as boolean)}
        team={team}
        onRestrictedUserInvite={onRestrictedUserInvite}
      />
    ));
  };

  return (
    <div className={style.container} ref={collaborationModalRef}>
      <div style={{ padding: "25px 25px 20px 25px" }}>
        <CollaborationHeader
          title={team.isOwner ? `Manage  "${name}"  team` : `"${name}"  team members`}
          onDismiss={() => {
            onDismiss();
          }}
        />
        <div className={style.search}>
          <div className={style.subtitleContainer}>
            <span className={style.subtitle}>Search a team member</span>
            {canManageSeats && (
              <span className={style.manageSeats} onClick={onEditSeats}>
                Manage seats
              </span>
            )}
          </div>
          <div className={style.searchContainer}>
            <input
              placeholder="Search members..."
              value={searchQuery}
              onChange={handleSearch}
              className={style.searchInput}
              autoFocus={true}
            />
          </div>
        </div>
      </div>
      <div className={style.divider}></div>
      <div className={style.collaborators}>
        {renderMembers()}
        <div ref={loadMoreRef}>
          {isLoading ? (
            <Skeleton count={4} className={style.userLineSkeleton} containerClassName={style.collaborators} />
          ) : null}
        </div>
      </div>
      {inviteeName && <SendInviteToast inviteeName={inviteeName} />}
    </div>
  );
}
