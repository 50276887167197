import style from "./new-paid-seats-modal.module.css";
import StyledButton from "frontend/ui-components/styled-button";
import React, { useEffect } from "react";
import { InviteFailedEmailsList } from "frontend/ui-components/invite-emails";
import { InviteResponse } from "frontend/utils/invite-utils";
import useStateValue from "frontend/state/value";
import { Action } from "frontend/state";

export default function NewPaidSeatsModal({
  onDismiss,
  inviteUsersResponse,
}: {
  onDismiss: () => void;
  inviteUsersResponse: InviteResponse | null;
}) {
  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    if (inviteUsersResponse?.addedSeats && user) {
      const seats_count = Number(user?.planInfo?.seats_count ?? 0) + Number(inviteUsersResponse?.addedSeats);
      const updatedUser = { ...user, planInfo: { ...user?.planInfo }, seats_count };
      dispatch({ type: Action.UpdatedUser, payload: updatedUser });
    }
  }, []);

  function renderContent(invitedCount?: number, addedSeats?: number) {
    const inviteSentMessage =
      invitedCount && invitedCount > 1 ? `${invitedCount} new invites sent,` : `${invitedCount} new invite sent,`;
    const addedSeatsMessage =
      addedSeats && addedSeats > 1 ? `${addedSeats} new paid seats` : `${addedSeats} new paid seat`;
    return (
      <span>
        {inviteSentMessage} <br />
        <b>{addedSeatsMessage}</b> added.
      </span>
    );
  }

  function renderNewPaidSeatsModal() {
    const invitedCount = inviteUsersResponse?.usersCount ?? 0;
    const failedEmails = inviteUsersResponse?.failed;
    const failedCount = failedEmails?.length;
    const addedSeats = Math.min(inviteUsersResponse?.addedSeats ?? 0, invitedCount);

    return (
      <div className={style.container}>
        <div className={style.internalContainer}>
          <div className={style.successHeader}>Invites sent and seats added</div>
          <div className={style.successBody}>{renderContent(invitedCount, addedSeats)}</div>
          {failedCount && failedCount > 0 ? (
            <div className={style.failedContainer}>
              <InviteFailedEmailsList failedEmails={failedEmails} />
            </div>
          ) : null}
          <div className={style.successButtonContainer}>
            <StyledButton title="Got it" onClick={() => onDismiss()} customStyle={{ width: 100 }} />
          </div>
        </div>
      </div>
    );
  }

  return renderNewPaidSeatsModal();
}
