import { Line } from "react-konva";
import { StickyNote } from "shared/datamodel/schemas";
import { CSSProperties, useEffect, useState } from "react";
import { StickyNoteMarginX, StickyNoteMarginY } from "./sticky-note-element";

enum LineSide {
  Left = "left",
  Right = "right",
}

const setMouseTo = (cursor: CSSProperties["cursor"]) => (e: any) => {
  const container = e.target.getStage()!.container();
  container.style.cursor = cursor;
};

const setMouseEWResize = setMouseTo("ew-resize");
const resetMouse = setMouseTo("inherit");

function EdgeLine({
  lineSide,
  element,
  changeElement,
  sticky,
}: {
  lineSide: LineSide;
  element: StickyNote;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
  sticky: any;
}) {
  const expandFactor = 2;
  const { x, y, width, height, isWide, scaleX, scaleY } = element;
  const lineHeight = height + StickyNoteMarginY;
  const [xValue, setXValue] = useState(lineSide == LineSide.Left ? x : x + scaleX * (width + StickyNoteMarginX));

  function recalcHeight(width: number): number {
    const text = element.text ?? "";
    const minimumHeight = element.isWide ? element.width / 2 : element.width;
    const lineHeight = element.fontSize as number;
    const textLength = text.length;
    const emptyLines = (text.match(/^[ \t]*$/gm) || []).length;
    const numberOfLines =
      Math.ceil(((element.fontSize as number) * textLength) / (width + StickyNoteMarginX)) + emptyLines;
    const textHeight = numberOfLines * lineHeight;
    return Math.max(textHeight, minimumHeight);
  }

  useEffect(() => {
    setXValue(lineSide == LineSide.Left ? x : x + scaleX * (width + StickyNoteMarginX));
  }, [x, scaleX, width, isWide, element]);

  return (
    <Line
      name="edge-anchor"
      x={xValue}
      y={y}
      hitStrokeWidth={10}
      strokeScaleEnabled={false}
      points={[0, 0, 0, lineHeight]}
      scaleY={scaleY}
      draggable
      onMouseEnter={setMouseEWResize}
      onMouseLeave={resetMouse}
      onDragStart={(e) => {
        setMouseEWResize(e);
        e.currentTarget.attrs.undo = { width, height, isWide };
        window.dispatchEvent(new CustomEvent("transform-start", { detail: { nodes: [sticky] } }));
      }}
      onDragMove={(e) => {
        const cursorDistance = (width / expandFactor) * scaleX;
        const shouldDragRight = Math.floor(e.target.x()) > Math.floor(xValue + cursorDistance);
        const shouldDragLeft = Math.floor(e.target.x()) < Math.floor(xValue - cursorDistance);

        let newProps: any;
        if (lineSide == LineSide.Right) {
          if (shouldDragRight && !isWide) {
            newProps = { width: width * expandFactor, height: recalcHeight(width * expandFactor), isWide: true };
          } else if (shouldDragLeft && isWide) {
            newProps = { width: width / expandFactor, height: recalcHeight(width / expandFactor), isWide: false };
          }
        }
        if (lineSide == LineSide.Left) {
          //expand sticky note from the left side
          if (shouldDragLeft && !isWide) {
            newProps = {
              width: width * expandFactor,
              x: x - scaleX * width,
              height: recalcHeight(width * expandFactor),
              isWide: true,
            };
          } else if (shouldDragRight && isWide) {
            newProps = {
              width: width / expandFactor,
              x: x + (scaleX * width) / expandFactor,
              height: recalcHeight(width / expandFactor),
              isWide: false,
            };
          }
        }
        if (newProps) {
          e.currentTarget.attrs.curProps = newProps;
          changeElement(newProps, { shouldAdd: false });
          setTimeout(() => window.dispatchEvent(new CustomEvent("transform")), 20);
        }
      }}
      onDragEnd={(e) => {
        resetMouse(e);
        // complete the action with undo
        const { curProps, undo } = e.currentTarget.attrs;
        if (curProps && undo) {
          changeElement(curProps, { shouldAdd: true, previousProps: undo });
        }
        if (lineSide === LineSide.Left) {
          e.target.setPosition({ x, y });
        } else {
          e.target.setPosition({ x: x + scaleX * (width + StickyNoteMarginX), y });
        }
        window.setTimeout(() => window.dispatchEvent(new CustomEvent("transform-end")), 20);
      }}
    />
  );
}

export default function EdgeLines({
  element,
  sticky,
  changeElement,
}: {
  element: StickyNote;
  sticky: any;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  return (
    <>
      <EdgeLine sticky={sticky} lineSide={LineSide.Left} element={element} changeElement={changeElement} />
      <EdgeLine sticky={sticky} lineSide={LineSide.Right} element={element} changeElement={changeElement} />
    </>
  );
}
