export const ColorNames: { [key: string]: string } = {
  aliceblue: "#f0f8ff",
  antiquewhite: "#faebd7",
  aqua: "#00ffff",
  aquamarine: "#7fffd4",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  bisque: "#ffe4c4",
  black: "#000000",
  blanchedalmond: "#ffebcd",
  blue: "#0000ff",
  blueviolet: "#8a2be2",
  brown: "#a52a2a",
  burlywood: "#deb887",
  cadetblue: "#5f9ea0",
  chartreuse: "#7fff00",
  chocolate: "#d2691e",
  coral: "#ff7f50",
  cornflowerblue: "#6495ed",
  cornsilk: "#fff8dc",
  crimson: "#dc143c",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgoldenrod: "#b8860b",
  darkgray: "#a9a9a9",
  darkgreen: "#006400",
  darkgrey: "#a9a9a9",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkseagreen: "#8fbc8f",
  darkslateblue: "#483d8b",
  darkslategray: "#2f4f4f",
  darkslategrey: "#2f4f4f",
  darkturquoise: "#00ced1",
  darkviolet: "#9400d3",
  deeppink: "#ff1493",
  deepskyblue: "#00bfff",
  dimgray: "#696969",
  dimgrey: "#696969",
  dodgerblue: "#1e90ff",
  firebrick: "#b22222",
  floralwhite: "#fffaf0",
  forestgreen: "#228b22",
  fuchsia: "#ff00ff",
  gainsboro: "#dcdcdc",
  ghostwhite: "#f8f8ff",
  goldenrod: "#daa520",
  gold: "#ffd700",
  gray: "#808080",
  green: "#008000",
  greenyellow: "#adff2f",
  grey: "#808080",
  honeydew: "#f0fff0",
  hotpink: "#ff69b4",
  indianred: "#cd5c5c",
  indigo: "#4b0082",
  ivory: "#fffff0",
  khaki: "#f0e68c",
  lavenderblush: "#fff0f5",
  lavender: "#e6e6fa",
  lawngreen: "#7cfc00",
  lemonchiffon: "#fffacd",
  lightblue: "#add8e6",
  lightcoral: "#f08080",
  lightcyan: "#e0ffff",
  lightgoldenrodyellow: "#fafad2",
  lightgray: "#d3d3d3",
  lightgreen: "#90ee90",
  lightgrey: "#d3d3d3",
  lightpink: "#ffb6c1",
  lightsalmon: "#ffa07a",
  lightseagreen: "#20b2aa",
  lightskyblue: "#87cefa",
  lightslategray: "#778899",
  lightslategrey: "#778899",
  lightsteelblue: "#b0c4de",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  limegreen: "#32cd32",
  linen: "#faf0e6",
  magenta: "#ff00ff",
  maroon: "#800000",
  mediumaquamarine: "#66cdaa",
  mediumblue: "#0000cd",
  mediumorchid: "#ba55d3",
  mediumpurple: "#9370db",
  mediumseagreen: "#3cb371",
  mediumslateblue: "#7b68ee",
  mediumspringgreen: "#00fa9a",
  mediumturquoise: "#48d1cc",
  mediumvioletred: "#c71585",
  midnightblue: "#191970",
  mintcream: "#f5fffa",
  mistyrose: "#ffe4e1",
  moccasin: "#ffe4b5",
  navajowhite: "#ffdead",
  navy: "#000080",
  oldlace: "#fdf5e6",
  olive: "#808000",
  olivedrab: "#6b8e23",
  orange: "#ffa500",
  orangered: "#ff4500",
  orchid: "#da70d6",
  palegoldenrod: "#eee8aa",
  palegreen: "#98fb98",
  paleturquoise: "#afeeee",
  palevioletred: "#db7093",
  papayawhip: "#ffefd5",
  peachpuff: "#ffdab9",
  peru: "#cd853f",
  pink: "#ffc0cb",
  plum: "#dda0dd",
  powderblue: "#b0e0e6",
  purple: "#800080",
  rebeccapurple: "#663399",
  red: "#ff0000",
  rosybrown: "#bc8f8f",
  royalblue: "#4169e1",
  saddlebrown: "#8b4513",
  salmon: "#fa8072",
  sandybrown: "#f4a460",
  seagreen: "#2e8b57",
  seashell: "#fff5ee",
  sienna: "#a0522d",
  silver: "#c0c0c0",
  skyblue: "#87ceeb",
  slateblue: "#6a5acd",
  slategray: "#708090",
  slategrey: "#708090",
  snow: "#fffafa",
  springgreen: "#00ff7f",
  steelblue: "#4682b4",
  tan: "#d2b48c",
  teal: "#008080",
  thistle: "#d8bfd8",
  tomato: "#ff6347",
  turquoise: "#40e0d0",
  violet: "#ee82ee",
  wheat: "#f5deb3",
  white: "#ffffff",
  whitesmoke: "#f5f5f5",
  yellow: "#ffff00",
  yellowgreen: "#9acd32",
};

// returns the opacity as a number between 0 and 1
export function getOpacity(color: string): number {
  color = normalizeColor(color);
  if (color.match(/transparent/i)) return 0;
  let match = color.match(/#......(..)/i);
  if (match) return parseInt(match[1], 16) / 255;
  match = color.match(/#...(.)/i);
  if (match) {
    const digit = parseInt(match[1], 16);
    return (digit * 16 + digit) / 255;
  }
  return 1;
}

function normalizeColor(color: string): string {
  if (color[0] !== "#") {
    const lower = color.toLowerCase();
    if (ColorNames[lower]) return ColorNames[lower];
  }
  return color;
}

// turn base color and opacity number to string representation:
// #RGB, number -> #RGBA
// #RRGGBB, number -> #RRGGBBAA
export function combineColorOpacity(color: string, opacity: number): string {
  color = normalizeColor(color);
  if (color[0] === "#") {
    if (color.length === "#rgb".length) return color + Math.floor(opacity / 16).toString(16);
    if (color.length === "#rrggbb".length) return color + Math.floor(opacity).toString(16).padStart(2, "0");
  }
  return color; // color format is unknown: just return it`
}

export function replaceColorOpacity(color: string | null | undefined, newOpacity: number): string {
  color = normalizeColor(color ?? "#FFFFFF");
  if (color.match(/^#......$/)) {
    return color + Math.floor(newOpacity).toString(16).padStart(2, "0");
  }
  let m = color.match(/^(#......)(..)$/);
  if (m) {
    return m[1] + Math.floor(newOpacity).toString(16).padStart(2, "0");
  }
  m = color.match(/^(#...)(.)$/);
  if (m) {
    return m[1] + Math.floor(newOpacity / 16).toString(16);
  }
  return color;
}

// parse a color string to base color and opacity
// accepts color codes as #RRGGBBAA, #RRGGBB, #RBGA, #RGB
// returns base color as #RRGGBB and opacity as a number between 0 and 255
export function parseColor(color: string): { color: string; opacity: number } {
  color = normalizeColor(color);
  if (color.match(/^transparent$/i)) {
    return { color: "#FFFFFF", opacity: 0 };
  }
  let m = color.match(/^(#[0-9a-f]{6})([0-9a-f]{2})?$/i);
  if (m) {
    return { color: m[1], opacity: m[2] ? parseInt(m[2], 16) : 255 };
  }
  m = color.match(/^(#[0-9a-f]{3})([0-9a-f]{1})?$/i);
  if (m) {
    return { color: m[1], opacity: m[2] ? parseInt(m[2], 16) * 16 : 255 };
  }
  return { color: "#FFFFFF", opacity: 255 };
}

export function convertHexToHsl(hex: string) {
  hex = hex === "transparent" ? "#FFFFFF" : hex;
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;
  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: {
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      }
      case g: {
        h = (b - r) / d + 2;
        break;
      }
      case b: {
        h = (r - g) / d + 4;
        break;
      }
    }
    h /= 6;
  }
  return [h, s, l];
}

export function convertHexToHsv(hex: string) {
  const hsl = convertHexToHsl(hex);
  return convertHslToHsv(hsl[0], hsl[1], hsl[2]);
}

export function convertHexToRgba(hex: string) {
  hex = hex === "transparent" ? "#FFFFFF" : hex;
  const isShort = hex.length === 3 || hex.length === 4;

  if (hex[0] === "#") {
    hex = hex.slice(1);
  }

  const hexR = isShort ? `${hex.slice(0, 1)}${hex.slice(0, 1)}` : hex.slice(0, 2);
  const hexG = isShort ? `${hex.slice(1, 2)}${hex.slice(1, 2)}` : hex.slice(2, 4);
  const hexB = isShort ? `${hex.slice(2, 3)}${hex.slice(2, 3)}` : hex.slice(4, 6);
  const hexA = (isShort ? `${hex.slice(3, 4)}${hex.slice(3, 4)}` : hex.slice(6, 8)) || "ff";

  return [hexR, hexG, hexB, hexA].map((hexValue) => parseInt(hexValue, 16));
}

export function convertHsvToHex(h: number, s: number, v: number) {
  const rgb = convertHsvToRgb(h, s, v);
  return convertRgbToHex(rgb[0], rgb[1], rgb[2]);
}

export function convertHsvToRgb(h: number, s: number, v: number) {
  let r = 0,
    g = 0,
    b = 0;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0: {
      (r = v), (g = t), (b = p);
      break;
    }
    case 1: {
      (r = q), (g = v), (b = p);
      break;
    }
    case 2: {
      (r = p), (g = v), (b = t);
      break;
    }
    case 3: {
      (r = p), (g = q), (b = v);
      break;
    }
    case 4: {
      (r = t), (g = p), (b = v);
      break;
    }
    case 5: {
      (r = v), (g = p), (b = q);
      break;
    }
  }
  return [r, g, b];
}

export function convertRgbToHex(r: number, g: number, b: number) {
  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
}

export function convertHslToHsv(h: number, s: number, l: number) {
  const v = l + s * Math.min(l, 1 - l);
  return [h, 2 * (1 - l / v), v];
}

export function convertHslToHex(h: number, s: number, l: number) {
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
}

export function getTextColorForBackgroundColor(backgroundColor: string) {
  const [r, g, b] = convertHexToRgba(backgroundColor);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 125 ? "#113357" : "#FFFFFF";
}
