import ganttConstant from "elements/gantt/constants";
import useFeatureValue from "frontend/hooks/use-features";
import { useAtomValue } from "jotai";
import consts from "shared/consts";
import { BoardPermission } from "shared/datamodel/schemas";
import { boardAtom } from "state-atoms";

export const useGanttMaxTasks = () => {
  const board = useAtomValue(boardAtom);
  const featureValue = useFeatureValue(consts.FEATURE_NAMES.GANTT_MAX_TASKS, ganttConstant.MaxTasksAllowed.toString());

  const maxAllowedTasksInPlan =
    (board?.permission === BoardPermission.public ? Infinity : parseInt(featureValue)) ?? ganttConstant.MaxTasksAllowed;

  return maxAllowedTasksInPlan;
};
