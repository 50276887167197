import React from "react";
import { FontProperties } from "shared/datamodel/schemas/textEnabled";
import consts from "backend/shared/consts";
import { EditableTextInput } from "frontend/editableTextInput";
import { Frame } from "shared/datamodel/schemas/frame";
import { editingElementIdAtom, posScaleAtom } from "state-atoms/stage-atoms";
import { useAtomValue, useSetAtom } from "jotai";

export default function FrameTitleEditableTextInput({
  element,
  onChangeElement,
}: {
  element: Frame;
  onChangeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  const scale = useAtomValue(posScaleAtom).scale;
  const setEditingElementId = useSetAtom(editingElementIdAtom);
  const { x, y, width, title } = {
    ...element,
  };

  return (
    <EditableTextInput
      x={x}
      y={y - 18 / scale - 8}
      width={width}
      height={18 / scale}
      value={title}
      placeholder={"Frame title"}
      isFixedHeight={true}
      fontSize={12 / scale}
      font={consts.DEFAULTS.FONT}
      fill={"#797E93"}
      onChange={(value: string) => {
        const newTitle = value.trim() || "Frame";
        onChangeElement({ title: newTitle }, { shouldAdd: true, previousProps: { title } });
      }}
      onStopEditing={() => {
        setEditingElementId(null);
      }}
      align={"left"}
      verticalAlign={"top"}
      fontProps={FontProperties.Light}
    />
  );
}
