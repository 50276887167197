import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const Bold = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M6.37113 4.6437V6.38267V8.12164L10.9235 8.12163L10.9318 8.12164H10.9776C11.4268 8.12164 11.8575 7.93843 12.1751 7.61231C12.4927 7.28619 12.6711 6.84387 12.6711 6.38267C12.6711 5.92147 12.4927 5.47915 12.1751 5.15303C11.8575 4.82691 11.4268 4.6437 10.9776 4.6437H6.37113ZM14.1092 9.36036C14.8384 8.5518 15.2456 7.48879 15.2456 6.38267C15.2456 5.22031 14.7959 4.10557 13.9955 3.28365C13.1951 2.46174 12.1096 2 10.9776 2H6.37113C6.29136 2 6.212 2.00413 6.13333 2.01227V2H4V4.43488V6.26667V9.44349C4 9.48028 4.00146 9.51673 4.00434 9.55276V13.7333H4V18H6.13333V17.9873C6.21342 17.9957 6.29423 18 6.37547 18H10.9294C12.204 17.9984 13.4259 17.4773 14.3266 16.5512C15.2274 15.6251 15.7333 14.3697 15.7333 13.0608C15.7333 11.7509 15.2266 10.4946 14.3246 9.56828C14.2547 9.49652 14.1829 9.4272 14.1092 9.36036ZM6.37547 10.7653L10.9292 10.7653C11.52 10.7669 12.0862 11.0086 12.5041 11.4377C12.9233 11.8682 13.1588 12.452 13.1588 13.0608C13.1588 13.6691 12.9237 14.2526 12.5051 14.683C12.0866 15.1132 11.519 15.3554 10.9269 15.3563H6.37547V10.7653Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Bold.displayName = "Bold";

export { Bold };
