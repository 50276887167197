import { z } from "zod";

export type BoardMondayInfoDBSchema = {
  id: number;
  type: string;
  instance_id: number;
  board_id: number;
  board_view_id: number;
  workspace_id: number;
  monday_url: string;
};

export enum BoardMondayInfoType {
  object_view = "object_view",
  board_view = "board_view",
  board_column = "board_column",
  dashboard_widget = "dashboard_widget",
}

export const boardMondayInfoSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(BoardMondayInfoType),
  instanceId: z.number(),
  boardId: z.number().nullable(),
  boardViewId: z.number().nullable(),
  workspaceId: z.number(),
  mondayURL: z.string().nullable(),
});

export type BoardMondayInfo = z.infer<typeof boardMondayInfoSchema>;

export function boardMondayInfoFromDBModel(obj: any): BoardMondayInfo {
  return boardMondayInfoSchema.parse({
    id: obj.id,
    type: obj.type,
    instanceId: Number.parseInt(obj.instance_id, 10),
    boardId: obj.board_id ? Number.parseInt(obj.board_id, 10) : null,
    boardViewId: obj.board_view_id ? Number.parseInt(obj.board_view_id, 10) : null,
    workspaceId: Number.parseInt(obj.workspace_id, 10),
    mondayURL: obj.monday_url,
  });
}
