import { getUsersByIds, getUsersByName, getUsersByPage } from "frontend/api";
import { UserCache, User } from "shared/datamodel/schemas";
import { hasMorePagesAtom, pageAtom, singleUserCacheAtom } from "state-atoms";
import { useGenericCache } from "./use-general-cache";
import { useState, useCallback } from "react";
import useSWR from "swr";

export function useUsersCache() {
  const [currentName, setCurrentName] = useState("");

  const { getItems, isLoading, hasMore, updateCache } = useGenericCache<User, UserCache, "id">({
    cacheAtom: singleUserCacheAtom,
    fetchByIds: getUsersByIds,
    entityName: "users",
    entityKey: "user",
    keyField: "id",
    paginationConfig: {
      hasPagination: true,
      pageAtom,
      hasMorePagesAtom,
      fetchByPage: getUsersByPage,
    },
  });

  const { data: users } = useSWR(
    currentName ? ["search-users", currentName] : null,
    async ([, name]) => {
      const users = await getUsersByName(name);
      updateCache(users);
      return users;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 5000,
    }
  );

  const searchUsersByName = useCallback(
    async (name: string): Promise<User[]> => {
      if (!name) return [];
      setCurrentName(name);
      return users || [];
    },
    [users]
  );

  return { getUsers: getItems, searchUsersByName, isLoading, hasMore };
}
