import React from "react";
import classNames from "classnames";
import StyledButton from "frontend/ui-components/styled-button";
import { getPathPrefix } from "../utils/getPathPrefix";
import style from "./templates-modal-covers.module.css";
import { CoverType } from "./templates-modal";

type TemplatesModalCoversProps = {
  coverType: CoverType;
  emptyTemplatesType: "personal" | "account";
  searchTerm?: string;
  onCreateTemplate?: () => void;
  onUpgradeCTAClick: (source: string) => void;
};

function NoSearchReultsCover({ searchTerm }: { searchTerm?: string }) {
  return (
    <div className={style.noSearchResults}>
      <img src={getPathPrefix("/images/no-search-results-glass.png")} alt="no-search-results" />
      <span>
        Couldn&apos;t find templates for <b>{searchTerm}</b>
      </span>
    </div>
  );
}

function UpgradeScreenCover({ onUpgradeCTAClick }: { onUpgradeCTAClick: (source: string) => void }) {
  return (
    <div className={style.coverContainer}>
      <div className={classNames(style.cover, style.upgradeScreen, style.upgradeStateImageCover)}>
        <div>
          <p className={style.title}>Let&apos;s create your first template</p>
          <p className={style.subtitle}>Build a custom template.</p>
          <p className={style.subtitle}>Eliminate repetitive tasks and ensure consistency across your team.</p>
        </div>
        <div data-testid="create-template-upgrade-cta">
          <StyledButton
            title="Upgrade"
            onClick={() => onUpgradeCTAClick("create-template")}
            customStyle={{ height: 40, width: 202, marginTop: 40, fontSize: 16, fontWeight: 500 }}
          />
        </div>
      </div>
    </div>
  );
}

function EmptyStateCover({
  emptyTemplatesType,
  onCreateTemplate,
}: {
  emptyTemplatesType: "personal" | "account";
  onCreateTemplate?: () => void;
}) {
  return (
    <div className={style.coverContainer}>
      <div className={classNames(style.cover, style.emptyState, style.emptyStateNoTemplatesImageCover)}>
        <div>
          <p className={style.title}>{`No ${emptyTemplatesType} templates yet`}</p>
          <p className={style.subtitle}>Looking to boost your productivity with custom templates? </p>
          <p className={style.subtitle}>Create your own canvas, save it, and HOP — you have your perfect template!</p>
        </div>
        {onCreateTemplate && (
          <StyledButton
            title="Create a template"
            onClick={onCreateTemplate}
            customStyle={{ margin: 16, height: 40, width: 202 }}
          />
        )}
      </div>
    </div>
  );
}

export default function TemplatesModalCovers({
  onUpgradeCTAClick,
  searchTerm,
  onCreateTemplate,
  coverType,
  emptyTemplatesType,
}: TemplatesModalCoversProps) {
  if (coverType === "emptyState") {
    return <EmptyStateCover emptyTemplatesType={emptyTemplatesType} onCreateTemplate={onCreateTemplate} />;
  }
  if (coverType === "upgradeScreen") {
    return <UpgradeScreenCover onUpgradeCTAClick={onUpgradeCTAClick} />;
  }
  if (coverType === "noSearchResults") {
    return <NoSearchReultsCover searchTerm={searchTerm} />;
  }

  return null;
}
