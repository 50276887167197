import { BillingPeriod } from "frontend/billing/billing-period-picker";
import { stringToPlan } from "frontend/billingUtils";
import { Plan } from "shared/consts";
import { PlanData } from "shared/datamodel/schemas/billing";

export function getPriceByPlanMapping(plansData: PlanData[]): Record<Plan, Record<BillingPeriod, number>> {
  const priceByPlan = plansData?.reduce((p: any, c) => {
    if (c.interval) {
      const intervalToPriceMap: any = {};
      const plan = stringToPlan(c.name.toLowerCase())!;
      intervalToPriceMap[c.interval] = c.price;
      if (p[plan]) {
        p[plan] = {
          ...p[plan],
          ...intervalToPriceMap,
        };
      } else {
        p[plan] = intervalToPriceMap;
      }
    }
    return p;
  }, {});
  return priceByPlan;
}
