import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";

export type TextAlignment = "left" | "center" | "right";
export enum FontProperties {
  None = 0,
  Bold = 1 << 0, // 1
  Italic = 1 << 1, // 2
  Light = 1 << 2, // 4 (Unsure if this exists, probably legacy)
  BoldAndItalic = Bold | Italic, // 3

  Underline = 1 << 3, // 8
  BoldAndUnderline = Bold | Underline, // 9
  ItalicAndUnderline = Italic | Underline, // 10
  BoldItalicAndUnderline = Bold | Italic | Underline, // 11

  LineThrough = 1 << 4, // 16
  BoldAndLineThrough = Bold | LineThrough, // 17
  ItalicAndLineThrough = Italic | LineThrough, // 18
  BoldItalicAndLineThrough = Bold | Italic | LineThrough, // 19

  DecorationBits = Underline | LineThrough, // 24
  BoldAndDecorationBits = Bold | DecorationBits, // 25
  ItalicAndDecorationBits = Italic | DecorationBits, // 26
  BoldItalicAndDecorationBits = Bold | Italic | DecorationBits, // 27

  StyleBits = Bold | Italic,
}

export const textEnabledSchema = z.object({
  text: z.string().default(""),
  textColor: z.string().optional(),
  fontSize: z.string().or(z.number()).default(16),
  cursorPosition: z.number().optional(),
  align: z.enum(["left", "center", "right"]),
  fontProps: z.nativeEnum(FontProperties).default(FontProperties.None),
  font: z.string().default("Poppins"),
});

const textEnabledSchemaInternal = textEnabledSchema.merge(canvasElementBaseSchema);

export type TextEnabledElement = z.infer<typeof textEnabledSchemaInternal>;
