import { KonvaEventObject } from "konva/types/Node";
import { Group, Shape } from "react-konva";
import { Point } from "shared/datamodel/schemas";
import { PointInCanvas } from "frontend/utils/math-utils";
import { useKeyPress } from "react-use";
import * as connectorConsts from "./connector-constants";
import { drawScaledCircle } from "./connector-drawing-utils";
import { HANDLE_COLOR } from "./connector-constants";
import { AnchorIndex } from "./connector-utils";

export function AnchorPoints({
  start,
  end,
  onDragStart,
  onDragMove,
  onDragEnd,
}: {
  start: Point;
  end: Point;
  onDragStart: (index: number, evt: KonvaEventObject<MouseEvent>) => void;
  onDragMove: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
}) {
  const isSpacePressed = useKeyPress("space")[0];

  const renderAnchor = (anchor: Point, index: AnchorIndex) => {
    return (
      <Group
        x={anchor.x}
        y={anchor.y}
        name="connector-anchor"
        key={index}
        index={index}
        draggable
        onDragStart={(e) => {
          onDragStart(e.target.attrs.index, e);
        }}
        onDragEnd={(e) => {
          if (!e.evt) {
            // dragging was cancelled because space was pressed
            return;
          }
          const point = e.target.getPosition() as PointInCanvas;
          onDragEnd(e.target.attrs.index, point, e);
        }}
        onDragMove={(e) => {
          if (isSpacePressed) {
            e.target.stopDrag();
            return;
          }
          const index = e.target.attrs.index;
          const point = e.target.getPosition() as PointInCanvas;
          onDragMove(index, point, e);
        }}
      >
        <Shape
          fill="white"
          name="connector-anchor-inner"
          stroke={HANDLE_COLOR}
          strokeWidth={1.5}
          hitStrokeWidth={1.5}
          strokeScaleEnabled={false}
          radius={connectorConsts.ConnectorTransformPointRadius}
          sceneFunc={drawScaledCircle}
        />
      </Group>
    );
  };
  return (
    <>
      {renderAnchor(start, AnchorIndex.Start)}
      {renderAnchor(end, AnchorIndex.End)}
    </>
  );
}
