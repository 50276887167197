import { useAtomValue } from "jotai";
import { CSSProperties, useEffect, useLayoutEffect, useRef } from "react";
import { Html } from "react-konva-utils";
import { posScaleAtom } from "state-atoms/stage-atoms";
import { handleTabInContentEditable } from ".";
import style from "./free-size-text-editor.module.css";
import consts from "shared/consts";

export function FreeSizeTextEditor({
  absX,
  absY,
  scale = 1,
  rotation = 0,
  width = "fit-content",
  minWidth,
  fontSize,
  initialValue,
  updateText,
  updateTextSize,
  onEnter,
  onEscape,
  textProps,
  placeholder = consts.DEFAULTS.TEXT,
  positionType = "center",
}: {
  absX: number;
  absY: number;
  scale?: number;
  rotation?: number;
  width?: string | number;
  minWidth?: string | number;
  fontSize: number;
  initialValue: string;
  updateText: (initial: string, final: string) => void;
  updateTextSize: (width: number, height: number) => void;
  onEnter?: () => boolean; // return true to prevent default
  onEscape?: () => boolean; // return true to prevent default
  textProps: CSSProperties;
  placeholder?: string;
  positionType?: "top-left" | "center";
}) {
  const posScale = useAtomValue(posScaleAtom);
  const stageScale = posScale.scale;
  const curValue = useRef<string | null>(null);
  const ref = useRef<any>(null);

  // Updates text for caller only when this element is unmounted and editing is over
  useEffect(() => {
    const initial = initialValue;
    return () => {
      const current = curValue.current;
      if (current != null && initial != current) {
        updateText(initial, current);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      checkSize(ref.current);
    }
  });

  // Initialize: set text state, focus div and setting cursor at end
  // for some reason this effect is called twice, so I use a state
  // variable to avoid second update.
  function changeRef(el: HTMLSpanElement | null) {
    if (el) {
      if (curValue.current == null) {
        curValue.current = initialValue;
        el.textContent = initialValue;
        el.focus();
        const sel = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(el);
        // to place cursor at end of text instead of selecting, uncomment the next line
        // range.collapse(false);
        if (sel) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
      ref.current = el;
    }
  }

  // Update the size of the textarea after every render (if needed)
  // I found this is the only reliable way that works for changes
  // in text, font-size, font-weight and style.
  const prevSize = useRef({ width: 0, height: 0 });
  function checkSize(e: HTMLElement) {
    const width = e.scrollWidth;
    const height = e.scrollHeight;
    if (width != prevSize.current.width || height != prevSize.current.height) {
      prevSize.current = { width, height };
      updateTextSize(width, height);
    }
  }

  const fontSizeProp = `${fontSize * scale}px`;
  const transformProp = positionType == "center" ? "translate(-50%,-50%)" : undefined;
  const widthProp = +width ? +width * scale + "px" : width;
  const finalStyle = { ...textProps, fontSize: fontSizeProp, transform: transformProp, width: widthProp, minWidth };

  function inputHandler(node: HTMLElement) {
    const text = node.innerText?.trimEnd() ?? null;
    // fix for Safari which won't leave empty contenteditable alone
    if (node.textContent?.length == 0) {
      node.innerHTML = "";
    }
    curValue.current = text;
    checkSize(node);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key == "Enter") {
      if (onEnter) {
        e.preventDefault();
      }
    }
    if (e.key == "Escape") {
      if (onEscape && onEscape()) {
        e.preventDefault();
      }
    } else {
      handleTabInContentEditable(e);
    }
  }

  function onKeyUp(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key == "Enter") {
      if (onEnter && onEnter()) {
        e.preventDefault();
      }
    }
  }

  // Note for future maintainers:
  // Don't try to position the <Html> with divProps and transform={false},
  // it won't work when moving and zooming, the stage while the text is displayed
  return (
    <Html transform={false}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          transform: `translate(${absX * stageScale + posScale.x}px,
            ${absY * stageScale + posScale.y}px) rotate(${rotation}deg) scale(${stageScale},${stageScale})`,
          transformOrigin: "top left",
        }}
      >
        <span
          ref={changeRef}
          aria-label="textbox"
          data-testid="textbox"
          role="textbox"
          className={style.textarea}
          placeholder={placeholder}
          contentEditable={true}
          style={finalStyle}
          onFocus={(e) => {
            checkSize(e.currentTarget);
          }}
          onPaste={(e) => {
            const plainText = e.clipboardData.getData("text/plain");
            let sel, range;
            if (window.getSelection) {
              sel = window.getSelection();
              if (sel && sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();
                range.insertNode(document.createTextNode(plainText));
                //cursor at the last with this
                range.collapse(false);
                sel.removeAllRanges();
                sel.addRange(range);
              }
            }
            e.preventDefault();
            inputHandler(e.currentTarget);
          }}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onInput={(e) => {
            inputHandler(e.currentTarget);
          }}
        />
      </div>
    </Html>
  );
}
