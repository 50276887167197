import Constants from "elements/gantt/constants";
import { Group, Rect, Text } from "react-konva";
import { GanttDateCell } from "elements/gantt/layout-builder";
import { getCornerRadius } from "elements/gantt/utils";

export default function GanttHeaderDateCell({
  x,
  y,
  width,
  height,
  title,
  fill,
  isHeader,
  isFirstSubHeader,
  isLastSubHeader,
  cornerRadius,
}: GanttDateCell) {
  return (
    <Group x={x} y={y} listening={false}>
      <Rect
        width={width}
        height={height}
        fill={fill}
        stroke={isHeader ? "#DADCE0" : fill}
        strokeWidth={1}
        cornerRadius={getCornerRadius(cornerRadius)}
      />
      <Text
        width={width}
        height={height}
        text={title}
        align={"center"}
        verticalAlign={"middle"}
        fontFamily={"Poppins"}
        fontSize={isHeader ? Constants.HeaderFontSize : Constants.HeaderSubFontSize}
        fontVariant={isHeader ? "400" : "300"}
      />

      {/* Left border */}
      {isFirstSubHeader && <Rect x={-0.5} width={1} height={height} fill={"#DADCE0"} />}
      {/* Right border */}
      {isLastSubHeader && <Rect x={width - 0.5} width={1} height={height} fill={"#DADCE0"} />}
    </Group>
  );
}
