import { EditableText } from "frontend/editableText";
import useCurrentAndPrev from "frontend/hooks/use-cur-and-prev";
import { useEffect } from "react";
import consts from "shared/consts";
import { TextAlignment, TextEnabledElement } from "shared/datamodel/schemas/textEnabled";
import { toNumber } from "shared/util/utils";

export default function ElementTextComponent({
  id,
  element,
  onChangeElement,
  contentArea,
  scaleX = 1,
  scaleY = 1,
  isWide = false,
  onTextHeightChange,
  isEditing,
  onStopEditing,
  align = "center",
  verticalAlign = "top",
  placeholder = "",
  layerRef,
}: {
  id: string;
  element: TextEnabledElement;
  onChangeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    submenuData?: any,
    elementId?: string
  ) => void;
  contentArea: { x: number; y: number; width: number; height: number; paddingX?: number; paddingY?: number };
  scaleX?: number;
  scaleY?: number;
  isWide?: boolean;
  onTextHeightChange?: (height: number) => void;
  isEditing: boolean;
  onStopEditing: () => void;
  align?: TextAlignment;
  verticalAlign?: "top" | "bottom" | "middle";
  placeholder: string;
  layerRef?: any;
}) {
  const { text, fontSize, font = consts.DEFAULTS.FONT } = element;
  const fontSizeInPixels = toNumber(fontSize, consts.DEFAULTS.FONTSIZE);

  const textState = useCurrentAndPrev<string>();
  useEffect(() => {
    if (isEditing) {
      // when going into edit mode, remember current text
      textState(element.text ?? "");
    } else {
      // when going out of edit mode, reset it and mark an undo point
      const [prev, cur] = textState();
      if (prev != cur && cur != null) {
        onChangeElement({ text: cur }, { shouldAdd: true, previousProps: { text: prev } }, undefined, id);
      }
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && layerRef && layerRef.current) {
      layerRef.current.batchDraw(); //force re-rendering as a workaround to fix resizableText not disappearing bug
    }
  }, [isEditing]);

  function onChangeText(text: string) {
    textState(text);
    onChangeElement({ text }, { shouldAdd: false }, undefined, id);
  }

  return (
    <EditableText
      x={contentArea.x}
      y={contentArea.y}
      width={contentArea.width}
      height={contentArea.height}
      paddingX={contentArea.paddingX}
      paddingY={contentArea.paddingY}
      scaleX={scaleX}
      scaleY={scaleY}
      isWide={isWide}
      isFixedHeight={onTextHeightChange == undefined}
      onChange={onChangeText}
      onTextHeightChange={onTextHeightChange}
      text={text}
      placeholder={placeholder}
      fill={element.textColor ?? consts.DEFAULTS.TEXT_COLOR}
      fontSize={fontSizeInPixels}
      isEditing={isEditing}
      onStopEditing={onStopEditing}
      align={align}
      verticalAlign={verticalAlign}
      fontProps={element.fontProps}
      font={font}
    />
  );
}
