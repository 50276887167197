import { KonvaEventObject } from "konva/types/Node";
import { useState } from "react";
import { Group, Shape, Rect, Text } from "react-konva";

const multipleHeadsWidth = 14;
const multipleHeadsHeight = 9;
const multipleHeads =
  "M 0 -0.1 A 2.185 2.185 0 1 0 0 -4.47 A 2.185 2.185 0 0 0 0 -0.1 Z M -3.934 -0.362 A 1.311 1.311 0 1 0 -3.934 -2.984 A 1.311 1.311 0 0 0 -3.934 -0.362 Z M 3.932 -0.362 A 1.311 1.311 0 1 0 3.932 -2.984 A 1.311 1.311 0 0 0 3.932 -0.362 Z M -3.768 2.533 A 3.627 3.627 0 0 0 -4.079 3.997 H 4.078 A 3.603 3.603 0 0 0 3.768 2.533 L 3.763 2.523 A 3.82 3.82 0 0 0 2.885 1.293 A 4.168 4.168 0 0 0 1.561 0.463 A 4.316 4.316 0 0 0 -1.561 0.463 A 4.107 4.107 0 0 0 -2.885 1.293 C -3.263 1.648 -3.564 2.069 -3.769 2.533 Z M -3.091 1.101 C -2.917 0.938 -2.73 0.789 -2.53 0.657 A 2.622 2.622 0 0 0 -6.993 2.521 H -4.077 A 4.09 4.09 0 0 1 -3.09 1.101 Z M 4.077 2.523 H 6.992 A 2.622 2.622 0 0 0 2.528 0.656 C 2.728 0.789 2.916 0.938 3.09 1.101 A 4.09 4.09 0 0 1 4.077 2.523 Z";
const singleHeadWidth = 9;
const singleHeadHeight = 9;
const singleHead =
  "M 0 -0.15 A 2.185 2.185 0 1 0 0 -4.52 A 2.185 2.185 0 0 0 0 -0.15 Z M -4.1 3.97 A 4.1 3.8 0 0 1 4.1 3.97 H -4 Z";
const chevronWidth = 8;
const chevronHeight = 3;
const chevronDown = "M 3 -1.5 L 0 1.5 L -3 -1.5";
const chevronUp = "M -3 1.5 L 0 -1.5 L 3 1.5";
const chevronRight = "M -1.5 -3 L 1.5 0 L -1.5 3";
const chevronLeft = "M 1.5 3 L -1.5 0 L 1.5 -3";
const gap = 3;
const height = 15;
const hh = height / 2;

interface OrgchartCollapseButtonProps {
  x: number;
  y: number;
  belongsTo: string;
  color: string;
  collapsed: boolean;
  direct: number;
  indirect: number;
  onClick?: () => void;
}
export function OrgchartCollapseButtonHorizontal({
  x,
  y,
  belongsTo,
  color,
  collapsed,
  direct,
  indirect,
  onClick,
}: OrgchartCollapseButtonProps) {
  const [hover, setHover] = useState(false);
  const mouseHandlingProps = {
    name: "orgchart-collapse anchor",
    onClick,
    onMouseEnter: (e: KonvaEventObject<MouseEvent>) => {
      setHover(true);
      e.target.to({
        scaleX: 1.2,
        scaleY: 1.2,
        duration: 0.1,
      });
      e.target.getStage()!.container().style.cursor = "pointer";
    },
    onMouseLeave: (e: KonvaEventObject<MouseEvent>) => {
      setHover(false);
      e.target.to({
        scaleX: 1,
        scaleY: 1,
        duration: 0.1,
      });
      e.target.getStage()!.container().style.cursor = "default";
    },
  };
  const indirectReports = indirect.toString();
  const directReports = direct.toString();

  return (
    <Group x={x} y={y} type="collapse-button" {...mouseHandlingProps} belongsTo={belongsTo}>
      <Shape
        fill={color}
        sceneFunc={(context, shape) => {
          context.save();
          context._context.font = "9px Poppins";
          const indirectSize = context._context.measureText(indirectReports);
          const directSize = context._context.measureText(directReports);
          const totalWidth =
            multipleHeadsWidth +
            gap +
            indirectSize.width +
            gap +
            singleHeadWidth +
            gap +
            directSize.width +
            gap +
            chevronWidth;
          // total-width doesn't include the rounded caps of the rectangle
          context.beginPath();
          // draw the rounded-rect background
          context.arc(-totalWidth / 2, 0, hh, 0, 2 * Math.PI, false);
          context.arc(totalWidth / 2, 0, hh, 0, 2 * Math.PI, false);
          context.rect(-totalWidth / 2, -hh, totalWidth, height);
          context.fillStrokeShape(shape);

          // draw the icons and text
          // to draw the icons in correct place I must translate the context, so I just
          // use translate commands all along.
          context._context.fillStyle = "white";
          context._context.strokeStyle = "white";
          context.translate((-totalWidth + multipleHeadsWidth) / 2, 0);

          context.beginPath();
          if ((context as any).isPdfContext) {
            context._context.strokeStyle = "transparent";
            (context as any).drawSvgPath(multipleHeads);
            context.fill();
          } else {
            context._context.fill(new Path2D(multipleHeads));
          }
          context.translate(multipleHeadsWidth / 2 + gap, 0);
          context._context.fillText(indirectReports, 0, hh - 4.5);
          context.translate(indirectSize.width + gap + singleHeadWidth / 2, 0);

          context.beginPath();
          if ((context as any).isPdfContext) {
            (context as any).drawSvgPath(singleHead);
            context.fill();
          } else {
            context._context.fill(new Path2D(singleHead));
          }
          context.translate(singleHeadWidth / 2 + gap, 0);
          context._context.fillText(directReports, 0, hh - 4.5);
          context.translate(directSize.width + gap * 3, 0);

          context.beginPath();
          if ((context as any).isPdfContext) {
            (context as any).drawSvgPath(collapsed ? chevronDown : chevronUp);
            context.stroke();
          } else {
            context._context.stroke(new Path2D(collapsed ? chevronDown : chevronUp));
          }

          context.restore();
        }}
      />
      {hover && (
        <CollapseButtonTooltip x={0} y={0} tooltip={`${indirectReports} Total & ${directReports} direct reports`} />
      )}
    </Group>
  );
}

export function OrgchartCollapseButtonVertical({
  x,
  y,
  belongsTo,
  color,
  collapsed,
  direct,
  indirect,
  onClick,
}: OrgchartCollapseButtonProps) {
  const [hover, setHover] = useState(false);
  const mouseHandlingProps = {
    name: "orgchart-collapse anchor",
    onClick,
    onMouseEnter: (e: KonvaEventObject<MouseEvent>) => {
      setHover(true);
      e.target.to({
        scaleX: 1.2,
        scaleY: 1.2,
        duration: 0.1,
      });
      e.target.getStage()!.container().style.cursor = "pointer";
    },
    onMouseLeave: (e: KonvaEventObject<MouseEvent>) => {
      setHover(false);
      e.target.to({
        scaleX: 1,
        scaleY: 1,
        duration: 0.1,
      });
      e.target.getStage()!.container().style.cursor = "default";
    },
  };
  const indirectReports = indirect.toString();
  const directReports = direct.toString();

  return (
    <Group x={x} y={y} type="collapse-button" {...mouseHandlingProps} belongsTo={belongsTo}>
      <Shape
        fill={color}
        sceneFunc={(context, shape) => {
          context.save();
          context._context.font = "9px Poppins";
          const indirectSize = context._context.measureText(indirectReports);
          const directSize = context._context.measureText(directReports);
          const width = Math.max(indirectSize.width, directSize.width, chevronWidth, 18);
          const ww = width / 2;
          const totalHeight =
            multipleHeadsHeight + gap + 9 + gap + singleHeadHeight + gap + 9 + gap * 2 + chevronHeight;
          // total-height doesn't include the rounded caps of the rectangle
          context.beginPath();
          // draw the rounded-rect background
          context.arc(0, -totalHeight / 2, ww, 0, 2 * Math.PI, false);
          context.arc(0, totalHeight / 2, ww, 0, 2 * Math.PI, false);
          context.rect(-ww, -totalHeight / 2, width, totalHeight);
          context.fillStrokeShape(shape);
          // draw the icons and text
          // to draw the icons in correct place I must translate the context, so I just
          // use translate commands all along.
          context._context.fillStyle = "white";
          context._context.strokeStyle = "white";
          context.translate(0, (-totalHeight + multipleHeadsHeight) / 2);
          context._context.fill(new Path2D(multipleHeads));
          context.translate(0, multipleHeadsHeight / 2 + gap);
          context._context.fillText(indirectReports, -indirectSize.width / 2, 9);
          context.translate(0, 9 + gap + singleHeadHeight / 2);
          context._context.fill(new Path2D(singleHead));
          context.translate(0, singleHeadHeight / 2 + gap);
          context._context.fillText(directReports, -directSize.width / 2, 9);
          context.translate(0, 9 + gap * 2 + chevronHeight / 2);
          context._context.stroke(new Path2D(collapsed ? chevronRight : chevronLeft));

          context.restore();
        }}
      />
      {hover && (
        <CollapseButtonTooltip x={0} y={0} tooltip={`${indirectReports} Total & ${directReports} direct reports`} />
      )}
    </Group>
  );
}

function CollapseButtonTooltip({ x, y, tooltip }: { x: number; y: number; tooltip: string }) {
  return (
    <Group x={x - 80} y={y + 10} listening={false}>
      <Rect width={160} height={22} fill="#113255" />
      <Text
        width={160}
        height={22}
        text={tooltip}
        fill="white"
        fontFamily="Poppins"
        fontSize={10}
        fontStyle="normal"
        align="center"
        verticalAlign="middle"
      />
    </Group>
  );
}
