import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const ShapeMulti = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M12.25 1.70001C15.7018 1.70001 18.5 4.49823 18.5 7.95001C18.5 11.1467 16.1003 13.7826 13.0038 14.155V18.2H1.99987V7.19605H6.04504C6.41745 4.09968 9.05337 1.70001 12.25 1.70001ZM16.8604 7.95001C16.8604 5.40376 14.7962 3.33962 12.25 3.33962C9.96057 3.33962 8.06065 5.00857 7.70092 7.19605H13.0038V12.4991C15.1914 12.1394 16.8604 10.2395 16.8604 7.95001ZM11.4172 12.4854V8.78267H7.71457C8.05714 10.6612 9.53871 12.1428 11.4172 12.4854ZM11.4172 14.145C8.63139 13.774 6.42598 11.5685 6.05501 8.78267H3.58649V16.6134H11.4172V14.145Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeMulti.displayName = "ShapeMulti";

export { ShapeMulti };
