import consts from "shared/consts";
import { defaultShapeDimensions } from "shared/datamodel/schemas";
import ShapesData from "frontend/data/shapes/shapes-visuals";

export function getDefaultSizeForShape(type: string, subtype?: string) {
  if (type != consts.CANVAS_ELEMENTS.SHAPE) {
    return defaultShapeDimensions;
  }
  const viewbox = (ShapesData as any)[subtype!]!.viewbox;
  const [, , width, height] = viewbox;
  return { width, height };
}

export const enum CornersBitmask {
  None = 0,
  TopLeft = 1, // 2^0
  TopRight = 2, // 2^1
  BottomRight = 4, // 2^2
  BottomLeft = 8, // 2^3
}
/**
 * A utility class to compute the corner radius of a cell in a table.
 */
export class Corners {
  private corners: ([number, number, number, number] | undefined)[];

  constructor(private readonly radius: number) {
    if (Number.isFinite(this.radius)) {
      this.radius = Math.max(this.radius, 0);
    } else {
      this.radius = 0;
    }
    this.corners = Array(16);
  }

  /**
   * Get the corner radius for cell. Accepts flags saying is the cell on the top, left, right, bottom of the table.
   * @param top
   * @param left
   * @param right
   * @param bottom
   * @returns
   */
  get(top: boolean, left: boolean, right: boolean, bottom: boolean): [number, number, number, number] | undefined {
    return this.byBitmask(this.toBitmask(top, left, right, bottom));
  }

  /**
   * Get the corner radius for a cell given a bitmask of the corners.
   * @param cornersBitmask
   * @returns
   */
  byBitmask(cornersBitmask: number) {
    return (this.corners[cornersBitmask] ??= this.makeCorners(cornersBitmask));
  }

  private makeCorners(bitmask: number): [number, number, number, number] {
    return [
      bitmask & CornersBitmask.TopLeft ? this.radius : 0,
      bitmask & CornersBitmask.TopRight ? this.radius : 0,
      bitmask & CornersBitmask.BottomRight ? this.radius : 0,
      bitmask & CornersBitmask.BottomLeft ? this.radius : 0,
    ];
  }

  private toBitmask(top: boolean, left: boolean, right: boolean, bottom: boolean): number {
    return (
      (top && left ? CornersBitmask.TopLeft : 0) |
      (top && right ? CornersBitmask.TopRight : 0) |
      (bottom && left ? CornersBitmask.BottomLeft : 0) |
      (bottom && right ? CornersBitmask.BottomRight : 0)
    );
  }
}
