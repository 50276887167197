import React, { forwardRef, Ref } from "react";
import { useSearchContext } from "frontend/boards-grid/search-section/context/search-context";
import { getFilterValue } from "frontend/boards-grid/search-section/utils";
import { CloseIcon } from "frontend/icons/close";
import { SearchIcon } from "frontend/icons/search";
import styles from "./search-bar.module.css";

export const SearchBar = forwardRef(({ isOpen }: { isOpen: boolean }, ref: Ref<HTMLDivElement>) => {
  const { onFilterChange, filters } = useSearchContext();
  const textValue = filters.find((f) => f.type === "text")?.value as string;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      const lastFilter = filters.filter((f) => !!f.value).at(-1);
      if (lastFilter) {
        if (lastFilter.type === "text" && textValue.length > 0) {
          return;
        }
        onFilterChange(lastFilter?.type, null);
      }
    }
  };

  return (
    <div className={styles.searchbar} ref={ref} data-isopen={isOpen}>
      <SearchIcon />
      {filters
        .filter((f) => !!f.value && f.type !== "text")
        .map((filter, i) => {
          return (
            <div className={styles.filter} key={i}>
              {getFilterValue(filter)}
              <CloseIcon
                onClick={(e) => {
                  onFilterChange(filter.type, null);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          );
        })}
      <input
        type="text"
        placeholder="Search..."
        value={textValue}
        onChange={(e) => {
          onFilterChange("text", e.target.value);
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
});

SearchBar.displayName = "SearchBar";
