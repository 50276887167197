import { useEffect } from "react";

type Config = {
  disableDetection?: boolean;
  preventScroll?: boolean;
  event?: "mouseup" | "mousedown" | "click";
};

export default function useOutsideRef<T extends Node>(
  ref: React.RefObject<T>,
  onClickOutSide: (e: Event) => void,
  config: Config = {},
  deps: any = []
) {
  const { disableDetection = false, preventScroll = false, event = "mousedown" } = config;

  function handleScroll(event: Event) {
    if (!disableDetection && ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  function handleClicksOutside(event: Event) {
    if (!disableDetection && ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
      onClickOutSide(event);
    }
  }

  function handleKey(event: any) {
    if (ref.current && event.key === "Escape") {
      onClickOutSide(event);
    }
  }

  useEffect(() => {
    if (ref.current) {
      document.addEventListener("keydown", handleKey);
      document.addEventListener(event, handleClicksOutside);
      preventScroll && document.addEventListener("wheel", handleScroll, { passive: false, capture: true });
      preventScroll && document.addEventListener("scroll", handleScroll, { passive: false, capture: true });
      return () => {
        document.removeEventListener(event, handleClicksOutside);
        document.removeEventListener("keydown", handleKey);
        preventScroll && document.removeEventListener("wheel", handleScroll, { capture: true });
        preventScroll && document.removeEventListener("scroll", handleScroll, { capture: true });
      };
    }
  }, [ref.current, disableDetection, preventScroll, event, ...deps]);
}

export function useOutsideRefs(
  refs: React.RefObject<any>[],
  onClickOutSide: () => void,
  event: "mousedown" | "mouseup" = "mousedown"
) {
  function handleClicksOutside(event: Event) {
    let inside = false;
    for (const ref of refs) {
      if (ref.current) inside ||= ref.current.contains(event.target);
    }
    if (!inside) onClickOutSide();
  }

  function handleKey(event: any) {
    if (event.key === "Escape") {
      onClickOutSide();
    }
  }

  useEffect(
    () => {
      document.addEventListener("keydown", handleKey);
      document.addEventListener(event, handleClicksOutside);
      document.addEventListener("scroll", onClickOutSide);
      return () => {
        document.removeEventListener(event, handleClicksOutside);
        document.removeEventListener("keydown", handleKey);
        document.removeEventListener("scroll", onClickOutSide);
      };
    },
    refs.map((r) => r.current)
  );
}
