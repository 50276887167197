import { z } from "zod";
import { Permission } from "./user";
import { State } from "./team";

export function fallback<T>(schema: z.ZodSchema<T>, value: T) {
  return z.any().transform((val) => {
    const safe = schema.safeParse(val);
    return safe.success ? safe.data : value;
  });
}

export const userProjectPermissionSchema = z.object({
  id: z.string(),
  state: fallback(z.nativeEnum(State), State.active),
  permission: fallback(z.nativeEnum(Permission), Permission.editor),
});

export type UserProjectPermission = z.infer<typeof userProjectPermissionSchema>;

function userProjectPermissionSchemaFromDBModel(obj: any): UserProjectPermission {
  return userProjectPermissionSchema.parse({
    id: obj.id.toString(),
    state: obj.state,
    permission: obj.permission,
  });
}

export const projectSchema = z.object({
  id: z.number(),
  name: z.string(),
  documentId: z.string(),
  updatedAt: z.date(),
  accountId: z.string(),
  teamId: z.number(),
  isOwner: z.boolean().default(false),
  permission: z.nativeEnum(Permission).default(Permission.editor),
  state: fallback(z.nativeEnum(State), State.active),
  userProjectPermissions: z.array(userProjectPermissionSchema),
});

export type Project = z.infer<typeof projectSchema>;

export function projectFromDBModel(obj: any): Project {
  return projectSchema.parse({
    id: obj.id,
    name: obj.name,
    documentId: obj.document_id,
    updatedAt: new Date(obj.updated_at),
    accountId: obj.account_id.toString(),
    teamId: obj.team_id,
    isOwner: obj.is_owner,
    permission: obj.permission,
    state: obj.state,
    userProjectPermissions: obj.userProjectPermissions?.map(userProjectPermissionSchemaFromDBModel) || [],
  });
}

export const projectsResponseSchema = z.object({
  projects: z.array(projectSchema),
});

export type ProjectsResponse = z.infer<typeof projectsResponseSchema>;

const projectCacheSchema = z.record(
  z.string(), // projectId key
  z.object({
    project: projectSchema,
    lastUpdated: z.date(),
  })
);

export type ProjectCache = z.infer<typeof projectCacheSchema>;
