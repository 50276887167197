import Konva from "konva";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Line, Rect, Text } from "react-konva";

const DEFAULT_HEIGHT = 25;
const DEFAULT_WIDTH = 25;

type KonvaTooltipProps = {
  x: number;
  y: number;
  text: string;
  parentWidth: number;
};

function centerWidth(width1: number, width2: number) {
  return (width1 - width2) / 2;
}

const Triangle = ({
  width = 100,
  height = 100,
  x = 0,
  y = 0,
}: {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}) => {
  // Points for an upside-down triangle, with the tip at the bottom
  const points = [
    width / 2,
    height, // Bottom center point
    0,
    0, // Top-left corner
    width,
    0, // Top-right corner
  ];

  return <Line x={x} y={y} points={points} closed fill="#113357" />;
};

export const KonvaTooltip = ({ x, y, parentWidth, text }: KonvaTooltipProps) => {
  const [textSize, setTextSize] = useState<{ width: number; height: number } | undefined>();
  const textRef = useRef<Konva.Text>(null);

  useLayoutEffect(() => {
    setTextSize(textRef.current?.measureSize(text));
  }, [text]);

  const wrapperHeight = (textSize?.height ?? DEFAULT_HEIGHT) * 2;
  const wrapperWidth = (textSize?.width ?? DEFAULT_WIDTH) + 20;

  return (
    <>
      <Rect
        fill="#113357"
        y={y}
        x={x + centerWidth(parentWidth, wrapperWidth)}
        width={wrapperWidth}
        height={wrapperHeight}
        cornerRadius={[3, 3, 3, 3]}
      />
      <Text
        ref={textRef}
        y={y + (textSize?.height ?? DEFAULT_HEIGHT) / 2}
        x={x + centerWidth(parentWidth, textSize?.width || parentWidth)}
        text={text}
        fontFamily="Poppins"
        fill="white"
        fontSize={10}
        fontVariant="300"
      />
      <Triangle x={x + centerWidth(parentWidth, 20)} y={y + wrapperHeight - 5} width={20} height={10} />
    </>
  );
};
