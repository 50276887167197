import { parse } from "date-fns";
import consts from "shared/consts";
import { NewElementData } from "shared/datamodel/canvas-element";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas";
import { placeStickyNote } from "shared/datamodel/sticky-note";
import { placeLiveTimeline, placeStaticTimeline } from "shared/datamodel/timeline";

export function getSmartTemplateColumnsSettings(template: { alias: string | null }) {
  switch (template.alias) {
    case "timeline": {
      return [
        {
          id: "date",
          type: "date",
          title: "Date",
        },
      ];
    }
    default: {
      return [];
    }
  }
}

export function createElementsFromItems(
  template: CanvasTemplateMinimal,
  items: any[],
  selectedColumns: Record<string, string>,
  liveIntegrationId?: string
): Record<string, any> {
  switch (template.alias) {
    case "timeline": {
      const itemsWithDates = items.map((item) => {
        const dateString = item.columnValues.find((column: any) => column.id === selectedColumns.date)?.value;
        const date = parse(dateString, "yyyy-MM-dd", new Date()).getTime();
        return { title: item.name, date };
      });
      if (liveIntegrationId) {
        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const minDate = items.length > 0 ? Math.min(...itemsWithDates.map((item) => item.date)) : Date.now();
        const maxDate = items.length > 0 ? Math.max(...itemsWithDates.map((item) => item.date)) : Date.now();
        const startDate = minDate - oneWeek;
        const endDate = maxDate + oneWeek;
        const timeline = placeLiveTimeline({ x: 0, y: 0 }, startDate, endDate, liveIntegrationId, selectedColumns.date);
        return {
          [`${consts.CANVAS_ELEMENTS.TIMELINE}-${timeline.id}`]: timeline.item,
        };
      } else {
        const elementsData = createTimelineElements({ position: { x: 0, y: 0 }, items: itemsWithDates });
        return elementsData.reduce((acc, { id, element, type }) => {
          const elementId = `${type}-${id}`;
          acc[elementId] = element;
          return acc;
        }, {} as Record<string, any>);
      }
    }
    default: {
      return {};
    }
  }
}

export function createTimelineElements({
  position: { x, y },
  items,
}: {
  position: { x: number; y: number };
  items: { title: string; date: number }[];
}): NewElementData[] {
  const data = new Array<NewElementData>();
  let startDate = Math.min(...items.map((item) => item.date));
  let endDate = Math.max(...items.map((item) => item.date));

  // add a day before and after the first and last date
  const day = 24 * 60 * 60 * 1000;
  startDate -= day;
  endDate += day;

  const timeline = placeStaticTimeline({ x, y }, startDate, endDate);
  const timelineId = `${consts.CANVAS_ELEMENTS.TIMELINE}-${timeline.id}`;
  items.forEach((item) => {
    const { id, stickyNote } = placeStickyNote({ color: consts.STICKY_NOTE_COLORS[0] }, { x: 0, y: 0 });
    stickyNote.containerId = timelineId;
    stickyNote.text = item.title;
    stickyNote.zIndexLastChangeTime = timeline.item.zIndexLastChangeTime + 10; // make sure the sticky note is on top
    const elementId = `${consts.CANVAS_ELEMENTS.STICKY_NOTE}-${id}`;
    data.push({ id, element: stickyNote, type: consts.CANVAS_ELEMENTS.STICKY_NOTE });
    timeline.item.itemsDates[elementId] = item.date;
  });
  data.push({ id: timeline.id, element: timeline.item, type: consts.CANVAS_ELEMENTS.TIMELINE });
  return data;
}
