import type { GanttElement } from "./schema";
import { BaseElementProvider, ElementProps, ToolbarProps } from "elements/base/provider";
import type { ElementController } from "elements/base/controller";
import GanttController from "elements/gantt/controller";
import GanttElementComponent from "elements/gantt/gantt-component";
import React from "react";
import consts, { TypeCanvasElement } from "shared/consts";
import type { BoardContext } from "elements/index";
import GanttToolbar from "elements/gantt/toolbar/gantt-toolbar";
import GanttGhostController from "elements/gantt/controllers/ghost-dummy-controller";

export default class GanttElementProvider extends BaseElementProvider<GanttElement> {
  getType(): TypeCanvasElement {
    return consts.CANVAS_ELEMENTS.GANTT;
  }

  getElementRenderer(): React.FC<ElementProps<GanttElement>> {
    return GanttElementComponent;
  }

  getToolbarRenderer(): React.FC<ToolbarProps<GanttElement>> {
    return GanttToolbar;
  }

  createController(
    id: string,
    element: GanttElement,
    context: BoardContext,
    allElementsData: [string, any][] = []
  ): ElementController<GanttElement> {
    if (context.isReadOnly && allElementsData.length > 0) {
      return new GanttGhostController(id, element, context, allElementsData);
    }

    return new GanttController(id, element, context);
  }

  isConnectorsEnabled(): boolean {
    return false;
  }

  isFFEnabled(): boolean {
    return true;
  }
}
