import Konva from "konva";
import { canvasPointToNormalizedPoint, isNormalizedPointInsideElement } from "../../../utils/node-utils";
import { parseStrokeWidth } from "shared/util/utils";
import { defaultShapeStrokeWidth } from "shared/datamodel/schemas";
import { getElementTypeForId } from "frontend/canvas-designer-new/elements/canvas-elements-utils";
import { IRect, Point } from "../../../utils/math-utils";
import consts from "shared/consts";
import { getOpacity } from "../../../utils/color-utils";

// TODO: Everything in this file can be moved to node-utils or similar, nothing is connector specific

export function lineWidth(node: any) {
  // if I move to clipped shaped, remember to change this
  return node.attrs.element.strokeWidth ? parseStrokeWidth(node.attrs.element.strokeWidth) : defaultShapeStrokeWidth;
}

export function getSize(rect: IRect) {
  return rect.width * rect.height;
}

export function isFilled(node: Konva.Node) {
  const color = node.attrs.type == consts.CANVAS_ELEMENTS.DRAWING ? node.attrs.element.stroke : node.attrs.element.fill;
  const transparency = color ? getOpacity(color) : 1;
  return transparency > 0.001; // 0.001 is arbitrary; I just need a small number
}

export function isInside(canvasPoint: Point, node: Konva.Node) {
  const type = getElementTypeForId(node.attrs.id);
  const npoint = canvasPointToNormalizedPoint(canvasPoint, type, node.attrs.element);
  return isNormalizedPointInsideElement(npoint, type, node.attrs.element);
}
