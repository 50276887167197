import { z } from "zod";
import { boardUserSchema } from "./board-user";

const videoPresenceSchema = z
  .object({
    sessionId: z.string(),
    videoTrackName: z.string(),
    audioTrackName: z.string(),
    videoMuted: z.boolean(),
    audioMuted: z.boolean(),
    joinTimestamp: z.number().positive().int(),
  })
  .nullish();

// TODO: It would be good to merge this with the first-class concept of `client`
// that Replicache itself manages if possible.
export const clientStateSchema = z.object({
  cursor: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .nullish(),
  userInfo: boardUserSchema,
  lastUpdated: z.number(), // timestamp
  present: z.boolean().default(false),
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
  scale: z.number().optional(),
  videoPresence: videoPresenceSchema,
});

export type ClientState = z.infer<typeof clientStateSchema>;
export type VideoPresence = z.infer<typeof videoPresenceSchema>;
