import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const AlignLeft = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M3.35211 18L3.35211 2.00001L2 2.00001V18H3.35211ZM5.9998 5.00002V8.00002L17.9998 8.00002V5.00002L5.9998 5.00002ZM5.9998 15L5.9998 12H13.9998V15H5.9998Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

AlignLeft.displayName = "AlignLeft";

export { AlignLeft };
