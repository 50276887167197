import Konva from "konva";
import { Point } from "frontend/utils/math-utils";
import { KonvaEventListener, KonvaEventObject } from "konva/types/Node";
import { drawPropsForNode } from "frontend/canvas-designer-new/elements/canvas-elements-utils";

export type OnResizeCallback = (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;

export interface ITransformHooks {
  get isMutating(): boolean;
  getCallbacks(): {
    onTransformStart: KonvaEventListener<Konva.Group, Event>;
    onTransform: KonvaEventListener<Konva.Group, Event>;
    onTransformEnd: KonvaEventListener<Konva.Group, Event>;
  };
}

export class TransformHooks implements ITransformHooks {
  private _isMutating = false;
  protected callbacks: any;
  constructor(protected readonly id: string, protected readonly onResize: OnResizeCallback) {
    this.callbacks = {
      onTransformStart: this.onTransformStart.bind(this),
      onTransform: this.onTransform.bind(this),
      onTransformEnd: this.onTransformEnd.bind(this),
    };
  }

  public get isMutating(): boolean {
    return this._isMutating;
  }

  public getCallbacks() {
    return this.callbacks;
  }

  onTransformStart(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget;
    node.attrs.undoProperties = drawPropsForNode(node);
    this._isMutating = true;
  }

  onTransform(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget;
    const scale = node.scale();
    scale.x = Math.max(scale.x, 0.001);
    scale.y = Math.max(scale.y, 0.001);
    const rotation = node.rotation();
    // resizing rotated shapes causes them to skew in Konva's transformer.
    // I reset it because it looks so bad.
    // Miro does something else: it applies the change in scale to the shapes before the rotation
    node.skewX(0);
    node.skewY(0);
    this.onResize(this.id, node.getPosition(), scale.x, scale.y, rotation);
  }

  onTransformEnd(_: Konva.KonvaEventObject<Event>) {
    this._isMutating = false;
  }
}

export class TransformHooksText extends TransformHooks {
  constructor(readonly id: string, readonly onResize: OnResizeCallback, readonly fontSizePixels: number) {
    super(id, onResize);
    this.callbacks.onTransform = this.onTransform.bind(this);
  }

  public onTransform(e: KonvaEventObject<Event>) {
    const node = e.currentTarget;
    const origSX = node.scaleX(),
      origSY = node.scaleY();
    let scaleX = origSX;
    let scaleY = origSY;
    if (scaleX < 0) scaleX = -scaleX;
    if (scaleY < 0) scaleY = -scaleY;
    if (this.fontSizePixels * scaleY < 1) {
      const newScaleY = 1 / this.fontSizePixels;
      scaleX *= newScaleY / scaleY;
      scaleY = newScaleY;
    }
    origSX != scaleX && node.scaleX(scaleX);
    origSY != scaleY && node.scaleY(scaleY);
    const rotation = node.rotation();
    // fix for scaling of rotated nodes (see comment in canvas-element.tsx)
    node.skew({ x: 0, y: 0 });
    this.onResize(this.id, node.getPosition(), scaleX, scaleY, rotation);
  }
}
