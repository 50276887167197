import React from "react";
import style from "./no-canvases-empty-state.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { CanvasTemplate, BlankCanvasTemplate } from "shared/datamodel/schemas";
import { getPathPrefix } from "../utils/getPathPrefix";

export default function NoCanvasesEmptystate({
  subtitle,
  createNewBoard,
  onDismiss,
}: {
  subtitle: string;
  createNewBoard?: (template: CanvasTemplate) => void;
  onDismiss?: any;
}) {
  return (
    <div className={style.noCanvases}>
      <img src={getPathPrefix("/images/add-new-canvas.png")} />
      <span>{subtitle}</span>
      <StyledButton
        style={ButtonStyle.filled}
        title={"Create a new canvas"}
        onClick={() => {
          createNewBoard && createNewBoard(BlankCanvasTemplate);
          onDismiss && onDismiss();
        }}
        customStyle={{ fontWeight: 500 }}
      />
    </div>
  );
}
