import consts from "../../consts";
import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";
import { FontProperties } from "./textEnabled";

const textStylePlain = z.object({
  textColor: z.string(),
  fontSize: z.number(),
  align: z.enum(["left", "center", "right"]),
  valign: z.enum(["top", "middle", "bottom"]),
  fontProps: z.nativeEnum(FontProperties),
  font: z.string(),
});

export const defaultTextStyleForTable = {
  textColor: "#113357",
  fontSize: 36,
  align: "left",
  valign: "top",
  fontProps: FontProperties.None,
  font: consts.DEFAULTS.FONT,
} as const;

const rowOrColumn = z.object({ id: z.string(), size: z.number() });

export const tableElementSchemaInternal = z.object({
  type: z.literal("table"),
  title: z.string(),
  rows: rowOrColumn.array(),
  cols: rowOrColumn.array(),
  stroke: z.string(),
  initialStyle: z.optional(
    z.array(z.object({ row: z.number().optional(), col: z.number().optional(), style: z.any() }))
  ),
  rotate: z.number().default(0),
});

export const tableCellSchema = z
  .object({
    text: z.string(),
    fill: z.string(),
    containedIds: z.string().array(),
    mergedTo: z.string().optional(),
    spanX: z.number().optional(),
    spanY: z.number().optional(),
  })
  .merge(textStylePlain);

export const tableElementSchema = z.object({
  ...canvasElementBaseSchema.shape,
  ...tableElementSchemaInternal.shape,
});

export type TableTextSchema = z.infer<typeof textStylePlain>;
export type TypeTableElement = z.infer<typeof tableElementSchema>;
export type TypeTableCell = z.infer<typeof tableCellSchema>;
