import { addMonths, eachDayOfInterval, endOfMonth, format, getDay, isSameDay, startOfMonth } from "date-fns";
import * as locales from "date-fns/locale";
import React, { useMemo, useState } from "react";
import styles from "./date-picker.module.css";
import cn from "classnames";
import { getPathPrefix } from "../utils/getPathPrefix";

function getLocale() {
  const locale = navigator.language || "en-US";
  const normalizedLocale = locale.split("-").join("");

  if (Object.prototype.hasOwnProperty.call(locales, normalizedLocale)) {
    return (locales as any)[normalizedLocale];
  }
  return locales.enUS;
}

export default function DatePicker({
  selected,
  fromDate = selected,
  toDate = selected,
  focusedDate = fromDate,
  onSelected,
  enableRangeSelection = false,
}: {
  selected?: Date;
  fromDate?: Date;
  toDate?: Date;
  focusedDate?: Date;
  enableRangeSelection?: boolean;
  onSelected: (fromDate: Date, toDate?: Date) => void;
}) {
  const date = focusedDate;
  const [month, setMonth] = useState(date ? startOfMonth(date) : startOfMonth(new Date()));
  const locale = getLocale();

  const start = startOfMonth(month);
  const end = endOfMonth(month);
  const firstDayOfMonth = getDay(start);

  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);

  const weekdays = useMemo(() => {
    //TODO: localize
    // TODO: week days width should be responsive, because some language have long names
    return [0, 1, 2, 3, 4, 5, 6].map((i) => locale.localize!.day(i, { width: "narrow" }));
  }, [locale]);
  const spaces = useMemo(
    () => [0, 1, 2, 3, 4, 5, 6].map((i) => (i < firstDayOfMonth ? <span key={i} /> : null)),
    [firstDayOfMonth]
  );
  const monthDays = useMemo(() => eachDayOfInterval({ start, end }), [month]);

  function prevMonth() {
    setMonth((month) => addMonths(month, -1));
  }
  function nextMonth() {
    setMonth((month) => addMonths(month, 1));
  }
  function onDateClicked(e: React.MouseEvent<HTMLSpanElement>) {
    const dateAsStr = (e.target as HTMLElement).dataset.date;
    const date = new Date(dateAsStr!);
    if (enableRangeSelection) {
      // when choosing end date
      if (!isSelectingStartDate && fromDate) {
        if (date < fromDate) {
          // if chose a date before the start date, swap them
          onSelected && onSelected(date, fromDate);
          setIsSelectingStartDate(true);
        } else {
          // if chose a date after the start date, set it as end date
          onSelected && onSelected(fromDate, date);
          setIsSelectingStartDate(true);
        }
      } else {
        onSelected && onSelected(date);
        setIsSelectingStartDate(false);
      }
    } else {
      onSelected && onSelected(date);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={prevMonth}>
          <img width={10} height={10} src={getPathPrefix("/images/chevrons/chevron-left.svg")} />
        </button>
        {format(month, "LLLL yyyy", { locale })}
        <button onClick={nextMonth}>
          <img width={10} height={10} src={getPathPrefix("/images/chevrons/chevron-right.svg")} />
        </button>
      </div>

      <div className={styles.hr} />

      <div className={styles.calendar}>
        {weekdays.map((name, index) => (
          <div key={index} className={styles.dayName}>
            {name}
          </div>
        ))}
        {spaces}
        {monthDays.map((day: Date) => (
          <button
            key={day.getTime()}
            className={cn(styles.day, {
              [styles.inRange]: fromDate && toDate && day > fromDate && day < toDate,
              [styles.rangeStart]: fromDate && isSameDay(day, fromDate),
              [styles.rangeEnd]: toDate && isSameDay(day, toDate),
              [styles.selected]: (fromDate && isSameDay(day, fromDate)) || (toDate && isSameDay(day, toDate)),
            })}
            data-date={day.toISOString()}
            onClick={onDateClicked}
          >
            {format(day, "d", { locale })}
          </button>
        ))}
      </div>
    </div>
  );
}
