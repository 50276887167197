import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { NextParsedUrlQuery } from "next/dist/server/request-meta";

type Options = {
  replace?: boolean;
};

export default function useUrlQueryParamValue(
  id: string,
  options: Options = { replace: false }
): [string | null, (value: string | null | undefined) => void] {
  const router = useRouter();
  const [searchParams, setSearchParams] = useState<NextParsedUrlQuery | null>(null);
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setSearchParams(router.query);
  }, [router.query]);

  useEffect(() => {
    if (searchParams) {
      const newValue = searchParams[id];
      if (typeof newValue === "string" && value !== newValue) {
        setValue(newValue);
      } else {
        setValue(null);
      }
    }
  }, [searchParams]);

  const setParamValue = useCallback(
    (value: string | null | undefined) => {
      const func = options.replace ? router.replace : router.push;
      const currentValue = router.query[id];
      if (value && currentValue !== value) {
        func({ query: { ...router.query, [id]: value } }, undefined, { shallow: true });
      } else if (!value && currentValue) {
        const { [id]: val, ...rest } = router.query;
        func({ query: rest }, undefined, { shallow: true });
      }
    },
    [id, router.query]
  );

  // make sure to reset the value when the using component is dismissed
  useEffect(() => {
    return () => setParamValue(null);
  }, [id]);

  return [value, setParamValue];
}
