import { Group, Rect, Text } from "react-konva";
import Constants from "elements/gantt/constants";
import type { IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import useObservableController from "elements/hooks/use-observable-controller";
import React, { CSSProperties, useRef } from "react";
import CellTextEditor from "../../../../canvas-designer-new/utility-elements/canvas-text-editor";
import { getCornerRadius } from "elements/gantt/utils";
import BoundingBox from "frontend/geometry/bounding-box";
import { measureNodesScreenSpace } from "utils/node-utils";
import { SplitCellToolbar } from "elements/gantt/components/split-cell-toolbar/split-cell-toolbar";

interface GanttSplitCellProps {
  controller: IGanttSplitCellController;
}

export default function GanttSplitCell({ controller }: GanttSplitCellProps) {
  const { x, y, width, height } = controller.getRect();
  const groupRef = useRef<any>();
  const isSelected = controller.isSelected();
  useObservableController(controller);

  function onClickRect() {
    if (isSelected) {
      controller.setEditing(true);
    } else {
      controller.selectRow();
    }
  }

  function onChangeTitle(title: string) {
    controller.changeTitle(title);
    controller.setEditing(false);
  }

  function getBackgroundColor() {
    const color = controller.getColor();
    switch (controller.splitType()) {
      case "primary": {
        return color;
      }
      case "secondary": {
        return Constants.TasksColors[color][0];
      }
      case "regular": {
        return Constants.TasksColors[color][1];
      }
    }
  }

  function getTextColor() {
    if (!controller.getColor()) {
      return "#000";
    }
    switch (controller.splitType()) {
      case "primary":
      case "secondary":
      case "regular": {
        return "#323338";
      }
      default: {
        return controller.getColor();
      }
    }
  }

  const cellBbox: BoundingBox | null =
    groupRef.current && BoundingBox.expandOnAll(measureNodesScreenSpace([groupRef.current], { skipStroke: true }));

  function renderTitle() {
    if (controller.isEditing()) {
      return (
        <CellTextEditor
          contentArea={{ x: 0, y: 0, width, height }}
          background={"transparent"}
          value={controller.getTitle()}
          placeholder={"Add text"}
          onChange={() => {}}
          onFinish={onChangeTitle}
          padding={0}
          verticalAlign={"middle"}
          finishHotKeys={["Enter", "Escape"]}
          cssTextArea={
            {
              font: `400 14px Poppins`,
              textAlign: "center",
              color: getTextColor(),
            } as CSSProperties
          }
        />
      );
    }
    if (isSelected && controller.getTitle() === "") {
      return (
        <Text
          width={width}
          height={height}
          text={"Add text"}
          align={"center"}
          verticalAlign={"middle"}
          fontFamily={"Poppins"}
          fontSize={14}
          fill={"#676879"}
          listening={false}
        />
      );
    }

    return (
      <Text
        x={Constants.taskCellPadding / 2}
        y={Constants.taskCellPadding / 2}
        width={width - Constants.taskCellPadding}
        height={height - Constants.taskCellPadding}
        text={controller.getTitle()}
        align={"center"}
        verticalAlign={"middle"}
        fontFamily={"Poppins"}
        fontSize={14}
        fill={getTextColor()}
        listening={false}
      />
    );
  }

  return (
    <Group x={x} y={y} listening={true} onClick={onClickRect} ref={groupRef}>
      <Rect
        width={width}
        height={height}
        fill={"white"}
        cornerRadius={getCornerRadius(controller.getCornerRadius())}
        listening={false}
      />
      <Rect
        id={`cell-${controller.getSplitId()}-${controller.getRowId()}`}
        width={width}
        height={height}
        fill={getBackgroundColor()}
        stroke={Constants.LayoutBorderColor}
        strokeWidth={Constants.LayoutBorderWidth}
        cornerRadius={getCornerRadius(controller.getCornerRadius())}
      />
      {renderTitle()}
      {cellBbox && isSelected && <SplitCellToolbar cellBbox={cellBbox} controller={controller} />}
    </Group>
  );
}
