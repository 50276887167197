import tracking from "frontend/tracking";
import { useOutsideRefs } from "frontend/utils/click-outside-handler";
import { useEffect, useRef, useState } from "react";
import consts, { Plan } from "shared/consts";
import { BillingInterval } from "shared/datamodel/schemas/billing";
import { PromotionType } from "shared/datamodel/schemas/promotion";
import Instructions from "./instructions";
import style from "./promotion.module.css";

export default function Promotion({
  id,
  plan,
  interval,
  discount,
  duration,
  code,
  validUntill,
  type,
  usersCount,
  onClose,
  closeOnOutsideClick,
  showInstructionsImmediately,
  hideValidUntill,
  instructionsTitle,
}: {
  id: string;
  plan: Plan;
  interval: BillingInterval;
  discount: number;
  duration: number;
  code: string;
  validUntill: Date;
  type: PromotionType;
  usersCount: number;
  onClose: () => void;
  closeOnOutsideClick?: boolean;
  showInstructionsImmediately?: boolean;
  hideValidUntill?: boolean;
  instructionsTitle?: string;
}) {
  const [showInstructions, setShowInstructions] = useState(showInstructionsImmediately);

  const ref = useRef(null);

  useOutsideRefs(closeOnOutsideClick ? [ref] : [], () => closeOnOutsideClick && onClose && onClose());

  const features = [
    "Unlimited canvases",
    "Unlimited templates",
    "export without watermark",
    "Anonymous visitors",
    "up to 30 collaborators",
  ];

  useEffect(() => {
    console.log("promo id", id);
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_seen", id, type);
  }, []);

  function renderCheckmark() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
        <path
          d="M1 4.89351L5.02759 8.80264L13.0828 0.984375"
          stroke="#7517F8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderFeature(feature: string) {
    return (
      <div className={style.featureContainer}>
        {renderCheckmark()}
        <span className={style.featureText}>{feature}</span>
      </div>
    );
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={onClose}
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
      >
        <path d="M0.59375 0.73877L7.87239 8.54665" stroke="#848199" strokeLinecap="round" />
        <path d="M7.87305 0.738281L0.594409 8.54616" stroke="#848199" strokeLinecap="round" />
      </svg>
    );
  }

  function onClick() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_cta_clicked", id, type);
    const promotionPrice = consts.PRICING.PRO.VALUE * (discount / 100);
    const promotionValue = usersCount * promotionPrice;
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.PROMOTION_CLICKED, promotionValue);
    setShowInstructions(true);
  }

  function getDescriptionByCampeginType() {
    switch (type) {
      case PromotionType.IN_APP: {
        return (
          <span>
            {`whoohoo! 🎉 Upgrade to Canvas Pro and`}
            <br />
            {`get `}
            <b>unlimited templates and canvases,</b>
            <br />
            <b>public sharing </b>
            {`and more, and enjoy our `} <br />
            {`limited ${discount}% discount for ${
              duration === 12 ? "a whole year!!" : `${duration} months on Canvas`
            } `}
          </span>
        );
      }
      case PromotionType.EMAIL: {
        return (
          <span>
            <b>Congratulations! </b>
            {`You’ve received a ${discount}%`} <br />
            {`discount for ${duration} months on Canvas!`}
          </span>
        );
      }
      default: {
        break;
      }
    }
  }

  function renderPromotion() {
    return (
      <div className={style.container}>
        {renderCloseIcon()}
        <div className={style.leftSide}>
          <div className={style.leftContent}>
            <span className={style.promoTitile}>{`${discount}% Off!`}</span>
            <span className={style.promoDescription}>{getDescriptionByCampeginType()}</span>
          </div>
          <button onClick={onClick} className={style.cta}>
            Claim your discount
          </button>
        </div>
        <div className={style.rightSide}>
          <div className={style.rightContent}>
            <span className={style.rightTitle}>Upgrade and get:</span>
            <div className={style.featuresList}>{features.map((i) => renderFeature(i))}</div>
          </div>
        </div>
      </div>
    );
  }

  function handleOnClose() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_closed", id, type);
    onClose && onClose();
  }
  return (
    <div ref={ref}>
      {!showInstructions && renderPromotion()}
      {showInstructions && (
        <Instructions
          id={id}
          plan={plan}
          interval={interval}
          type={type}
          code={code}
          title={instructionsTitle}
          validUntill={validUntill}
          hideValidUntill={hideValidUntill}
          onClose={handleOnClose}
        />
      )}
    </div>
  );
}
