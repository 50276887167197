/* This file uses snoplay and google analytics functions that are not defined or imported.
   Everything is fine since we use the snowplowLoaded function before calling snowplow, thus
   ensuring definition. Same for google analytics events. */
/* eslint-disable no-undef */

import consts from "../shared/consts";
import mixpanel from "mixpanel-browser";
import { readCookieValue } from "./utils/cookie-utils";
import posthog from "posthog-js";
import { isInIframe } from "./utils/url-utils";

function snowplowLoaded() {
  if (typeof window !== "undefined") {
    return window.snowplow;
  }
}

function trackFormStepChanged(eventData) {
  trackSelfDescribedEvent(eventData, "form_step_changed_event");
}

function trackPageView(pageTitle) {
  if (snowplowLoaded()) {
    snowplow("trackPageView", pageTitle);
  }
}

function trackSelfDescribedEvent(eventData, eventSchema) {
  if (snowplowLoaded()) {
    snowplow("trackSelfDescribingEvent", {
      schema: `iglu:com.filedriver/${eventSchema}/jsonschema/1-0-0`,
      data: eventData,
    });
  }
}

function registerProperties(properties) {
  mixpanel.register(properties);
  posthog.register(properties);
}

function initMixPanelClient() {
  const source = readCookieValue(consts.COOKIE_NAMES.SOURCE);
  mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: false });
  mixpanel.register_once({
    page_url: window.location.href,
    referrer: source,
  });
}

function initPostHogClient() {
  posthog.init("phc_7H06ROj6qJO4V14Hfs6xKkLUxofriHj8sovBV2IZ6ae", { api_host: "https://eu.posthog.com" });
}

function trackMixPanel(event, props, retriesNumber = 0) {
  console.log("trackMixPanel", { event, props });
  const MAX_RETRIES_NUMBER = 2;
  if (retriesNumber < MAX_RETRIES_NUMBER) {
    try {
      mixpanel.track(event, props);
    } catch {
      //when a user enters directly to a canvas, we need to initialized the client first since it is being initialized in boards page
      console.log("error sending mix panel event, retrying");
      initMixPanelClient();
      trackMixPanel(event, props, ++retriesNumber);
    }
  } else {
    console.log("max retry limit reached, skipping");
  }
}

function trackPostHog(event, props, retriesNumber = 0) {
  console.log("trackPostHog", { event, props });
  const MAX_RETRIES_NUMBER = 2;
  if (retriesNumber < MAX_RETRIES_NUMBER) {
    try {
      posthog.capture(event, props);
    } catch {
      //when a user enters directly to a canvas, we need to initialized the client first since it is being initialized in boards page
      console.log("error sending posthog event, retrying");
      initPostHogClient();
      trackPostHog(event, props, ++retriesNumber);
    }
  } else {
    console.log("max retry limit reached for posthog, skipping");
  }
}
/**
 * Legacy function to track events in snowplow, mixpanel and posthog with very specific parameters
 * @deprecated Use trackAnalyticsEvent instead
 * @see trackAnalyticsEvent
 *   For migrating to trackAnalyticsEvent, use the following rules:
 * - action param -> eventName param
 * - stringValue param -> params.stringValue param
 * - property param -> params.property param
 * - floatValue param -> params.floatValue param
 * - context param -> params.context param
 * - category param -> eventName param
 * - trackEvent(category, action, stringValue, property, floatValue, context) -> trackAnalyticsEvent(eventName, params)
 * @param {*} category
 * @param {*} action
 * @param {*} [stringValue]
 * @param {*} [property]
 * @param {*} [floatValue]
 * @param {*} [context]
 */
function trackEvent(category, action, stringValue, property, floatValue, context) {
  const inIframe = isInIframe();
  trackMixPanel(action, { stringValue, property, floatValue, context, isInIframe: inIframe });
  trackPostHog(action, { stringValue, property, floatValue, context, isInIframe: inIframe });

  if (snowplowLoaded()) {
    if (context) {
      snowplow("trackStructEvent", category, action, property, stringValue, floatValue, [
        {
          schema: "iglu:com.filedriver/uploaded_file_context/jsonschema/1-0-0",
          data: context,
        },
      ]);
    } else {
      snowplow("trackStructEvent", category, action, property, stringValue, floatValue);
    }
  }
}

function trackAnalyticsEvent(eventName, params) {
  params = params || {};
  params.inIframe = isInIframe();
  trackMixPanel(eventName, params);
  trackPostHog(eventName, params);

  if (snowplowLoaded()) {
    snowplow("trackStructEvent", eventName, params);
  }
}

function getSessionId() {
  //return '553e5011-9de4-49ff-b55a-835ffdc99a52'
  //return '9478d276-9a94-4267-957f-ec7be5952aed'
  //let cookieName = '_sp_id.0dc6';
  //let value = getCookieValue(cookieName);
  const value = getSnowplowCookie();
  if (value) {
    const sessionId = value.split(".")[5]; //The session Id is the 6th element in the array (0-based) splitted by '.'. Example value - "4df62d12-2f20-461f-a8c6-c9208dd2e0ae.1602752302.132.1606601666.1606595200.9478d276-9a94-4267-957f-ec7be5952aed"
    return sessionId;
  }
  return null;
}

function userId() {
  // let cookieName = '_sp_id.0dc6';
  let res = null;
  //let res = 'f15c4399-b0ef-44ff-be13-7f2b22f5f1d7';
  return function get() {
    if (res != null) {
      return res;
    }
    const value = getSnowplowCookie();
    // let value = getCookieValue(cookieName);
    if (value) {
      const idx = value.indexOf(".");
      res = value.substring(0, idx);
    }
    return res;
  };
}

function invokeTrackableEvent(category, elementToTrack, value, cb) {
  cb();
  trackEvent(category, elementToTrack, value);
}

function getCookieValue(cookieName) {
  const b = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : "";
}

function getSnowplowCookie() {
  const cookie = document.cookie;
  if (cookie) {
    return cookie
      .split(";")
      .map((i) => i.trim())
      .filter((c) => c.startsWith("_sp_id"))[0]
      .split("=")[1];
  } else {
    return null;
  }
}

function isWorkEmail(email) {
  //TODO: Add to this list as we discover more personal emails
  const blackList = ["gmail", "hotmail", "yahoo", "aol", "outlook", "Zoho", "icloud", "msn"];
  let res = true;
  blackList.forEach((domain) => {
    if (email.includes(domain)) {
      res = false;
    }
  });
  return res;
}

function isEmail(email) {
  return /[\w.-]+@[\w.-]+\.[\w.-]+/.test(email);
}

function getDomain(email) {
  return email.substring(email.indexOf("@") + 1);
}

function reportGoogleAnalyticsConversion(conversionType, conversionData) {
  switch (conversionType) {
    case consts.GA_CONVERSION_TYPES.HARD_SIGNUP: {
      gtag_report_hard_signup_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.UPGRADE_CTA: {
      gtag_report_upgrade_cta_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.NEW_ITEM_PLACED: {
      gtag_report_new_item_placed_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.INVITE_LINK_COPIED: {
      gtag_report_invite_link_copied_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.CANVAS_LIMIT_REACHED: {
      gtag_report_canvas_limit_reched_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.RETURNING_USER: {
      gtag_report_retention_conversion(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PROMOTION_CLICKED: {
      gtag_report_promo_conversion(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PAID_PLAN_SELECTED: {
      gtag_report_paid_plan_selected_conversion(conversionData);
      gtag_report_paid_plan_selected_conversion_fixed(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PAYMENT_COMPLETED: {
      gtag_report_payment_completed(conversionData);
      gtag_report_payment_completed_fixed(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.TEAM_SIGNUP: {
      gtag_report_team_signup_conversion();
      break;
    }
    default: {
      break;
    }
  }
}

export default {
  trackAnalyticsEvent,
  registerProperties,
  trackEvent,
  invokeTrackableEvent,
  userId,
  getCookieValue,
  trackSelfDescribedEvent,
  trackFormStepChanged,
  isWorkEmail,
  getSessionId,
  isEmail,
  getDomain,
  trackPageView,
  reportGoogleAnalyticsConversion,
  initMixPanelClient,
  initPostHogClient,
};
