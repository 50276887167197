import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const ShapeTriangle = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M10.2376 2L19.4752 18H1L10.2376 2ZM3.63932 16.4762H16.8359L10.2376 5.04762L3.63932 16.4762Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeTriangle.displayName = "ShapeTriangle";

export { ShapeTriangle };
