import { useEffect, useRef } from "react";
import consts from "shared/consts";
import useStateValue from "frontend/state/value";
import { Action } from "frontend/state";
import { getUser } from "frontend/api";
import useUrlQueryParamValue from "./use-url-query-param";
import { useGetFetchTemplates } from "./use-canvas-templates";

/**
 * Since now the server works really fast in production, after a successful checkout the client reloads very quickly and stripe's webhook
 * doesn't make it before the client loads, and the user doesn't see the change (only after another refresh.)
 * Here we are detecting if the url indicates on a successful transaction, and if so, we are reloading the user after a few seconds
 */
export default function useReloadUserAfterCheckout() {
  const [paymentCompletedUrlParams] = useUrlQueryParamValue(consts.URL_QUERY_PARAMS.SETUP_INTENT);
  const didReloadUser = useRef(false);
  const [, dispatch] = useStateValue();
  const fetchTemplates = useGetFetchTemplates();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    async function reloadUser() {
      //reload users data after updating
      try {
        const user = await getUser();
        dispatch({ type: Action.UpdatedUser, payload: user });
        fetchTemplates();
      } catch {
        console.error("couldn't reload user after a successful transaction");
      }
    }

    if (paymentCompletedUrlParams && !didReloadUser.current) {
      didReloadUser.current = true;
      timeout = setTimeout(() => {
        reloadUser();
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [paymentCompletedUrlParams]);
}
