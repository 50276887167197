import { hierarchy, tree } from "d3-hierarchy";
import { Degrees, PointAndDirection } from "frontend/utils/transform";
import { OrgChartOrientation } from "shared/datamodel/schemas/org-chart";
import * as treeUtils from "./tree-layout-utils";

// TODO: pass these as parameters to orgchartskeleon
const SeparationLayers = 100;
const SeparationSiblings = 1.5;
const NodeWidth = 150;

//#region types
export interface NodeData {
  id: string;
  x: number;
  y: number;
  descendants: number;
  directChildren: number;
  collapsed: boolean;
  parentId?: string;
  edgeToParent?: any;
}

export interface EdgeData {
  childId: string;
  parentId: string;
  parentAnchor: PointAndDirection;
  childAnchor: PointAndDirection;
}

export interface CollapsedButtonData {
  nodeId: string;
  collapsed: boolean;
  x: number;
  y: number;
  direct: number;
  total: number;
}

export interface TreeData {
  nodesData: NodeData[];
  rootNode: NodeData;
  collapseButtonOffset: {
    x: number;
    y: number;
    rotation: number;
  };
  descendants: DescendantsCount;
}
//#endregion

//TODO: use types
export function computeTreeData(
  layout: any,
  rootId: string,
  orientation: OrgChartOrientation,
  nodeHeight: number
): TreeData {
  let nodesData = [] as NodeData[],
    rootNode: NodeData;

  const layoutUtil = treeUtils.TreeLayoutHelper.from(orientation, NodeWidth, nodeHeight, SeparationLayers);
  const convert = treeUtils.LayoutProps[orientation].convert;

  const descendants = computeDescendants(layout, rootId);
  const the_tree = computeTreeLayout(layout, rootId, layoutUtil.getNodeSizeForD3tree());
  const collapseButtonOffset = layoutUtil.getNodeSocket("toLeaves", 12);
  const selfOfs = layoutUtil.getNodeSocket("toRoot", 1);
  const parentOfs = layoutUtil.getNodeSocket("toLeaves", 1);

  the_tree.eachAfter((node) => {
    const [x, y] = convert(node.x, node.y);
    const { total, direct } = descendants[node.data.id];

    // root node is a bit simpler than others, since it has no parent (so no edges up) and can't be dragged
    // inside the tree
    if (node.data.id == rootId) {
      rootNode = { id: node.data.id, x, y, descendants: total, directChildren: direct, collapsed: !node.children };
      return;
    }

    // without dragging, things are also simpler
    const nodedata: NodeData = {
      id: node.data.id,
      x,
      y,
      parentId: node.parent?.data.id,
      descendants: total,
      directChildren: direct,
      collapsed: !node.children,
    };

    if (node.parent) {
      const [parentX, parentY] = convert(node.parent.x, node.parent.y);
      nodedata.parentId = node.parent.data.id;
      nodedata.edgeToParent = {
        parentAnchor: {
          x: parentX + parentOfs.x,
          y: parentY + parentOfs.y,
          rotation: parentOfs.rotation as Degrees,
        },
        childAnchor: {
          x: x + selfOfs.x,
          y: y + selfOfs.y,
          rotation: selfOfs.rotation as Degrees,
        },
      };
    }
    nodesData.push(nodedata);
  });

  return { nodesData, rootNode: rootNode!, collapseButtonOffset, descendants };
}

interface DescendantsCount {
  [key: string]: { direct: number; total: number };
}

export function computeDescendants(layout: any, rootId: string): DescendantsCount {
  const descendantsCount: DescendantsCount = {};

  const root = hierarchy(layout[rootId], (d) => d?.childrenIds?.map((id: any) => layout[id]));
  root.sum(() => 1);
  root.each(
    (node) => (descendantsCount[node.data.id] = { direct: node.children?.length ?? 0, total: node.value! - 1 })
  );

  return descendantsCount;
}

export function computeTreeLayout(layout: any, rootId: string, nodeSize: [number, number]) {
  const root = hierarchy(layout[rootId], (d) =>
    !d.childrenIds || d.collapsed ? null : d.childrenIds.map((id: any) => layout[id])
  );
  const treeLayoutMaker = tree<any>()
    .nodeSize(nodeSize)
    .separation(() => SeparationSiblings);
  return treeLayoutMaker(root);
}
