import consts, { TypeCanvasElement } from "shared/consts";
import { Group } from "react-konva";
import React, { useMemo } from "react";
import { CanvasElement, Point } from "shared/datamodel/schemas";
import { ElementController } from "elements/base/controller";
import useObservableController from "elements/hooks/use-observable-controller";
import { TransformHooks } from "frontend/hooks/use-transform-hooks";

export interface ElementComponentProps<T extends CanvasElement> {
  id: string;
  type: TypeCanvasElement;
  element: T;
  controller: ElementController<T>;
  isSelectable: boolean;
  isConnectable: boolean;
  isDraggable: boolean;
  x?: number;
  y?: number;
  children: any;
  onResize?: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}

function BaseElementComponent<T extends CanvasElement>({
  id,
  type,
  element,
  controller,
  isSelectable,
  isConnectable,
  isDraggable,
  x,
  y,
  children,
  onResize,
}: ElementComponentProps<T>) {
  // force update the component when the controller changes
  useObservableController(controller);

  const mutation = useMemo(() => {
    if (!onResize) return { getCallbacks: () => {} };
    return new TransformHooks(id, onResize);
  }, [id, onResize]);

  return (
    <Group
      x={x ?? element.x}
      y={y ?? element.y}
      id={id}
      name={id}
      key={id}
      type={type}
      element={element}
      isCanvasElement={true}
      isSelectable={isSelectable}
      isConnectable={isConnectable}
      isConnector={type === consts.CANVAS_ELEMENTS.CONNECTOR}
      isDraggable={isDraggable}
      isFrame={type === consts.CANVAS_ELEMENTS.FRAME}
      onResize={onResize}
      scaleX={element.scaleX}
      scaleY={element.scaleY}
      {...mutation.getCallbacks()}
    >
      {children}
    </Group>
  );
}

export default BaseElementComponent;
