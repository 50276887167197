import Konva from "konva";
import React, { useRef } from "react";
import { Group, Path, Rect } from "react-konva";

type GanttMondayIconProps = {
  x: number;
  y: number;
};

export const GanttMondayIcon = ({ x, y }: GanttMondayIconProps) => {
  const groupRef = useRef<Konva.Group>(null);

  return (
    <Group x={x} y={y} ref={groupRef}>
      <Rect
        filters={[Konva.Filters.Blur]}
        width={28}
        height={18}
        fill="#fff"
        opacity={0.7}
        shadowBlur={3.7}
        shadowColor="#000000"
        shadowOpacity={0.1}
        cornerRadius={15}
      />
      <Group x={5} y={3}>
        <Path
          fill="#FB275D"
          data="M2.21 10.38c-.805 0-1.545-.419-1.934-1.093a2.05 2.05 0 0 1 .061-2.152l3.98-6.093A2.23 2.23 0 0 1 6.282 0a2.22 2.22 0 0 1 1.902 1.142c.37.684.324 1.507-.12 2.15L4.086 9.385a2.23 2.23 0 0 1-1.877.995"
        />
        <Path
          fill="#FC0"
          data="M8.764 10.38a2.23 2.23 0 0 1-1.93-1.09 2.04 2.04 0 0 1 .061-2.147l3.973-6.08A2.23 2.23 0 0 1 12.834.002c.81.017 1.544.459 1.914 1.149a2.04 2.04 0 0 1-.142 2.16l-3.973 6.078a2.23 2.23 0 0 1-1.869.993"
        />
        <Path
          fill="#00CA72"
          data="M15.717 10.535a2.207 2.207 0 0 0 2.214-2.201 2.207 2.207 0 0 0-2.214-2.201 2.207 2.207 0 0 0-2.213 2.2c0 1.217.99 2.202 2.213 2.202"
        />
      </Group>
    </Group>
  );
};
