import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const TextAlignCenter = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M15.2888 3H4.7126V5H15.2888V3ZM15.2888 11H4.7126V13H15.2888V11ZM2 7H17.9999V9H2V7ZM17.9999 15H2V17H17.9999V15Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

TextAlignCenter.displayName = "TextAlignCenter";

export { TextAlignCenter };
