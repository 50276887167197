import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const CardStackIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { width = 23, height = 21 } = props;
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 23 21"
      ref={ref}
      style={{ verticalAlign: "middle" }}
      renderPath={(color: string) => {
        return (
          <>
            <path d="M2 3.21212L3.81818 5.0303L6.84848 2" stroke={color} strokeWidth="1.5" strokeLinecap="square" />
            <path
              d="M2 10.4846L3.81818 12.3028L6.84848 9.27246"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              d="M2 17.7575L3.81818 19.5757L6.84848 16.5454"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path d="M11.0909 3.81836H22" stroke={color} strokeWidth="1.5" strokeLinecap="square" />
            <path d="M11.0909 11.0908H22" stroke={color} strokeWidth="1.5" strokeLinecap="square" />
            <path d="M11.0909 18.3638H22" stroke={color} strokeWidth="1.5" strokeLinecap="square" />
          </>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

CardStackIcon.displayName = "CardStackIcon";

export { CardStackIcon };
