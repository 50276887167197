import { Crown } from "frontend/icons/crown";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import React from "react";
import style from "./responsive-template-card.module.css";
import cn from "classnames";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas/canvas-template";

export enum TemplateCardStyle {
  small = "small",
  regular = "regular",
}

export function ResponsiveTemplateCard({
  template,
  previewAvailable,
  onClick,
  onPreview,
  cardStyle = TemplateCardStyle.regular,
}: {
  template: CanvasTemplateMinimal;
  previewAvailable: boolean;
  onClick: (template: CanvasTemplateMinimal) => void;
  onPreview: (template: CanvasTemplateMinimal) => void;
  cardStyle?: TemplateCardStyle;
}) {
  const isSmall = cardStyle === TemplateCardStyle.small;

  const name = template.name;

  return (
    <div
      className={cn(style.gridContainer, { [style.small]: isSmall })}
      data-testid={template.name}
      onClick={() => onClick(template)}
    >
      <div className={cn(style.templateImage, { [style.small]: isSmall })}>
        <div className={style.imageContainer}>
          {template.thumbnailImageURL && <img src={template.thumbnailImageURL} />}
        </div>
        {previewAvailable && (
          <div className={style.buttons}>
            <StyledButton
              title="Use Template"
              size={ButtonSize.small}
              style={ButtonStyle.filled}
              customStyle={{ width: 120, height: 26 }}
            />
            <StyledButton
              title="Preview"
              size={ButtonSize.small}
              style={ButtonStyle.outline}
              onClick={(e: any) => {
                e.stopPropagation();
                onPreview(template);
              }}
              customStyle={{ width: 120, height: 26 }}
            />
          </div>
        )}
      </div>
      <div className={style.titleContainer}>
        <div className={style.templateTitle}>{name}</div>
        {template.upgradeRequired && (
          <div className={style.proBadge}>
            <Crown size={14} />
          </div>
        )}
      </div>
    </div>
  );
}
