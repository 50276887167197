import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const Comment = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={() => {
        return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11107_443)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0H20V15.2146H5.61409L0 20.0002V0ZM1.39535 1.3381V17.0205L5.08358 13.8765H18.6047V1.3381H1.39535ZM4.65137 4.5846H15.349V5.9227H4.65137V4.5846ZM13.0235 8.93742H4.65137V10.2755H13.0235V8.93742Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_11107_443">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Comment.displayName = "Comment";

export { Comment };
