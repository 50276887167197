import Konva from "konva";
import { IRect, lerp } from "./math-utils";

type WatermarkImage = {
  image: Konva.Image;
  cleanup: () => void;
};

export function maxPixelRatioPossible({ width, height }: { width: number; height: number }) {
  let pixelRatio = Math.min(32767 / width, 32767 / height);
  const maxAreaAllowed = 16384 * 16384;
  pixelRatio = Math.min(pixelRatio, Math.floor(Math.sqrt(maxAreaAllowed / (width * height))));
  return pixelRatio;
}

export function getAreaToExport(layerToExport: Konva.Layer, elementId?: string | Konva.Node) {
  let node: Konva.Node = layerToExport;
  if (elementId) {
    let targetNode: Konva.Node | undefined;
    if (typeof elementId === "string") {
      targetNode = layerToExport.findOne((node: Konva.Node) => node.attrs.id === elementId && !node.attrs.isFrameTitle);
    } else {
      targetNode = elementId;
    }
    if (targetNode) {
      node = targetNode;
    }
  }
  return node.getClientRect({ relativeTo: layerToExport.getStage() as any });
}

export function calcPixelRatio(area: IRect, minFinalSize: number, maxFinalSize: number, quality: number) {
  const maxPossiblePixelRatio = maxPixelRatioPossible(area);
  const smallerSide = Math.min(area.width, area.height);
  const largerSide = Math.max(area.width, area.height);
  const lowerBound = minFinalSize / smallerSide;
  const upperBound = maxFinalSize / largerSide;
  return Math.min(lerp(lowerBound, upperBound, quality), maxPossiblePixelRatio);
}

export async function createWatermark(clientRect: IRect) {
  return new Promise<WatermarkImage>((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = () => {
      const scaleX = clientRect.width / img.width;
      const scaleY = clientRect.height / img.height;
      const scale = Math.min(scaleX, scaleY) / 2;
      const image = new Konva.Image({
        x: clientRect.x + (clientRect.width - img.width * scale) / 2,
        y: clientRect.y + (clientRect.height - img.height * scale) / 2,
        scaleX: scale,
        scaleY: scale,
        image: img,
        opacity: 0.5,
      });
      resolve({
        image,
        cleanup: () => {
          img.remove();
        },
      });
    };
    img.onerror = reject;
    img.src = "/images/watermark.svg";
  });
}

export async function getSnapshotOfFrame({
  canvasLayer,
  frameId,
  posScale,
  withWatermark,
  quality,
  onProgress,
}: {
  canvasLayer: Konva.Layer;
  frameId: string;
  posScale: { x: number; y: number; scale: number };
  withWatermark: boolean;
  quality: number;
  onProgress: (step: "start" | "end") => void;
}) {
  const area = getAreaToExport(canvasLayer, frameId);
  // 2. Konva requires us to adjust for the stage's current scale and position when exporting
  const adjustedArea = {
    x: area.x * posScale.scale + posScale.x,
    y: area.y * posScale.scale + posScale.y,
    width: area.width * posScale.scale,
    height: area.height * posScale.scale,
  };
  if (adjustedArea.width == 0 || adjustedArea.height == 0) {
    throw new Error("area is 0, not exporting");
  }

  const minFinalSize = 512;
  const maxFinalSize = 32767;
  const pixelRatio = calcPixelRatio(adjustedArea, minFinalSize, maxFinalSize, quality);

  const config = {
    ...adjustedArea,
    quality,
    pixelRatio,
  };
  return getSnapshotBasic(canvasLayer, area, withWatermark, config, false, onProgress);
}

export async function getSnapshotBasic(
  canvasLayer: Konva.Layer,
  area: IRect,
  withWatermark: boolean,
  exportConfig: any,
  whiteBackground?: boolean,
  onProgress?: (step: "start" | "end") => void
) {
  let background = null;
  let watermark = null;
  try {
    onProgress && onProgress("start");
    if (whiteBackground) {
      background = new Konva.Rect({
        ...area,
        fill: "white",
        globalCompositeOperation: "destination-over",
      });
      canvasLayer.add(background);
    }
    if (withWatermark) {
      watermark = await createWatermark(area);
      if (watermark) canvasLayer.add(watermark.image);
    }
    // this can take awhile.. should show a 'working' spinner
    return canvasLayer.toDataURL(exportConfig) as string;
  } finally {
    onProgress && onProgress("end");
    background?.remove();
    watermark?.cleanup();
    watermark?.image.remove();
  }
}
