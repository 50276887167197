import { Channel } from "pusher-js";
import { useEffect } from "react";

export const usePusher = <T>(
  channel: Channel | undefined,
  events: string[],
  callback: (data: T) => void,
  dependencies?: unknown[]
) => {
  useEffect(() => {
    if (!channel) {
      return;
    }
    events.forEach((event) => {
      channel.bind(event, (data: any) => {
        callback(data);
      });
    });
    return () => {
      events.forEach((event) => {
        channel.unbind(event);
      });
    };
  }, [callback, channel, events, dependencies]);
};
