import { useRef } from "react";

export default function useCurrentAndPrev<T>() {
  const prev = useRef<T | null>(null);
  const cur = useRef<T | null>(null);
  const update = (value?: T) => {
    const bothValues = [prev.current, cur.current] as const;
    if (value == undefined) {
      prev.current = cur.current = null;
    } else {
      cur.current = value;
      if (prev.current == null) prev.current = value;
    }
    return bothValues;
  };
  return update;
}
