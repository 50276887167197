import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const ShapeRhombus = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <>
            <g clipPath="url(#clip0_10274_209)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10L9.89949 0.100505L19.799 10L9.89949 19.8995L0 10ZM2.33064 10L9.89949 17.5689L17.4684 10L9.89949 2.43114L2.33064 10Z"
                fill={color}
              />
            </g>
            <defs>
              <clipPath id="clip0_10274_209">
                <rect width="20" height="20" fill={color} />
              </clipPath>
            </defs>
          </>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeRhombus.displayName = "ShapeRhombus";

export { ShapeRhombus };
