import { CSSProperties, useEffect } from "react";
import style from "./options-picker.module.css";
import { DropdownPickerStyle } from "./dropdown-picker";
import classNames from "classnames";

export default function OptionsPicker({
  scrollToSelected,
  containerStyle,
  pickerStyle,
  children,
}: {
  scrollToSelected?: string;
  containerStyle?: CSSProperties;
  pickerStyle?: DropdownPickerStyle;
  children: any;
}) {
  useEffect(() => {
    if (scrollToSelected) {
      const selectedOption = document.getElementById(scrollToSelected);
      selectedOption?.scrollIntoView({ block: "end" });
    }
  }, []);
  return (
    <div
      id={"dropdown-picker"}
      className={classNames(style.container, { [style.dark]: pickerStyle === DropdownPickerStyle.Dark })}
      style={containerStyle}
    >
      {children}
    </div>
  );
}
