import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { z } from "zod";
import { AppState, appStateSchema } from "shared/datamodel/schemas/app-state";

const canvasSchema = z.object({
  appState: appStateSchema,
  documentId: z.string().nullish(),
  pusherChannel: z.any().nullish(),
});

type State = z.infer<typeof canvasSchema>;
type Action = {
  type: string;
  payload?: any;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "set_state": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

type ICanvasContext = [State, React.Dispatch<Action>];

export const CanvasContext = createContext({} as ICanvasContext);

export function CanvasProvider({
  children,
  appState,
  documentId,
  pusherChannel,
}: {
  children: any;
  appState: AppState;
  documentId?: string;
  pusherChannel?: any;
}) {
  const [state, dispatch] = useReducer(reducer, {
    appState,
    documentId,
    pusherChannel,
  });

  const value = useMemo(() => [state, dispatch] as ICanvasContext, [state, dispatch]);

  useEffect(() => {
    dispatch({
      type: "set_state",
      payload: {
        appState,
        documentId,
        pusherChannel,
      },
    });
  }, [appState, documentId]);

  return <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>;
}

export function useCurrentCanvasValue() {
  return useContext(CanvasContext);
}
