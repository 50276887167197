import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const LineThrough = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M13.9424 3.01776C12.7217 2.40663 10.9409 1.91365 9.29443 2.01273C8.46436 2.06266 7.62598 2.26577 6.90967 2.72251C6.1792 3.18834 5.61572 3.89367 5.32275 4.86354L5.32227 4.86552C5.14453 5.45876 5.12402 6.08785 5.2627 6.69139C5.40088 7.29493 5.69336 7.85229 6.11133 8.30904C6.13965 8.34007 6.1709 8.36865 6.2041 8.39449C6.40625 8.55195 6.61279 8.70384 6.82275 8.85H2V10.35H18V8.85H9.88818C9.75488 8.79263 9.62061 8.73679 9.48682 8.68095C8.64795 8.33122 7.80908 7.98145 7.17871 7.25198C6.95654 6.99516 6.80078 6.6877 6.72461 6.35626C6.64453 6.00588 6.65625 5.64067 6.75879 5.29624C6.94873 4.66779 7.29053 4.2587 7.71582 3.98727C8.15625 3.70662 8.72559 3.54966 9.38428 3.51002C10.7148 3.42995 12.2339 3.83984 13.2705 4.35899C13.6411 4.54446 14.0913 4.39456 14.2769 4.02419C14.4624 3.65382 14.3125 3.20323 13.9424 3.01776ZM14.2637 11.3246C13.9941 11.0102 13.5205 10.974 13.2061 11.2437C12.8916 11.5134 12.8555 11.9869 13.125 12.3013C13.3354 12.5463 13.4932 12.8317 13.5894 13.1399C13.6851 13.4481 13.7168 13.7727 13.6831 14.0937C13.6489 14.4147 13.5498 14.7254 13.3911 15.0066C13.2358 15.2826 13.0264 15.5245 12.7759 15.7182C11.9985 16.2459 11.0894 16.5458 10.1509 16.5839C9.20605 16.6222 8.27002 16.394 7.44922 15.9253L6.43066 15.3345C6.07227 15.1266 5.61328 15.2486 5.40576 15.6069C5.19775 15.9652 5.31982 16.4241 5.67822 16.632L6.69873 17.224L6.70264 17.2264C7.76855 17.8357 8.98486 18.1324 10.2114 18.0827C11.4385 18.0329 12.626 17.6386 13.6392 16.9449L13.6694 16.923C14.0894 16.6038 14.439 16.2023 14.6978 15.7429C14.9565 15.2836 15.1191 14.7762 15.1748 14.252C15.23 13.7277 15.1782 13.1976 15.0215 12.6942C14.8647 12.1908 14.6069 11.7247 14.2637 11.3246Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

LineThrough.displayName = "LineThrough";

export { LineThrough };
