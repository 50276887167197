import useOutsideRef from "frontend/utils/click-outside-handler";
import { convertHexToHsv, convertHsvToHex } from "frontend/utils/color-utils";
import { isPointInRect } from "frontend/utils/math-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import style from "./custom-colors-picker.module.css";

export default function CustomColorsPicker({
  hex: inputHex,
  onChange,
  onDismiss,
}: {
  hex?: string;
  onChange: (hex: string) => void;
  onDismiss?: (hex: string) => void;
}) {
  inputHex = inputHex || "#FFFFFF";
  const [hsv, setHsv] = useState(convertHexToHsv(inputHex));
  const [h, s, v] = hsv;
  const hex = convertHsvToHex(h, s, v);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseInHSLPicker, setIsMouseInHSLPicker] = useState(false);
  const [isMouseInHuePicker, setIsMouseInHuePicker] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const slPickerRef = useRef<HTMLDivElement>(null);
  const huePickerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLInputElement>(null);

  const shouldSendLastPickedColor = useRef(true);
  const lastPickedColorRef = useRef<string>(hex);

  const onDismissWithColor = useCallback(() => {
    shouldSendLastPickedColor.current = false; // make sure the last value only sent once
    onDismiss && onDismiss(hex);
  }, [hex, onDismiss]);
  useOutsideRef(containerRef, onDismissWithColor, undefined, [onDismissWithColor]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousedown", handleMouseDown);
      if (shouldSendLastPickedColor.current && onDismiss) {
        onDismiss(lastPickedColorRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!textRef.current || hex === inputHex) return;
    textRef.current.value = hex;
    lastPickedColorRef.current = hex;
    onChange(hex);
  }, [hex]);

  const updateColorForMousePosition = (e: MouseEvent, isMouseInHSLPicker: boolean, isMouseInHuePicker: boolean) => {
    if (isMouseInHSLPicker && slPickerRef.current) {
      const { left, top, width, height } = slPickerRef.current.getBoundingClientRect();
      const x = Math.max(Math.min(e.clientX - left, width), 0);
      const y = Math.max(Math.min(e.clientY - top, height), 0);
      const s = x / width;
      const v = 1 - y / height;
      setHsv(([h]) => [h, s, v]);
    } else if (isMouseInHuePicker && huePickerRef.current) {
      const { left, width } = huePickerRef.current.getBoundingClientRect();
      const x = Math.max(Math.min(e.clientX - left, width), 0);
      const h = x / width;
      setHsv(([, s, v]) => [h, s, v]);
    }
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isMouseDown) return;
      updateColorForMousePosition(e, isMouseInHSLPicker, isMouseInHuePicker);
    },
    [isMouseDown, isMouseInHSLPicker, isMouseInHuePicker]
  );

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  function handleMouseDown(e: MouseEvent) {
    setIsMouseDown(true);
    const isMouseInHSLPicker = isPointInsideHSVPicker({ x: e.clientX, y: e.clientY });
    setIsMouseInHSLPicker(isMouseInHSLPicker);
    const isMouseInHuePicker = isPointInsideHuePicker({ x: e.clientX, y: e.clientY });
    setIsMouseInHuePicker(isMouseInHuePicker);
    updateColorForMousePosition(e, isMouseInHSLPicker, isMouseInHuePicker);
  }

  function handleMouseUp() {
    setIsMouseDown(false);
    setIsMouseInHSLPicker(false);
    setIsMouseInHuePicker(false);
  }

  function validateAndFixHex(value: string) {
    // if there are more than one #, remove all but the first one
    if (value.indexOf("#") !== value.lastIndexOf("#")) {
      value = `#${value.split("#")[1]}`;
    }

    if (value.indexOf("#") === -1) {
      return `#${value}`;
    }

    return value;
  }

  function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = validateAndFixHex(e.target.value);
    textRef.current!.value = value;
    if (value.length !== 7) return;
    const hsv = convertHexToHsv(value);
    setHsv(hsv);
  }

  function isPointInsideHSVPicker(point: { x: number; y: number }) {
    if (!slPickerRef.current) return false;
    const { left, top, width, height } = slPickerRef.current.getBoundingClientRect();
    return isPointInRect(point, { x: left, y: top, width, height });
  }

  function isPointInsideHuePicker(point: { x: number; y: number }) {
    if (!huePickerRef.current) return false;
    const { left, top, width, height } = huePickerRef.current.getBoundingClientRect();
    return isPointInRect(point, { x: left, y: top, width, height });
  }

  return (
    <div className={style.container} ref={containerRef}>
      <div className={style.hsvPicker} style={{ backgroundColor: convertHsvToHex(h, 1, 1) }} ref={slPickerRef}>
        <div className={style.white} />
        <div className={style.black} />
        <div
          className={style.pointer}
          style={{
            left: `${s * 100}%`,
            top: `${(1 - v) * 100}%`,
            backgroundColor: hex,
          }}
        />
      </div>
      <div className={style.huePicker} ref={huePickerRef}>
        <div
          className={style.pointer}
          style={{
            left: `${h * 100}%`,
            top: `50%`,
            backgroundColor: convertHsvToHex(h, 1, 1),
          }}
        />
      </div>
      <div className={style.hexContainer}>
        <input
          type="text"
          data-testid="customcolorinput"
          defaultValue={hex}
          onChange={handleTextChange}
          ref={textRef}
        />
      </div>
    </div>
  );
}
