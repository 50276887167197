import { CanvasUtils } from "frontend/utils/canvas-utils";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export const documentIdAtom = atom<{ documentId: string }>({ documentId: "" });

export const posScaleAtom = atom<{ x: number; y: number; scale: number }>({ x: 0, y: 0, scale: 0 });

export const editingElementIdAtom = atom<string | null>(null);

export const selectedElementIdsAtom = atom<string[]>([]);

export const internalSelectionAtom = atom<string[]>([]);

const allHighlightedElementIdsPerContainerAtom = atom<{ [containerId: string]: string[] }>({});

export const highlightedElementIdsPerContainerAtom = (containerId: string) =>
  atom(
    (get) => get(allHighlightedElementIdsPerContainerAtom)[containerId],
    (get, set, update: string[]) => {
      const allHighlightedElementIds = get(allHighlightedElementIdsPerContainerAtom);
      set(allHighlightedElementIdsPerContainerAtom, { ...allHighlightedElementIds, [containerId]: update });
    }
  );

// all element ids that are highlighted
export const highlightedElementIdsAtom = atom((get) => {
  const allHighlightedElementIds = get(allHighlightedElementIdsPerContainerAtom);
  return Object.values(allHighlightedElementIds).flat();
});

export const deleteElementIdAtom = atom<string>("");

export const isPreviewExportModeAtom = atom<boolean>(false);

export const isThumbnailExportModeAtom = atom<boolean>(false);

export const isExportingAtom = atom<boolean>(false);

export const isStageReadyAtom = atom<boolean>(false);

export const editingElementLinkAtom = atom<string>("");

export const utilsAtom = atomFamily((_documentId: string | null | undefined) => atom<CanvasUtils | null>(null));

export const isAnyKindOfExportAtom = atom<boolean>(
  (get) => get(isPreviewExportModeAtom) || get(isThumbnailExportModeAtom) || get(isExportingAtom)
);

export const canvasTooltipAtom = atom<{ label: string; relativeTo: any } | null>(null); // relativeTo should be a konva node

export const mondayIntegrationPopupAtom = atom<{
  isOpen: boolean;
  selectedResult?: "map" | "cancel" | undefined;
  integrationId?: string;
}>({
  isOpen: false,
});

export const mouseStateAtom = atom<"up" | "down" | "unknown">("unknown");
