import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { Permission, User } from "shared/datamodel/schemas";
import { Team, UserTeamPermission, State } from "shared/datamodel/schemas/team";
import { isBusyAtom, teamsAtom } from "state-atoms";
import { createTeam, deleteTeam, editTeam } from "frontend/services/teamsService";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import useStateValue from "frontend/state/value";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";

export function useTeam() {
  const setIsBusy = useSetAtom(isBusyAtom);
  const [myTeams, setMyTeams] = useAtom(teamsAtom);

  const [{ user }, dispatch] = useStateValue();
  const [{ teams }] = useStateValue();

  useEffect(() => {
    if (myTeams?.length === 0) {
      setMyTeams(teams);
    }
  }, [teams.length]);

  const convertToUserTeamPermission = (teamUsers: User[]) => {
    return teamUsers.map((u) => ({
      id: u.id,
      state: State.active,
      permission: u.permission ?? Permission.editor,
    }));
  };

  // create team
  const handleCreateTeam = useCallback(
    async (teamName: string, users: User[] = [], usersPermission?: Permission): Promise<Team | undefined> => {
      //add team to DB
      setIsBusy(true);
      try {
        const usersForDB = users.map((user) => Number(user.id));
        const teamFromDB = await createTeam(teamName, usersForDB, usersPermission ?? Permission.editor, dispatch);
        if (teamFromDB) {
          tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "new_team_created", teamName, teamFromDB.accountId);

          // set selected team and project
          await addToLocalStorageArray("selectedTeamsAndProjects", {
            selectedTeam: teamFromDB.id,
            selectedProject: null,
          });
          //add team to myTeam
          setMyTeams([...myTeams, teamFromDB]);
          return teamFromDB;
        }
      } catch (e: any) {
        console.error(`Create Team Error for user ${user?.id}: `, e);
      } finally {
        setIsBusy(false);
      }
    },
    [setIsBusy, setMyTeams, user, dispatch]
  );

  // delete team
  const handleDeleteTeam = useCallback(
    async (team: Team, isSelected?: boolean) => {
      //delete team from DB
      setIsBusy(true);
      try {
        await deleteTeam(team, dispatch);
        tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "delete_team", team.name, team.accountId);
        //delete team from myTeam
        const filteredTeams = myTeams?.filter((t) => t.id !== team.id);
        setMyTeams(filteredTeams);

        // set selected team and project
        if (isSelected) {
          const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
          const selectedProjectId = selectedTeamsAndProjects.find(
            (item: any) => item.selectedTeam === filteredTeams[0].id
          )?.selectedProject;
          setLocalStorageItem(
            "selectedTeamsAndProjects",
            selectedTeamsAndProjects.filter(
              (item: any) => item.selectedTeam !== team.id && item.selectedTeam !== filteredTeams[0].id
            )
          );
          addToLocalStorageArray("selectedTeamsAndProjects", {
            selectedTeam: filteredTeams[0].id,
            selectedProject: selectedProjectId,
          });
        }
      } catch (e: any) {
        console.error(`Delete Team Error for user ${user?.id}: `, e);
      } finally {
        setIsBusy(false);
      }
    },
    [setIsBusy, setMyTeams, user, dispatch]
  );

  // edit team name
  const handleEditTeamName = useCallback(
    async (name: string, team: Team) => {
      //change team in DB
      try {
        const editedTeam = await editTeam(team, { name }, dispatch);
        tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "edit_team_name", team.name, team.accountId);
        // change selectedTeam users list
        setMyTeams([...myTeams.filter((t) => t.id !== team.id), editedTeam as Team]);
      } catch (e: any) {
        console.error(`Edit Team Name Error for user ${user?.id}: `, e);
      }
    },
    [setMyTeams, user, dispatch]
  );

  // edit members --> add and remove
  const handleEditTeamMembers = useCallback(
    async ({
      team,
      users,
      permission,
      state,
    }: {
      team: Team;
      users: User[];
      permission?: Permission;
      state?: State | null;
    }) => {
      try {
        const usersTeamPermission: UserTeamPermission[] = users.map((user) => ({
          id: user.id,
          permission: permission ?? (user as User).permission ?? Permission.editor,
          state: state ?? State.active,
        }));
        const editedTeam = await editTeam(team, { users: usersTeamPermission }, dispatch);
        tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "edit_team_members", team.name, team.accountId);
        // change selectedTeam users list
        setMyTeams([...myTeams.filter((t) => t.id !== team.id), editedTeam] as Team[]);
      } catch (e: any) {
        console.error(`Edit Team Members Error for user ${user?.id}: `, e);
      } finally {
        setIsBusy(false);
      }
    },
    [setIsBusy, setMyTeams, user, dispatch]
  );

  // leave team
  const handleLeaveTeam = useCallback(
    async (team: Team, isSelected?: boolean) => {
      setIsBusy(true);
      try {
        const userId = user?.id;
        if (team.userTeamPermissions?.length === 1) {
          await handleDeleteTeam(team, isSelected);
        } else if (userId) {
          const userPermission =
            team.userTeamPermissions?.find((teammate) => teammate.id === userId)?.permission ?? Permission.editor;
          const deletedPermission: UserTeamPermission = {
            id: userId,
            state: State.deleted,
            permission: userPermission,
          };

          if (team.isOwner) {
            const newOwnerPermission = team.userTeamPermissions?.find((teammate) => teammate.id !== userId);
            const newOwnerId = newOwnerPermission?.id ?? null;

            if (newOwnerId) {
              await editTeam(team, { users: [deletedPermission], newOwner: Number(newOwnerId) }, dispatch);
            }
          } else {
            await editTeam(team, { users: [deletedPermission] }, dispatch);
          }

          tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "leave_team", team.name, team.accountId);

          const filteredTeams = myTeams?.filter((t) => t.id !== team.id);
          setMyTeams(filteredTeams);

          if (isSelected) {
            const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
            const selectedProjectId = selectedTeamsAndProjects.find(
              (item: any) => item.selectedTeam === filteredTeams[0].id
            )?.selectedProject;

            const updatedSelectedTeamsAndProjects = selectedTeamsAndProjects.filter(
              (item: any) => item.selectedTeam !== team.id && item.selectedTeam !== filteredTeams[0].id
            );
            updatedSelectedTeamsAndProjects.push({
              selectedTeam: filteredTeams[0].id,
              selectedProject: selectedProjectId,
            });

            setLocalStorageItem("selectedTeamsAndProjects", updatedSelectedTeamsAndProjects);
          }
        }
      } catch (error) {
        console.error(`Leave Team Error for user ${user?.id}: `, error);
      } finally {
        setIsBusy(false);
      }
    },
    [setIsBusy, setMyTeams, user, dispatch, handleDeleteTeam]
  );

  const hasTeamEditorPermission = useCallback(
    (team: Team) => {
      return (
        team.isOwner ||
        (user &&
          team.userTeamPermissions
            .map((user) => {
              return user.permission === Permission.editor ? user.id : null;
            })
            .includes(user.id))
      );
    },
    [user]
  );

  const getEditableTeamsForUser = useCallback(() => {
    return myTeams?.filter((team) => hasTeamEditorPermission(team));
  }, [myTeams, hasTeamEditorPermission]);

  const getTeamName = useCallback(
    (teamId: number | null): string => {
      const team = myTeams?.find((team) => team?.id === teamId) ?? getDefaultAccountTeam();
      return team.name;
    },
    [myTeams]
  );

  const getTeamById = useCallback(
    (teamId?: number | null): Team | null => {
      if (!teamId) return null;
      return myTeams?.find((team) => team?.id === teamId) ?? null;
    },
    [myTeams]
  );

  const getDefaultAccountTeam = useCallback((): Team => {
    return myTeams?.find((team) => team?.accountTeam) ?? myTeams[0];
  }, [myTeams]);

  const getTeam = useCallback(
    (teamId?: number | null): Team => {
      return getTeamById(teamId) ?? getDefaultAccountTeam();
    },
    [getTeamById, getDefaultAccountTeam]
  );

  return {
    convertToUserTeamPermission,
    handleCreateTeam,
    handleDeleteTeam,
    handleEditTeamName,
    handleEditTeamMembers,
    handleLeaveTeam,
    getEditableTeamsForUser,
    getTeamName,
    hasTeamEditorPermission,
    getTeamById,
    getDefaultAccountTeam,
    getTeam,
    myTeams,
  };
}
