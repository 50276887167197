import { Board } from "./board";
import { Project, UserProjectPermission } from "./project";
import { Team, UserTeamPermission } from "./team";
import { MondayUserRole, Permission, User } from "./user";

export enum UserRole {
  Admin = "admin",
  Member = "member",
  Guest = "guest",
  Restricted = "Restricted",
  Anonymous = "anonymous",
}

export enum InstanceRole {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
}

export enum InstanceType {
  Board = "board",
  Project = "project",
  Team = "team",
  Account = "account",
}

export enum InstanceAction {
  Create = "create",
  Delete = "delete",
  Edit = "edit",
  Share = "share",
  View = "view",
  Invite = "invite",
}
export const instanceActionsByRole: Record<UserRole, Record<InstanceRole, Set<InstanceAction>>> = {
  [UserRole.Admin]: {
    [InstanceRole.Owner]: new Set([
      InstanceAction.Create,
      InstanceAction.Delete,
      InstanceAction.Edit,
      InstanceAction.Share,
      InstanceAction.Invite,
    ]),
    [InstanceRole.Editor]: new Set([
      InstanceAction.Create,
      InstanceAction.Delete,
      InstanceAction.Edit,
      InstanceAction.Share,
      InstanceAction.Invite,
    ]),
    [InstanceRole.Viewer]: new Set([InstanceAction.View, InstanceAction.Invite]),
  },
  [UserRole.Member]: {
    [InstanceRole.Owner]: new Set([
      InstanceAction.Create,
      InstanceAction.Delete,
      InstanceAction.Edit,
      InstanceAction.Share,
      InstanceAction.Invite,
    ]),
    [InstanceRole.Editor]: new Set([
      InstanceAction.Create,
      InstanceAction.Delete,
      InstanceAction.Edit,
      InstanceAction.Share,
      InstanceAction.Invite,
    ]),
    [InstanceRole.Viewer]: new Set([InstanceAction.View]),
  },
  [UserRole.Guest]: {
    [InstanceRole.Owner]: new Set([
      InstanceAction.Create,
      InstanceAction.Delete,
      InstanceAction.Edit,
      InstanceAction.Share,
    ]),
    [InstanceRole.Editor]: new Set([InstanceAction.Edit, InstanceAction.Share]),
    [InstanceRole.Viewer]: new Set([InstanceAction.View]),
  },
  [UserRole.Restricted]: {
    [InstanceRole.Owner]: new Set([]),
    [InstanceRole.Editor]: new Set([]),
    [InstanceRole.Viewer]: new Set([]),
  },
  [UserRole.Anonymous]: {
    [InstanceRole.Owner]: new Set([]),
    [InstanceRole.Editor]: new Set([InstanceAction.Edit]),
    [InstanceRole.Viewer]: new Set([InstanceAction.View]),
  },
};

export function getUserRole(user: User | MondayUserRole, applyRestrictedLimitation: boolean): UserRole {
  const isRestricted = applyRestrictedLimitation && !user.isSubscribedToCanvas;
  if (!user) {
    return UserRole.Anonymous;
  } else if (isRestricted) {
    return UserRole.Restricted;
  } else if (user.isAdmin) {
    return UserRole.Admin;
  } else if (user.isGuest) {
    return UserRole.Guest;
  } else {
    return UserRole.Member;
  }
}

export function getUserPermission(
  userId: string,
  permissions: (UserProjectPermission | UserTeamPermission)[]
): InstanceRole {
  const user = permissions?.find((user) => user.id === userId);
  return user?.permission === Permission.editor ? InstanceRole.Editor : InstanceRole.Viewer;
}

export function getInstanceRole({
  instance,
  type,
  userRole,
}: {
  instance: Team | Project | Board;
  type: InstanceType;
  userRole: UserRole;
}): InstanceRole {
  if (userRole === UserRole.Restricted) {
    return InstanceRole.Viewer;
  }
  if (instance?.isOwner) {
    return InstanceRole.Owner;
  }
  switch (type) {
    case InstanceType.Board: {
      return (instance as Board).isReadOnly ? InstanceRole.Viewer : InstanceRole.Editor;
    }
    case InstanceType.Project:
    case InstanceType.Team: {
      return instance.permission === Permission.editor ? InstanceRole.Editor : InstanceRole.Viewer;
    }
    default: {
      return InstanceRole.Viewer;
    }
  }
}

export function getUserAndInstanceRole({
  user,
  instance,
  type,
  applyRestrictedLimitation,
}: {
  user: User | MondayUserRole;
  instance?: Team | Project | Board;
  type?: InstanceType;
  applyRestrictedLimitation: boolean;
}) {
  const userRole = getUserRole(user, applyRestrictedLimitation);
  if (userRole === UserRole.Restricted) {
    return { userRole, instanceRole: InstanceRole.Viewer };
  } else if (instance && type) {
    const instanceRole = getInstanceRole({ instance, type, userRole });
    return { userRole, instanceRole };
  } else {
    return { userRole, instanceRole: InstanceRole.Viewer };
  }
}
