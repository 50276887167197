import React, { forwardRef, useRef } from "react";
import IconBase, { IconProps } from "./icon-base";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

type VisibleCursorsProps = IconProps & { tooltip?: boolean };

const VisibleCursors = forwardRef<SVGSVGElement, VisibleCursorsProps>((props, ref) => {
  const hoverRef = useRef<any>(null);
  const hover = useHover(hoverRef);
  const { tooltip = false, ...iconProps } = props;

  return (
    <div data-testid="visibleCursorIcon" ref={hoverRef} style={{ display: "flex", justifyContent: "center" }}>
      <IconBase
        ref={ref}
        fill="none"
        renderPath={() => {
          return (
            <path
              d="M2 2L20.0862 8.02874L12.0479 12.0479L8.02874 20.0862L2 2Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          );
        }}
        viewBox="0 0 21 21"
        {...iconProps}
      >
        {props.children}
      </IconBase>
      {hover && tooltip && <Tooltip label={"Visible cursors"} relativeTo={hoverRef}></Tooltip>}
    </div>
  );
});

VisibleCursors.displayName = "VisibleCursors";

export { VisibleCursors };
