import tracking from "frontend/tracking";
import { CloseIcon } from "frontend/ui-components/svg-shapes";
import React, { useEffect, useState } from "react";
import consts from "shared/consts";
import style from "./payment-error-modal.module.css";

export default function PaymentErrorModal({ onClick, onDismiss }: { onClick: () => void; onDismiss: () => void }) {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-error-showed");
  }, []);

  function onClosedClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-error-showed-closed");
    onDismiss && onDismiss();
  }

  function onButtonClicked() {
    setLoading(true);
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-error-showed-continue-clicked");
    onClick && onClick();
  }

  function renderModal() {
    return (
      <React.Fragment>
        <div className={style.container}>
          <CloseIcon
            onClick={onClosedClicked}
            style={{ position: "absolute", right: "13px", top: "13px", cursor: "pointer" }}
          />
          <div className={style.contentWrapper}>
            <div className={style.content}>
              <span className={style.modalTitle}>Payment Unsuccessful</span>
              <span className={style.modalText}>
                Your payment method was declined, <br /> please try another payment method.
              </span>
            </div>
            <button onClick={onButtonClicked} className={style.button}>
              {loading ? "Loading..." : "Continue"}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return renderModal();
}
