import { IObservableController, ObservableController } from "elements/base/observable";
import { IGanttController } from "elements/gantt/controller";
import { IGanttSplitCellController } from "./split-cell-controller";

export interface IGanttSplitColumnController extends IObservableController {
  getSplitId(): string;

  isSelected(): boolean;

  selectColumn(): void;

  showSelectionBorder(): boolean;

  getColumnCells(): IGanttSplitCellController[];

  canDelete(): boolean;

  deleteColumn(): void;

  addRowAtBottom(): void;

  addSubGroup(): void;
}

export class GanttSplitColumnController extends ObservableController implements IGanttSplitColumnController {
  constructor(private id: string, private ganttController: WeakRef<IGanttController>) {
    super();
  }

  getSplitId() {
    return this.id;
  }

  isSelected() {
    return (
      this.ganttController.deref()?.getSelectedSplitId() === this.id &&
      !this.ganttController.deref()?.getSelectedRowId()
    );
  }

  showSelectionBorder() {
    const isRowSelected = this.getColumnCells().some((cell) => cell.isSelected());
    return this.isSelected() && !isRowSelected;
  }

  selectColumn() {
    this.ganttController.deref()?.setSelectedColumnRow(this.id, null);
  }

  getColumnCells() {
    return this.ganttController.deref()?.getSplitCells(this.id) ?? [];
  }

  canDelete(): boolean {
    return this.ganttController.deref()?.canDeleteColumn(this.id) ?? false;
  }

  deleteColumn(): void {
    this.ganttController.deref()?.deleteColumn(this.id);
  }

  addRowAtBottom() {
    this.ganttController.deref()?.addSplitRowBelow();
  }

  addSubGroup() {
    this.ganttController.deref()?.addSplitColumnRight(this.id);
  }
}
