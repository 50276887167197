/* eslint-disable no-await-in-loop */
import { BaseElementController, ElementController } from "elements/base/controller";
import { GanttElement, GanttSplit } from "elements/gantt/schema";
import { BoardContext } from "elements/index";
import GanttLayout, {
  GanttConnectorLayout,
  GanttDateCell,
  GanttGridCell,
  GanttSplitCell,
  GanttTaskCell,
} from "elements/gantt/layout-builder";
import { GanttSplitCellController, IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import { Patch, produceWithPatches } from "immer";
import {
  cleanDate,
  countGanttTaskNodes,
  countGanttTaskNodesInReflect,
  createCellId,
  extractStartAndEndDate,
  getRelatedRows,
  hasUserSeenMondayIntegrationPopup,
  removeDuplicateConnectors as removeDuplicateConnectorsUtil,
  trackGanttEvent,
} from "./utils";
import { TasksColors } from "elements/gantt/constants";
import { placeTaskCard, taskCardPrefix } from "shared/datamodel/task-card";
import { GanttSplitRow, IntegrationItem, integrationItemPrefix, LockType, TaskCard } from "shared/datamodel/schemas";
import { ReadTransaction } from "@workcanvas/reflect";
import GanttTaskCellController from "elements/gantt/controllers/task-cell-controller";
import consts from "shared/consts";
import addDays from "date-fns/addDays";
import {
  GanttSplitColumnController,
  IGanttSplitColumnController,
} from "elements/gantt/controllers/split-column-controller";
import BoundingBox from "frontend/geometry/bounding-box";
import { SupportedGanttElements } from "elements/gantt/types";
import GanttMondayItemCellController from "elements/gantt/controllers/monday-item-cell-controller";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { getFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { getMondayBoardItemMapping } from "elements/gantt/controllers/controller-utils";

export interface IGanttController extends ElementController<GanttElement> {
  addSplitColumnRight(splitId?: string): void;

  addSplitRowBelow(splitId?: string, rowId?: string): void;

  addSplitLastRow(): void;

  canAddSplitColumnRight(): boolean;

  canAddSplitRowBelow(splitId?: string): boolean;

  changeRowTitle(splitId: string, rowId: string, title: string): void;

  changeRowColor(splitId: string, rowId: string, color: string): void;

  canDeleteColumn(splitId: string): boolean;

  deleteColumn(splitId: string): void;

  deleteRow(splitId: string, rowId: string): void;

  getCellLayout(splitId: string, rowId: string): GanttSplitCell | undefined;

  getDateColumnsLayout(): GanttDateCell[];

  getHoveredGridCell(point: { x: number; y: number }): GanttGridCell | undefined;

  getHoveredTaskCell(point: { x: number; y: number }): GanttTaskCell | undefined;

  getLayoutCells(): GanttGridCell[];

  getLayoutRect(): { x: number; y: number; width: number; height: number };

  getSelectedSplitId(): string | null;

  getSelectedRowId(): string | null;

  getSplitCells(splitId: string): IGanttSplitCellController[];

  getSplitColumns(): IGanttSplitColumnController[];

  getTaskCellLayout(taskId: string): GanttTaskCell | undefined;

  getTaskCells(): (IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>)[];

  setSelectedColumnRow(splitId: string | null, rowId: string | null): void;

  dragTasksIn(rowId: string, date: Date, taskId: string): Promise<void>;

  /**
   * Drag a task out of the gantt element
   * returns true if the task was dragged out, false otherwise
   */
  dragTasksOut(taskId: string, newPosition: { x: number; y: number }): Promise<boolean>;

  dropElements(rowId: string, date: Date, taskId: string): Promise<void>;

  changeDateForTask(taskId: string, startDate?: number, endDate?: number): Promise<void>;

  addConnector(fromTaskId: string, toTaskId: string, type: "custom" | "monday"): Promise<void>;

  getConnectors(): GanttConnectorLayout[];

  changeTaskTitle(taskId: string, newTitle: string): void;

  createTask(rowId?: string, date?: Date): Promise<boolean>;

  isReadOnly(): boolean;

  removeConnector(id: string): Promise<void>;

  canDeleteRow(splitId: string, rowId: string): boolean;

  canAddNewTask(): Promise<boolean>;

  setMaxAllowedTasksInPlan(amount: number): void;

  isSelected(): boolean;

  deleteSelectedElement(): void;

  moveTaskOut(taskId: string): Promise<void>;

  updateLayout(): void;

  showMondayIntegrationPopup(visible: { isOpen: boolean; integrationId?: string }): void;

  getShowMondayIntegrationPopup(): {
    isOpen: boolean;
    integrationId?: string;
  };

  getIdsMappings(): { itemIdToTaskId: Map<string, string>; taskIdToItemId: Map<string, string> };

  setMondayIntegrationFetcher(func: (itemId: string, integrationId: string) => Promise<any | null>): void;

  setSelectedConnector(connectorId: string | null): void;

  getSelectedConnectorId(): string | null;
}

export default class GanttController extends BaseElementController<GanttElement> implements IGanttController {
  #layout: GanttLayout;
  #splitControllers: GanttSplitColumnController[] = [];
  #splitCellControllers: Record<string, GanttSplitCellController> = {};
  #splitRowsBySplitId: Record<string, string[]> = {};
  #tasksControllers: Record<string, IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>> =
    {};
  #isSelected: boolean = false;
  #selectedSplitId: string | null = null;
  #selectedConnectorId: string | null = null;
  #selectedRowId: string | null = null;
  #maxAllowedTasksInPlan: number = 0;

  #mondayIntegrationFetcher: ((id: string, integrationId: string) => Promise<any | null>) | null = null;

  #unsubscribe: (() => void) | undefined;

  #shouldShowMondayIntegrationPopup: {
    isOpen: boolean;
    integrationId?: string;
  } = {
    isOpen: false,
  };

  constructor(id: string, element: GanttElement, context: BoardContext) {
    super(id, element, context);
    this.#isSelected = context.selectedElementIds.includes(id);
    // Initialize the layout
    this.#layout = new GanttLayout(this.element);
    this.updateLayout();
  }

  updateElement(element: GanttElement) {
    // this check needs to happen before calling `super` because `super` will update the element
    const shouldUpdateLayout = this.#shouldUpdateLayout(element);
    super.updateElement(element);
    if (shouldUpdateLayout) {
      this.#layout.setElement(this.element);
      this.updateLayout();
    }
  }

  updateContext(context: BoardContext) {
    super.updateContext(context);
    this.#isSelected = context.selectedElementIds.includes(this.id);
    if (!this.#isSelected) {
      this.setSelectedColumnRow(null, null);
      this.setSelectedConnector(null);
    }
  }

  updateLayout(): void {
    // remove split cell controllers that are not in the layout anymore
    const allRowIds = this.#layout.getSplitCells().map((cell) => cell.rowId);
    for (const key of Object.keys(this.#splitCellControllers)) {
      if (!allRowIds.includes(key)) {
        delete this.#splitCellControllers[key];
      }
    }

    // update or create split cell controllers with layout cells
    this.#layout.getSplitCells().forEach((cell) => {
      const controller = this.#splitCellControllers[cell.rowId];
      if (controller) {
        controller.updateCellLayout(cell);
      } else {
        this.#splitCellControllers[cell.rowId] = new GanttSplitCellController(cell, new WeakRef(this));
      }
    });

    this.#splitRowsBySplitId = Object.entries(this.#splitCellControllers).reduce((acc, [id, controller]) => {
      acc[controller.getSplitId()] = acc[controller.getSplitId()] ?? [];
      acc[controller.getSplitId()].push(id);
      return acc;
    }, {} as Record<string, string[]>);

    this.#splitControllers = Object.keys(this.#splitRowsBySplitId).map(
      (id) => new GanttSplitColumnController(id, new WeakRef(this))
    );

    this.notify();
  }

  getSplitCells(splitId: string): IGanttSplitCellController[] {
    return this.#splitRowsBySplitId[splitId]?.map((rowId) => this.#splitCellControllers[rowId]) ?? [];
  }

  getSplitColumns(): IGanttSplitColumnController[] {
    return this.#splitControllers;
  }

  async dragTasksIn(rowId: string, date: Date, taskId: string) {
    await this.dropElements(rowId, date, taskId);
  }

  async dragTasksOut(taskId: string, newPosition: { x: number; y: number }): Promise<boolean> {
    if (this.element.lock) {
      return false;
    }
    const element = await this.context.reflect?.mutate.getElement(taskId);
    if (!element || element.containerId !== this.id) {
      return false;
    }
    const didPatch = await this.patchElement(taskId, (draft: TaskCard) => {
      delete draft.containerId;
      draft.x = newPosition.x;
      draft.y = newPosition.y;
    });
    return !!didPatch;
  }

  async dropElements(rowId: string, date: Date, taskId: string) {
    if (this.element.lock) {
      return;
    }

    const rowCell = this.#layout.getSplitCells().find((cell) => cell.rowId === rowId);
    const splitId = rowCell?.splitId;
    if (!splitId) {
      return;
    }
    const id = this.id;
    const canAddNewTask = await this.canAddNewTask();
    let hasMoved = false;
    const newElement = await this.patchElement(taskId, (draft: TaskCard | IntegrationItem) => {
      let dateDiff = 0;
      if (!canAddNewTask && draft.containerId !== id) {
        return;
      }
      hasMoved = draft.containerId === id;
      draft.containerId = id;

      if (draft.type === "taskCard") {
        const { dueDate, toDate = dueDate, fromDate = dueDate } = draft;
        dateDiff = toDate && fromDate ? toDate - fromDate : 0;
        draft.fromDate = date.getTime();
        draft.toDate = date.getTime() + dateDiff;

        if (rowCell?.color) {
          draft.color = rowCell?.color;
        }
      }
      draft.fieldValues ??= {};
      for (const [key, value] of Object.entries(this.createFieldValues(splitId, rowId))) {
        draft.fieldValues[key] = value;
      }

      if (draft.type === "integrationItem") {
        const integrationId = draft.integrationId;
        !hasMoved &&
          getMondayBoardItemMapping(this.context.documentId, integrationId).then((columnMappings) => {
            const startId = columnMappings?.mapping?.start_date;
            const endId = columnMappings?.mapping?.end_date;
            // i want to keep the code and show the popup every time
            const hasShowedPopup = false && hasUserSeenMondayIntegrationPopup(integrationId);
            if (!hasShowedPopup && (!startId || !endId)) {
              this.showMondayIntegrationPopup({
                isOpen: true,
                integrationId: integrationId,
              });
            }
          });

        if (rowCell?.color) {
          draft.fill = rowCell?.color;
        }

        const dates = extractStartAndEndDate(draft);
        draft.fieldValues ??= {};
        if (!dates) {
          draft.fieldValues["fromDate"] = date.getTime();
          draft.fieldValues["toDate"] = date.getTime();
        } else {
          dateDiff = dates.toDate && dates.fromDate ? dates.toDate - dates.fromDate : 0;
          draft.fieldValues["fromDate"] = date.getTime();
          draft.fieldValues["toDate"] = date.getTime() + dateDiff;
        }
      }
    });
    this.#tasksControllers[taskId]?.updateIntegration?.("reflect", undefined);
    if (newElement) {
      // only change last change time if the task was actually moved
      if (!hasMoved) {
        trackGanttEvent("gantt_task_created", { amount: this.getTaskCells().length + 1, from: "dragged" });
      }

      const dates = extractStartAndEndDate(newElement);
      if (!dates) {
        return;
      }
      await this.changeDependantTasksDates(taskId, dates.toDate);
    }
  }

  async changeDateForTask(taskId: string, startDate?: number, endDate?: number) {
    if (this.element.lock) {
      return;
    }

    const newElement = await this.patchElement(taskId, (draft: TaskCard | IntegrationItem) => {
      if (draft.type === "taskCard") {
        if (draft.lock || !draft.fromDate || !draft.toDate) {
          return;
        }
        startDate ??= draft.fromDate;
        endDate ??= draft.toDate;
        const newStartDate = Math.min(startDate, draft.toDate);
        const newEndDate = Math.max(endDate, draft.fromDate);
        if (newStartDate === draft.fromDate && newEndDate === draft.toDate) {
          return;
        }
        draft.fromDate = newStartDate;
        draft.toDate = newEndDate;
      }

      if (draft.type === "integrationItem") {
        const oldDates = extractStartAndEndDate(draft);
        if (draft.lock || !oldDates) {
          return;
        }

        startDate ??= oldDates.fromDate;
        endDate ??= oldDates.toDate;
        const defaultDate = cleanDate(new Date()).getTime();
        const newStartDate = Math.min(startDate, oldDates.toDate ?? defaultDate);
        const newEndDate = Math.max(endDate, oldDates.fromDate ?? defaultDate);
        if (newStartDate === oldDates.fromDate && newEndDate === oldDates.toDate) {
          return;
        }

        draft.fieldValues ??= {};
        draft.fieldValues["fromDate"] = newStartDate;
        draft.fieldValues["toDate"] = newEndDate;
      }
    });
    this.#tasksControllers[taskId]?.updateIntegration?.("reflect", undefined);

    if (!newElement) {
      return;
    }
    const dates = extractStartAndEndDate(newElement);
    await this.changeDependantTasksDates(taskId, dates!.toDate!);
  }

  async changeDependantTasksDates(taskId: string, newEndDate: number) {
    const dependantTaskIds = this.getConnectors()
      .filter((connector) => connector.from.id === taskId)
      .map((connector) => connector.to.id);
    for (const id of dependantTaskIds) {
      const newTask = await this.patchElement(id, (draft: TaskCard | IntegrationItem) => {
        const dates = extractStartAndEndDate(draft);

        if (!dates || !dates.fromDate || !dates.toDate) {
          return;
        }

        const { fromDate, toDate } = dates;

        if (fromDate <= newEndDate) {
          const diff = toDate - fromDate;

          if (draft.type === "taskCard") {
            draft.fromDate = addDays(newEndDate, 1).getTime();
            draft.toDate = draft.fromDate + diff;
          }

          if (draft.type === "integrationItem") {
            draft.fieldValues ??= {};

            draft.fieldValues["fromDate"] = addDays(newEndDate, 1).getTime();
            draft.fieldValues["toDate"] = draft.fieldValues["fromDate"] + diff;
          }
        }
      });
      this.#tasksControllers[id]?.updateIntegration?.("reflect", undefined);
      if (newTask) {
        const dates = extractStartAndEndDate(newTask);

        if (!dates || !dates.fromDate || !dates.toDate) {
          return;
        }
        const { toDate } = dates;

        await this.changeDependantTasksDates(id, toDate);
      }
    }
  }

  getDateColumnsLayout(): GanttDateCell[] {
    return this.#layout.getDateCells() ?? [];
  }

  getLayoutCells(): GanttGridCell[] {
    return this.#layout.getLayoutCells() ?? [];
  }

  getHoveredGridCell(point: { x: number; y: number }): GanttGridCell | undefined {
    return this.#layout.getGridCellAtPoint(point);
  }

  getTaskCells(): (IGanttBaseCellController<TaskCard> | IGanttBaseCellController<IntegrationItem>)[] {
    return Object.values(this.#tasksControllers);
  }

  getTaskCellLayout(taskId: string): GanttTaskCell | undefined {
    return this.#layout.getTaskCells().find((cell) => cell.elementId === taskId);
  }

  getHoveredTaskCell(point: { x: number; y: number }): GanttTaskCell | undefined {
    return this.#layout.getTaskCellAtPoint(point);
  }

  setSelectedConnector(connectorId: string | null): void {
    this.#selectedConnectorId = connectorId;
    this.notify();
  }

  getSelectedConnectorId(): string | null {
    return this.#selectedConnectorId;
  }

  setSelectedColumnRow(splitId: string | null, rowId: string | null): void {
    if (this.isReadOnly() || this.element.lock) {
      return;
    }
    if (this.#selectedSplitId == splitId && this.#selectedRowId == rowId) {
      return;
    }
    const oldSplitId = this.#selectedSplitId;
    const oldRowId = this.#selectedRowId;
    this.#selectedSplitId = splitId;
    this.#selectedRowId = rowId;
    if (oldRowId) {
      this.#setSplitCellSelected(oldRowId, false);
    }
    if (rowId) {
      this.#setSplitCellSelected(rowId, true);
    }
    if (oldSplitId) {
      this.#splitControllers.find((c) => c.getSplitId() === oldSplitId)?.notify();
    }
    if (splitId) {
      this.#splitControllers.find((c) => c.getSplitId() === splitId)?.notify();
    }
    this.notify();
  }

  getSelectedSplitId(): string | null {
    return this.#selectedSplitId;
  }

  getSelectedRowId(): string | null {
    return this.#selectedRowId;
  }

  changeRowTitle(splitId: string, rowId: string, title: string) {
    const [, patch, inverse] = produceWithPatches(this.element, (draft) => {
      const split = draft.splits.find((s) => s.id === splitId);
      if (split) {
        const row = split.rows.find((r) => r.id === rowId);
        if (row) {
          row.title = title;
        }
      }
    });
    this.performAction({
      do: () => this.context.reflect?.mutate.patchCanvasEl({ changes: [{ id: this.id, patch }] }),
      undo: () => this.context.reflect?.mutate.patchCanvasEl({ changes: [{ id: this.id, patch: inverse }] }),
    });
  }

  async changeRowColor(_splitId: string, rowId: string, color: string) {
    const patches: Record<string, Patch[]> = {};
    const inversePatches: Record<string, Patch[]> = {};

    const relatedRows = getRelatedRows(this.#layout.getSplitCells(), rowId);

    const [, patchSplitColors, inverseSplitColors] = produceWithPatches(this.element, (draft) => {
      draft.splits.forEach((split) => {
        split.rows.forEach((row) => {
          if (relatedRows.find((r) => r.rowId === row.id)) {
            row.color = color;
          }
        });
      });
    });

    const relatedRowsIds = relatedRows.map((r) => r.rowId);
    patches[this.id] = patchSplitColors;
    inversePatches[this.id] = inverseSplitColors;
    const rowTasks = this.#layout.getTaskCells().filter((cell) => relatedRowsIds.includes(cell.rowId));
    if (rowTasks.length) {
      for (const cell of rowTasks) {
        const task = await this.context.reflect?.mutate.getElement(cell.elementId);
        if (!task) continue;
        const [, patch, inverse] = produceWithPatches(task, (draft: any) => {
          const element = task as TaskCard | IntegrationItem;
          if (element.type === "taskCard") {
            draft.color = color;
          }
          if (element.type === "integrationItem") {
            draft.fill = color;
          }
        });
        patches[cell.elementId] = patch;
        inversePatches[cell.elementId] = inverse;
      }
    }
    this.performAction({
      do: () =>
        this.context.reflect?.mutate.patchCanvasEl({
          changes: Object.entries(patches).map(([id, patch]) => ({ id, patch })),
        }),
      undo: () =>
        this.context.reflect?.mutate.patchCanvasEl({
          changes: Object.entries(inversePatches).map(([id, patch]) => ({ id, patch })),
        }),
    });
  }

  async deleteRow(splitId: string, rowId: string): Promise<void> {
    if (this.element.lock) {
      return;
    }

    await this.patchElement(this.id, (draft: GanttElement) => {
      const split = draft.splits.find((s) => s.id === splitId);
      if (!split) {
        return;
      }
      const rowIndex = split.rows.findIndex((r) => r.id === rowId);
      if (rowIndex === -1) {
        return;
      }
      const row = split.rows[rowIndex];
      const rowsForParent = split.rows.filter((r) => r.parentRowId === row.parentRowId);
      // make sure there is at least one row for that parent in the split
      if (rowIndex !== -1 && rowsForParent.length > 1) {
        split.rows.splice(rowIndex, 1);
      }
      if (split.rows.length === 0) {
        // remove the split if there are no rows
        const splitIndex = draft.splits.findIndex((s) => s.id === splitId);
        if (splitIndex !== -1) {
          draft.splits.splice(splitIndex, 1);
        }
      }
    });
  }

  canAddSplitRowBelow(splitId?: string): boolean {
    if (this.element.lock) {
      return false;
    }
    const split = splitId ? this.element.splits.find((s) => s.id === splitId) : this.element.splits.at(0);
    return !!split;
  }

  canAddSplitColumnRight(): boolean {
    return !this.element.lock && !this.isReadOnly();
  }

  canDeleteColumn(): boolean {
    return !this.element.lock && !this.isReadOnly() && this.element.splits.length > 1;
  }

  canDeleteRow(splitId: string, rowId: string): boolean {
    if (this.element.lock) {
      return false;
    }

    // only allow deleting if the row is not the last one
    const split = this.element.splits.find((s) => s.id === splitId);
    if (!split) {
      return false;
    }

    const row = split?.rows.find((r) => r.id === rowId);
    const parentRowId = row?.parentRowId;

    return split?.rows.filter((r) => r.parentRowId === parentRowId).length > 1;
  }

  deleteColumn(splitId: string): void {
    if (!this.canDeleteColumn() || this.element.lock) {
      return;
    }
    this.patchElement(this.id, (draft: GanttElement) => {
      const splitIndex = draft.splits.findIndex((s) => s.id === splitId);
      if (splitIndex === -1) {
        return;
      }

      // move child rows to parent row
      if (splitIndex < draft.splits.length - 1) {
        const split = draft.splits[splitIndex];
        const nextSplit = draft.splits[splitIndex + 1];
        for (const row of split.rows) {
          const childRows = nextSplit.rows.filter((r) => r.parentRowId === row.id);
          for (const childRow of childRows) {
            childRow.parentRowId = row.parentRowId;
            childRow.color = row.color;
          }
        }
      }

      draft.splits.splice(splitIndex, 1);
    });
  }

  getCellLayout(splitId: string, rowId: string): GanttSplitCell | undefined {
    return this.#layout.getSplitCells().find((cell) => cell.splitId === splitId && cell.rowId === rowId);
  }

  getLayoutRect(): { x: number; y: number; width: number; height: number } {
    const size = this.#layout.getLayoutSize();
    return {
      x: this.element.x,
      y: this.element.y,
      width: size.width,
      height: size.height,
    };
  }

  // Main function to add a new row below the specified row
  async addSplitRowBelow(splitId?: string, rowId?: string): Promise<void> {
    if (this.element.lock) {
      return;
    }

    await this.patchElement(this.id, (draft: GanttElement) => {
      const splitIndex = splitId ? draft.splits.findIndex((s) => s.id === splitId) : 0;
      if (splitIndex === -1) {
        throw new Error(`Split with id ${splitId} not found`);
      }
      this.addRowBelow(draft, splitIndex, rowId, undefined);
    });
  }

  /**
   * Add a new row below the specified row
   *
   * This is a recursive function that will add a new row also
   * in the next splits
   */
  addRowBelow(draft: GanttElement, splitIndex: number, rowId?: string, parentRowId?: string) {
    const split = draft.splits[splitIndex];
    const rowIndex = rowId ? split.rows.findIndex((r) => r.id === rowId) : split.rows.length - 1;
    if (rowIndex === -1) {
      return;
    }

    const row = split.rows[rowIndex];
    const color = this.#getColorForNewRow(split.id, rowId);

    // Add the new row
    const newRow = { id: createCellId(), parentRowId: parentRowId ?? row.parentRowId, title: "", color };
    split.rows.splice(rowIndex + 1, 0, newRow);

    // create child rows in next splits
    if (splitIndex < draft.splits.length - 1) {
      const nextRowId = draft.splits[splitIndex + 1].rows.find((r) => r.parentRowId === row.id)?.id;
      if (nextRowId) {
        this.addRowBelow(draft, splitIndex + 1, nextRowId, newRow.id);
      }
    }
  }

  addSplitLastRow(): void {
    this.addSplitRowBelow();
  }

  async addSplitColumnRight(splitId?: string) {
    if (this.element.lock) {
      return;
    }
    this.startActionSequence("gantt");

    // get it before the patch because the patch will update the element
    const taskCells = this.#layout.getTaskCells();
    const newSplitId = createCellId();
    const newElement = await this.patchElement(
      this.id,
      (draft: GanttElement) => {
        const splitIndex = splitId ? draft.splits.findIndex((s) => s.id === splitId) : draft.splits.length - 1;
        if (splitIndex === draft.splits.length - 1) {
          const newRows = draft.splits[splitIndex].rows.map((row) => {
            return { title: "", id: createCellId(), color: row.color, parentRowId: row.id };
          });
          const newSplit: GanttSplit = { id: newSplitId, rows: newRows, type: "custom" };
          draft.splits.push(newSplit);
        } else {
          const newRows = draft.splits[splitIndex].rows.map((row) => {
            return { title: "", id: createCellId(), color: row.color, parentRowId: row.id };
          });
          const childRows = draft.splits[splitIndex + 1].rows;
          for (const row of childRows) {
            row.parentRowId = newRows.find((newRow) => row.parentRowId === newRow.parentRowId)?.id;
          }
          const newSplit: GanttSplit = { id: newSplitId, rows: newRows, type: "custom" };
          draft.splits.splice(splitIndex + 1, 0, newSplit);
        }
      },
      "gantt"
    );

    if (!newElement) {
      return;
    }

    const newSplit = newElement.splits.find((s) => s.id === newSplitId)!;
    for (const row of newSplit.rows) {
      // get all tasks of parentRowId and update their fieldValues
      const relatedTasks = taskCells.filter((cell) => cell.rowId === row.parentRowId);
      for (const cell of relatedTasks) {
        await this.patchElement(
          cell.elementId,
          (draft: TaskCard) => {
            draft.fieldValues ??= {};
            draft.fieldValues[newSplit.id] = row.id;
          },
          "gantt"
        );
      }
    }

    this.endActionSequence("gantt");
    this.notify();
  }

  async addConnector(fromTaskId: string, toTaskId: string, type: "custom" | "monday") {
    if (this.element.lock) {
      return;
    }
    await this.patchElement(this.id, (draft: GanttElement) => {
      draft.connectors = draft.connectors || [];
      draft.connectors.push({
        id: createCellId(),
        type,
        from: fromTaskId,
        to: toTaskId,
      });
    });
    this.notify();
  }

  getConnectors(): GanttConnectorLayout[] {
    return this.#layout.getConnectors();
  }

  subscribe(observer: () => void) {
    super.subscribe(observer);

    // observe tasks only after subscribing to avoid unnecessary updates
    this.#observeTasks();
  }

  destroy(): void {
    if (this.#unsubscribe) {
      this.#unsubscribe();
    }
    super.destroy();
  }

  #shouldUpdateLayout(newElement: GanttElement): boolean {
    return (
      newElement.startDate !== this.element.startDate ||
      newElement.endDate !== this.element.endDate ||
      newElement.granularity !== this.element.granularity ||
      newElement.connectors?.length !== this.element.connectors?.length ||
      JSON.stringify(newElement.splits) !== JSON.stringify(this.element.splits)
    );
  }

  #setSplitCellSelected(rowId: string, selected: boolean) {
    const controller = this.#splitCellControllers[rowId];
    if (controller) {
      controller.setSelected(selected);
    }
  }

  #tasksChanged(tasks: { id: string; task: SupportedGanttElements }[]) {
    const amountOfTasksOnGantt = countGanttTaskNodes(tasks.map((t) => t.task));
    sessionStorage.setItem(this.id, amountOfTasksOnGantt.toString());

    this.notify();

    const deletedTasks = Object.keys(this.#tasksControllers).filter((id) => !tasks.find((t) => t.id === id));
    for (const id of deletedTasks) {
      delete this.#tasksControllers[id];
      const attachedConnectors = this.#layout.getConnectors().filter((c) => c.from.id === id || c.to.id === id);
      Promise.allSettled(
        attachedConnectors.map((c) => {
          this.removeConnector(c.id);
        })
      );
    }
    this.removeDuplicateConnectors();

    for (const id of deletedTasks) {
      delete this.#tasksControllers[id];
    }
    for (const { id, task } of tasks) {
      let controller = this.#tasksControllers[id];
      if (controller) {
        if (controller instanceof GanttTaskCellController && task.type === "taskCard") {
          controller.updateElement(task);
        }
        if (controller instanceof GanttMondayItemCellController && task.type === "integrationItem") {
          controller.updateElement(task);
        }
      } else {
        if (task.type === "taskCard") {
          controller = new GanttTaskCellController(id, task, this.context);
          this.#tasksControllers[id] = controller;
          controller.updateController(this);
        }
        if (task.type === "integrationItem") {
          controller = new GanttMondayItemCellController(id, task, this.context);
          controller.setMondayIntegrationFetcher?.(this.#mondayIntegrationFetcher);
          this.#tasksControllers[id] = controller;
          controller.updateController(this);
          setTimeout(() => {
            controller.updateIntegration?.("monday");
          }, 0);
        }
      }
    }
    const newTasks = Object.entries(this.#tasksControllers).map(([id, controller]) => ({ id, task: controller }));
    this.#layout.setTasks(newTasks);
    this.notify();
  }

  #observeTasks() {
    if (this.#unsubscribe) {
      this.#unsubscribe();
    }
    const shortenId = (id: string) => {
      const [, type, shortId] = id.split("-");
      return `${type}-${shortId}`;
    };
    const query = async (tx: ReadTransaction) => {
      const allItems = await tx.scan().entries().toArray();
      const tasks = allItems.filter(
        ([id]) =>
          id.startsWith(taskCardPrefix) ||
          (id.startsWith(integrationItemPrefix) && getFeatureFlag("gantt-monday-integration-items"))
      );
      return tasks
        .map(([id, value]) => ({ id: shortenId(id), task: value as SupportedGanttElements }))
        .filter(({ task }) => !task.hidden && task.containerId === this.id);
    };
    this.#unsubscribe = this.context.reflect.subscribe(query, (data) => this.#tasksChanged(data));
  }

  #getColorForNewRow(splitId: string, parentRowId?: string | null) {
    // if the first split, round robin a color from consts.COLOR_PALETTE
    // if not the first split, get the color of the parent row
    const splitIndex = this.element.splits.findIndex((s) => s.id === splitId);
    if (splitIndex === -1) {
      return TasksColors[0];
    }
    if (splitIndex === 0) {
      const rowsCount = this.element.splits[splitIndex].rows.length;
      return TasksColors[rowsCount % TasksColors.length];
    }

    const parentRow = this.element.splits[splitIndex - 1].rows.find((r) => r.id === parentRowId);
    if (!parentRow?.color) {
      return TasksColors[0];
    }

    return parentRow.color;
  }

  async changeTaskTitle(taskId: string, newTitle: string) {
    const task = await this.context.reflect.mutate.getElement(taskId);
    if (!task) {
      return;
    }
    const [, patch, inverse] = produceWithPatches(task as TaskCard, (draft) => {
      draft.title = newTitle;
    });

    this.performAction({
      do: () => this.context.reflect.mutate.patchCanvasEl({ changes: [{ id: taskId, patch }] }),
      undo: () => this.context.reflect.mutate.patchCanvasEl({ changes: [{ id: taskId, patch: inverse }] }),
    });
  }

  async createTask(rowId?: string, date?: Date): Promise<boolean> {
    if (!(await this.canAddNewTask())) {
      this.context?.showUpgradeModal?.("gantt");
      return false;
    }

    const taskCardElement = placeTaskCard({ x: 0, y: 0 });
    const taskId = `${taskCardElement.card.type}-${taskCardElement.id}`;
    taskCardElement.card.title = "My New Task";
    taskCardElement.card.containerId = this.id;

    const fromDate = date?.getTime() ?? cleanDate(this.element.startDate).getTime();

    const lastSplit = this.element.splits.at(-1);
    if (!lastSplit) {
      return false;
    }
    const row = lastSplit.rows.find((row) => row.id === rowId) ?? lastSplit.rows[0];
    const color = this.#getParentRow(this.element.splits.length - 1, row.id)?.color ?? consts.COLOR_PALETTE[4];

    taskCardElement.card.fromDate = fromDate;
    taskCardElement.card.toDate = addDays(fromDate, 1).getTime();
    taskCardElement.card.fieldValues = this.createFieldValues(lastSplit.id, row.id);

    taskCardElement.card.color = color;

    this.performAction({
      do: () => this.context.reflect.mutate.putElement({ id: taskId, element: taskCardElement.card }),
      undo: () => this.context.reflect.mutate.deleteElement(taskId),
    });
    return true;
  }

  #getParentRow(splitIndex: number, rowId: string): GanttSplitRow | null {
    const split = this.element.splits[splitIndex];
    const row = split.rows.find((r) => r.id === rowId);
    if (!row) {
      return null;
    }
    if (splitIndex === 0 || !row.parentRowId) {
      return row;
    }
    return this.#getParentRow(splitIndex - 1, row.parentRowId);
  }

  createFieldValues(splitId: string, rowId: string) {
    const values: Record<string, string> = {};
    let nextRowId: string | null | undefined = rowId;
    let splitIndex = this.element.splits.findIndex((s) => s.id === splitId);
    while (nextRowId) {
      const split = this.element.splits[splitIndex];
      const row = split.rows.find((r) => r.id === nextRowId);
      if (!row) {
        break;
      }
      values[split.id] = row.id;
      nextRowId = row.parentRowId;
      splitIndex--;
    }
    return values;
  }

  isReadOnly() {
    return this.context.isReadOnly || this.element.lock === LockType.Full;
  }

  async removeConnector(id: string) {
    if (this.element.lock) {
      return;
    }

    await this.patchElement(this.id, (draft: GanttElement) => {
      const connector = draft.connectors?.findIndex((c) => c.id === id) ?? -1;
      if (connector === -1) {
        return;
      }
      draft.connectors?.splice(connector, 1);
    });
    this.notify();
  }

  async removeDuplicateConnectors() {
    if (this.element.lock) {
      return;
    }

    await this.patchElement(this.id, (draft: GanttElement) => {
      draft.connectors = removeDuplicateConnectorsUtil(draft.connectors ?? []);
    });
    this.notify();
  }

  async canAddNewTask() {
    const numExistingGanttTasksInCanvas = await countGanttTaskNodesInReflect(this.context.reflect);

    return numExistingGanttTasksInCanvas < this.#maxAllowedTasksInPlan;
  }

  setMaxAllowedTasksInPlan(amount: number) {
    this.#maxAllowedTasksInPlan = amount;
  }

  isSelected() {
    return this.#isSelected;
  }

  deleteSelectedElement(): void {
    if (!this.#selectedSplitId) {
      return;
    }
    if (this.#selectedRowId) {
      this.deleteRow(this.#selectedSplitId, this.#selectedRowId);
    } else {
      this.deleteColumn(this.#selectedSplitId);
    }
  }

  async moveTaskOut(taskId: string) {
    this.context.reflect.mutate.getElement(taskId).then(async (task) => {
      if (!task) {
        return;
      }
      const newElement = await this.context.undoRedoStack.patchElement(taskId, (draft) => {
        const { x: ganttX, y: ganttY, height: ganttHeight, width: ganttWidth } = this.getLayoutRect();
        const { x, y } = BoundingBox.moveBBoxOutside(
          {
            x: task.x,
            y: task.y,
            width: consts.DEFAULTS.CARD_WIDTH,
            height: 190,
          },
          {
            x: ganttX,
            y: ganttY,
            width: ganttWidth * (this.element.scaleX ?? 1),
            height: ganttHeight * (this.element.scaleY ?? 1),
          }
        );

        draft.x = x;
        draft.y = y;
      });
      if (newElement) {
        this.context.setSelectedIds([]);
        this.context.setMovingElements(null);
      }
    });
  }

  showMondayIntegrationPopup(details: { isOpen: boolean; integrationId: string }) {
    this.#shouldShowMondayIntegrationPopup = details;
    this.notify();
  }

  getShowMondayIntegrationPopup() {
    return this.#shouldShowMondayIntegrationPopup;
  }

  getIdsMappings() {
    const itemIdToTaskId = new Map<string, string>();
    const taskIdToItemId = new Map<string, string>();

    this.getTaskCells().forEach((t) => {
      if (t.element.type === "integrationItem") {
        itemIdToTaskId.set(t.element.configuration?.itemId ?? "", t.id);
        taskIdToItemId.set(t.id, t.element.configuration?.itemId ?? "");
      }
    });

    return {
      itemIdToTaskId,
      taskIdToItemId,
    };
  }

  setMondayIntegrationFetcher(fetcher: (id: string, integrationId: string) => any | null) {
    this.#mondayIntegrationFetcher = fetcher;
    const tasks = this.getTaskCells();
    for (const task of tasks) {
      if (task instanceof GanttMondayItemCellController) {
        task.setMondayIntegrationFetcher(fetcher);
      }
    }
  }
}
