import { getRecentBoards, updateBoardAccess, fetchBoard } from "frontend/api";
import { Board, BoardCache, MinimalBoard } from "shared/datamodel/schemas";
import { useGenericCache } from "./use-general-cache";
import { boardCacheAtom } from "state-atoms/boards-atom";
import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import { useFeatureFlag } from "../use-feature-flag/use-feature-flag";

async function fetchBoardsById(id: string) {
  const result = await fetchBoard(id);
  return result.board;
}

export function useBoardsCache() {
  const isNewHomePageEnabled = useFeatureFlag("home-page-refactor");
  const { getItem, isLoading, updateCache } = useGenericCache<MinimalBoard, BoardCache, "documentId">({
    cacheAtom: boardCacheAtom,
    fetchById: fetchBoardsById,
    entityName: "boards",
    entityKey: "board",
    keyField: "documentId",
  });

  const { trigger: fetchRecentBoards, isMutating: isLoadingRecent } = useSWRMutation("recent-boards", async () => {
    const boards = await getRecentBoards();
    updateCache(boards);
    return boards;
  });

  const accessBoard = useCallback(
    (board: Board) => {
      updateCache([board]);
      isNewHomePageEnabled && updateBoardAccess(board.documentId);
      return board;
    },
    [updateCache, isNewHomePageEnabled]
  );

  return {
    getBoard: getItem,
    isLoading: isLoading || isLoadingRecent,
    fetchRecentBoards,
    accessBoard,
  };
}
