import { Degrees } from "frontend/utils/transform";
import type { Point } from "shared/datamodel/schemas";

export interface ITransform {
  x: number;
  y: number;
  rotate?: number;
  scaleX?: number;
  scaleY?: number;
}

interface PointAndDirection {
  x: number;
  y: number;
  rotation?: number; // degrees
}

// the points for our regular polygons as we draw them, normalized
// with width=height=radius=scale=1, rotation=0, x,y=0
export const trianglePoints = [0, -1, 0.866, 0.5, -0.866, 0.5];
export const diamondPoints = [0, -1, 1, 0, 0, 1, -1, 0];
export const hexagonPoints = [0, -1, 0.866, -0.5, 0.866, 0.5, 0, 1, -0.866, 0.5, -0.866, -0.5];

/**
 * Compute x,y for polar coordinates (angle, radius)
 * @param rad angle in radians
 * @param radius radius (default = 1)
 * @returns [x,y]
 */
export const cossin = (rad: number, radius = 1): Point => ({ x: Math.cos(rad) * radius, y: Math.sin(rad) * radius });

/**
 * Compute length of vector
 * @param x
 * @param y
 * @returns vector length
 */
export const length = (x: number, y: number) => Math.sqrt(x * x + y * y);

/**
 * Compute distance between 2 points
 * @param x1 first point x
 * @param y1 first point y
 * @param x2 second point x
 * @param y2 second point y
 * @returns distance from (x1,y1) to (x2,y2)
 */
export const distance = (x1: number, y1: number, x2: number, y2: number) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);

/**
 * Returns the 2 numbers in order
 * @param a
 * @param b
 * @returns [min(a,b),max(a,b)]
 */
export const minmax = (a: number, b: number) => (a < b ? [a, b] : [b, a]);

/**
 * This class represents a simple 2d transform, with move, rotate and scale.
 * It can be used to transform points, normals, and angles.
 * Can't use it to transform vectors, because vectors are not affected by move.
 * Can't use it to compose transforms together (see Konva.Transform if you need that)
 *
 * transform of point: scale, followed by rotate, followed by move (compose(move,rotate,scale))
 * transform of normal: 1/scale, -rotate, no move (google for explanation)
 */
export class Transform {
  private cos: number;
  private sin: number;

  static of({ x = 0, y = 0, rotate = 0, scaleX = 1, scaleY = 1 }: ITransform) {
    return new Transform(x, y, rotate, scaleX, scaleY);
  }

  // rotation is in degrees
  constructor(private x = 0, private y = 0, private rotate = 0, private scaleX = 1, private scaleY = 1) {
    const rad = (rotate * Math.PI) / 180;
    this.cos = Math.cos(rad);
    this.sin = Math.sin(rad);
  }

  // transform point === compose(move,rotate,scale)
  point(p: Point) {
    const x1 = p.x * this.scaleX;
    const y1 = p.y * this.scaleY;
    return {
      x: x1 * this.cos - y1 * this.sin + this.x,
      y: x1 * this.sin + y1 * this.cos + this.y,
    };
  }
  get pointTransformer() {
    return this.point.bind(this);
  }

  // inverse transform === compose(scale(-1), rotate(-1), move(-x,-y))
  invPoint(p: PointAndDirection) {
    const x1 = p.x - this.x;
    const y1 = p.y - this.y;
    let rotation = p.rotation ?? 0;
    rotation -= this.rotate;
    return {
      x: (x1 * this.cos + y1 * this.sin) / this.scaleX,
      y: (-x1 * this.sin + y1 * this.cos) / this.scaleY,
      rotation,
    };
  }

  // transforming normals is using 1/scale, and no need to move anything
  // google 'transform 2d normal' for full explanation
  normal(p: Point) {
    const x1 = p.x / this.scaleX;
    const y1 = p.y / this.scaleY;
    const x = x1 * this.cos - y1 * this.sin;
    const y = x1 * this.sin + y1 * this.cos;
    const d = length(x, y);
    return { x: x / d, y: y / d };
  }

  rotateAngle(a: Degrees) {
    return a + this.rotate;
  }
}
