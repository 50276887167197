import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

const Text = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M2 2.68182C2 2.30526 2.307 2 2.68571 2H9.27923H10.5961H17.3143C17.693 2 18 2.30526 18 2.68182V7H16.6286V3.36364H10.5961V16.6831H13.8593V18H10.5961V18H9.27923V18H6.01663V16.6831H9.27923V3.36364H3.37143V7H2V2.68182Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Text.displayName = "Text";

export { Text };
