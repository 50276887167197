import React from "react";
import style from "./search-empty-state.module.css";
import { getPathPrefix } from "../../../utils/getPathPrefix";

export default function SearchEmptyState({ searchTerm, subtitle }: { searchTerm: string; subtitle: string }) {
  return (
    <div className={style.noSearchResults}>
      <img src={getPathPrefix("/images/no-search-results.png")} />
      <span className={style.title}>No results found</span>
      <div>
        <span className={style.searchTerm}>{`“${searchTerm}”`}</span>
        <span> {subtitle} </span>
      </div>
    </div>
  );
}
