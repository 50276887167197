import { Plan } from "../../consts";
import { z } from "zod";

export const billingIntervalSchema = z.union([z.literal("year"), z.literal("month"), z.null()]);

export const stripePortalConfigSchema = z.object({
  type: z.union([z.literal("admin"), z.literal("upgrade"), z.literal("update-payment")]),
  planId: z.nativeEnum(Plan).optional(),
  interval: billingIntervalSchema.optional(),
  openInNewTab: z.boolean().optional(),
});

export const planData = z.object({
  name: z.string(),
  price: z.number(),
  interval: billingIntervalSchema,
  trialDays: z.number(),
});

export const invoicePreviewSchema = z.object({
  dueToday: z.number(),
  dueNextPeriod: z.number(),
  nextPeriod: z.date(),
  trialEndDate: z.number().optional(),
  plan: z.nativeEnum(Plan),
  interval: z.string(),
});

export const createSetupIntentBodySchema = z.object({
  newPlan: z.nativeEnum(Plan),
  seatsCount: z.number(),
  billingInterval: billingIntervalSchema,
});

export type CreateSetupIntentBody = z.infer<typeof createSetupIntentBodySchema>;

export type InvoicePreview = z.infer<typeof invoicePreviewSchema>;

export type StripePortalConfig = z.infer<typeof stripePortalConfigSchema>;

export type PlanData = z.infer<typeof planData>;

export type BillingInterval = z.infer<typeof billingIntervalSchema>;
