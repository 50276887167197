import { useEffect, useRef } from "react";
import Konva from "konva";
import { Group, Line, Rect, Text } from "react-konva";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useAtomValue } from "jotai";
import {
  CARD_WIDTH,
  CARD_HEIGHT,
  DEFAULT_CARD_STACK_HEIGHT,
  DEFAULT_CARD_STACK_TITLE,
  DEFAULT_CARD_STACK_WIDTH,
} from "shared/datamodel/card-stack";
import { FontProperties, Point } from "shared/datamodel/schemas";
import { editingElementLinkAtom } from "state-atoms";
import { IntegrationCanvasElementInStack } from "../integration-element";

import { ANIMATION_DURATION } from "./card-stack-utils";
import { EditableText } from "frontend/editableText";
import { noop } from "frontend/utils/fn-utils";
import { LiveIconKonva } from "frontend/icons/live-icon";
import { MondayLogoKonva } from "frontend/icons/icons";

export function CardStackFrame({
  x,
  y,
  width = DEFAULT_CARD_STACK_WIDTH,
  height = DEFAULT_CARD_STACK_HEIGHT,
  title = DEFAULT_CARD_STACK_TITLE,
  isEditing = false,
  onStopEditing,
  onChangeTitle = noop,
  isLive,
  integrationIcon,
}: {
  x: number;
  y: number;
  width?: number;
  height?: number;
  title?: string;
  isEditing?: boolean;
  onStopEditing?: () => void;
  onChangeTitle?: (newValue: string) => void;
  isLive?: boolean;
  integrationIcon?: string;
}) {
  const showLiveIcon = isLive !== undefined;
  const headerHeight = 63;
  const titlePadding = showLiveIcon ? 50 : 20;

  function renderIntegrationIcon() {
    switch (integrationIcon) {
      case "monday": {
        return <MondayLogoKonva x={width - 50} y={headerHeight / 2 - 9} />;
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Group listening={false} x={x} y={y}>
      <Rect
        height={height}
        width={width}
        strokeWidth={3}
        stroke={"#EBEDF3"}
        strokeScaleEnabled={true}
        strokeEnabled={true}
        shadowForStrokeEnabled={false}
        fill={"white"}
        shadowColor="black"
        shadowBlur={10}
        shadowOffsetY={3}
        shadowOpacity={0.15}
      />
      <Rect
        height={headerHeight}
        width={width}
        strokeWidth={3}
        stroke={"#EBEDF3"}
        strokeScaleEnabled={true}
        strokeEnabled={true}
      />
      {showLiveIcon && <LiveIconKonva color={isLive ? "#00B875" : "#E5E8EA"} size={24} x={28} y={headerHeight / 2} />}
      <EditableText
        x={titlePadding}
        y={0}
        width={width - titlePadding - (showLiveIcon ? 24 : 0) - (integrationIcon ? 50 : 20)}
        height={headerHeight}
        verticalAlign="middle"
        isFixedHeight={true}
        isEditing={isEditing}
        onStopEditing={onStopEditing}
        onChange={onChangeTitle}
        text={title}
        placeholder={"Add title"}
        fontSize={20}
        fill={"#113255"}
        font={"Poppins"}
        fontProps={FontProperties.Bold}
      />
      {renderIntegrationIcon()}
    </Group>
  );
}

interface CardStackMoreUndisplayedProps {
  x: number;
  y: number;
  width: number;
  height: number;
  count: number;
  onClick: () => void;
}

export function CardStackMoreUndisplayed(props: CardStackMoreUndisplayedProps) {
  const { x, y, width, height, count, onClick } = props;
  return (
    <>
      <Line points={[x, y, x + width, y]} stroke="#EBEDF3" strokeWidth={2} />
      <Text
        x={x}
        y={y}
        width={width}
        height={height}
        fontFamily="Poppins"
        fontSize={20}
        align="center"
        verticalAlign="middle"
        fill="#0072FF"
        text={`${count} more...`}
        onClick={onClick}
      />
    </>
  );
}

export function AnimatableCard({
  id,
  element,
  syncService,
  changeElement,
  target,
  initial,
  display,
}: {
  id: string;
  element: any;
  syncService?: SyncService<RW>;
  changeElement: any;
  target: Point;
  initial: Point;
  display: boolean;
}) {
  const editingElementLinkId = useAtomValue(editingElementLinkAtom);
  const ref = useRef<Konva.Group>(null);
  const posRef = useRef<Point>(initial);
  useEffect(() => {
    if (ref.current) {
      ref.current.to({
        x: target.x,
        y: target.y,
        duration: ANIMATION_DURATION,
        easing: Konva.Easings.StrongEaseOut,
        onFinish: () => (posRef.current = { ...target }),
      });
    }
  }, [target, ref.current]);

  const shape = display ? (
    <IntegrationCanvasElementInStack
      key={id}
      id={id}
      syncService={syncService}
      element={element}
      isEditingLink={editingElementLinkId == id}
      changeElement={changeElement}
    />
  ) : null;
  const p = posRef.current;
  return (
    <Group key={id} ref={ref} {...p}>
      {shape}
    </Group>
  );
}

export function ShadowCard({ x, y }: { x: number; y: number }) {
  return <Rect x={x} y={y} width={CARD_WIDTH} height={CARD_HEIGHT} fill="grey" opacity={0.1} strokeEnabled={false} />;
}
