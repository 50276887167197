import useAnyEvent from "frontend/hooks/use-any-event";
import { useHotkey } from "frontend/shortcut-context";
import isHotkey from "is-hotkey";

const isModifierOn = (e: KeyboardEvent) => e.ctrlKey || e.metaKey || e.altKey || e.shiftKey;
const isArrowKey = isHotkey(["up", "down", "left", "right"]);
const isShiftAndArrowKey = isHotkey(["shift+up", "shift+down", "shift+left", "shift+right"]);
const isEnterKey = (e: KeyboardEvent) => e.key == "Enter" && !isModifierOn(e);
const isDelete = (e: KeyboardEvent) => (e.key == "Delete" || e.key == "Backspace") && !isModifierOn(e);

export function TableKeyboardShortcuts({
  moveCursor,
  editCell,
  extendSelection,
  clearSelection,
  onClipboardEvent,
}: {
  moveCursor?: (dx: number, dy: number) => void;
  editCell?: (char?: string) => void;
  extendSelection?: (dx: number, dy: number) => void;
  clearSelection?: () => boolean;
  onClipboardEvent?: (e: ClipboardEvent) => void;
}) {
  useHotkey(
    isArrowKey,
    (e) => {
      if (moveCursor) {
        const dx = e.key == "ArrowLeft" ? -1 : e.key == "ArrowRight" ? 1 : 0;
        const dy = e.key == "ArrowUp" ? -1 : e.key == "ArrowDown" ? 1 : 0;
        moveCursor(dx, dy);
        return true;
      }
      return false;
    },
    { allowRepeat: true }
  );
  useHotkey(
    (e) => isEnterKey(e), // || isCharacterKey(e),
    (e) => {
      if (editCell) {
        if (e.key == "Enter") editCell();
        else editCell(e.key);
        return true;
      }
      return false;
    },
    { disabled: !editCell }
  );
  useHotkey(
    (e) => isDelete(e),
    () => {
      return clearSelection ? clearSelection() : false;
    }
  );
  useHotkey(
    (e) => isShiftAndArrowKey(e),
    (e) => {
      if (extendSelection) {
        const dx = e.key == "ArrowLeft" ? -1 : e.key == "ArrowRight" ? 1 : 0;
        const dy = e.key == "ArrowUp" ? -1 : e.key == "ArrowDown" ? 1 : 0;
        extendSelection(dx, dy);
        return true;
      }
      return false;
    },
    { allowRepeat: true }
  );

  // @ts-expect-error TS doesn't like window being passed as an argument in listeners, but it works.
  useAnyEvent("copy", onClipboardEvent ?? noop, window, { passive: false });
  // @ts-expect-error (See above)
  useAnyEvent("cut", onClipboardEvent ?? noop, window, { passive: false });
  // @ts-expect-error (See above)
  useAnyEvent("paste", onClipboardEvent ?? noop, window, { passive: false });

  return null;
}
