import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import React from "react";

export default function MenuToggle({
  isNewMenu,
  setIsNewMenu,
}: {
  isNewMenu: boolean;
  setIsNewMenu: (isNewMenu: boolean) => void;
}) {
  const smallStyle = {
    fontSize: 9,
    fontWeight: 300,
  };
  const unselectedStyle = {
    padding: "6px 11px",
  };

  const customStyleOldMenu = !isNewMenu ? smallStyle : { ...smallStyle, ...unselectedStyle };
  const customStyleNewMenu = isNewMenu ? smallStyle : { ...smallStyle, ...unselectedStyle };

  return (
    <div>
      <StyledButton
        onClick={() => setIsNewMenu(false)}
        title={"old side menu"}
        customStyle={customStyleOldMenu}
        style={!isNewMenu ? ButtonStyle.filled : ButtonStyle.outline}
      />

      <StyledButton
        onClick={() => setIsNewMenu(true)}
        title={"new side menu"}
        customStyle={customStyleNewMenu}
        style={isNewMenu ? ButtonStyle.filled : ButtonStyle.outline}
      />
    </div>
  );
}
