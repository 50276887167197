import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { useState, useEffect } from "react";
import { Project } from "shared/datamodel/schemas/project";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import { ProjectCellNew } from "./projects-cell-new";

export default function ProjectList({
  team,
  selectedProject,
  handleProjectSelection,
  onEditSeats,
}: {
  team: TeamWithProjects;
  selectedProject: Project | null;
  handleProjectSelection: (project: Project) => void;
  onEditSeats: () => void;
}) {
  const [projects, setProjects] = useState<Project[]>([]);
  const [parentTeam, setParentTeam] = useState<Team | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { getProjects, isLoading: isLoadingProjects } = useProjectsCache();
  const { getTeams, isLoading: isLoadingTeam } = useTeamsCache();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setError(null);
        if (!team?.id || !team.projects?.length) {
          throw new Error("Invalid team or project data");
        }
        const [fetchedProjects, parentTeams] = await Promise.all([
          getProjects(team.projects.map((project) => String(project.id))),
          getTeams([String(team.id)]),
        ]);
        if (!fetchedProjects?.length) {
          throw new Error("No projects found");
        }

        if (!parentTeams?.[0]) {
          throw new Error("Parent team not found");
        }

        setProjects(fetchedProjects);
        setParentTeam(parentTeams[0]);
      } catch (err) {
        console.error("Error fetching project data:", err);
        setError(err instanceof Error ? err.message : "Failed to load projects");
        setProjects([]);
        setParentTeam(null);
      }
    };

    fetchProjects();
  }, [team.id, team.projects, getProjects, getTeams]);

  if (error) {
    return <div className="ml-6 text-red-500">{error}</div>;
  }

  if (isLoadingTeam || isLoadingProjects) {
    return <div className="ml-6 text-gray-500">Loading projects...</div>;
  }

  if (!projects.length) {
    return <div className="ml-6 text-gray-500">No projects found</div>;
  }

  return (
    <div className="ml-6">
      {projects.map((project) => (
        <ProjectCellNew
          key={project.id}
          project={project}
          parentTeam={parentTeam!}
          isSelected={selectedProject?.id === project.id}
          onClick={() => handleProjectSelection(project)}
          onEditSeats={onEditSeats}
        />
      ))}
    </div>
  );
}
