import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { BlankCanvasTemplate, CanvasTemplate } from "shared/datamodel/schemas";

export function AccountMembersButton({ setShowAccountMembers }: { setShowAccountMembers: (show: boolean) => void }) {
  return (
    <div>
      <StyledButton
        title={"account members"}
        style={ButtonStyle.outline}
        customStyle={{
          height: 32,
          width: 150,
          fontSize: 13,
          fontWeight: 500,
        }}
        onClick={() => setShowAccountMembers(true)}
      />
    </div>
  );
}

export function CreatePrivateCanvas({
  onCreateCanvas,
}: {
  onCreateCanvas: (template: CanvasTemplate, projectId: number | null, teamId: number | null) => void;
}) {
  const renderCreateCanvasAction = () => {
    return (
      <StyledButton
        title="Create Canvas"
        style={ButtonStyle.outline}
        customStyle={{ height: 32, width: 150, fontSize: 13, fontWeight: 500, marginTop: 10 }}
        onClick={() => {
          onCreateCanvas(BlankCanvasTemplate, null, null);
        }}
      />
    );
  };
  return renderCreateCanvasAction();
}

export function MyCanvasesButton({ onMyCanvasesClick }: { onMyCanvasesClick: () => void }) {
  return (
    <div>
      <StyledButton
        title="My Canvases"
        style={ButtonStyle.outline}
        customStyle={{ height: 32, width: 150, fontSize: 13, fontWeight: 500, marginTop: 10 }}
        onClick={onMyCanvasesClick}
      />
    </div>
  );
}

export function RecentCanvasesButton({ onRecentCanvasesClick }: { onRecentCanvasesClick: () => void }) {
  return (
    <div>
      <StyledButton
        title="Recent Canvases"
        style={ButtonStyle.outline}
        customStyle={{ height: 32, width: 150, fontSize: 13, fontWeight: 500, marginTop: 10 }}
        onClick={onRecentCanvasesClick}
      />
    </div>
  );
}
