import { getBoardIntegrations, getIntegrationItems } from "frontend/api";

export const getMondayBoardItemMapping = async (
  documentId: string,
  integrationId: string
): Promise<{
  mapping: Record<string, string>;
  shouldApplyForAll: boolean;
} | null> => {
  const allConfigurations = await getBoardIntegrations(documentId);
  const configuration = allConfigurations.find((c: any) => c.id === integrationId);
  return configuration.configuration?.columnMappings;
};

export const combineMappingWithData = (
  columnMappings: {
    mapping: Record<string, string>;
    shouldApplyForAll: boolean;
  } | null,
  item: {
    name: string;
    columnValues: {
      id: string;
      type: string;
      value: string;
    }[];
  }
) => {
  const startId = columnMappings?.mapping?.start_date;
  const endId = columnMappings?.mapping?.end_date;
  const dependencyId = columnMappings?.mapping?.dependency;

  const dateColumns = item?.columnValues?.filter((column: any) => column.type === "date");
  const startDate = dateColumns?.find((column: any) => column.id === startId)?.value || "";
  const endDate = dateColumns?.find((column: any) => column.id === endId)?.value || "";
  const title = item?.name ?? "";

  const dependentTaskIds = item?.columnValues?.find((column: any) => column.id === dependencyId)?.value || "";
  let dependencies: string[] = [];
  if (dependentTaskIds) {
    dependencies =
      (JSON.parse(dependentTaskIds)?.linkedPulseIds ?? []).map((d: any) => d.linkedPulseId.toString()) ?? [];
  }

  return {
    startDate: new Date(new Date(startDate).toDateString()).getTime(),
    endDate: new Date(new Date(endDate).toDateString()).getTime(),
    dependencies,
    title,
    startId,
    endId,
    dependencyId,
  };
};

export const getIntegrationItemGanttValues = (documentId: string, integrationId: string, itemId: string) => {
  return new Promise<{
    startDate: number;
    endDate: number;
    title: string;
    startId: string | undefined;
    endId: string | undefined;
    dependencyId: string | undefined;
    url: string | undefined;
    dependencies: string[];
  } | null>((resolve, reject) =>
    getMondayBoardItemMapping(documentId, integrationId).then((columnMappings) => {
      getIntegrationItems(documentId, {
        [integrationId]: { itemIds: [itemId] },
      })
        .then(async (d) => {
          const item = (d[integrationId].items ?? []).find((item: any) => item.id === itemId);
          if (!item) {
            return resolve(null);
          }
          const data = { ...combineMappingWithData(columnMappings, item), url: item.url };

          return resolve(data);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    })
  );
};
