import style from "./teamCell.module.css";
import styles from "./rename-team.module.css";
import { useRef, useState } from "react";
import { useHover, useOnClickOutside } from "usehooks-ts";
import { Team } from "shared/datamodel/schemas/team";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import InlineRename, { InlineRenameStyle } from "./inlineRename";
import useStateValue from "frontend/state/value";
import { useTeam } from "frontend/hooks/use-team";
import { InitialsIcon } from "../../ui-components/initialsIcon";
import Modal from "frontend/modal/modal";
import { CollaborationType, InviteMode, isBannerNotificationOnAtom } from "state-atoms";
import ManageTeamMembers from "frontend/modals/collaboration/team/manage-team-members";
import LeaveCollaborationModal from "frontend/modals/collaboration/leave-collaboration-modal";
import DeleteCollaborationModal from "frontend/modals/collaboration/delete-collaboration-modal";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { useAtomValue } from "jotai";
import InviteModal from "frontend/modals/invite-modal";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export function TeamCell({
  team,
  isSelected,
  onClick,
  onDismiss,
  onEditSeats,
}: {
  team: Team;
  isSelected: boolean;
  onClick: () => void;
  onDismiss: () => void;
  onEditSeats: () => void;
}) {
  const [{ user }] = useStateValue();

  const [showMenu, setShowMenu] = useState(false);
  const [inlineRenameFocuse, setInlineRenameFocuse] = useState(false);
  const [teamName, setTeamName] = useState(team.name);
  const [showInviteTeammatesModal, setShowInviteTeammatesModal] = useState(false);
  const [showManageTeamModal, setShowManageTeamModal] = useState(false);
  const [showLeaveCollaborationModal, setShowLeaveCollaborationModal] = useState(false);
  const [showDeleteCollaborationModal, setShowDeleteCollaborationModal] = useState(false);
  const { myTeams } = useTeam();
  const isBannerNotificationOn = useAtomValue(isBannerNotificationOnAtom);

  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement | null>(null);
  const hover = useHover(ref);

  const { handleEditTeamName } = useTeam();

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  const shouldEnableMenu = () => {
    if (team.accountTeam) {
      return user?.isAdmin ?? false;
    }
    return true;
  };

  function menuClicked(e: any) {
    e.stopPropagation();
    setShowMenu((show) => !show);
  }

  function renameClicked(e: any) {
    e.stopPropagation();
    setShowMenu(false);
    setInlineRenameFocuse(true);
  }

  const renderTeamMenu = (team: Team) => {
    return (
      <>
        <Floater
          relativeTo={menuButtonRef}
          side={"bottom"}
          ref={menuRef}
          extraStyle={{
            zIndex: "1",
            filter: "drop-shadow(0px 4.799998760223389px 9.599997520446777px rgba(0, 0, 0, 0.20))",
            marginTop: isBannerNotificationOn ? "-159px" : "-109px",
          }}
        >
          <div className={style.arrow} />
          <div className={style.teamMenu}>
            {team.accountTeam && user?.isAdmin ? (
              <>
                <span
                  data-testid="Invite members"
                  className={style.item}
                  onClick={() => {
                    tracking.trackEvent(
                      consts.TRACKING_CATEGORY.ADMIN_ACTION,
                      "team_picker_invite_to_team_button_clicked",
                      "invite_type",
                      "account"
                    );
                    setShowInviteTeammatesModal(true);
                    setShowMenu(false);
                  }}
                >
                  {"Invite members"}
                </span>
                {team.userTeamPermissions.length > 0 && (
                  <span
                    data-testid="Manage team"
                    className={style.item}
                    onClick={() => {
                      setShowManageTeamModal(true);
                      setShowMenu(false);
                    }}
                  >
                    {"Manage team"}
                  </span>
                )}
              </>
            ) : null}
            {team.isOwner ? (
              <>
                <span
                  data-testid="Invite members"
                  className={style.item}
                  onClick={() => {
                    tracking.trackEvent(
                      consts.TRACKING_CATEGORY.ADMIN_ACTION,
                      "team_picker_invite_to_team_button_clicked",
                      "invite_type",
                      "account"
                    );
                    setShowInviteTeammatesModal(true);
                    setShowMenu(false);
                  }}
                >
                  {"Invite members"}
                </span>
                {team.userTeamPermissions.length > 0 && (
                  <span
                    data-testid="Manage team"
                    className={style.item}
                    onClick={() => {
                      setShowManageTeamModal(true);
                      setShowMenu(false);
                    }}
                  >
                    {"Manage team"}
                  </span>
                )}
                <span
                  data-testid="Rename"
                  className={style.item}
                  onClick={(e) => {
                    renameClicked(e);
                    setShowMenu(false);
                  }}
                >
                  {"Rename"}
                </span>
                <span
                  data-testid="Leave"
                  className={style.item}
                  onClick={() => {
                    setShowLeaveCollaborationModal(true);
                    setShowMenu(false);
                  }}
                >
                  {"Leave"}
                </span>
                <span
                  data-testid="Delete"
                  className={classNames(style.item, style.delete)}
                  onClick={() => {
                    setShowDeleteCollaborationModal(true);
                    setShowMenu(false);
                  }}
                >
                  {"Delete"}
                </span>
              </>
            ) : !team.accountTeam ? (
              <span
                data-testid="Leave"
                className={style.item}
                onClick={() => {
                  setShowLeaveCollaborationModal(true);
                  setShowMenu(false);
                }}
              >
                {"Leave"}
              </span>
            ) : null}
          </div>
        </Floater>
      </>
    );
  };

  const renderVisibleText = () => (
    <InlineRename
      value={teamName}
      textStyle={InlineRenameStyle.regular}
      style={{
        minWidth: "140px",
        maxWidth: "140px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      focus={inlineRenameFocuse}
      onEditing={(editing: boolean) => setInlineRenameFocuse(editing)}
      onChange={(newName: string) => {
        setTeamName(newName);
        handleEditTeamName(newName, team);
      }}
      styles={styles}
    />
  );

  return (
    <div
      key={team.id}
      data-testid={teamName}
      ref={ref}
      className={classNames(style.cell, { [style.selectedProject]: isSelected, [style.rename]: inlineRenameFocuse })}
    >
      {!inlineRenameFocuse && (
        <span>
          <InitialsIcon text={team.name} list={myTeams} />
        </span>
      )}
      {hover && teamName.length > 14 && <Tooltip side="right" label={teamName} relativeTo={ref} />}
      <div className={style.teamName} onClick={onClick}>
        {renderVisibleText()}
      </div>
      {shouldEnableMenu() && (hover || showMenu) && (
        <div
          data-testid="teamcellmenuicon"
          className={style.menuIcon}
          onClick={(e) => menuClicked(e)}
          ref={menuButtonRef}
        />
      )}
      {showMenu && renderTeamMenu(team)}
      {showInviteTeammatesModal && (
        <Modal dimBackground={true}>
          <InviteModal
            team={team}
            onDismiss={() => setShowInviteTeammatesModal(false)}
            inviteMode={InviteMode.Edit}
            onEditSeats={onEditSeats}
            source="edit-team-members"
          />
        </Modal>
      )}
      {showManageTeamModal && (
        <Modal dimBackground={true}>
          <ManageTeamMembers
            onDismiss={() => {
              setShowManageTeamModal(false);
              onDismiss();
            }}
            team={team}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {showLeaveCollaborationModal && (
        <Modal dimBackground={true}>
          <LeaveCollaborationModal
            type={CollaborationType.Team}
            onDismiss={() => {
              setShowLeaveCollaborationModal(false);
              onDismiss();
            }}
            subtitle={`You will lose access to "${team.name}" team and it’s canvases.`}
            team={team}
            isSelected={isSelected}
          />
        </Modal>
      )}
      {showDeleteCollaborationModal && (
        <Modal dimBackground={true}>
          <DeleteCollaborationModal
            type={CollaborationType.Team}
            onDismiss={() => {
              setShowDeleteCollaborationModal(false);
              onDismiss();
            }}
            subtitle={`All projects and canvases within this team will be permanently deleted. You can move canvases to another team or export them before deleting.`}
            team={team}
            isSelected={isSelected}
          />
        </Modal>
      )}
    </div>
  );
}
