import { useEffect, useState } from "react";
import { TeamSize } from "../types";
import OptionCard from "../option-card/option-card";
import style from "./choose-team-size.module.css";
import tracking from "frontend/tracking";
import { getPathPrefix } from "utils/getPathPrefix";
import consts from "shared/consts";
import OnboardingButton from "../onboarding-button/onboarding-button";

const ONBOARDING_TEAM_SIZE_ARRAY = [
  {
    teamSizeKey: "myself" as TeamSize,
    teamSizeName: "For myself",
    imageName: "myself",
  },
  {
    teamSizeKey: "team" as TeamSize,
    teamSizeName: "With my team",
    imageName: "team",
  },
];

type ChooseTeamSizeProps = {
  onCompleted: (teamSize: TeamSize) => void;
};

export default function ChooseTeamSize({ onCompleted }: ChooseTeamSizeProps) {
  const [selectedTeamSize, setSelectedTeamSize] = useState<TeamSize | null>(null);

  const continueClicked = () => {
    if (selectedTeamSize) {
      onCompleted(selectedTeamSize);
    }
  };

  useEffect(() => {
    tracking.trackAnalyticsEvent("onboarding_team_size", {
      category: consts.TRACKING_CATEGORY.PAGE_VIEWS,
    });
  }, []);

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <h1 className={style.title}>How do you plan to use WorkCanvas?</h1>
      </div>
      <div className={style.chooseTemplateCardsContainer}>
        {ONBOARDING_TEAM_SIZE_ARRAY.map(({ teamSizeKey, teamSizeName, imageName }) => (
          <OptionCard
            key={teamSizeKey}
            title={teamSizeName}
            imagePathName={getPathPrefix(`/images/onboarding/choose_team_size/${imageName}.png`)}
            isSelected={teamSizeKey === selectedTeamSize}
            onClick={() => setSelectedTeamSize(teamSizeKey)}
          />
        ))}
      </div>

      <OnboardingButton onClick={continueClicked} title="Get started" disabled={!selectedTeamSize} />
    </div>
  );
}
