import { useEffect, useState } from "react";
import { OnboardingTemplate } from "../types";
import OptionCard from "../option-card/option-card";
import style from "./choose-template.module.css";
import tracking from "frontend/tracking";
import { getPathPrefix } from "utils/getPathPrefix";
import OnboardingButton from "../onboarding-button/onboarding-button";

const ONBOARDING_TEMPLATES_ARRAY: OnboardingTemplate[] = [
  {
    templateKey: "orgchart",
    templateAlias: process.env.ONBOARDING_ORGCHART_TEMPLATE_ALIAS ?? null,
    templateName: "Org Chart",
    imageName: "orgchart",
  },
  {
    templateKey: "flowchart",
    templateAlias: process.env.ONBOARDING_FLOWCHART_TEMPLATE_ALIAS ?? null,
    templateName: "Flowchart",
    imageName: "flowchart",
  },
  {
    templateKey: "ganttchart",
    templateAlias: process.env.ONBOARDING_GANTTCHART_TEMPLATE_ALIAS ?? null,
    templateName: "Gantt Chart",
    imageName: "ganttchart",
  },
  {
    templateKey: "blank",
    templateAlias: null,
    templateName: "Blank Canvas",
    imageName: "blank",
  },
];

type OnboardingChooseTemplateProps = {
  onCompleted: (templateAlias: string | null) => void;
};

export default function OnboardingChooseTemplate({ onCompleted }: OnboardingChooseTemplateProps) {
  const [selectedTemplate, setSelectedTemplate] = useState<OnboardingTemplate | null>(null);

  const continueClicked = () => {
    if (selectedTemplate) {
      onCompleted(selectedTemplate.templateAlias);
      tracking.trackAnalyticsEvent("onboarding_choose_template_continue", {
        template: selectedTemplate.templateKey,
      });
    }
  };

  const onTemplateSelected = (template: OnboardingTemplate) => {
    setSelectedTemplate(template);
    tracking.trackAnalyticsEvent("onboarding_choose_template_selected", {
      template: template.templateKey,
    });
  };

  useEffect(() => {
    tracking.trackAnalyticsEvent("onboarding_choose_template");
  }, []);

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <h1 className={style.title}>What would you like to create first?</h1>
        <p className={style.subtitle}>Select a template to get started</p>
      </div>
      <div className={style.chooseTemplateCardsContainer}>
        {ONBOARDING_TEMPLATES_ARRAY.map((template) => (
          <OptionCard
            key={template.templateKey}
            title={template.templateName}
            imagePathName={getPathPrefix(`/images/onboarding/choose_template/${template.imageName}.png`)}
            isSelected={selectedTemplate?.templateKey === template.templateKey}
            onClick={() => onTemplateSelected(template)}
          />
        ))}
      </div>
      <OnboardingButton onClick={continueClicked} title="Get started" disabled={!selectedTemplate} />
    </div>
  );
}
