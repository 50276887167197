import { z } from "zod";

export const elementResizeInfo = z.object({
  clientRect: z.any().nullish(),
  type: z.string(),
  id: z.string(),
  affectedConnectors: z.any().nullish(),
});

export type ElementResizeInfo = z.infer<typeof elementResizeInfo>;
