import React, { useMemo, useState } from "react";
import { Group, Rect } from "react-konva";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { CardStack } from "shared/datamodel/schemas/card-stack";
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  GAP_X,
  GAP_Y,
  HEADER,
  PADDING_X,
  PADDING_Y,
  PADDING_Y_BOTTOM,
} from "shared/datamodel/card-stack";
import BaseCanvasElement from "../base-canvas-element";
import { SyncService } from "frontend/services/syncService";
import { useCardStackElements } from "frontend/subscriptions";
import { isPointInRect, Point } from "frontend/utils/math-utils";
import { OnResizeCallback, TransformHooks } from "frontend/hooks/use-transform-hooks";
import { KonvaEventObject } from "konva/types/Node";
import { clamp } from "rambda";
import { useEvent } from "react-use";
import { AnimatableCard, CardStackFrame, CardStackMoreUndisplayed, ShadowCard } from "./card-stack-subelements";
import {
  cardGridHeightParams,
  cardGridWidthParams,
  computeColumnsAndRows,
  EVT_ELEMENT_DRAG,
  EVT_ELEMENT_DROP,
  posToColAndRow,
  roundSizeDownToNearestStep,
  sizeOfCardsLine,
  cardPosition,
} from "./card-stack-utils";
import { useAtomValue } from "jotai";
import { transformerRefAtom } from "state-atoms";
import { getElementTypeForId } from "../canvas-elements-utils";
import consts from "shared/consts";

export class TransformHooksCardStack extends TransformHooks {
  constructor(id: string, onResize: OnResizeCallback) {
    super(id, onResize);
    this.callbacks.onTransformEnd = this.onTransformEnd1.bind(this);
  }

  onTransformEnd1(e: KonvaEventObject<Event>) {
    const node = e.currentTarget;
    const element = node.attrs.element;
    this.onTransformEnd(e);

    const { x: scaleX, y: scaleY } = node.scale();
    const { width, height } = element;
    const fixed = roundSizeDownToNearestStep(width, height, scaleX, scaleY);
    const fixedScaleX = fixed.width / width;
    const fixedScaleY = fixed.height / height;
    this.onResize(this.id, node.getPosition(), fixedScaleX, fixedScaleY, node.rotation());
  }
}

export function CardStackElement({
  syncService,
  id,
  element,
  changeElement,
  onChangeElement,
  isEditing,
  onStopEditing,
  onResize,
}: {
  syncService?: SyncService<RW>;
  id: string;
  element: CardStack;
  changeElement: any;
  onChangeElement: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
  isEditing: boolean;
  onStopEditing: () => void;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}) {
  const mutation = useMemo(() => new TransformHooksCardStack(id, onResize), [id, onResize]);
  const cardsDataReflect = useCardStackElements(syncService?.getReplicache(), id);
  const [draggedCardsData, setDraggedCardsData] = useState<any>(null);
  const transformerRef = useAtomValue(transformerRefAtom);
  // On every render of the card-stack I recompute the layout of the cards.

  // first step: compute the size of the card-stack in columns and rows of cards, and also in pixels.
  const { columns, rows } = computeColumnsAndRows(element.width, element.height, element.scaleX, element.scaleY);
  const width = sizeOfCardsLine(columns, cardGridWidthParams);
  const height = sizeOfCardsLine(rows, cardGridHeightParams);

  // all cards will be positioned relative to the stack position and internal padding.
  const cardLayoutStartX = element.x + PADDING_X;
  const cardLayoutStartY = element.y + HEADER + PADDING_Y;

  // TODO: event should be broadcast on the canvas, not the window, in case we want 2 canvases in the same window
  useEvent(EVT_ELEMENT_DROP, (ev) => {
    const integrationItemIds = ev.detail.ids.filter(
      (id: any) => getElementTypeForId(id) === consts.CANVAS_ELEMENTS.INTEGRATION
    );
    if (integrationItemIds.length === 0) {
      return;
    }
    const dropArea = {
      x: cardLayoutStartX,
      y: cardLayoutStartY,
      width: width,
      height: height,
    };
    const isDroppedHere = isPointInRect(ev.detail, dropArea);
    if (isDroppedHere) {
      // if the mouse drops the integration items here, they will be marked as beloning to this stack.
      // also we'll define new x,y for them so they fit in where the shadow-cards are
      const { col, row } = posToColAndRow(ev.detail.x, ev.detail.y, cardLayoutStartX, cardLayoutStartY, columns, rows);
      let x = col * (CARD_WIDTH + GAP_X) + cardLayoutStartX;
      let y = row * (CARD_HEIGHT + GAP_Y) + cardLayoutStartY;
      // reduce by 1 so it comes in first in the sort order and pushes the rest forward
      x--;
      y--;
      const changesList = integrationItemIds.map((cardId: any) => [cardId, { x, y, containerId: id }]);
      syncService
        ?.getReplicache()
        ?.mutate.changeElements({ info: changesList as any })
        .then(() => {
          // after putting the cards in the stack, we render a different object for them,
          // and the transformer is still set on the previous object.
          // Best solution is to change the transformer to point to the new nodes of the cards
          // Easy solution is to update the transformer and it goes away
          if (transformerRef?.current) {
            transformerRef.current.forceUpdate();
          }
        });
    }
    setDraggedCardsData(null);
  });

  useEvent(EVT_ELEMENT_DRAG, (ev) => {
    const integrationItemIds = ev.detail.ids.filter(
      (id: any) => getElementTypeForId(id) === consts.CANVAS_ELEMENTS.INTEGRATION
    );
    if (integrationItemIds.length === 0) {
      return;
    }
    const dropArea = {
      x: cardLayoutStartX,
      y: cardLayoutStartY,
      width: width,
      height: height,
    };
    const isMouseAboveMe = isPointInRect(ev.detail.mousePosition, dropArea);
    setDraggedCardsData(isMouseAboveMe ? ev.detail : null);
  });

  const numItems = cardsDataReflect.length + (draggedCardsData ? draggedCardsData.count : 0);
  const numCardsDisplayed = Math.min(numItems, columns * rows);
  const numOfUndisplayedCards = numItems - numCardsDisplayed;

  // TODO: maybe do this in useEffect instead of every render, because not every mouse movement should re-render every stack
  let insertIndex = Infinity,
    insertCount = 0;
  if (draggedCardsData) {
    const { x, y } = draggedCardsData.mousePosition;
    const { col, row } = posToColAndRow(x, y, cardLayoutStartX, cardLayoutStartY, columns, rows);
    const numRealCardsDisplayed = Math.min(columns * rows, cardsDataReflect.length);
    // the index for that location is:
    insertIndex = clamp(0, numRealCardsDisplayed, row * columns + col);
    insertCount = draggedCardsData.count;
  }

  // second step: layout the cards in a grid. Left to right, top to bottom
  // TODO: allow many columns.
  // try grouping the cards by rows, sort each row from left to right, then
  // sort rows from top to bottom.
  cardsDataReflect.sort((a, b) => a[1].y - b[1].y);

  // Update in reflect the new position of the cards inside the stack (only if they moved)
  // It's important to update in reflect since other components rely on getting correct x,y from reflect.
  // Notice that at 'insertIndex' we skip ahead, leaving space for the 'shadow cards'
  const updates = cardsDataReflect.reduce((acc, cur, i) => {
    const indexInGrid = i < insertIndex ? i : i + insertCount;
    const newPos = cardPosition(indexInGrid, columns);
    newPos.x += cardLayoutStartX;
    newPos.y += cardLayoutStartY;
    if (newPos.x !== cur[1].x || newPos.y !== cur[1].y) {
      acc.push([cur[0], newPos]);
    }
    return acc;
  }, [] as [string, any][]);

  if (updates.length) {
    syncService?.getReplicache()?.mutate.changeElements({ info: updates as any });
  }

  const cards = [];
  for (let i = 0; i < cardsDataReflect.length; i++) {
    const [id, cardElement] = cardsDataReflect[i];
    const gridIndex = i < insertIndex ? i : i + insertCount;
    const gridPos = cardPosition(gridIndex, columns);
    const initial = { x: cardElement.x - cardLayoutStartX, y: cardElement.y - cardLayoutStartY };
    cards.push(
      <AnimatableCard
        key={id}
        id={id}
        element={cardElement}
        changeElement={(props: any, _undoConfig: any, _updateSubMenuData: any) => {
          onChangeElement(id, new Map(Object.entries(props)), new Map(Object.entries(element)), true);
        }}
        target={gridPos}
        initial={initial}
        display={gridIndex < numCardsDisplayed}
      />
    );
  }

  const shadows = [];
  for (let i = 0; i < insertCount; i++) {
    const { x, y } = cardPosition(insertIndex + i, columns);
    shadows.push(<ShadowCard key={i} x={x} y={y} />);
  }

  // I reverse the list to draw the cards from last (bottom) to first (top).
  // This might seem strange, but when the user expands a card the card will cover
  // those below it, which makes sense.
  cards.reverse();

  function expandHeightForAllCards() {
    const newHeight = sizeOfCardsLine(cardsDataReflect.length, cardGridHeightParams);
    const { scaleY = 1 } = element;
    const newScale = newHeight / element.height;
    const props = new Map([["scaleY", newScale]]);
    const prevProps = new Map([["scaleY", scaleY]]);
    onChangeElement(id, props, prevProps, true);
  }

  return (
    <>
      {/*
      Card stack first draws a transparent rect to take its place.
      I must do this for Konva's transformer to work. The transformer expects
      absolute control over the element it scales, and I want to move the size by
      steps, not continuously. So I have to let Konva have its rect, and draw
      the stack frame separately.
       */}
      <BaseCanvasElement
        id={id}
        type={"cardStack"}
        x={element.x}
        y={element.y}
        element={element}
        onResize={onResize}
        mutation={mutation}
        isSelectable={true}
        isEditingLink={false}
        changeElement={changeElement}
      >
        <Rect width={element.width} height={element.height} fill={"transparent"} />
      </BaseCanvasElement>

      {/*
      This is the graphic for the stack frame
       */}
      <CardStackFrame
        x={element.x}
        y={element.y}
        width={width}
        height={height}
        title={element.title}
        isEditing={isEditing}
        onStopEditing={onStopEditing}
        onChangeTitle={function (newValue: string): void {
          changeElement({ title: newValue }, { shouldAdd: false });
        }}
      />
      {/*
      the cards must be drawn as children of a group that follows the stack around
      this way dragging stack moves the cards seamlessly without animating them slowly.
       */}
      <Group x={cardLayoutStartX} y={cardLayoutStartY}>
        {shadows}
        {cards}
      </Group>
      {numOfUndisplayedCards > 0 && (
        <CardStackMoreUndisplayed
          x={element.x}
          y={element.y + height - PADDING_Y_BOTTOM}
          width={width}
          height={PADDING_Y_BOTTOM}
          count={numOfUndisplayedCards}
          onClick={expandHeightForAllCards}
        />
      )}
    </>
  );
}
