import { Point } from "frontend/utils/math-utils";
import { useAtomValue } from "jotai";
import { KonvaEventObject } from "konva/types/Node";
import { useEffect, useRef, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { useUnmount } from "react-use";
import { stageRefAtom } from "state-atoms";

export function MindmapNewNodeButton({ position, onClick }: { position: Point; onClick: () => void }) {
  const HandleRadius = 10;
  const HandleActiveColor = "#1973FF";
  const HandleInactiveColor = "#E3E5EB";
  const LineActiveColor = "#fff";
  const LineInactiveColor = "#848199";
  const stageAtom = useAtomValue(stageRefAtom);
  const [hover, setHover] = useState(false);

  useUnmount(() => {
    if (hover && stageAtom.current) {
      stageAtom.current.container().style.cursor = "default";
    }
  });

  const circleRef = useRef<any>(null);
  const line1Ref = useRef<any>(null);
  const line2Ref = useRef<any>(null);

  useEffect(() => {
    circleRef.current?.to({
      scaleX: hover ? 1.2 : 1,
      scaleY: hover ? 1.2 : 1,
      fill: hover ? HandleActiveColor : HandleInactiveColor,
      duration: 0.1,
    });
    line1Ref.current?.to({
      stroke: hover ? LineActiveColor : LineInactiveColor,
      duration: 0.1,
    });
    line2Ref.current?.to({
      stroke: hover ? LineActiveColor : LineInactiveColor,
      duration: 0.1,
    });
  }, [hover]);

  const mouseHandlingProps = {
    name: "mindmap-socket anchor",
    onClick,
    onMouseEnter: (e: KonvaEventObject<MouseEvent>) => {
      setHover(true);
      e.target.getStage()!.container().style.cursor = "pointer";
    },
    onMouseLeave: (e: KonvaEventObject<MouseEvent>) => {
      setHover(false);
      e.target.getStage()!.container().style.cursor = "default";
    },
  };

  return (
    <Group x={position.x} y={position.y}>
      <Circle ref={circleRef} radius={HandleRadius} fill={HandleInactiveColor} {...mouseHandlingProps} />
      <Line ref={line1Ref} points={[-5, 0, 5, 0]} stroke={LineInactiveColor} strokeWidth={1.5} listening={false} />
      <Line ref={line2Ref} points={[0, -5, 0, 5]} stroke={LineInactiveColor} strokeWidth={1.5} listening={false} />
    </Group>
  );
}
