import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import style from "./dropdown-picker.module.css";
import useOutsideRef from "frontend/utils/click-outside-handler";
import cn from "classnames";
import OptionsPicker from "./options-picker";
import { MagnifyingGlassIcon } from "frontend/ui-components/picker/magnifying-glass-icon";
import { getPathPrefix } from "../../utils/getPathPrefix";

export enum DropdownPickerStyle {
  Light = "light",
  Dark = "dark",
}

type DropdownPickerProrps<T> = {
  options: T[];
  isSelected: (option: T) => boolean;
  titles: (option: T) => string;
  enabled: boolean;
  onChange: (option: T) => void;
  optionRenderer?: ({ option, selected }: { option: T; selected: boolean }) => {
    element: ReactNode;
    enabled: boolean;
    customStyle?: CSSProperties;
    handlesSelectedState?: boolean;
    customIcon?: string;
    wrapWithContainer?: boolean;
  };
  selectionHeaderRenderer?: ({ selectedOptions }: { selectedOptions: T[] }) => ReactNode;
  multiValue?: boolean;
  placeholder?: string;
  scrollToSelected?: boolean;
  optionsPickerStyle?: CSSProperties;
  pickerCustomIcon?: string;
  pickerStyle?: DropdownPickerStyle;
  pickerCustomStyle?: CSSProperties;
  optionsContainerStyle?: CSSProperties;
  enableSearch?: boolean;
  onSearch?: (search: string) => void;
  styles?: {
    pickerActiveStyle?: CSSProperties;
    optionsPickerActiveStyle?: CSSProperties;
    searchContainer?: string;
    optionListClassName?: string;
    optionClassName?: string;
    optionTitleClassName?: string;
    placeHolderClassName?: string;
  };
};

export default function DropdownPicker<T>({
  options,
  isSelected,
  titles,
  enabled,
  onChange,
  optionRenderer,
  selectionHeaderRenderer,
  multiValue = false,
  placeholder,
  scrollToSelected,
  optionsPickerStyle,
  pickerCustomIcon,
  pickerStyle,
  pickerCustomStyle,
  optionsContainerStyle,
  enableSearch = false,
  onSearch,
  styles,
}: DropdownPickerProrps<T>) {
  const [showPicker, setShowPicker] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const ref = useRef(null);
  useOutsideRef(ref, () => setShowPicker(false));

  useEffect(() => {
    if (!enableSearch) {
      return;
    }
    if (searchValue && onSearch) {
      onSearch(searchValue);
    }
  }, [searchValue, enableSearch]);

  function onChooseOption(option: T) {
    setSearchValue("");
    onChange(option);
    if (!multiValue) {
      setShowPicker(false);
    }
  }

  function renderOptions() {
    const filteredOptions = options.filter((option) => {
      if (!searchValue) {
        return true;
      }
      return titles(option).toLowerCase().includes(searchValue.toLowerCase());
    });
    return filteredOptions.map((option, index) => {
      const selected = isSelected(option);
      const {
        element,
        enabled,
        customStyle = {},
        handlesSelectedState = false,
        wrapWithContainer = true,
      } = optionRenderer ? optionRenderer({ option, selected }) : { element: null, enabled: true };
      const title = element ?? titles(option);

      if (!wrapWithContainer) {
        return (
          <div id={`${option}`} key={index} onClick={() => enabled && onChooseOption(option)}>
            {element}
          </div>
        );
      }

      return (
        <div
          id={`${option}`}
          key={index}
          className={cn(style.option, styles?.optionClassName ?? "", {
            [style.selected]: selected && !handlesSelectedState,
            [style.disabled]: !enabled,
            [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
          })}
          data-selected={selected}
          onClick={() => enabled && onChooseOption(option)}
          style={customStyle}
        >
          <div className={cn(style.optionTitle, styles?.optionTitleClassName ?? "")} title={titles(option)}>
            {title}
          </div>
          {!handlesSelectedState && selected && <img src={getPathPrefix("/images/feature-check-i.svg")} />}
        </div>
      );
    });
  }

  let selectedOptions = options.filter(isSelected);
  // if no placeholder, select first option by default
  if (!placeholder && options.length > 0 && selectedOptions.length === 0) {
    selectedOptions = [options[0]];
  }

  function renderSelectedOptions() {
    if (placeholder && selectedOptions.length === 0) {
      return (
        <div
          style={optionsPickerStyle}
          className={cn(styles?.placeHolderClassName ?? "", style.placeholderTitle, {
            [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
          })}
          title={placeholder}
        >
          {placeholder}
        </div>
      );
    }
    const { element, customIcon } = optionRenderer
      ? optionRenderer({ option: selectedOptions[0], selected: false })
      : { element: null, customIcon: null };
    const selectedTitles = element ?? selectedOptions.map(titles).join(", ");

    if (selectionHeaderRenderer) {
      return selectionHeaderRenderer({ selectedOptions });
    }

    return (
      <div
        style={{ ...optionsPickerStyle, ...pickerCustomStyle }}
        className={style.selectedTitle}
        title={typeof selectedTitles === "string" ? selectedTitles : titles(selectedOptions[0])}
      >
        {customIcon && <img className={style.customIcon} src={customIcon} />}
        {selectedTitles}
      </div>
    );
  }

  function renderIcon() {
    if (!enabled) {
      return null;
    }
    if (pickerCustomIcon) {
      return <img src={pickerCustomIcon} />;
    }
    return (
      <img
        src={getPathPrefix("/images/chevron.svg")}
        className={cn(style.chevron, {
          [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
        })}
      />
    );
  }

  const shouldShowPicker = options.length > 0 && enabled && showPicker;

  return (
    <div className={style.picker} ref={ref}>
      <div
        className={cn(style.selectedOption, {
          [style.disabled]: !enabled,
          [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
        })}
        onClick={() => {
          setShowPicker(!showPicker);
        }}
        style={{ ...pickerCustomStyle, ...(shouldShowPicker ? styles?.pickerActiveStyle : {}) }}
      >
        {renderSelectedOptions()}
        {renderIcon()}
      </div>
      {shouldShowPicker && (
        <OptionsPicker
          containerStyle={{
            ...optionsPickerStyle,
            ...optionsContainerStyle,
            ...(styles?.optionsPickerActiveStyle ?? {}),
          }}
          scrollToSelected={scrollToSelected && selectedOptions.length > 0 ? titles(selectedOptions[0]) : undefined}
          pickerStyle={pickerStyle}
        >
          {enableSearch && (
            <div className={cn(style.searchContainer, styles?.searchContainer ?? "")}>
              <MagnifyingGlassIcon />
              <input
                type="text"
                value={searchValue}
                placeholder="Search"
                autoFocus
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
            </div>
          )}
          <div className={cn(style.optionsList, styles?.optionListClassName ?? "")}>{renderOptions()}</div>
        </OptionsPicker>
      )}
    </div>
  );
}
