import { ConnectorLine } from "./connector-line";
import { ConnectorEndpoint, PathMetrics } from "./connector-utils";
import { computeConnectorDrawingData } from "./connector-drawing-utils";
import React, { forwardRef, ReactNode, Ref, useMemo } from "react";
import Konva from "konva";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";
import { parseStrokeWidth } from "shared/util/utils";
import { Group } from "react-konva";
import { normalPoints } from "frontend/geometry/normalAt";
import { Radians, toDegrees } from "utils/transform";

const DISTANCE_FROM_ANCHOR = 0.005;

const SimpleConnector = forwardRef(
  (
    {
      id,
      p1,
      p2,
      lineType,
      element,
      curveStrength,
      overlayNodes,
    }: {
      id: string;
      p1: ConnectorEndpoint;
      p2: ConnectorEndpoint;
      lineType: "line" | "curve" | "elbow";
      element: any;
      curveStrength?: number;
      overlayNodes?: {
        node: ((offset: { x: number; y: number }) => ReactNode | null) | ReactNode;
        rotatable: boolean;
        relativePosition?: number;
      }[];
    },
    ref: Ref<Konva.Shape>
  ) => {
    const startBbox = { ...p1, width: 0, height: 0 };
    const endBbox = { ...p2, width: 0, height: 0 };
    const startRotation = p1.rotation ?? 0;
    const endRotation = p2.rotation ?? 0;

    const memoizedElement = useDeepEqualMemo({
      lineType,
      innerPoints: element.innerPoints,
      strokeWidth: parseStrokeWidth(element.strokeWidth),
      scaleX: element.scaleX,
      scaleY: element.scaleY,
      rotate: element.rotate,
      anchorsMode: element.anchorsMode,
      pointerStyles: element.pointerStyles,
    });

    const data = useMemo(
      () =>
        computeConnectorDrawingData(
          p1,
          p2,
          memoizedElement,
          startBbox,
          endBbox,
          startRotation,
          endRotation,
          curveStrength
        ),
      [p1, p2, memoizedElement, startBbox, endBbox, startRotation, endRotation, curveStrength]
    );

    const overlayNode = useMemo(() => {
      if (!overlayNodes) {
        return null;
      }

      return overlayNodes.map(({ node, rotatable, relativePosition }, index) => {
        const metrics = new PathMetrics(data);
        const anchorPointRelativePosition = relativePosition ?? 0.5;
        const anchorPoint = metrics.getPointAlongPath(anchorPointRelativePosition);

        const anchorPointStart = metrics.getPointAlongPath(anchorPointRelativePosition - DISTANCE_FROM_ANCHOR);
        const anchorPointEnd = metrics.getPointAlongPath(anchorPointRelativePosition + DISTANCE_FROM_ANCHOR);

        if (!rotatable) {
          return (
            <Group id={id} x={anchorPoint[0]} y={anchorPoint[1]} key={index}>
              {typeof node === "function"
                ? node({
                    x: anchorPoint[0],
                    y: anchorPoint[1],
                  })
                : node}
            </Group>
          );
        }

        const rotation = toDegrees(
          normalPoints(
            {
              x: anchorPointStart[0],
              y: anchorPointStart[1],
            },
            {
              x: anchorPointEnd[0],
              y: anchorPointEnd[1],
            }
          ) as Radians
        );

        return (
          <Group id={id} x={anchorPoint[0]} y={anchorPoint[1]} rotation={rotation} key={index}>
            {typeof node === "function"
              ? node({
                  x: anchorPoint[0],
                  y: anchorPoint[1],
                })
              : node}
          </Group>
        );
      });
    }, [data, id, overlayNodes]);

    return (
      <>
        <ConnectorLine p1={p1} p2={p2} id={id} element={element} data={data} ref={ref} />
        {overlayNode}
      </>
    );
  }
);

SimpleConnector.displayName = "SimpleConnector";
export default SimpleConnector;
