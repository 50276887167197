import React, { useRef, useState } from "react";
import collaborationStyle from "./collaboration-modal.module.css";
import style from "./new-collaboration-modal.module.css";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import CollaborationHeader from "./collaboration-header";

export default function NewCollaborationModal({
  type,
  subtitle,
  inputPlaceholder,
  onDismiss,
  onCreateTeam,
  onCreateProject,
}: {
  type: any;
  subtitle: string;
  inputPlaceholder: string;
  onDismiss: () => void;
  onCreateTeam?: (name: string) => void;
  onCreateProject?: (name: string) => void;
}) {
  const [name, setName] = useState<string>("");

  const modalRef = useRef(null);

  return (
    <div className={collaborationStyle.container} ref={modalRef}>
      <CollaborationHeader title={`Create new ${type}`} subtitle={subtitle} onDismiss={onDismiss} />
      <div className={style.inviteField}>
        <form
          className={style.nameInput}
          onSubmit={(e) => {
            e.preventDefault();
            onCreateTeam && onCreateTeam(name);
            onCreateProject && onCreateProject(name);
            onDismiss();
          }}
        >
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={inputPlaceholder}
            autoFocus={true}
            autoComplete="off"
          />
        </form>
        <StyledButton
          title={`Create ${type}`}
          style={ButtonStyle.filled}
          size={ButtonSize.regular}
          customStyle={{ width: "130px", fontWeight: 500, letterSpacing: "0.124px", alignSelf: "end" }}
          onClick={() => {
            onCreateTeam && onCreateTeam(name);
            onCreateProject && onCreateProject(name);
            onDismiss();
          }}
        />
      </div>
    </div>
  );
}
