import style from "./onboarding-button.module.css";
import classNames from "classnames/bind";

type OnboardingButtonProps = {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export default function OnboardingButton({ onClick, title, disabled, isLoading }: OnboardingButtonProps) {
  return (
    <button
      disabled={disabled || isLoading}
      className={classNames(style.button, { [style.disabled]: disabled })}
      onClick={onClick}
      data-testid="onboarding-button"
    >
      {isLoading && <div className={style.shimmer} />}
      {isLoading ? "Loading..." : title}
    </button>
  );
}
